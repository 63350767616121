import React, { useMemo, useCallback } from "react";
import { StyledTable } from "../../../components/common/table/styles";
import { RightOutlined } from "@ant-design/icons";
import { EmptyData } from "../../../components/common/empty";
import { StyledTag } from "../../../components/common/tag/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  capitalizeFirstLetter,
  formatDateToLocalTime,
} from "../../../utils/helper";

interface BulkImportTableProps {
  setDetailsData: (data: Record<string, any>) => void;
}

interface TableData {
  key: string;
  entity: string;
  createdAt: string;
  upDatedAt: string;
  status: string;
}

const BulkImportTable: React.FC<BulkImportTableProps> = ({
  setDetailsData,
}) => {
  const bulkData = useSelector(
    (state: RootState) => state.adminCenter.importData
  );

  const bulkImportData = useMemo(() => {
    return [...bulkData].sort((a, b) => {
      const dateA = new Date(a.createdAt)?.getTime();
      const dateB = new Date(b.createdAt)?.getTime();
      return dateB - dateA; // Ascending order
    });
  }, [bulkData]);
  
  

  const columns = useMemo(
    () => [
      {
        title: "Import Type",
        dataIndex: "entity",
        key: "entity",
        render: (entity: string) => capitalizeFirstLetter(entity),
      },
      {
        title: "Started At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => formatDateToLocalTime(createdAt),
      },
      {
        title: "Completed At",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (_:any,record:any) => {
          return record?.status !== "PREVIEW"
            ? formatDateToLocalTime(record?.updatedAt)
            : null ;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status: string) => (
          <StyledTag
            style={{ letterSpacing: "0.2px" }}
            color={
              status === "FAILED"
                ? "red"
                : status === "PREVIEW"
                ? "purple"
                : status === "COMPLETED"
                ? "green"
                : status === "QUEUED"
                ? "default"
                :status === "IN_PROGRESS"
                ? "blue"
                : "orange"
            }
          >
            • {status === "PREVIEW" ? "Draft" : capitalizeFirstLetter(status)}
          </StyledTag>
        ),
      },
      {
        dataIndex: "action",
        render: () => <RightOutlined />,
      },
    ],
    []
  );

  const handleRowClick = useCallback(
    (record: TableData) => {
      setDetailsData(record);
    },
    [setDetailsData]
  );

  return bulkData.length ? (
    <StyledTable
      columns={columns}
      dataSource={bulkImportData}
      pagination={false}
      onRow={(record: TableData) => ({
        onClick: () => handleRowClick(record),
      })}
      isCursorPointerShow
    />
  ) : (
    <EmptyData description="No Records Found" />
  );
};

export default BulkImportTable;
