import {
  Button,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
} from "antd";
import {
  DeleteIconDiv,
  NestedCard,
  StyledRadio,
  UsageBasedAddPriceContainer,
} from "../styles";
import { useEffect, useMemo, useState } from "react";
import Extra from "../../../../../../components/common/extra";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import PriceModal from "./PriceModal";
import GroupByTag from "../../../../../../components/GroupTag.tsx";
import { useSelector } from "react-redux";

interface Field {
  id: number;
}

const UsageBasedAddPrice: React.FC<any> = ({
  isGroupFieldShow = false,
  form,
  editCardData,
}) => {
  const [priceModal, setPriceModal] = useState<
    "STANDARD" | "TIERED" | "VOLUME"
  >("STANDARD");
  const [isPriceLimitConfigurationShown, setIsPriceLimitConfigurationShown] =
    useState(false);
  const [priceModalFields, setPriceModalFields] = useState<Field[]>([]);

  const { billingDetails } = useSelector((state: any) => state.pricePlan);
  const [tagValue, setTagValue] = useState("");
  const [firstUnitDefaultValue, setFirstUnitDefaultValue] = useState("");
  const priceModalChangeHandler = (e: RadioChangeEvent) => {
    setPriceModal(e.target.value);
  };

  const lastUnitChangeHandler = (e: any) => {
    setPriceModal(e.target.value);
  };

  const isPriceLimitConfigurationHandler = () => {
    setIsPriceLimitConfigurationShown(!isPriceLimitConfigurationShown);
  };
  const handleAddField = () => {
    setPriceModalFields((prevFields) => [...prevFields, { id: Date.now() }]);
  };

  const handleRemoveField = (id: number) => {
    setPriceModalFields((prevFields) =>
      prevFields.filter((field) => field.id !== id)
    );
  };

  useEffect(() => {
    if (editCardData) {
      setPriceModal(editCardData?.pricingModal ?? "STANDARD");
      const ids: any = [];
      Object.keys(editCardData).forEach((key) => {
        if (key.startsWith("firstUnit")) {
          const index = Number(key.split("-")[1]);
          ids.push(index);
        }
      });
      if (ids.length) {
        const data = ids.slice(1, ids.length - 1);
        data.map((i: any) =>
          setPriceModalFields((prev) => [...prev, { id: i }])
        );
      }

      setIsPriceLimitConfigurationShown(
        editCardData?.[
          `${billingDetails.supportedCurrencies[0]}-minPriceLimit`
        ] ??
          editCardData?.[
            `${billingDetails.supportedCurrencies[0]}-maxPriceLimit`
          ] ??
          false
      );
    }
  }, [editCardData, billingDetails]);

  const pricingModalInitialValues =useMemo(()=>{
    return form.getFieldValue('pricingModal') || priceModal
  },[priceModal,form])
  
 
  // useEffect(() => {
  //   return () => {
  //     setPriceModal("STANDARD");
  //   };
  // }, []);

  const renderFields = (fields: Field[], removeField: (id: number) => void) => (
    <>
      {fields.map((field, i) => (
        <PriceModal
          lastUnitChangeHandler
          form={form}
          editCardData={editCardData}
          isPriceRangeShown={true}
          index={i + 3}
          removeField={removeField}
          id={field.id}
          uniqueID={i + 1}
        />
      ))}
    </>
  );

  return (
    <UsageBasedAddPriceContainer>
      <NestedCard
        maxWidth="890px"
        style={{ width: pricingModalInitialValues === "STANDARD" ? "680px" : "890px" }}
      >
        <Form.Item
          label="Pricing Model"
          name="pricingModal"
          style={{ marginBottom: "15px" }}
          initialValue={priceModal}
          rules={[
            {
              required: true,
              message: "Please Select Pricing Model!",
            },
          ]}
          extra={
            <Extra
              text={
                pricingModalInitialValues === "STANDARD"
                  ? "Simple model with a fixed flat/per unit/package price"
                  : pricingModalInitialValues === "TIERED"
                  ? " The amount to charge varies incrementally as usage increases, different units may be at different prices depending on the tier they fall into. We also support defining distinct pricing types for each slab."
                  : "Similar to the tiered pricing model, except that only one slab is applied at the end of the billing period based on the total usage units."
              }
            />
          }
        >
          <StyledRadio
            defaultValue={pricingModalInitialValues}
            onChange={priceModalChangeHandler}
          >
            <Radio value="STANDARD">Standard</Radio>
            <Radio value="TIERED">Tiered</Radio>
            <Radio value="VOLUME">Volume</Radio>
          </StyledRadio>
        </Form.Item>
        <PriceModal
          lastUnitChangeHandler
          form={form}
          editCardData={editCardData}
          uniqueID={0}
          isPriceRangeShown={pricingModalInitialValues === "STANDARD" ? false : true}
        />
        {pricingModalInitialValues !== "STANDARD" && (
          <>
            {renderFields(priceModalFields, handleRemoveField)}
            <PriceModal
              editCardData={editCardData}
              form={form}
              lastUnitChangeHandler
              uniqueID={priceModalFields?.length + 1}
              isPriceRangeShown={true}
              index={2}
            />
            <p>
              <Button
                type="link"
                style={{
                  color: "black",
                  cursor: "pointer",
                  marginTop: "1rem",
                }}
                icon={<PlusOutlined />}
                onClick={handleAddField}
              >
                Add another slab
              </Button>
            </p>
          </>
        )}
        <Divider dashed />
        {isPriceLimitConfigurationShown ? (
          <div>
            <div className="priceLimitHeader">
              <p>Price Limit for Rate Card</p>
              <DeleteIconDiv onClick={isPriceLimitConfigurationHandler}>
                <DeleteOutlined />
              </DeleteIconDiv>
            </div>
            {billingDetails?.supportedCurrencies?.length > 0 &&
              billingDetails?.supportedCurrencies.map((currency: any) => (
                <div className="priceLimitFields">
                  <Form.Item
                    name={`${currency}-minPriceLimit`}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const maxValue = getFieldValue(
                            `${currency}-maxPriceLimit`
                          );
                          if (value && maxValue) {
                            if (Number(value) <= Number(maxValue)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "Min price must be less than or equal to max price"
                              )
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Min Price"
                      type="number"
                      size="small"
                      suffix={currency}
                      onChange={() => {
                        form.validateFields([
                          `${currency}-minPriceLimit`,
                          `${currency}-maxPriceLimit`,
                        ]);
                      }}
                      onWheel={(e: any) => e.target.blur()}
                    />
                  </Form.Item>
                  <Form.Item
                    name={`${currency}-maxPriceLimit`}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const minValue = getFieldValue(
                            `${currency}-minPriceLimit`
                          );
                          if (value && minValue) {
                            if (Number(value) >= Number(minValue)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "Max price must be greater than or equal to min price"
                              )
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Max Price"
                      type="number"
                      size="small"
                      suffix={currency}
                      onChange={() => {
                        form.validateFields([
                          `${currency}-minPriceLimit`,
                          `${currency}-maxPriceLimit`,
                        ]);
                      }}
                      onWheel={(e: any) => e.target.blur()}
                    />
                  </Form.Item>
                </div>
              ))}
            <div style={{ color: "#8693A5" }}>
              <Extra text="These provide minimum and maximum price limits for the rate card. The fields are optional." />
            </div>
          </div>
        ) : (
          <p
            onClick={isPriceLimitConfigurationHandler}
            style={{ cursor: "pointer" }}
            className="addConfigureText"
          >
            <PlusOutlined /> Configure Price Limit for Rate Card
          </p>
        )}
      </NestedCard>
      {isGroupFieldShow && (
        <>
          <GroupByTag />
        </>
      )}
    </UsageBasedAddPriceContainer>
  );
};

export default UsageBasedAddPrice;
