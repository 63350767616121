import { useEffect, useState, useCallback, useMemo } from "react";
import {
  Container,
  DownloadCard,
  Header,
  Label,
  Section,
  StyledSteps,
  TabHeader,
  Value,
} from "./styles";
import CustomButton from "../../../components/common/button";
import CustomDrawer from "../../../components/common/drawer";
import {
  Card,
  Dropdown,
  Form,
  Menu,
  Select,
  Typography,
  UploadFile,
} from "antd";
import Configure from "./Configure";
import MapFields from "./MapFields";
import BulkImportTable from "./BulkImportTable";
import {
  DownloadOutlined,
  FileOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { StyledTag } from "../../../components/common/tag/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  archivedBulkImports,
  downloadS3File,
  getBulkImportById,
  getImports,
  imports,
  importsInitiate,
} from "../../../redux/feature/adminCenter/adminCenterAsyncThunk";
import { setCsvFile } from "../../../redux/feature/adminCenter/adminCenterSlice";
import Button from "../../../components/common/button";
import { formatDateToLocalTime, requiredFields } from "../../../utils/helper";
import { getEvents } from "../../../redux/feature/events/eventThunk";

const { Title, Paragraph } = Typography;

type DetailsData = {
  status?: string;
  key?: string;
  entity?: string;
  importId?: string;
  createdAt?: string;
  updatedAt?: string;
  files?: any;
  stats?: {
    processedRecords: null;
    successfulRecords: null;
    failedRecords: null;
  };
};

const BulkImport = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [importType, setImportType] = useState<string>("");
  const [fieldOption, setFieldOption] = useState<string>("");
  const [selectedImportId, setSelectedImportId] = useState<any>("");
  const [eventSchema, setEventSchema] = useState(null);
  const events = useSelector((state: RootState) => state.event?.events);
  const [file, setFile] = useState<any>(null);
  const [detailsData, setDetailsData] = useState<DetailsData | null>(null);

  const detailStatus = useMemo(() => {
    return detailsData?.status;
  }, [detailsData]);

  const modalOpen = useSelector(
    (state: RootState) => state.adminCenter.isBulkImportCsvModalOpen
  );
  const loading = useSelector((state: RootState) => state.adminCenter.loading);
  const { Text } = Typography;
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const csvFile = useSelector((state: RootState) => state.adminCenter.csvFile);
  useEffect(() => {
    dispatch(getImports());
  }, []);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
    setCurrentStep(0);
    setFile(null);
    dispatch(setCsvFile(null));
    setImportType("");
    setEventSchema(null);
    form.resetFields();
    if (detailsData) {
      setDetailsData(null);
    }
  };

  useEffect(() => {
    if (!modalOpen) {
      setIsModalOpen(false);
    }
  }, [modalOpen]);

  useEffect(() => {
    if ((!events || events?.length === 0) && importType === "EVENTS") {
      dispatch(
        getEvents({
          sort: "-updated_at",
        })
      );
    }
  }, [importType]);

  const handleStepChange = useCallback(async (step: number) => {
    try {
      await form.validateFields();
      setCurrentStep(step);
    } catch (error) {}
  }, []);

  const downloadHandler = async (id: string) => {
    try {
      const response: any = await dispatch(downloadS3File(id)).unwrap();
      const fileUrl = response[0]?.s3url;

      if (fileUrl) {
        const anchor = document.createElement("a");
        anchor.href = fileUrl;
        anchor.download = "";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor); // Clean up the DOM
      } else {
        console.error("File URL not found in response:", response);
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const handleNextStep = useCallback(
    async (detailStatus: any) => {
      if (detailStatus === "PREVIEW") {
        setCurrentStep(2);
      } else {
        try {
          const value = await form.validateFields();
          await uploadCsvFile(value);
        } catch (error) {}
      }
    },
    [file]
  );

  const uploadCsvFile = async (value: any) => {
    try {
      const formData = new FormData();
      formData.append("csvFile", file);
      formData.append("entity", value.importType);
      if (value.importType === "EVENTS") {
        const additionalParams = JSON.stringify([
          { key: "schemaName", value: value.eventSchema },
        ]);
        formData.append("additionalParams", additionalParams);
      }

      await dispatch(imports(formData))
        .unwrap()
        .then((res: any) => {
          setFieldOption(res?.userDefinedColumns);
          setSelectedImportId(res?.importId);
          setCurrentStep(1);
        });
      dispatch(setCsvFile(undefined));
    } catch (error) {
      console.error("Failed to upload CSV file:", error);
      dispatch(setCsvFile(undefined));
    }
  };

  const handleSubmit = async () => {
    try {
      const data = await form.validateFields();

      const columnMappings = Object.keys(data).map((key) => ({
        defaultColumnName: key,
        userDefinedColumnName: data[key],
        isRequired: requiredFields.includes(key) || false,
      }));
      await dispatch(
        importsInitiate({
          importId: selectedImportId,
          columnMappings: columnMappings,
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      toggleModal();
    }
  };

  useEffect(() => {
    if (detailStatus === "PREVIEW") {
      setIsModalOpen(true);
      form.setFieldValue("importType", detailsData?.entity);
      setImportType(detailsData?.entity || "");
      setFile({ name: detailsData?.files?.inputFile });
      setSelectedImportId(detailsData?.importId);
      setCurrentStep(1);
      //@ts-ignore
      setFieldOption(detailsData?.userDefinedColumns);
      //@ts-ignore
      setEventSchema(detailsData?.additionalParams[0]?.value);
    }
  }, [detailsData]);

  const handleArchive = (e: any) => {
    try {
      dispatch(archivedBulkImports(detailsData?.importId));
    } catch (error) {
      console.error("Failed to archive import:", error);
    } finally {
      toggleModal();
    }
  };

  const handleRefreshStatus=(importId:any)=>{
    try {
      dispatch(getBulkImportById(importId)).then((res) => {
        console.log("►►► ~ dispatch ~ res:", res)

      	setDetailsData(res.payload)
      })
    } catch (error) {
      console.error("Failed to refresh import status:", error);
    }
  }

  const menu = useMemo(() => {
    return (
      <Menu onClick={(e) => handleArchive(e)}>
        <Menu.Item key="Archive">Archive</Menu.Item>
      </Menu>
    );
  }, [detailsData]);

  return (
    <Container>
      <Header>
        <TabHeader>Bulk Imports</TabHeader>
        <CustomButton
          type="primary"
          text="New Import"
          position="start"
          onClick={toggleModal}
        />
      </Header>

      <BulkImportTable setDetailsData={setDetailsData} />

      <CustomDrawer
        onClose={toggleModal}
        onFinish={() =>
          currentStep === 0 ? handleNextStep(detailStatus) : handleSubmit()
        }
        submitText={detailStatus === "PREVIEW" ? "Start Importing" : "Proceed"}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {detailStatus === "PREVIEW"
                ? "Edit New Bulk Import"
                : "New Bulk Import"}
            </div>
            {detailStatus === "PREVIEW" && (
              <div>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <MoreOutlined />
                </Dropdown>
              </div>
            )}
          </div>
        }
        isOpen={isModalOpen}
        width={480}
        loading={loading}
      >
        <div style={{ padding: "24px" }}>
          <StyledSteps
            size="small"
            current={currentStep}
            items={[{ title: "Configure" }, { title: "Map Fields" }]}
            onChange={handleStepChange}
          />
          <Form requiredMark={false} layout="vertical" form={form}>
            {currentStep === 0 ? (
              <Configure
                file={file}
                setFile={setFile}
                importType={importType}
                setImportType={setImportType}
                setFieldOption={setFieldOption}
                detailsData={detailsData}
                setEventSchema={setEventSchema}
                eventSchema={eventSchema}
              />
            ) : (
              <MapFields
                file={file}
                importType={importType}
                fieldOption={fieldOption}
                eventSchema={eventSchema}
              />
            )}
          </Form>
        </div>
      </CustomDrawer>

      {detailsData && detailStatus !== "PREVIEW" && (
        <CustomDrawer
          onClose={() => setDetailsData(null)}
          isOpen={!!detailsData}
          isActionButtonsShown={false}
          title={
            <div>
              <span>Import Details</span>
              <p>{detailsData?.importId}</p>
            </div>
          }
          width={432}
        >
          <Container>
            {(detailStatus === "IN_PROGRESS" || detailStatus === "QUEUED") && (
              <div style={{ textAlign: "right" }}>
                <CustomButton
                  isGrey
                  text="Refresh Status"
                  icon={<ReloadOutlined spin={loading}/>}
                  position="end"
                  onClick={()=>{
                    handleRefreshStatus(detailsData?.importId)
                  }}
                />
              </div>
            )}
            <Form.Item label="Uploaded File" layout="vertical">
              <div className="uploadedFile">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FileOutlined style={{ marginRight: "8px" }} />
                  <Text>{detailsData?.files?.inputFile}</Text>
                </div>
                {detailStatus !== "PREVIEW" && (
                  <DownloadOutlined
                    onClick={() =>
                      detailsData.importId &&
                      downloadHandler(detailsData.importId)
                    }
                  />
                )}
              </div>
            </Form.Item>
            <Section>
              <Label>Type</Label>
              <Value>{detailStatus}</Value>
            </Section>
            <Section>
              <Label>Import Started At</Label>
              <Value>
                {formatDateToLocalTime(detailsData?.createdAt) ?? "-"}
              </Value>
            </Section>
            <Section>
              <Label>Import Completed At</Label>
              <Value>
                {formatDateToLocalTime(detailsData?.updatedAt) ?? "-"}
              </Value>
            </Section>
            <Section>
              <Label>Status</Label>
              <StyledTag
                style={{ letterSpacing: "0.2px" }}
                color={
                  detailStatus === "FAILED"
                    ? "red"
                    : detailStatus === "PREVIEW"
                    ? "purple"
                    : detailStatus === "COMPLETED"
                    ? "green"
                    : detailStatus === "QUEUED"
                    ? "default"
                    : "orange"
                }
              >
                {" "}
                • {detailStatus === "PREVIEW" ? "Draft" : detailStatus}
              </StyledTag>
            </Section>
            <Section>
              <Label>Processed Records</Label>
              <Value>{detailsData?.stats?.processedRecords ?? "-"}</Value>
            </Section>
            <Section>
              <Label>Successful Records</Label>
              <Value>{detailsData?.stats?.successfulRecords ?? "-"}</Value>
            </Section>
            <Section>
              <Label>Failed Records</Label>
              <Value>{detailsData?.stats?.failedRecords ?? "-"}</Value>
            </Section>
            {detailStatus !== "PREVIEW" && detailStatus !== "ARCHIVED" && (
              <Card
                style={{
                  maxWidth: 400,
                  borderColor: "#cbd5e1",
                  borderWidth: "2px",
                }}
                bordered={true}
                bodyStyle={{ padding: "16px" }}
              >
                <DownloadCard
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "8px",
                    marginBottom: "8px",
                    borderBottom: "2px solid #cbd5e1",
                  }}
                >
                  <InfoCircleOutlined style={{ fontSize: "14px" }} />
                  <Title
                    level={5}
                    style={{
                      letterSpacing: "0.15px",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginLeft: "8px",
                      marginBottom: 0,
                    }}
                  >
                    DOWNLOAD RESULTS
                  </Title>
                </DownloadCard>
                <Paragraph
                  style={{ letterSpacing: "0.15px", marginBottom: "8px" }}
                >
                  Download the CSV containing the results of this import action.
                </Paragraph>
                <Button
                  icon={<DownloadOutlined />}
                  type="default"
                  onClick={() =>
                    detailsData.importId &&
                    downloadHandler(detailsData.importId)
                  }
                  text="Download CSV"
                />
              </Card>
            )}
          </Container>
        </CustomDrawer>
      )}
    </Container>
  );
};

export default BulkImport;
