import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Tag,
} from "antd";
import NewItemCard from "../../../../components/common/card/NewItem";
import CustomDrawer from "../../../../components/common/drawer";
import CustomButton from "../../../../components/common/button";
import {
  AddItemCard,
  NewEventSchemaContainer,
} from "../../../events/schemas/newEventSchema/styles";
import FilterConditionsForm from "../addFilterConditionsForm";
import { StyledCard } from "../../../../components/common/card/NewItem/styles";
import PerUnitCalculationFilter from "../perUnitCalculationFilter";
import PageHeader from "../../../../components/common/pageHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { getEvents } from "../../../../redux/feature/events/eventThunk";
import {
  activeUsageMeter,
  createUsageMeters,
  getUsageMeter,
  getUsageMeters,
} from "../../../../redux/feature/billableItems/billableThunk";
import { useNavigate, useParams } from "react-router-dom";
import {
  capitalizeFirstLetter,
  transformUsageMeterFormValues,
} from "../../../../utils/helper";
import PageLoading from "../../../../components/Loader";
import AlertModal from "../../../../components/common/alertModal";
import { StyledTag } from "../../../../components/common/tag/styles";

interface Field {
  id: number;
}
const NewUsageMeter = () => {
  const [attributeFields, setAttributeFields] = useState<Field[]>([]);
  const [calculationConditionFields, setCalculationConditionFields] = useState<
    Field[]
  >([]);
  const [isEventSchemaShown, setIsEventSchemaShown] = useState(false);
  const [changeComputation, setChangeComputation] = useState(false);
  const [isSourceDrawerOpen, setIsSourceDrawerOpen] = useState(false);
  const [codePreview, setCodePreview] = useState("attributes['at1']");
  const [isConformationModalOpen, setIsConformationModalOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [initValues, setInitValues] = useState<any>();
  console.log("🚀 ~ NewUsageMeter ~ initValues:", initValues);
  const dispatch = useDispatch<AppDispatch>();

  const { currentUsageMeter, loading } = useSelector(
    (state: RootState) => state.billable
  );
  console.log("🚀 ~ NewUsageMeter ~ loading:", loading);
  console.log("🚀 ~ NewUsageMeter ~ currentUsageMeter:", currentUsageMeter);
  const conformationModalHandler = async () => {
    try {
      const data = await form.validateFields();
      if (data) {
        setIsConformationModalOpen(!isConformationModalOpen);
      }
    } catch (e) {
      console.log(
        "��� ~ file: index.tsx:127 ~ conformationModalHandler ~ e:",
        e
      );
    }
  };
  const { id } = useParams();
  console.log("🚀 ~ NewUsageMeter ~ id:", id);

  useEffect(() => {
    if (currentUsageMeter && id && initValues) {
      if (currentUsageMeter?.eventSchemaId) {
        setIsEventSchemaShown(true);
        handleEventSelect(currentUsageMeter.eventSchemaId);
      }
      setAggregationType(currentUsageMeter?.aggregation);
      setAttributeValueOfPerUnitCalculation(initValues.chooseAttribute);
    }
  }, [currentUsageMeter, dispatch, initValues]);

  useEffect(() => {
    console.log(
      "🚀 ~ useEffect ~ currentUsageMeter?.usageMeterId:",
      currentUsageMeter?.usageMeterId
    );
    console.log("🚀 ~ useEffect ~ id:", id);
    if (id && currentUsageMeter?.usageMeterId) {
      const data = transUsageValuesReverse(currentUsageMeter);
      setInitValues(data);
    }
    if (id && currentUsageMeter?.usageMeterId !== id) {
      dispatch(getUsageMeter(id))
        .unwrap()
        .then((res) => {
          console.log("🚀 ~ .then ~ res:", res);
          const data = transUsageValuesReverse(res);
          setInitValues(data);
        })
        .catch((e) => {
          console.log("🚀 ~ file: index.tsx:85 ~ useEffect ~ e:", e);
          message.error("Failed to fetch event details.");
        });
    }
  }, []);

  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [eventList, setEventList] = useState([]);
  const [attributeDimension, setAttributeDimension] = useState<any>([]);
  const [attributeList, setAttributeList] = useState<any>([]);
  const [aggregationType, setAggregationType] = useState<
    "count" | "sum" | null
  >(null);
  const [
    attributeValueOfPerUnitCalculation,
    setAttributeValueOfPerUnitCalculation,
  ] = useState(null);
  const { events } = useSelector((state: RootState) => state.event);

  useEffect(() => {
    if (!events || events?.length === 0) {
      dispatch(
        getEvents({
          sort: "-updated_at",
        })
      );
    }
  }, [dispatch]);

  useMemo(() => {
    if (events && events.length > 0) {
      const eventsData = events.map((event: any) => ({
        value: event?.event_schema_id,
        label:
          event?.status === "INACTIVE"
            ? `${event?.name} (${capitalizeFirstLetter(event?.status)})`
            : event?.name,
        disabled: event?.status === "INACTIVE",
      }));
      setEventList(eventsData);
    }
  }, [events]);

  useMemo(() => {
    const atr =
      attributeValueOfPerUnitCalculation ??
      form.getFieldValue("chooseAttribute");
    let tempStr = `attributes['${atr}]`;

    calculationConditionFields?.forEach((field) => {
      const operator = form.getFieldValue(`computationOperator-${field.id}`);
      const attribute = form.getFieldValue(`computationAttribute-${field.id}`);
      if (!operator || !attribute) return;
      tempStr = "(" + tempStr + ` ${operator} ` + `attributes['${attribute}'])`;
    });
    setCodePreview(tempStr);
  }, [
    calculationConditionFields,
    changeComputation,
    attributeValueOfPerUnitCalculation,
  ]);

  useMemo(() => {
    if (selectedEvent) {
      const attributesData = selectedEvent?.attributes?.map((attr: any) => ({
        value: attr.name,
        label: attr.name,
      }));
      const dimensionsData = selectedEvent?.dimensions?.map((dim: any) => ({
        value: dim,
        label: dim,
      }));
      setAttributeDimension([
        ...(attributesData ?? []),
        ...(dimensionsData ?? []),
      ]);
      setAttributeList(attributesData);
    }
  }, [selectedEvent]);

  const handleEventSelect = (eventId: any) => {
    const selected = events.find(
      (event: any) => event.event_schema_id === eventId
    );
    setSelectedEvent(selected);
  };

  const DependencyNameData = [{ value: "test", label: "Test" }];

  const sourceDrawerHandler = () => {
    setIsSourceDrawerOpen((prev) => !prev);
  };
  const templateShownHandler = (value: boolean) => {
    setIsEventSchemaShown(value);
    setSelectedEvent(null);
  };

  const handleAddField = (
    setFields: React.Dispatch<React.SetStateAction<Field[]>>
  ) => {
    setFields((prevFields) => [...prevFields, { id: Date.now() }]);
  };
  const onValuesChange = (changedValues: any, allValues: any) => {
    const isNameFilled = !!allValues.name;
    const isAggregationFilled = !!allValues.aggregationType;
    const isEventSchemaIdFilled = !!allValues.eventSchemaId;
    const isFormComplete = (isNameFilled && isAggregationFilled && isEventSchemaIdFilled);
    setIsFormValid(!isFormComplete);
  };
  const handleRemoveField = (
    id: number,
    setFields: React.Dispatch<React.SetStateAction<Field[]>>
  ) => {
    setFields((prevFields) => prevFields.filter((field) => field.id !== id));
  };

  const handleComputationChange = () => {
    setChangeComputation(!changeComputation);
  };

  const addAttributeField = useCallback(
    () => handleAddField(setAttributeFields),
    []
  );
  const addCalculationFilterField = useCallback(
    () => handleAddField(setCalculationConditionFields),
    []
  );
  const removeAttributeField = useCallback(
    (id: number) => handleRemoveField(id, setAttributeFields),
    [attributeFields]
  );
  const removeCalculationConditionField = useCallback(
    (id: number) => handleRemoveField(id, setCalculationConditionFields),
    [calculationConditionFields]
  );

  const renderFields = (
    fields: Field[],
    removeField: (id: number) => void,
    title: string,
    name1: string,
    name2: string,
    name3: string,
    isUnitShow = true
  ) => {
    return fields.map((field, i) => (
      <FilterConditionsForm
        title={title}
        key={field.id}
        id={field.id}
        removeField={removeField}
        isUnitShow={isUnitShow}
        name1={`${name1}-${field.id}`}
        name2={`${name2}-${field.id}`}
        name3={`${name3}-${field.id}`}
        attributeDimension={attributeDimension}
      />
    ));
  };

  const transUsageValuesReverse = (inputObject: any) => {
    console.log("►►► ~ transUsageValuesReverse ~ inputObject:", inputObject);
    const data: any = {};
    let countFlag = 0;
    let cnt1 = 0;
    let cnt2 = 0;

    data.description = inputObject?.description;
    data.billableName = inputObject?.billableName;
    data.aggregationType = inputObject?.aggregation;
    data.eventSchemaId = inputObject?.eventSchemaId;
    data.name = inputObject?.name;
    let attributes: any = [];
    let calculationFields: any = [];

    if (inputObject?.eventSchemaId) {
      setIsEventSchemaShown(true);
      handleEventSelect(inputObject?.eventSchemaId);
    }
    setAggregationType(inputObject?.aggregation);
    setAttributeValueOfPerUnitCalculation(inputObject?.chooseAttribute ?? null);
    function extractConditionsFilterCondition(obj: any) {
      if (obj?.and) {
        obj?.and.forEach(extractConditionsFilterCondition);
      } else {
        const operator = Object.keys(obj)[0];
        const conditionArray = obj[operator];
        const dimension = conditionArray
          ? conditionArray[0]["var"]?.replace("attributes.", "")
          : null;
        const value = conditionArray ? conditionArray[1] : null;
        const compId = Date.now() + cnt1;

        attributes.push({ id: compId });

        data[`operator-${compId}`] = operator;
        data[`chooseAttributeDimension-${compId}`] = dimension;
        data[`value-${compId}`] = value;
        cnt1++;
      }
    }
    setAttributeFields(attributes);

    function extractComputationPerUnit(obj: any) {
      if (!obj || typeof obj !== "object") return;
      const operator = obj ? Object.keys?.(obj)?.[0] : undefined;
      const values = operator ? obj[operator] : [];

      const nextAttribute = values?.[1];
      const baseAttribute = values?.[0];

      if (baseAttribute?.var) {
        if (countFlag === 0) {
          data.chooseAttribute = baseAttribute?.var?.replace("attributes.", "");
        }
      } else {
        extractComputationPerUnit(baseAttribute);
      }
      const compId = Date.now() + cnt2;
      calculationFields.push({ id: compId });

      data[`computationOperator-${compId}`] = operator;
      data[`computationAttribute-${compId}`] = nextAttribute?.var?.replace(
        "attributes.",
        ""
      );

      countFlag++;
      cnt2++;
    }
    setCalculationConditionFields(calculationFields);

    extractConditionsFilterCondition(inputObject?.filterCondition ?? {});
    extractComputationPerUnit(inputObject?.perUnit ?? {});

    return data;
  };

  const onSubmit = async (type: any) => {
    try {
      const data = await form.validateFields();
      const payload = transformUsageMeterFormValues(data);
      if (currentUsageMeter?.status !== "DRAFT") {
        console.log("if runed");
        
        const usageMeterCreated: any = await dispatch(
          createUsageMeters(payload)
        ).unwrap();

        if (type === "publish" && usageMeterCreated?.usageMeterId) {
          if (selectedEvent?.status === "DRAFT") {
            message.error("Active Event Schema Not Found For Usage Meter");
            message.warning("Usage Meter Saved But Not Published");
          } else {
            await dispatch(
              activeUsageMeter({
                usageMeterId: usageMeterCreated?.usageMeterId,
              })
            );
          }
        }
        form.resetFields();

        await dispatch(getUsageMeters({ sort: "-updated_at" }));

        if (usageMeterCreated?.usageMeterId) {
          navigate(`/usage-meter/${usageMeterCreated.usageMeterId}`);
        }
      } else {
        console.log("else runed");
        
        {
          if (selectedEvent?.status === "DRAFT" && type !== "draft") {
            message.error("Active Event Schema Not Found For Usage Meter");
            message.warning("Usage Meter Saved But Not Published");
          } else {
            type !== "draft" &&
              (await dispatch(
                activeUsageMeter({
                  usageMeterId: currentUsageMeter?.usageMeterId,
                })
              ));
          }
        }
        navigate(`/usage-meter/${currentUsageMeter?.usageMeterId}`);
      }
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:180 ~ onSubmit ~ error:", error);
    }
  };

  const renderEventSchemaFormFields = () => (
    <>
      <Form.Item
        label="Usage Meter Name"
        name="name"
        rules={[{ required: true, message: "Please input Usage Meter Name!" }]}
        className="name"
      >
        <Input placeholder="Usage Meter Name" disabled={id ? true : false} />
      </Form.Item>
      <Form.Item
        label="Usage Meter Description"
        name="description"
        className="name"
      >
        <Input.TextArea placeholder="Usage Meter Description" />
      </Form.Item>
      <Form.Item
        label="Billable Name"
        name="billableName"
        extra={
          <>
            <InfoCircleOutlined
              style={{ marginRight: "0.3rem", height: "12px", width: "12px" }}
            />{" "}
            <span>This name will be used in the invoices</span>
          </>
        }
      >
        <Input placeholder="Billable Name" />
      </Form.Item>
    </>
  );

  if (id && !initValues) {
    return <PageLoading />;
  }

  return (
    <NewEventSchemaContainer>
      {loading && <PageLoading />}
      <PageHeader
        title={id ? "Edit Usage Meter" : "Create a new Usage Meter"}
        path="/usage-meter"
      />
      <div className="body">
        <Form
          layout="vertical"
          form={form}
          initialValues={initValues}
          requiredMark={false}
          onValuesChange={onValuesChange}
        >
          <div className="form-body">
            <NewItemCard
              title="Define Usage Meter"
              subtitle="A usage meter aggregates processed events based on conditions, aggregation type and calculations."
            >
              {renderEventSchemaFormFields()}
            </NewItemCard>

            <NewItemCard
              title="Choose Event Schema"
              subtitle="Events ingested via the choosen event schema will also be aggregated for usage computation."
            >
              {isEventSchemaShown ? (
                <>
                  <div className="templateItem">
                    <Form.Item
                      label="Enter the template format"
                      name="eventSchemaId"
                      rules={[
                        {
                          required: true,
                          message: "Please input Format First!",
                        },
                      ]}
                      required={false}
                      layout="vertical"
                      style={{ width: "85%" }}
                    >
                      <Select
                        showSearch
                        onChange={handleEventSelect}
                        options={eventList}
                        placeholder="Event Schema"
                        notFoundContent={"No Data"}
                      />
                    </Form.Item>
                    <div
                      className="deleteIcon"
                      onClick={() => {
                        form.resetFields(['aggregationType']);
                        templateShownHandler(false);
                        setSelectedEvent(null);
                        setAggregationType(null)
                        form.resetFields([
                          "eventSchemaId",
                          ...attributeFields.flatMap((id) => [
                            `chooseAttributeDimension-${id.id}`,
                            `operator-${id.id}`,
                            `value-${id.id}`,
                          ]),
                        ]);
                        setAttributeFields(attributeFields.slice(0, 1));
                        onValuesChange({}, form.getFieldsValue());
                      }}
                    >
                      <DeleteOutlined />
                    </div>
                  </div>
                  {selectedEvent && (
                    <Collapse
                      className="collapse"
                      collapsible="header"
                      expandIconPosition="end"
                      defaultActiveKey={"1"}
                      items={[
                        {
                          key: "1",
                          label: (
                            <div className="flexible header">
                              {" "}
                              <h5 className="items headingText">
                                Attribute {selectedEvent?.attributes?.length}
                              </h5>
                              <h5 className="items headingText">
                                Dimensions {selectedEvent?.dimensions?.length}
                              </h5>
                            </div>
                          ),
                          children: (
                            <div className="flexible">
                              {selectedEvent?.attributes?.map((attr: any) => {
                                return (
                                  <div className="items attributes">
                                    <StyledTag color={"default"}>
                                      {attr?.name}
                                    </StyledTag>
                                    <StyledTag color={"orange"}>
                                      {attr?.default_unit}
                                    </StyledTag>
                                  </div>
                                );
                              })}
                              <Divider type="vertical" />
                              {selectedEvent?.dimensions?.map((dim: any) => {
                                return (
                                  <div className="items">
                                    <StyledTag color={"default"}>
                                      {dim}
                                    </StyledTag>
                                  </div>
                                );
                              })}
                            </div>
                          ),
                        },
                      ]}
                    />
                  )}
                </>
              ) : (
                <div
                  className="configureId"
                  onClick={() => templateShownHandler(true)}
                >
                  <span>
                    <PlusOutlined style={{ marginRight: "0.5rem" }} />
                    Associate Event Schema
                  </span>
                </div>
              )}
            </NewItemCard>
            {selectedEvent && (
              <>
                {" "}
                <NewItemCard
                  title="Set Filter Condition"
                  subtitle="Attributes defined in this Usage meter will be aggregated based on the value chosen here."
                >
                  {renderFields(
                    attributeFields,
                    removeAttributeField,
                    "ATTRIBUTES",
                    "chooseAttributeDimension",
                    "operator",
                    "value"
                  )}
                  <div
                    className="action"
                    onClick={addAttributeField}
                    style={{ cursor: "pointer" }}
                  >
                    <PlusOutlined /> <span>Add Filter</span>
                  </div>
                </NewItemCard>
                <NewItemCard
                  title="Set Aggregation Type"
                  subtitle="A usage meter aggregates processed events based on conditions, aggregation type and calculations."
                >
                  <Form.Item
                    label="Aggregation Type"
                    name="aggregationType"
                    layout="vertical"
                  >
                    <Select
                      placeholder="Aggregation Type"
                      onChange={setAggregationType}
                      options={[
                        { label: "Sum", value: "SUM" },
                        { label: "Count", value: "COUNT" },
                      ]}
                    />
                  </Form.Item>
                </NewItemCard>{" "}
              </>
            )}
            {aggregationType?.toLocaleLowerCase() === "sum" && (
              <StyledCard>
                <Row gutter={[64, 16]}>
                  <Col span={5}>
                    <div className="title">
                      <h3>Per Unit Calculation Function</h3>
                    </div>
                    <div>
                      <p className="subtitle">
                        Add an event level additional calculation for aggregated
                        events
                      </p>
                    </div>
                  </Col>
                  <Col span={19} style={{ display: "flex", gap: "3rem" }}>
                    <AddItemCard
                      title="Per Unit Calculation Functions"
                      bordered={false}
                      style={{ width: "400px" }}
                    >
                      <Form.Item
                        label="Choose Attribute"
                        name="chooseAttribute"
                        rules={[
                          {
                            required: true,
                            message: "Please input Choose Attribute!",
                          },
                        ]}
                        layout="vertical"
                        required={false}
                        style={{ width: "100%", marginBottom: "1rem" }}
                      >
                        <Select
                          placeholder="Choose Attribute"
                          options={attributeList}
                          onChange={setAttributeValueOfPerUnitCalculation}
                        />
                      </Form.Item>
                      {calculationConditionFields.map((field, i) => (
                        <PerUnitCalculationFilter
                          remove={removeCalculationConditionField}
                          key={i}
                          id={field.id}
                          attributeOption={attributeList}
                          name1={`computationOperator-${field.id}`}
                          name2={`computationAttribute-${field.id}`}
                          onChange={handleComputationChange}
                        />
                      ))}

                      <div
                        className="action"
                        onClick={addCalculationFilterField}
                        style={{ marginTop: "1rem" }}
                      >
                        <PlusOutlined /> <span>Add condition</span>
                      </div>
                    </AddItemCard>
                    {attributeValueOfPerUnitCalculation && (
                      <AddItemCard
                        title="CODE PREVIEW"
                        bordered={false}
                        style={{ flex: 1 }}
                      >
                        <Card style={{ width: "100%", boxShadow: "none" }}>
                          <p style={{ margin: 0 }}>{codePreview}</p>
                        </Card>
                      </AddItemCard>
                    )}
                  </Col>
                </Row>
              </StyledCard>
            )}
            <NewItemCard
              title="Configure External Data Sources"
              subtitle="Usage can be ingested and synced up from external sources like JDBC"
            >
              <div className="configureId" onClick={sourceDrawerHandler}>
                <span>
                  <PlusOutlined style={{ marginRight: "0.5rem" }} />
                  Add External Usage Source
                </span>
              </div>
              <CustomDrawer
                title="Configure External Usage Source"
                isOpen={isSourceDrawerOpen}
                onClose={sourceDrawerHandler}
              >
                <h1>test</h1>
              </CustomDrawer>
            </NewItemCard>
          </div>
        </Form>
      </div>
      <div className="footerContent">
        <div className="footerActions">
          <CustomButton
            text="Save As Draft"
            onClick={() => {
              onSubmit("draft");
            }}
            icon={<RightOutlined />}
            position="end"
            isGrey={true}
            loading={loading}
            disabled={initValues ? false : isFormValid}
          />
          <CustomButton
            text="Publish"
            onClick={conformationModalHandler}
            icon={<RightOutlined />}
            position="end"
            loading={loading}
            disabled={initValues ? false : isFormValid}
          />
        </div>
      </div>
      <AlertModal
        open={isConformationModalOpen}
        close={conformationModalHandler}
        title="Activate Usage Meter?"
        subtitle="Once the Usage Meter is activated, it cannot be edited again. Do you want to proceed?"
        submitText="Activate"
        onSubmit={() => {
          onSubmit("publish");
        }}
      />
    </NewEventSchemaContainer>
  );
};

export default NewUsageMeter;
