import React, { Children, useEffect, useMemo, useState } from "react";
import {
  Layout,
  Button,
  Breadcrumb,
  Typography,
  Avatar,
  Space,
  Col,
  Row,
  Popconfirm,
  Tabs,
  Table,
  Input,
  Card,
  Divider,
} from "antd";
import {
  CaretDownOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  CustomPopconfirm,
  CustomTabs,
  HeaderStyles,
  LayoutStyles,
  TitleStyles,
} from "./styles";
import UserAvatar from "../../../components/common/avatar";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/common/button";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  setIsEditAccount,
  setIsEditCustomer,
  setIsNewCustomer,
} from "../../../redux/feature/customer/customerSlice";
import EditCustomer from "./EditCustomer";
import {
  deleteCustomer,
  getAllCustomerAccounts,
  getCustomerById,
} from "../../../redux/feature/customer/customerAsyncThunk";
import CustomerDetails from "./customerDetails";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SettingsPage from "./setting";
import Contacts from "./contacts/Contacts";
import Modal from "../../../components/common/modal";
import PageLoading from "../../../components/Loader";
import PageLayout from "../../../components/common/pageLayout";
import AlertModal from "../../../components/common/alertModal";

const { Text } = Typography;
const ViewCustomerDetails = () => {
  const { viewCustomerDetails, filter} = useSelector(
    (state: RootState) => state.customer
  );
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const param = useParams();
  console.log("►►► ~ ViewCustomerDetails ~ param:", param);

  const [isDeleteModal, setIsDeleteModal] = useState(false);

  useEffect(() => {

    const handler = setTimeout(() => {
      dispatch(
        getCustomerById({
          search: searchValue,
          customer_id: param?.customerId,
        })
      );
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  
  }, [searchValue,param?.customerId]);

  const items = [
    {
      label: `Customer Details`,
      key: "1",
      children:<CustomerDetails  setSearchValue={setSearchValue}/>,
    },
  ];

  const header = (
    <HeaderStyles>
      <div
        className="leftIcon"
        onClick={() =>  navigate(-1)}
        style={{ cursor: "pointer" }}
      >
        <LeftOutlined className="icon" />
      </div>
      <Space size="middle" align="center" style={{ marginLeft: 16 }}>
        <UserAvatar user={viewCustomerDetails} />
        <TitleStyles>{viewCustomerDetails?.name}</TitleStyles>
      </Space>
      <Space size="middle" style={{ marginLeft: "auto" }}>
        <CustomButton
          type="default"
          text="Archive Customer"
          onClick={() => {
            setIsDeleteModal(true);
          }}
          isShowIcon={false}
        />
        <CustomButton
          type="default"
          text="Edit Customer"
          onClick={() => {
            dispatch(setIsEditCustomer(true));
          }}
          isGrey={true}
          isShowIcon={false}
        />
        <CustomButton
          type="primary"
          text="Add Account"
          onClick={() => {
            dispatch(setIsEditAccount(true));
          }}
          isShowIcon={false}
        />
      </Space>
    </HeaderStyles>
  );

  return !viewCustomerDetails ? (
    <PageLoading />
  ) : (
    <>
      <PageLayout
        items={items}
        isCustomExtraContentShown={true}
        tabBarExtraContent={header}
      />
      <EditCustomer />
      <AlertModal
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        title="Archive Customer"
        submitText='Archive'
        subtitle="The invoices will not be generated for this customer on archiving
            it. Are you sure you want to archive the customer?"
            onSubmit={() => {
              dispatch(deleteCustomer(viewCustomerDetails.customer_id));
              navigate(`/customer?__sort__=${filter}`);
            }}
      />
      {/* <Modal
        title="Archive Customer"
        isOpen={isDeleteModal}
        onSubmit={() => {
          dispatch(deleteCustomer(viewCustomerDetails.customer_id));
          navigate(`/customer?__sort__=${filter}`);
        }}
        onClose={() => setIsDeleteModal(false)}
        successBtnTitle="Delete"
        icon={<DeleteOutlined />}
      >
        <div style={{ padding: "2rem 0px" }}>
          <Text>
            The invoices will not be generated for this customer on archiving
            it. Are you sure you want to archive the customer?
          </Text>
        </div>
      </Modal> */}
    </>
  );
};

export default ViewCustomerDetails;
