import React, { useEffect, useMemo, useState } from "react";
import { Container } from "../../viewCustomer/customerDetails/Styles";
import { Button, Card, Col, Row, Tag } from "antd";
import { EmptyDataComponent } from "../../../../components/common/empty";
import { EditFilled, RightOutlined } from "@ant-design/icons";
import CustomButton from "../../../../components/common/button";
import { CustomDivider } from "../../../admin-center/userProfile/style";
import { Typography, Divider, Tooltip } from "antd";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { ContentStyles, CopyIcon } from "./Styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PricingScheduleCard from "./ViewSummary";
import {
  getAccountById,
  getWallet,
} from "../../../../redux/feature/customer/customerAsyncThunk";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  copyToClipboard,
  formatDateToLocalTime,
} from "../../../../utils/helper";
import { StyledCard } from "../../../billing/detailsPage/styles";
import PricePlanSchedule from "../PricePlanSchedule";
import ViewSchedule from "../PricePlanSchedule/ViewSchedule";
import ScheduleCard from "../PricePlanSchedule/ScheduleCard";
import { getAttachPricePlan, getPricePlans } from "../../../../redux/feature/pricePlan/pricePlanThunk";
import Segment from "../../../../components/common/segmented";
import SearchInput from "../../../../components/common/search";
import FilterOption from "../../../../components/common/filter/FilterOption";
import PricePlanDetailsTable from "../../../price-plans/NewPricePlan/PricePlanTable";
import InvoiceTemplate from "../../../billing/detailsPage/invoiceGroupDetail/InvoiceTemplate";
import { DetailsCard } from "../../../../components/common/detailsCard/styles";
import moment from "moment";
import { StyledTag } from "../../../../components/common/tag/styles";

const { Text, Title, Paragraph } = Typography;
const options = [
  {
    value: "-rateCardDetails.displayName",
    label: "Rate Card Name Descending",
  },
  {
    value: "2BrateCardDetails.displayName",
    label: "Rate Card Name Ascending",
  },
  {
    value: "-type",
    label: "Rate Card Type Descending",
  },
  {
    value: "2Btype",
    label: "Rate Card Type Ascending",
  },
];
const Summary: React.FC<any> = ({
  baseCurrencySymbol,
  viewAccountDetails,
  filter,
}) => {
  console.log("🚀 ~ baseCurrencySymbol:", baseCurrencySymbol)
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { accountId } = useParams();
  const { attachPricePlan, pricePlans } = useSelector(
    (state: RootState) => state.pricePlan
  );
  console.log("🚀 ~ attachPricePlan:", attachPricePlan)
  const [type, setType] = useState("Rate Cards");
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const editTemplateHandler = () => {
    setIsEditTemplateModalOpen(!isEditTemplateModalOpen);
  };

  const { AccountCurrency } = useSelector(
    (state: RootState) => state?.adminCenter
  );
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  useEffect(()=>{
   if(!pricePlans.length) dispatch(getPricePlans({
      sort: "-updated_at",
      type: "BILLING",
    }))
  },[])

  const attachPricePlanData = useMemo(() => {
    return attachPricePlan.length > 0
    ? attachPricePlan
    .map((plan: any) => {
            const currentDate = moment();
            const status = currentDate.isBefore(moment(plan.effective_from).startOf('day'))
            ? "Upcoming"
              : currentDate.isBetween(
                moment(plan.effective_from).startOf('day'),
                moment(plan.effective_until).endOf('day')
              )
              ? "Ongoing"
              : "Expired";
              return { ...plan, status };
            })
            .filter((plan: any) => plan.status !== "Expired")
            : [];
          }, [attachPricePlan, accountId]);
          console.log("🚀 ~ attachPricePlanData ~ attachPricePlanData:", attachPricePlanData)

  const filteredData = useMemo(() => {
    return pricePlans.find(
      (item: any) => item?.pricePlanId === attachPricePlanData[attachPricePlanData?.length - 1]?.price_plan_id
    );
  }, [attachPricePlanData,pricePlans]);

  const AddNewPlan = () => (
    <CustomButton
      type="primary"
      icon={<RightOutlined />}
      position="end"
      onClick={() => {
        navigate(
          `/customers/${viewAccountDetails.customer_id}/accounts/${viewAccountDetails.customer_account_id}/attach-price-plan`
        );
      }}
      text="Attach Price Plan"
    />
  );

  return (
    <Container>
      <Row gutter={24}>
        <Col span={16}>
          {attachPricePlanData.length ? (
            <>
              <ScheduleCard
                sortedPlans={[
                  attachPricePlanData[attachPricePlanData?.length - 1],
                ]}
                isSummary={true}
                onPlanClick={() => {}}
              />
              <div style={{ margin: "32px 0 24px 0" }}>
                <Segment
                  options={["Rate Cards", "Pricing Rules"]}
                  value={type}
                  onChange={setType}
                  isWhite={true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <SearchInput
                  width="300px"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    if (e.target.value) {
                      searchParams.set("__search__", e.target.value);
                    } else {
                      searchParams.delete("__search__");
                    }
                    navigate({
                      pathname: location.pathname,
                      search: `?${searchParams.toString()}`,
                    });
                  }}
                  TooltipSupportedFields="Name"
                />
                <FilterOption
                  options={options}
                  defaultValue="-rateCardDetails.displayName"
                />
              </div>
              <div
                style={{
                  maxHeight: "calc(100dvh - 520px)",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  border: "1px solid #cbd5e1 ",
                  borderRadius: "10px",
                }}
              >
                <PricePlanDetailsTable
                  isOtherChargesShown={true}
                  rateCards={filteredData}
                  isAction={false}
                  currency={baseCurrencySymbol?.currency_code}
                  isNew={false}
                  pagination={false}
                  searchTerm={searchValue}
                />
              </div>
            </>
          ) : (
            <EmptyDataComponent
              title="No Price Plan Attached"
              description="Please attach a price plan to this account to initiate billing."
              addButton={AddNewPlan}
            />
          )}
        </Col>
        <Col span={8}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              borderBottom: "1px solid rgb(234, 88, 12);",
            }}
          >
            <StyledCard>
              <div className="revenue">
                <p>Monthly Revenue</p>
                <div className="item">
                  <span>0</span>
                  <span className="currency">
                    {baseCurrencySymbol?.currency_code}
                  </span>
                </div>
              </div>
            </StyledCard>
            <DetailsCard
              topBarColor="rgb(234, 88, 12)"
              style={{ height: "calc(100dvh - 315px)" }}
            >
              <div className="topBar"></div>
              <div className="item">
                <div>Account Name</div>
                <b>{viewAccountDetails?.account_name} </b>
              </div>
              <div className="item">
                <div>Account ID</div>
                <b>
                  {viewAccountDetails?.customer_account_id}{" "}
                  <CopyOutlined
                    className="icon"
                    onClick={() => {
                      copyToClipboard(viewAccountDetails?.customer_account_id);
                    }}
                  />{" "}
                </b>
              </div>
              <div className="item">
                <div>Customer ID</div>
                <b>
                  {viewAccountDetails?.billoptim_customer_id}{" "}
                  <CopyOutlined
                    className="icon"
                    onClick={() => {
                      copyToClipboard(
                        viewAccountDetails?.billoptim_customer_id
                      );
                    }}
                  />{" "}
                </b>
              </div>
              {/* <div className="item">
                <div>Usage MTD</div>
                <b>-</b>
              </div> */}
              <div className="item">
                <div>Currency</div>
                <b>{baseCurrencySymbol?.currency_code}</b>
              </div>
              <div className="item">
                <div>Primary Email</div>
                <b>
                  {viewAccountDetails?.account_email}{" "}
                  {viewAccountDetails?.account_email && (
                    <CopyOutlined
                      className="icon"
                      onClick={() => {
                        copyToClipboard(viewAccountDetails?.account_email);
                      }}
                    />
                  )}
                </b>
              </div>
              <div className="item">
                <div>Address</div>
                <b>
                  {viewAccountDetails?.account_address_1}
                  {","}
                  {viewAccountDetails?.account_address_2}
                </b>
              </div>
              <div className="item">
                <div>Tags</div>
                <b>
                  {Array.isArray(viewAccountDetails?.account?.tags)
                    ? viewAccountDetails?.account?.tags.map(
                        (tag: string, index: number) => (
                          <StyledTag key={index} color="blue">
                            {tag}
                          </StyledTag>
                        )
                      )
                    : (() => {
                        if (typeof viewAccountDetails?.tags === "string") {
                          try {
                            const parsedTags = JSON.parse(
                              viewAccountDetails.tags
                            );
                            if (Array.isArray(parsedTags)) {
                              return parsedTags.map(
                                (tag: string, index: number) => (
                                  <StyledTag key={index} color="blue">
                                    {tag}
                                  </StyledTag>
                                )
                              );
                            }
                          } catch (error) {
                            console.error("Invalid JSON in tags:", error);
                          }
                        }
                        return "-";
                      })()}
                </b>
              </div>
              <div className="item">
                <div>Invoice Template</div>
                <b onClick={editTemplateHandler} className="edit">
                  Default Template <EditFilled />
                </b>
              </div>
              <div className="footerContent">
                <Divider dashed className="divider" />
                <div style={{ marginBottom: "8px" }}>
                  Created{" "}
                  {formatDateToLocalTime(viewAccountDetails?.created_at)}
                </div>
                <div>
                  Last Updated{" "}
                  {formatDateToLocalTime(viewAccountDetails?.updated_at)}
                </div>
              </div>
            </DetailsCard>
          </div>
        </Col>
        <InvoiceTemplate
          isOpen={isEditTemplateModalOpen}
          onClose={editTemplateHandler}
          modalFor="Account"
        />
      </Row>
    </Container>
  );
};

export default Summary;
