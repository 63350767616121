import styled from "styled-components";

export const CodeEditorContainer = styled.div`
    .editor-header{
      display: flex;
      justify-content: space-between;
      border: 1px solid rgba(0,0,0,0.2);
      border-bottom: none;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 0.7rem 1rem;

        span{
          font-size: 12px;
          font-weight: 600;
        }
        .iconContainer{
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
    }

    
`;