import React, { useEffect, useMemo, useState } from "react";
import {
  SearchOutlined,
  InfoCircleOutlined,
  CopyOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Card, Col, Divider, Input, Row, Table, Tooltip, Typography, message } from "antd";
import { Container, Header, CardContainer, CopyIcon } from "./Styles"; 
import { CustomDivider } from "../../../admin-center/userProfile/style";
import { useDispatch, useSelector } from "react-redux";
import { copyToClipboard, formatDateToLocalTime } from "../../../../utils/helper";
import { StyledTable } from "../../../../components/common/table/styles";
import { StyledCard } from "../../../billing/detailsPage/styles";
import { getCustomerById } from "../../../../redux/feature/customer/customerAsyncThunk";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DetailsCard } from "../../../../components/common/detailsCard/styles";
import { emptyAttachPricePlan } from "../../../../redux/feature/pricePlan/pricePlanSlice";

const { Title, Paragraph } = Typography;

const CustomerDetails: React.FC<any> = ({ setSearchValue}) => {
  const { AccountCurrency, currencies } = useSelector(
    (state: any) => state.adminCenter
  );
  const dispatch=useDispatch<AppDispatch>()
  const navigate = useNavigate();
  const { viewCustomerDetails ,loading} = useSelector(
    (state: RootState) => state.customer
  );
  const location = useLocation()

  const baseCurrencySymbol = useMemo(() => {
    const baseCurrencyId = AccountCurrency.find(
      ({ is_base }: { is_base: boolean }) => is_base
    )?.currency_id;

    const baseCurrency = currencies.find(
      ({ currency_id }: { currency_id: number }) =>
        currency_id === baseCurrencyId
    );

    return baseCurrency ? baseCurrency.currency_code : undefined;
  }, [AccountCurrency]);

  const customerAccountDataSource = useMemo(() => {
    return viewCustomerDetails?.customerAccount?.map((customerAc: any) => ({
      key: customerAc.customer_account_id,
      accountName: customerAc.account_name,
      customerId: customerAc.customer_id,
      accountId: customerAc.customer_account_id,
      currency_symbol: customerAc.currency_symbol,
      price_plan_name: customerAc?.price_plan_name,
      billOptimCustomerAccountId : customerAc?.billoptim_customer_account_id
    }));
  }, [viewCustomerDetails]);

  const columns = [
    {
      title: "Account Name",
      dataIndex: "accountName",
      key: "accountName",
      render: (_: any, record: any) => {
        return (
          <div>
            <div>
              <b>{record.accountName}</b>
            </div>
            <div>{record.billOptimCustomerAccountId}</div>
          </div>
        );
      },
    },
    {
      title: "Pricing Plan",
      dataIndex: "price_plan_name",
      key: "price_plan_name",
      render: (_: any, record: any) => (
        <Tooltip title={record?.price_plan_name}>
        <div style={{width:"100px",overflow:'hidden',textOverflow:'ellipsis'}}>{record?.price_plan_name ?? "-"}</div>
      </Tooltip>
      ),
    },
    {
      title: "Billed to-date",
      dataIndex: "billed",
      key: "billed",
      render: (_: any, record: any) => (
        <div>{`${record?.currency_symbol}0.00`}</div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => <RightOutlined />,
    },
  ];

  return (
    <Container>
      <Row gutter={24}>
        <Col span={16}>
          <Header>
            <Title level={4}>
              Total Accounts ({customerAccountDataSource?.length ?? "0"})
            </Title>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              suffix={<InfoCircleOutlined />}
              style={{ width: "200px", height: "30px", alignItems: "center" }}
              onChange={(e: any) => {
                setSearchValue(e.target.value);
                navigate(
                  `${location.pathname}${
                    e.target.value ? `?__search__=${e.target.value}` : ""
                  }`
                );
              }}
              allowClear
            />
          </Header>
          <StyledTable
            dataSource={customerAccountDataSource}
            columns={columns}
            loading={loading}
            isCursorPointerShow
            pagination={false}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  dispatch(emptyAttachPricePlan())
                  navigate(
                    `/customers/${record?.customerId}/accounts/${record?.accountId}/details`
                  );
                },
              };
            }}
          />
        </Col>
        <Col span={8}>
          <CardContainer>
            <StyledCard>
              <div>Monthly Revenue Across Accounts</div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div>
                  <b>0</b> <span>{baseCurrencySymbol}</span>
                </div>
              </div>
            </StyledCard>

            <DetailsCard>
              <div className="topBar"></div>
              <div className="item">
                <div>Customer Portal Link</div>
                <b>
                  {`https://dev.billoptim.com`}
                  <CopyIcon
                    onClick={() => copyToClipboard(`https://dev.billoptim.com`)}
                  />
                </b>
              </div>
              <div className="item">
                <div>Customer Name</div>
                <b>{viewCustomerDetails?.name}</b>
              </div>
              <div className="item">
                <div>E-mail ID</div>

                <b>
                  {viewCustomerDetails?.email}
                  <CopyIcon
                    onClick={() => copyToClipboard(viewCustomerDetails?.email)}
                  />
                </b>
              </div>
              <div className="item">
                <div>Customer ID</div>

                <b>
                  {viewCustomerDetails?.billoptim_customer_id}
                  <CopyIcon
                    onClick={() =>
                      copyToClipboard(
                        viewCustomerDetails?.billoptim_customer_id
                      )
                    }
                  />
                </b>
              </div>
              <div className="item">
                <div>Total Accounts</div>
                <b>{customerAccountDataSource?.length ?? "0"}</b>
              </div>
              <div className="item">
                <div>Address</div>
                <b>
                  {[
                    viewCustomerDetails?.address_1,
                    viewCustomerDetails?.address_2,
                    viewCustomerDetails?.city,
                    viewCustomerDetails?.postal_code,
                    viewCustomerDetails?.state,
                    viewCustomerDetails?.country,
                  ]
                    ?.filter(Boolean)
                    ?.join(", ")}
                </b>
              </div>

              <div className="footerContent">
                <Divider dashed className="divider" />
                <div style={{ marginBottom: "8px" }}>
                  Created{" "}
                  {formatDateToLocalTime(viewCustomerDetails?.created_at)}
                </div>
                <div>
                  Last Updated{" "}
                  {formatDateToLocalTime(viewCustomerDetails?.updated_at)}
                </div>
              </div>
            </DetailsCard>
          </CardContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerDetails;
