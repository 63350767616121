import { Tag } from "antd";
import styled from "styled-components";

export type TagColor =
  | "green"
  | "orange"
  | "gold"
  | "red"
  | "purple"
  | "blue"
  | "default"
  | "geekblue";

const colorConfig = {
  green: { bg: "#EDFCF6", text: "#0c5947", border: "#ACEED4" },
  orange: { bg: "#FFFBEB", text: "#92400e", border: "#FDE68A" },
  gold: { bg: "#FFF7ED", text: "#9a3412", border: "#FED7AA" },
  red: { bg: "#FEF2F2", text: "#991b1b", border: "#FECACA" },
  purple: { bg: "#FAF5FF", text: "#6b21a8", border: "#E9D5FF" },
  blue: { bg: "#F0F6FE", text: "#2847a5", border: "#C3DCFA" },
  geekblue: { bg: "#EEF2FF", text: "#3730a3", border: "#C3DCFA" },
  default: { bg: "#F1F5F9", text: "#334155", border: "#E2E8F0" },
};

export const StyledTag = styled(Tag)<{ color?: TagColor }>`
  border-radius: 8px;
  padding: 4px 8px;
  line-height: 14px;
  font-size: 12px;

  background-color: ${({ color }) =>
    color ? colorConfig[color]?.bg : colorConfig["default"]?.bg} !important;
  color: ${({ color }) =>
    color ? colorConfig[color]?.text : colorConfig["default"]?.text} !important;
  border: 1px solid
    ${({ color }) =>
      color ? colorConfig[color]?.border : colorConfig["default"]?.border} !important;
`;
