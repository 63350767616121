import { Form, Steps, Typography } from "antd";
import styled from "styled-components";

export const StyledSteps = styled(Steps)`
  width: 60% !important;
  margin-bottom: 20px !important;
`;

export const TabHeader = styled.h3`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  h3 {
    margin-bottom: 0 !important;
  }
`;

export const ConfigureContainer = styled.div`
  .sampleCsv {
    margin: 0 !important;
    padding: 16px;
    border: 1px solid #2853c2;
    border-radius: 8px;
    background: #f0f6fe;
  }
  .upload {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    padding: 20px;
    border: 1px dashed #bfbfbf;
    border-radius: 8px;
  }
  .uploadedFile {
    margin-top: 24px;
    padding: 12px 16px;
    border: 2px dashed #475569;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MapFieldsContainer = styled.div`
  .uploadedFile {
    padding: 12px 16px;
    border: 2px dashed #cbd5e1;
    border-radius: 12px;
    display: flex;
    background-color: #f8fafc;
    justify-content: space-between;
    align-items: center;
  }
  .ant-form-item-label label {
    font-size: 16px !important;
  }
  .alert {
    background-color: rgb(240, 246, 254);
    padding: 12px 16px;
    border-radius: 12px;

    span {
      font-size: 14px !important;
      line-height: 17px;
      font-weight: 400;
      margin-left: 8px;
    }
    .anticon {
      color: #2853c2;
    }
  }
`;

export const FieldsMatchingTableContainer = styled.div`
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 7px;
  margin-top: 24px;

  .thead {
    background-color: #f8fafc;
    border-radius: 10px;
    padding: 3px 10px;
    font-size: 10px;
    margin-bottom: 16px;

    .ant-col {
      font-size: 13px;
      display: flex;
      align-items: center;
    }
  }
  .tbody {
    padding: 0.8rem 10px;
    font-size: 10px;

    .ant-col {
      font-size: 13px;
      display: flex;
      align-items: center;
    }
    .value {
      font-weight: 600;
    }
  }
  .tbody:hover {
    background-color: #f8fafc;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  padding: 0 10px;
  .ant-form-item-label {
    width: 50% !important;
    text-align: left;
  }
`;

export const Container = styled.div`
  padding: 20px;

    .uploadedFile {
    padding: 12px 16px;
    border: 2px dashed #cbd5e1;
    border-radius: 12px;
    display: flex;
    background-color: #f8fafc;
    justify-content: space-between;
    align-items: center;
  }

`;

export const Section = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled(Typography.Text)`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #475569;
`;

export const Value = styled(Typography.Text)`
  font-weight: 500;
  font-size: 14px;
  color: #262626;
`;

export const DownloadCard = styled.div`
  letter-spacing: 0.15px;
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
  margin-bottom: 0;
`;