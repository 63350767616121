import { Form, Input, Select } from "antd";
import { useMemo } from "react";

type UsageMeterFieldsPropsType = {
  usageMeters: any;
};

const UsageMeterFields: React.FC<UsageMeterFieldsPropsType> = ({
  usageMeters,
}) => {

  const activeUSageMeters = useMemo(
    () => usageMeters.filter((usage: any) => usage.status === "ACTIVE"),
    [usageMeters]
  );

  return (
    <>
      <Form.Item label="Usage Meter" name="usage_meter_id">
        <Select size="large" placeholder="Usage Meter">
          {activeUSageMeters?.map((usageMeter: any) => {
            return (
              <Select.Option
                key={usageMeter?.usageMeterId}
                value={usageMeter?.usageMeterId}
              >
                {usageMeter?.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Usage"
        name="usage"
        rules={[{ required: true, message: "Please input Usage First!" }]}
      >
        <Input placeholder="Usage" type="text" />
      </Form.Item>
    </>
  );
};

export default UsageMeterFields;
