import { memo } from "react";
import { SpecificUsage } from "./styles";

const entityMap: Record<string, string> = {
  ADD_ONS_AND_FEATURES: "All Usage Meters, Add-Ons & Features",
  ALL_USAGE_METERS: "All Usage Meters",
  ALL_FIXED_FEES: "All Fixed Fees",
  ALL_LICENSE_FEES: "All License Fees",
  ALL_FEATURES: "All Features",
  ALL_CREDIT_GRANT_FEES: "All Credit Grant Fees",
  ALL_CREDIT_GRANT_FEES_EXCLUDING_SELF: "All Credit Grant Fees Excluding Self",
  SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES:
    "Specific Usage Meters, Add-Ons & Features",
};

interface ApplicableEntityListProps {
  item: any;
  drawerHandler: () => void;
}

const ApplicableEntityList: React.FC<ApplicableEntityListProps> = memo(({ item, drawerHandler }) => {
  console.log("rendered....");

  const applicableEntity = item?.grantDetails?.applicableEntityIds?.[0];
  const value =
    typeof applicableEntity?.value === "object"
      ? applicableEntity?.value?.value
      : applicableEntity?.value;

  const totalItems =
    (applicableEntity?.add_on?.length ?? 0) +
    (applicableEntity?.usage_base?.length ?? 0);

  if (entityMap[value] === "Specific Usage Meters, Add-Ons & Features") {
    return (
      <SpecificUsage>
        <span className="value">{totalItems} Items</span>
        <span className="span" onClick={drawerHandler}>
          Show Entities
        </span>
      </SpecificUsage>
    );
  }

  return <>{entityMap[value] || "null"}</>;
});

export default ApplicableEntityList;
