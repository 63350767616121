import React, { useEffect, useMemo, useState } from "react";
import {
  CaretDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  DeleteRowOutlined,
  DownOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  SearchOutlined,
  TagOutlined,
} from "@ant-design/icons";
import CustomButton from "../../../components/common/button";
import { PlanCard, ViewPlanContainer, ViewPricePlanHeader } from "./Styles";

import {
  Card,
  Tag,
  Switch,
  Button,
  Select,
  Tooltip,
  Divider,
  Row,
  Col,
  Segmented,
  Typography,
} from "antd";
import {
  ReloadOutlined,
  LockOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ScheduleItemWrapper } from "../../customer/viewAccount/PricePlanSchedule/AttachPricePlan/SchedulePreview/Styles";
import { ScheduleData } from "../../customer/viewAccount/PricePlanSchedule/Styles";
import {
  ContentStyles,
  CopyIcon,
} from "../../customer/viewCustomer/customerDetails/Styles";
import { SearchInput } from "../../customer/table/Styles";
import PricePlanDetailsTable from "../NewPricePlan/PricePlanTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeSinglePricePlanData,
  setBillingDetails,
  setIsClonedPricePlan,
} from "../../../redux/feature/pricePlan/pricePlanSlice";
import { EmptyDataComponent } from "../../../components/common/empty";
import Modal from "../../../components/common/modal";
import {
  activatePricePlan,
  deletePricePlan,
  getSinglePricePlan,
} from "../../../redux/feature/pricePlan/pricePlanThunk";
import { AxiosError } from "axios";
import PlanRateCardTable from "./PlanRateCardTable";
import { Container } from "../NewPricePlan/BillingDetails/Styles";
import PageLoading from "../../../components/Loader";
import {
  copyToClipboard,
  formatDateToLocalTime,
  reverseTransformPricePlanPayload,
  reverseTransformRateCard,
} from "../../../utils/helper";
import { setFilter } from "../../../redux/feature/customer/customerSlice";
import { DetailsCard } from "../../../components/common/detailsCard/styles";
import { exportToExcel } from "../../../utils/exportToExcel";
import { StyledTag } from "../../../components/common/tag/styles";
import AlertModal from "../../../components/common/alertModal";

const { Option } = Select;
const { Text } = Typography;

const ViewPricePlan: React.FC<any> = () => {
  const { singlePricePlan, loading } = useSelector(
    (state: RootState) => state.pricePlan
  );
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isConformationModalOpen, setsConformationModalOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSinglePricePlan(id));
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(removeSinglePricePlanData(null));
    };
  }, [dispatch]);

  const { creditGrantCount, fixedCount } = useMemo(() => {
    const creditGrantCount = singlePricePlan
      ? singlePricePlan?.rateCards?.filter(
        (item: any) => item.type === "CREDIT_GRANT"
      ).length
      : 0;

    const fixedCount = singlePricePlan
      ? singlePricePlan?.rateCards?.filter(
        (item: any) => item.type === "FIXED_FEE"
      ).length
      : 0;

    return { creditGrantCount, fixedCount };
  }, [singlePricePlan]);

  const onToggleStatusChange = async () => {
    try {
      if (singlePricePlan.status === "ACTIVE") return;
      await dispatch(activatePricePlan(singlePricePlan.pricePlanId));
      await dispatch(getSinglePricePlan(singlePricePlan.pricePlanId));
    } catch (err) {
      const error = err as AxiosError;
      console.log("🚀 ~ file: index.tsx:73 ~ onChange ~ error:", error);
    } finally {
      setsConformationModalOpen(false);
    }
  };

  return singlePricePlan ? (
    <ViewPlanContainer>
      <ViewPricePlanHeader>
        <div className="headerStyles">
          <CustomButton
            icon={<LeftOutlined />}
            onClick={() => {
              dispatch(removeSinglePricePlanData(null));
              dispatch(setFilter("-updated_at"));
              dispatch(setIsClonedPricePlan(false));
              navigate("/price-plan");
            }}
            text="Back"
            border={false}
            type="default"
          />
          <div className="header-btn">
            <CustomButton
              icon={<CopyOutlined />}
              onClick={() => {
                const { rateCards, ...rest } =
                  reverseTransformPricePlanPayload(singlePricePlan);
                const formattedRateCards = rateCards?.map(
                  ({ rateCardId, ...rate }: any, index: number) => ({
                    uniqueID: Date.now() + index,
                    ...rate,
                  })
                );

                dispatch(
                  setBillingDetails({
                    rateCards: formattedRateCards,
                    ...rest,
                    name: `${rest.name}-copy`,
                  })
                );
                dispatch(setIsClonedPricePlan(true));
                navigate(`/price-plan/${id}/clone`);
              }}
              text="Clone"
              border={false}
              type="default"
            />
            {singlePricePlan.status !== "ACTIVE" && (
              <CustomButton
                icon={<DeleteRowOutlined />}
                onClick={() => {
                  setIsDeleteModal(true);
                }}
                text="Archive"
                border={false}
                type="default"
              />
            )}
            <CustomButton
              icon={<DownOutlined />}
              onClick={() => {
                const { rateCards, supportedCurrencies, ...rest } =
                  singlePricePlan;
                const enrichedRateCards = rateCards.map((rateCard: any) => ({
                  ...rateCard,
                  supportedCurrencies,
                }));

                exportToExcel({
                  rateCards: enrichedRateCards,
                  pricePlanId: rest.pricePlanId,
                  title: "price-plan",
                });
              }}
              text="Download XLSX"
              border={false}
              type="default"
            />
          </div>
        </div>
      </ViewPricePlanHeader>
      <div style={{ padding: "0 7rem" }}>
        <PlanCard style={{ marginBottom: "16px" }}>
          <div className="card-header ">
            <div className="tooltip-container">
              <Tooltip title="Price Plan">
                <span className="icon-span">
                  <TagOutlined
                    style={{
                      fontSize: "30px",
                      rotate: "-87deg",
                      color: "white",
                    }}
                  />
                </span>
              </Tooltip>
              <div>
                <h5 style={{ margin: "0" }}>Price Plan Details</h5>
                <div className="plan_name">{singlePricePlan?.name}</div>
                <div className="status">
                  <StyledTag
                    color={
                      singlePricePlan?.status === "DRAFT" ? "orange" : "green"
                    }
                  >
                    • {singlePricePlan?.status}
                  </StyledTag>
                  <StyledTag icon={<ReloadOutlined />} color="orange">
                    {singlePricePlan?.type === "PURCHASE"
                      ? "One-Time"
                      : "Recurring "}
                  </StyledTag>
                </div>
              </div>
            </div>
            <div className="card-body-right-side">
              <div className="content">
                <div>Toggle Status</div>
                <Tooltip
                  title={
                    singlePricePlan.status === "ACTIVE"
                      ? " Price plan cannot be Deactivated"
                      : " Active Price plan"
                  }
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <LockOutlined />
                    <Switch
                      disabled={singlePricePlan.status === "ACTIVE"}
                      checked={singlePricePlan.status === "ACTIVE"}
                      onChange={() => setsConformationModalOpen(true)}
                      loading={loading}
                    />
                  </div>
                </Tooltip>
              </div>
              {singlePricePlan?.status !== "DRAFT" && (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <Select defaultValue="v1 (latest)" style={{ width: 150 }}>
                    <Option value="v1">v1 (latest)</Option>
                    <Option value="v2">v2</Option>
                  </Select>
                  <CustomButton
                    type="default"
                    text="Add Version"
                    icon={<PlusOutlined />}
                    position="start"
                    onClick={() => {}}
                  />
                </div>
              )}
            </div>
          </div>
        </PlanCard>
        <Row gutter={24}>
          <PlanRateCardTable
            selectedPricePlan={singlePricePlan.pricePlanId}
            isAddCurrency={true}
            isAddRoutes={true}
            span={17}
          />
          <Col span={7}>
            <DetailsCard>
              <div className="topBar"></div>
              <div>
                <div className="item">
                  <div>Price Plan ID</div>
                  <b>
                    {singlePricePlan.pricePlanId}
                    <CopyIcon
                      onClick={() =>
                        copyToClipboard(singlePricePlan.pricePlanId)
                      }
                    />
                  </b>
                </div>
                {singlePricePlan?.type === "BILLING" ? (
                  <>
                    <div className="item">
                      <div>Pricing Cycle</div>
                      <b>{singlePricePlan?.pricingCycleInterval}</b>
                    </div>
                    <div className="item">
                      <div>Pricing Cycle Type</div>
                      <b>
                        {singlePricePlan?.anniversaryCycle
                          ? "Anniversary Cycle"
                          : `${singlePricePlan?.pricingCycleStartOffsetDay} of every ${singlePricePlan?.pricingCycleInterval}`}
                      </b>
                    </div>
                    <div className="item">
                      <div>Grace Period</div>
                        <b>
                          {singlePricePlan?.gracePeriod}{" Days"}
                        </b>
                    </div>
                    <div className="item">
                      <div>Total Associated Accounts</div>
                      <b>{singlePricePlan?.associatedAccountsCount}</b>
                    </div>
                    <div className="item">
                      <div>Associated Accounts with Overrides</div>
                      <b>0</b>
                    </div>
                    <div className="item">
                      <div>Mid-cycle pricing changes</div>
                      <Tag
                        color={
                          singlePricePlan?.deferredRevenue ? "green" : "gold"
                        }
                        style={{ width: "fit-content" }}
                      >
                        {singlePricePlan?.deferredRevenue
                          ? "Enabled"
                          : "Disabled"}
                      </Tag>
                    </div>
                    <div className="item">
                      <div>View real-time revenue analytic</div>
                      <Tag
                        color={
                          singlePricePlan?.allowOngoingCycleUpdates
                            ? "green"
                            : "gold"
                        }
                        style={{ width: "fit-content" }}
                      >
                        {singlePricePlan?.allowOngoingCycleUpdates
                          ? "Enabled"
                          : "Disabled"}
                      </Tag>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="item">
                      <div>Entitlement Features</div>
                      <b>0</b>
                    </div>
                    <div className="item">
                      <div>Credit Grants</div>
                      <b>{creditGrantCount}</b>
                    </div>
                    <div className="item">
                      <div>Fixed Fees</div>
                      <b>{fixedCount}</b>
                    </div>
                  </>
                )}
                <div className="footerContent">
                  <Divider dashed className="divider" />
                  <div style={{ marginBottom: "8px" }}>
                    Created {formatDateToLocalTime(singlePricePlan?.createdAt)}
                  </div>
                  <div>
                    Last Updated{" "}
                    {formatDateToLocalTime(singlePricePlan?.updatedAt)}
                  </div>
                </div>
              </div>
            </DetailsCard>
          </Col>
        </Row>
      </div>
      <Modal
        title="Archive Price Plan?"
        isOpen={isDeleteModal}
        onSubmit={() => {
          dispatch(deletePricePlan(singlePricePlan.pricePlanId));
          navigate("/price-plan");
        }}
        onClose={() => setIsDeleteModal(false)}
        successBtnTitle="Archive"
        icon={<DeleteOutlined />}
      >
        <div style={{ padding: "2rem 0px" }}>
          <Text>
            The Price Plan will no longer be available for association with any
            accounts. The action cannot be undone.
          </Text>
        </div>
      </Modal>
      <AlertModal
        open={isConformationModalOpen}
        close={() => setsConformationModalOpen(false)}
        title="Activate Price Plan?"
        subtitle="Once the Price Plan is activated, it cannot be edited again. Do you want to proceed?"
        submitText="Activate"
        onSubmit={() => onToggleStatusChange()}
      />
    </ViewPlanContainer>
  ) : (
    <PageLoading />
  );
};

export default ViewPricePlan;
