import React, { useMemo, useState } from "react";
import { Button, Table, Tabs } from "antd";
import Modal from "../../../../../../components/common/modal";
import { useDispatch, useSelector } from "react-redux";
import { StyledTable } from "../../../../../../components/common/table/styles";
import { setEditCardData } from "../../../../../../redux/feature/pricePlan/pricePlanSlice";
import { RootState } from "../../../../../../redux/store";
const { TabPane } = Tabs;

interface Item {
  key: string;
  name: string;
}

const SpecificUsage: React.FC<any> = ({
  setIsSpecificUsage,
  setSelectedRowKeys,
  selectedRowKeys,
  selectedAddOns,
  selectedFeatures,
  setSelectedFeatures,
  setSelectedAddOns,
  setSpecificCreditGrantValues
}) => {
  const { addOns, usageMeters } = useSelector((state: any) => state.billable);
  const [selectedItems, setSelectedItems] = useState<{
    usageMeters: number[];
    addOns: number[];
    features: number[];
  }>({
    usageMeters: selectedRowKeys,
    addOns: selectedAddOns,
    features: selectedFeatures,
  });
  const dispatch = useDispatch();
  const editCardData = useSelector(
    (state: RootState) => state.pricePlan.editCardData
  );
  const { addOnDataSource, usageMetersDataSource } = useMemo(() => {
    const addOnDataSource = addOns.map((addon: any) => ({
      key: addon.addOnId,
      name: addon.name,
    }));

    const usageMetersDataSource = usageMeters.map((um: any) => ({
      key: um.usageMeterId,
      name: um.name,
    }));

    return { addOnDataSource, usageMetersDataSource };
  }, [addOns, usageMeters]);

  const handleAddOnSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedItems((prev) => ({ ...prev, addOns: newSelectedRowKeys }));
  };

  const handleUsageMeterSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedItems((prev) => ({ ...prev, usageMeters: newSelectedRowKeys }));
  };

  const handleFeatureSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedItems((prev) => ({ ...prev, features: newSelectedRowKeys }));
  };

  const addOnColumns = [
    {
      title: "All Add-Ons in the Organization",
      dataIndex: "name",
      key: "name",
    },
  ];

  const usageMetersColumns = [
    {
      title: "All Usage Meters in the Organization",
      dataIndex: "name",
      key: "name",
    },
  ];

  const handleModalClose = () => {
    setIsSpecificUsage(false);
  };

  const handleSubmit = () => {
    const selectedUsageMeters = usageMetersDataSource.filter((item: any) =>
      selectedItems?.usageMeters?.includes(item.key)
    );

    const selectedAddOnsData = addOnDataSource.filter((item: any) =>
      selectedItems?.addOns?.includes(item.key)
    );

    const selectedFeaturesData = usageMetersDataSource.filter((item: any) =>
      selectedItems?.features?.includes(item.key)
    );

    setSelectedRowKeys(selectedItems.usageMeters ?? []);
    setSelectedAddOns(selectedItems.addOns ?? []);
    setSelectedFeatures(selectedItems.features);
    if (selectedItems) {
      setSpecificCreditGrantValues({
        usage_base: selectedItems?.usageMeters,
        add_on: selectedItems?.addOns,
      });
    }
    if (
      editCardData?.creditID?.value ===
      "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES"
    ) {
      dispatch(
        setEditCardData({
          ...editCardData,
          creditID: {
            usage_base: selectedItems?.usageMeters,
            add_on: selectedItems?.addOns,
            value: editCardData?.creditID.value,
          },
        })
      );
    }
    setIsSpecificUsage(false);
  };

  return (
    <Modal
      title="Specific Usage Meters, Add-Ons & Features"
      isOpen={true}
      onSubmit={handleSubmit}
      onClose={handleModalClose}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Usage Meters" key="1">
          <StyledTable
            rowKey="key"
            rowSelection={{
              selectedRowKeys: selectedItems.usageMeters,
              onChange: handleUsageMeterSelectChange,
            }}
            columns={usageMetersColumns}
            dataSource={usageMetersDataSource}
            pagination={false}
          />
        </TabPane>

        <TabPane tab="Add-Ons" key="2">
          <StyledTable
            rowKey="key"
            rowSelection={{
              selectedRowKeys: selectedItems.addOns,

              onChange: handleAddOnSelectChange,
            }}
            columns={addOnColumns}
            dataSource={addOnDataSource}
            pagination={false}
          />
        </TabPane>

        <TabPane tab="Features" key="3">
          <StyledTable
            rowKey="key"
            rowSelection={{
              selectedRowKeys: selectedItems.features,
              onChange: handleFeatureSelectChange,
            }}
            columns={usageMetersColumns}
            dataSource={usageMetersDataSource}
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default SpecificUsage;
