import { useEffect, useState, useMemo } from "react";
import { PaymentContainer } from "./styles";
import { StyledTable } from "../../../../components/common/table/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const PaymentDetailsDrawerContent: React.FC<{
  selectedRow: any;
  walletID: string;
}> = ({ selectedRow, walletID }) => {
  const [paymentData, setPaymentData] = useState<any>(null);
  const {viewAccountDetails}=useSelector((state:RootState)=>state.customer)

  const lineItemRecords = useMemo(() => selectedRow?.lineItemRecords || [], [selectedRow]);
  console.log("🚀 ~ selectedRow:", selectedRow)
  console.log("🚀 ~ walletID:", walletID)

  useEffect(() => {
    if (lineItemRecords.length > 0) {
      const credits = lineItemRecords.reduce(
        (acc: number, lineItem: any) => acc + Number(lineItem.amount),
        0
      );

      const amount = Number(selectedRow?.totalAmount) - credits;

      const data = [
        ...lineItemRecords.map((item: any) => ({
          type: item?.type === "CREDIT" ? "Credit" : "Debit",
          transaction_id: "-",
          amount: item?.amount,
        })),
        {
          type: "Wallet Credit",
          transaction_id: walletID,
          amount: amount,
        },
      ];

      setPaymentData(data);
    } else {
      setPaymentData(null);
    }
  }, [lineItemRecords, selectedRow?.totalAmount, walletID]);

  return (
    <PaymentContainer>
      <div>
        <p className="heading">Reference Details : </p>
      </div>
      <div className="item">
        <p>Payment ID</p>
        <div>{selectedRow?.paymentId}</div>
      </div>
      <div className="item">
        <p>Amount</p>
        <div>{selectedRow?.totalAmount}</div>
      </div>
      <div className="item">
        <p>Description</p>
        <div>{selectedRow?.description}</div>
      </div>
      <div className="item">
        <p>Notes</p>
        <div>{selectedRow?.notes ?? "-"}</div>
      </div>
      <div className="item">
        <p>Payer Name</p>
        <div>{selectedRow?.accountId}</div>
      </div>
      <div className="item">
        <p>Payment Mode</p>
        <div>{selectedRow?.sourceType}</div>
      </div>
      <div className="item">
        <p>Txn Number</p>
        <div>{selectedRow?.transactionNumber}</div>
      </div>
      <div className="item">
        <p>Txn Description</p>
        <div>{selectedRow?.externalDescription ?? "-"}</div>
      </div>
      <div>
        <p className="heading usage" >Payment Usage : </p>
      </div>

      <div>
        <StyledTable
          pagination={false}
          columns={[
            {
              title: "Type",
              dataIndex: "type",
            },
            {
              title: "ID",
              dataIndex: "transaction_id",
            },
            {
              title: "Amount",
              dataIndex: "amount",
              render: (amount: string,record:any) => `${viewAccountDetails?.currency_code} ${amount}`,
            },
          ]}
          dataSource={paymentData}
        />
      </div>
    </PaymentContainer>
  );
};

export default PaymentDetailsDrawerContent;
