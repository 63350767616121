import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Spin, Steps, Tag } from "antd";
import {
  NewPricePlanContainer,
  PlanPreviewContainer,
  PricePlanContainer,
  PricePlanFormHeader,
} from "./Styles";
import CustomButton from "../../../components/common/button";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import BillingDetails from "./BillingDetails";
import { CustomSteps } from "./BillingDetails/Styles";
import RateCard from "./RateCard";
import PricingRules from "./PricingRules";
import { useDispatch, useSelector } from "react-redux";
import {
  setBillingDetails,
  setBillingRateCards,
  setEmptyBillingRateCards,
} from "../../../redux/feature/pricePlan/pricePlanSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  changePricePlanStatus,
  createPricePlan,
  getPricePlans,
  getSinglePricePlan,
} from "../../../redux/feature/pricePlan/pricePlanThunk";
import { reverseTransformPricePlanPayload, transformPricePlanPayload } from "../../../utils/helper";
import PageHeader from "../../../components/common/pageHeader";
import PageLoading from "../../../components/Loader";
import PlanPreview from "./PlanPreview";

const NewPricePlan:React.FC<any> = ({isClone=false}) => {
  const [form] = Form.useForm();
  const { currencies } = useSelector((state: any) => state.adminCenter);
  const { billingDetails,singlePricePlan } = useSelector((state: RootState) => state.pricePlan);
  const [isMidCyclePricing, setIsMidCyclePricing] = useState(false);
  const [isProceedBtnDisabled, setIsProceedBtnDisabled] = useState(false);
  const proceedBtnDisabled = (value: boolean) => {
    setIsProceedBtnDisabled(value);
  };
  const [selectedPreviewLineItem, setSelectedPreviewLineItem] =
    useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const planType = searchParams.get("plan-type");
  const location = useLocation();

  const { id } = useParams();

  useEffect(() => {
    if(!singlePricePlan && isClone){
      dispatch(getSinglePricePlan(id)).then((res) => {
        const { rateCards, ...rest } =
          reverseTransformPricePlanPayload(res.payload);
        const formattedRateCards = rateCards?.map(
          ({ rateCardId, ...rate }: any, index: number) => ({
            uniqueID: Date.now() + index,
            ...rate,
          })
        );
    
        dispatch(
          setBillingDetails({
            rateCards: formattedRateCards,
            ...rest,
            name: `${rest.name}-copy`,
          })
        );
      })
    }
  }, [id]);

  const handlePlanChange = (newPlanType: string) => {
    setSearchParams({ "plan-type": newPlanType });
  };
  const [isLoading, setIsLoading] = useState(false);

  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleNext = async () => {
    try {
      const values = await form.validateFields();
      if (Object.keys(values).length > 0) dispatch(setBillingDetails(values));
      setCurrent(current + 1);
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:45 ~ handleNext ~ error:", error);
    }
  };

  const   handleSubmit = async (type = "publish") => {
    setIsLoading(true);
    try {
      const data = billingDetails;
      const payload = transformPricePlanPayload(data);
      const pricePlan = await dispatch(createPricePlan(payload)).unwrap();
      if(pricePlan) {
        await dispatch(
          getPricePlans({
            sort: "-updated_at",
          }))
      }
      if (type === "publish" && pricePlan) {
        await dispatch(changePricePlanStatus(pricePlan));
      }
      dispatch(setEmptyBillingRateCards({ rateCards: [], pricingRules: [] }));
      navigate(`/price-plan/${pricePlan}??__sort__=-updated_at`);
      form.resetFields();
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:55 ~ handleSubmit ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStepChange = async (e: number) => {
    try {
      if (e === 2 && billingDetails?.rateCards?.length === 0) {
        return;
      }
      if (current === 0) {
        const values = await form.validateFields();
        if (Object.keys(values).length > 0) dispatch(setBillingDetails(values));
      }
      setCurrent(e);
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:73 ~ handleStepChange ~ error:", error);
    }
  };
  const renderContent = useMemo(() => {
    switch (current) {
      case 0:
        return (
          <BillingDetails
            form={form}
            isMidCyclePricing={isMidCyclePricing}
            setIsMidCyclePricing={setIsMidCyclePricing}
            pricePlanType={planType}
            setPricePlanType={handlePlanChange}
            isClone={isClone}
          />
        );
      case 1:
        return (
          <RateCard
            form={form}
            isMidCyclePricing={isMidCyclePricing}
            pricePlanType={planType}
            isNew={true}
            proceedBtnDisabled={proceedBtnDisabled}
          />
        );
      case 2:
        return <PricingRules />;

      default:
        return <BillingDetails form={form} />;
    }
  }, [current, form, isMidCyclePricing, planType]);

  const items = useMemo(() => {
    const baseItems = [
      { title: "Billing Details" },
      { title: "Add Rate Cards" },
    ];
    if (planType !== "ONE_TIME") {
      baseItems.push({ title: "Add Pricing Rules" });
    }

    return baseItems;
  }, [planType, current]);
  console.log("⏩ ~ selectedPreviewLineItem:", selectedPreviewLineItem);
  const type = useSearchParams()[0].get("plan-type");

  return (
    <NewPricePlanContainer>
      {isLoading && <PageLoading />}
      <PageHeader
        title={`${location.pathname.split("/")[2] === 'create' ? "Create New" : 'Clone'} Price Plan`}
        path="/price-plan/recurring"
        isActionButtonShown={true}
        btnText={
          current === (type === "RECURRING" ? 2 : 1) ? "Finished" : "Proceed"
        }
        onBtnClick={
          current === (type === "RECURRING" ? 2 : 1)
            ? () => handleSubmit("publish")
            : handleNext
        }
        icon={<RightOutlined style={{ marginRight: 0 }} />}
        isLoading={isLoading}
        disabled={isProceedBtnDisabled}
        isDraftBtnShow={current === (type === "RECURRING" ? 2 : 1)}
        draftOnBtnClick={() => handleSubmit("draft")}
      />

      <PricePlanContainer>
        <Row gutter={24}>
          <Col span={18}>
            <div className="contentContainer">
              <div className="stepsContainer">
                <CustomSteps
                  direction="vertical"
                  size="small"
                  current={current}
                  items={items}
                  onChange={handleStepChange}
                />
              </div>
              <div className="formContainer">{renderContent}</div>
            </div>
          </Col>
          <Col span={6}>
            <div className="contentContainer">
              <PlanPreview billingDetails={billingDetails } selectedPreviewLineItem={selectedPreviewLineItem} setSelectedPreviewLineItem={setSelectedPreviewLineItem}/>
            </div>
          </Col>
        </Row>
      </PricePlanContainer>
    </NewPricePlanContainer>
  );
};

export default NewPricePlan;
