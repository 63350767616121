import React, { useMemo, useState } from "react";
import moment from "moment";
import { Button, Card, Col, Row, Space, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowDownOutlined,
  CalendarOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomButton from "../../../../../components/common/button";
import { ScheduleData } from "../Styles";
import { StyledCard } from "./Styles";
import CalendarCard from "../CalendarCard";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { exportToExcel } from "../../../../../utils/exportToExcel";
import { StyledTag } from "../../../../../components/common/tag/styles";
import { EmptyDataComponent } from "../../../../../components/common/empty";

const ScheduleCard: React.FC<any> = ({
  isSummary = false,
  isNavigatable = false,
  sortedPlans = [],
  isCursorPointerShow=false
}) => {
  console.log("🚀 ~ sortedPlans:", sortedPlans)
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { customerId, accountId } = useParams();
  const { pricePlans, attachPricePlan } = useSelector(
    (state: RootState) => state.pricePlan
  );

  const getStatus = (plan: any) => {
    const currentDate = moment();
    if (currentDate.isBefore(moment(plan.effective_from))) {
      return "Upcoming";
    } else if (
      currentDate.isBetween(
        moment(plan.effective_from).startOf('day'),
        moment(plan.effective_until).endOf('day')
      )
    ) {
      return "Ongoing";
    } else {
      return "Expired";
    }
  };
  const AddNewPlan = () => (
    <CustomButton
      type="primary"
      icon={<RightOutlined />}
      position="end"
      onClick={() => {
        navigate(
          `/customers/${customerId}/accounts/${accountId}/attach-price-plan`
        );
      }}
      text="Attach Price Plan"
    />
  );

  return sortedPlans.length ? (
    <>
      {sortedPlans?.map((plan: any) => {
        const status = getStatus(plan);
        console.log("🚀 ~ {sortedPlans?.map ~ status:", status)
        return (
          <>
            <Col
              span={24}
              style={{
                display: "flex",
                gap: "1rem",
                padding: "0",
                height: "max-content",
                cursor: isCursorPointerShow? "pointer" : "default",
              }}
              onClick={() => {
                if (isNavigatable) {
                  navigate(
                    `/customers/${customerId}/accounts/${accountId}/price-plan-schedule/${plan?.price_plan_attach_id}`
                  );
                } else {
                  return;
                }
              }}
            >
             
              {!isSummary && (
                <CalendarCard startData={plan.effective_from} status={status} />
              )}
              <StyledCard className="detailCard">
                <div className="orderDetailContainer">
                  <div className="orderDetailHeader">
                    <div className="title">
                      <Space>
                        <h3>{plan?.priceplanname}</h3>
                        {status !== "Expired" && (
                          <StyledTag
                            color={status === "Ongoing" ? "green" : "geekblue"}
                          >
                            • {status} Plan
                          </StyledTag>
                        )}
                        {plan?.isOverRide && (
                          <StyledTag color="gold"> • Overridden</StyledTag>
                        )}
                      </Space>
                      <Space>
                        <Tooltip title="Download Schedule">
                          <div className="icon">
                            <DownloadOutlined
                              onClick={() => {
                                const {
                                  rateCards,
                                  supportedCurrencies,
                                  ...rest
                                } = pricePlans.find(
                                  (item: any) =>
                                    item?.pricePlanId === plan?.price_plan_id
                                );
                                const enrichedRateCards = rateCards.map(
                                  (rateCard: any) => ({
                                    ...rateCard,
                                    supportedCurrencies,
                                  })
                                );

                                exportToExcel({
                                  rateCards: enrichedRateCards,
                                  pricePlanId: plan?.price_plan_attach_id,
                                  title: "schedule-details",
                                });
                              }}
                            />
                          </div>
                        </Tooltip>
                        {isSummary ? (
                          <CustomButton
                            icon={<RightOutlined />}
                            onClick={() => {
                              navigate(
                                `/customers/${customerId}/accounts/${accountId}/price-plan-schedule`
                              );
                            }}
                            stopPropagation={true}
                            text="Manage Schedule"
                            type="default"
                            position="end"
                            isGrey={true}
                          />
                        ) : (
                          <>
                            {status === "Upcoming" && (
                              <Tooltip title="Detach Plan">
                                <CustomButton
                                  text="Detach Plan"
                                  icon={<CloseOutlined />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  position="end"
                                  type="default"
                                />
                              </Tooltip>
                            )}
                            {status === "Upcoming" && (
                              <Tooltip title="Edit Schedule">
                                <div
                                  style={{
                                    position: "relative",
                                    zIndex: "99999",
                                  }}
                                >
                                  <CustomButton
                                    text={"Edit Schedule"}
                                    icon={<EditOutlined />}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(
                                        `/customers/${customerId}/accounts/${accountId}/edit-schedule/${plan?.price_plan_attach_id}`
                                      );
                                    }}
                                    position="end"
                                    isGrey={true}
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </Space>
                    </div>
                    <div className="orderDetailItem">
                      <p className="version">Version : 1</p>
                    </div>
                    <div className="orderDetailItem">
                      <ScheduleData>
                        <p>
                          <CalendarOutlined />
                        </p>
                        <p>
                          {moment(plan.effective_from).format("MMM D, YYYY")}
                        </p>
                        <p>
                          <ArrowDownOutlined rotate={270} />
                        </p>
                        <p>
                          {moment(plan.effective_until).format("MMM D, YYYY")}
                        </p>
                      </ScheduleData>
                    </div>
                  </div>
                </div>
                <Row gutter={[20, 0]} className="cardContainer">
                  <Col span={6}>
                    <Card className="cardItem">
                      <p className="cardTitle">Pricing Cycle</p>
                      <p>{plan.pricing_cycle_interval ?? "-"}</p>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card className="cardItem">
                      <p className="cardTitle">Cycle Start Month</p>
                      <p>{plan.start_month_offset ?? "-"}</p>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card className="cardItem">
                      <p className="cardTitle">Cycle Start Date</p>
                      <p>{plan.start_day_offset ?? "-"}</p>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card className="cardItem">
                      <p className="cardTitle">Grace Period</p>
                      <p>{plan.grace_period ?? "-"}</p>
                    </Card>
                  </Col>
                </Row>
              </StyledCard>
            </Col>
            {plan?.hasDateGap && (
              <Col
                span={24}
                key={plan?.price_plan_attach_id}
                style={{
                  display: "flex",
                  gap: "1rem",
                  padding: "0",
                  height: "max-content",
                }}
              >
                {!isSummary && (
                  <CalendarCard
                    startData={plan?.gapPeriod?.from}
                    status="Detached"
                  />
                )}
                <StyledCard className="detailCard" style={{border:'1px dashed gray'}}>
                  <div
                    className="orderDetailContainer"
                    style={{ paddingBottom: "0px" }}
                  >
                    <div className="orderDetailHeader">
                      <div className="title">
                        <Space>
                          <h3>No Plan Attached</h3>
                          <StyledTag>• Detached</StyledTag>
                        </Space>
                        <CustomButton
                          text={"Attach Plan"}
                          isGrey={true}
                          icon={<PlusOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/customers/${customerId}/accounts/${accountId}/attach-price-plan?startDate=${plan?.gapPeriod?.from}&endDate=${plan?.gapPeriod?.to}`
                            );
                          }}
                          position="end"
                        />
                      </div>
                      <div
                        className="orderDetailItem"
                        style={{ marginTop: "0.5rem" }}
                      >
                        <ScheduleData>
                          <p>
                            <CalendarOutlined />
                          </p>
                          <p>
                            {moment(plan?.gapPeriod?.from).format(
                              "MMM D, YYYY"
                            )}
                          </p>
                          <p>
                            <ArrowDownOutlined rotate={270} />
                          </p>
                          <p>
                            {moment(plan?.gapPeriod?.to).format("MMM D, YYYY")}
                          </p>
                        </ScheduleData>
                      </div>
                    </div>
                  </div>
                </StyledCard>
              </Col>
            )}
          </>
        );
      })}{" "}
    </>
  ) : (
    <EmptyDataComponent
      title="No Price Plan Attached"
      description="Please attach a price plan to this account to initiate billing."
      addButton={AddNewPlan}
    />
  );
};

export default ScheduleCard;
