import React, { ReactNode, useRef, useState, useEffect } from "react";
import { Button } from "antd";
import { FilterPanel, StyledButton } from "./styles";
import { FilterOutlined } from "@ant-design/icons";

const FilterDropdown: React.FC<{
  children: ReactNode;
  onSubmitFilter?: () => void;
  isFilterApplied?: boolean;
  resetFilter: () => void
}> = ({ children, onSubmitFilter, isFilterApplied = false ,resetFilter}) => {
  const [visible, setVisible] = useState(false);
  const panelRef = useRef<HTMLDivElement>(null);

  const toggleVisibility = () => {
    setVisible((prev) => !prev);
  };

  // const handleClickOutside = (event: MouseEvent) => {
  //   const target = event?.target as Node;
  //   if (
  //     panelRef?.current &&
  //     !panelRef?.current?.contains(target) &&
  //     !(target as HTMLElement).closest(".ant-select-dropdown , .ant-picker-dropdown")
  //   ) {
  //     setVisible(false);
  //     // resetFilter()
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <div style={{ position: "relative" }} ref={panelRef}>
      <StyledButton
        icon={<FilterOutlined className="icon" />}
        iconPosition="end"
        type="default"
        onClick={toggleVisibility}
        isFilterApplied = {isFilterApplied}
      >
        <span className="btnText">Filter</span>
      </StyledButton>

      <FilterPanel visible={visible}>
        <div className="action">
          <div onClick={onSubmitFilter}>
            <Button
              type="link"
              onClick={() => setVisible(false)}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className="childrenContainer">{children}</div>
      </FilterPanel>
    </div>
  );
};

export default FilterDropdown;
