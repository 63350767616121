import { Col, Form, Input, Radio, Row, Select } from "antd";
import { useEffect, useState } from "react";
import AddonItem from "../AddonItem";
import Extra from "../../../../../../components/common/extra";
import CustomButton from "../../../../../../components/common/button";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import SpecificUsage from "./SpecificUsage";
import { CreditGrantFormContainer } from "./Styles";
import { useSearchParams } from "react-router-dom";
import { StyledRadio } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { setEditCardData } from "../../../../../../redux/feature/pricePlan/pricePlanSlice";

interface Field {
  id: number;
}
const Option = Select.Option;

const CreditGrant: React.FC<any> = ({
  addonValue,
  setAddonValue,
  setExpiryType,
  expiryType,
  errorMessage,
  specificCreditGrantValues,
  setSpecificCreditGrantValues,
  creditType,
  setCreditType,
  form1,
}) => {
  const [isSpecificUsage, setIsSpecificUsage] = useState(false);
  const [selectedUsage, setSelectedUsage] = useState<string[]>([]);
  const [selectedAddOns, setSelectedAddOns] = useState<string[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [creditGrantType, setCreditGrantType] = useState("ONETIME");
  const editCardData = useSelector(
    (state: RootState) => state.pricePlan.editCardData
  );
  const dispatch = useDispatch();
  const type = useSearchParams()[0].get("plan-type");

  useEffect(() => {
    if (Object.keys(editCardData).length >0) {
      setCreditGrantType(editCardData?.type ?? "ONETIME");
      setExpiryType(editCardData?.expiry_type ?? "NO_EXPIRY");
      setCreditType(editCardData?.creditID?.value);
    } else {
      setSelectedAddOns(specificCreditGrantValues?.add_on ?? []);
      setSelectedUsage(specificCreditGrantValues?.usage_base ?? []);
    }
  }, [specificCreditGrantValues, editCardData]);

  useEffect(() => {
    if (!Object.keys(editCardData).length) {
      setCreditType(form1.getFieldValue("creditID"));
    }
  }, [form1]);

  useEffect(() => {
    if (Object.keys(editCardData).length >0) {
      setSelectedAddOns(editCardData?.creditID?.add_on ?? []);
      setSelectedUsage(editCardData?.creditID?.usage_base ?? []);
    }
  }, [editCardData]);

  return (
    <CreditGrantFormContainer>
      <AddonItem
        editCardData={editCardData}
        addonValue={addonValue}
        setAddonValue={setAddonValue}
      />
      <Form.Item
        label="Rate Card Name"
        name="displayName"
        rules={[
          {
            required: true,
            message: "Please input the rate card name!",
          },
        ]}
      >
        <Input placeholder="Rate Card Name" />
      </Form.Item>
      <Form.Item
        label="Credit Grant Type"
        name="type"
        hidden={type !== "RECURRING"}
        initialValue="ONETIME"
        extra={
          <Extra text=" Credits will be granted only for the first pricing cycle" />
        }
      >
        <StyledRadio
          defaultValue={creditGrantType}
          onChange={(e: any) => {
            setCreditGrantType(e.target.value);
          }}
        >
          <Radio value="ONETIME">One Time</Radio>
          <Radio value="RECURRING">Recurring</Radio>
        </StyledRadio>
      </Form.Item>
      {creditGrantType === "RECURRING" && (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Recurrence Interval"
              name="interval"
              rules={[
                {
                  pattern: /^(?!0\d)([1-9]\d*|0)?(\.\d+)?$/,
                  message: "Please enter valid input.",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Recurrence Interval"
                width={"50%"}
                onWheel={(e: any) => e.target.blur()}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Recurrence Offset"
              name="offset"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const interval = getFieldValue("interval");
                    if (value && interval) {
                      if (Number(value) < Number(interval)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(`must be <= ${interval - 1}`)
                      );
                    }
                  },
                }),
                {
                  pattern: /^(?!0\d)([0-9]\d*|0)?(\.\d+)?$/,
                  message: "Please enter valid input.",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Recurrence Offset"
                onWheel={(e: any) => e.target.blur()}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item
        label="Expiry type"
        name="expiry_type"
        initialValue={expiryType}
      >
        <StyledRadio
          defaultValue={expiryType}
          onChange={(e: any) => {
            setExpiryType(e.target.value);
          }}
        >
          {type === "RECURRING" && (
            <Radio value="PRICING_CYCLE">Pricing Cycle</Radio>
          )}
          <Radio value="NO_EXPIRY">No Expiry</Radio>
          <Radio value="CUSTOM">Custom</Radio>
        </StyledRadio>
      </Form.Item>
      {expiryType === "CUSTOM" && (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Expiry Duration"
              name="expiry_duration"
              rules={[
                {
                  required: true,
                  message: "is required",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Expiry Duration"
                onWheel={(e: any) => e.target.blur()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Expiry Interval"
              name="expiry_interval"
              rules={[
                {
                  required: true,
                  message: "is required",
                },
              ]}
            >
              <Select placeholder="Expiry Interval">
                <Option value="D">Days</Option>
                <Option value="M">Months</Option>
                <Option value="Y">Years</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item
        label="Priority"
        name="priority"
        rules={[
          {
            required: true,
            message: "Please input the priority!",
          },
          {
            pattern: /^(?!0\d)([1-9]\d*|0)?(\.\d+)?$/,
            message: "Please enter valid input.",
          },
        ]}
        extra={
          <Extra text=" Lower number equals higher priority. Range 1-100" />
        }
      >
        <Input
          type="number"
          min={1}
          placeholder="Priority"
          onWheel={(e: any) => e.target.blur()}
        />
      </Form.Item>
      <Form.Item
        label="Apply Credits to"
        name="creditID"
        extra={
          <Extra text=" Choose the items that this Credit will be applied to." />
        }
        rules={[
          {
            required: true,
            message: "Please apply credit!",
          },
        ]}
      >
        <Select
          placeholder="Apply Credits to"
          onChange={(e: any) => {
            setCreditType(e);
            if (
              Object.keys(editCardData).length > 0 &&
              e === "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES"
            ) {
              dispatch(
                setEditCardData({
                  ...editCardData,
                  creditID: {
                    ...editCardData?.creditID,
                    value: e,
                  },
                })
              );
            } else if (Object.keys(editCardData).length > 0) {
              dispatch(
                setEditCardData({
                  ...editCardData,
                  creditID: {
                    value: e,
                  },
                })
              );
            }
          }}
        >
          <Option value="ADD_ONS_AND_FEATURES">
            All Usage Meters, Add-Ons & Features
          </Option>
          <Option value="ALL_USAGE_METERS">All Usage Meters</Option>
          <Option value="ALL_FIXED_FEES">All Fixed Fees</Option>
          <Option value="ALL_LICENSE_FEES">All License Fees</Option>
          <Option value="ALL_FEATURES">All Features</Option>
          <Option value="ALL_CREDIT_GRANT_FEES">All Credit Grant Fees</Option>
          <Option value="ALL_CREDIT_GRANT_FEES_EXCLUDING_SELF">
            All Credit Grant Fees Excluding Self
          </Option>
          <Option value="SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES">
            Specific Usage Meters, Add-Ons & Features
          </Option>
        </Select>
      </Form.Item>
      {creditType === "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES" && (
        <Form.Item>
          <CustomButton
            text={
              selectedUsage?.length ||
              selectedFeatures?.length ||
              selectedAddOns?.length
                ? `${
                    selectedUsage?.length +
                    selectedAddOns?.length +
                    selectedFeatures?.length
                  } items Selected`
                : " Specific Usage Meters, Add-Ons & Features"
            }
            icon={selectedUsage?.length ? <EditOutlined /> : <PlusOutlined />}
            onClick={() => {
              setIsSpecificUsage(true);
            }}
            type="default"
            position="end"
          />
          {errorMessage && (
            <div style={{ color: "red", marginBottom: "16px" }}>
              {errorMessage}
            </div>
          )}
        </Form.Item>
      )}
      {isSpecificUsage && (
        <SpecificUsage
          setIsSpecificUsage={setIsSpecificUsage}
          setSelectedRowKeys={setSelectedUsage}
          setSelectedAddOns={setSelectedAddOns}
          setSelectedFeatures={setSelectedFeatures}
          selectedAddOns={selectedAddOns}
          selectedFeatures={selectedFeatures}
          selectedRowKeys={selectedUsage}
          setSpecificCreditGrantValues={setSpecificCreditGrantValues}
          specificCreditGrantValues={specificCreditGrantValues}
        />
      )}
    </CreditGrantFormContainer>
  );
};

export default CreditGrant;
