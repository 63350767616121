import {
  Input,
  InputRef,
  Table,
  TableColumnsType,
  TableColumnType,
  Tag,
} from "antd";
import {
  CloseCircleOutlined,
  CopyOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useLocation, useNavigate } from "react-router-dom";
import { EmptyData } from "../../../components/common/empty";
import { useDispatch, useSelector } from "react-redux";
import UserAvatar from "../../../components/common/avatar";
import { Container, SearchInput, UserNameContainer } from "./Styles";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  emptyViewCustomerDetails,
  setFilter,
  setIsNewCustomer,
} from "../../../redux/feature/customer/customerSlice";
import {
  getAllCustomer,
  getAllCustomerAccounts,
  getCustomerById,
} from "../../../redux/feature/customer/customerAsyncThunk";
import { StyledTable } from "../../../components/common/table/styles";
import { formatDateToLocalTime } from "../../../utils/helper";
import CustomerHeader from "./CustomHeader";

type CustomerColumnsType = {
  key: React.Key;
  name: string;
  customer_id?: string;
  created_at: string;
};
type DataIndex = keyof CustomerColumnsType;

const Customers = ({ currentTabKey }: { currentTabKey?: string }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState(searchTerm);

  const { customers, filter, isApiCalling } = useSelector(
    (state: RootState) => state.customer
  );

  const columns: TableColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <UserNameContainer>
          <UserAvatar user={record} />
          <div className="textContainer">
            <div className="name">{record?.name}</div>
            <div className="email">{record?.email}</div>
          </div>
        </UserNameContainer>
      ),
    },
    {
      title: "Customer ID",
      dataIndex: "billoptim_customer_id",
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      render: (text: string, record: any) => formatDateToLocalTime(text),
    },
  ];

  useEffect(() => {
    const handler = setTimeout(() => setSearchValue(searchTerm), 1000);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    if (currentTabKey === "1")
      dispatch(
        getAllCustomer({
          sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
          search: searchValue,
        })
      );
  }, [currentTabKey, filter, searchValue.length]);

  const filteredCustomers = useMemo(() => {
    return customers?.map((item: any) => item);
  }, [customers]);

  const handleRowClick = (record: any) => {
    console.log("Clicked row data:", record);
  };
  return (
    <div style={{ padding: "0.5rem 0px" }}>
      <CustomerHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        currentTabKey={currentTabKey}
        tooltipText="Customer name, Email, Billing address"
      />
      {isApiCalling ? (
        <StyledTable
          pagination={false}
          columns={columns}
          loading={true}
          dataSource={[]}
          size="middle"
        />
      ) : filteredCustomers.length > 0 ? (
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={filteredCustomers}
          isCursorPointerShow
          // loading={isApiCalling}
          size="middle"
          onRow={(record: any) => ({
            onClick: () => {
              dispatch(emptyViewCustomerDetails());
              navigate(`${location.pathname}/${record?.customer_id}/details`);
            },
          })}
        />
      ) : (
        <EmptyData
          description="No Customer Added"
          btnText="New Customer"
          onClick={() => {
            dispatch(setIsNewCustomer(true));
          }}
        />
      )}
    </div>
  );
};

export default Customers;
