import { Input, Tooltip } from "antd";
import styled from "styled-components";

export const StyledInput = styled(Input)`
  width: ${(props) => props.width};
  height: 30px;
  align-items: center;

    .suffix{
      font-size: 12px;
      display: flex;
      gap: 10px;
      color: #A2A4B8;
      .tooltip .ant-tooltip-inner{
        font-size:8px;
      }
     
    }
     .ant-tooltip-inner{
      }`;

export const StyledDiv = styled.div`  
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  p{
    color: #475569;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const SupportedFields = styled.div`
  p,span{
    font-size: 13px !important;
  }
    p{
      font-weight:500;
    }
`;
