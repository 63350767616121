import { Tag } from "antd";
import { StyledTable } from "../../../../components/common/table/styles";
import { EmptyData } from "../../../../components/common/empty";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useMemo } from "react";
import { StyledTag } from "../../../../components/common/tag/styles";

const AssociatedUSageMeterDrawer: React.FC<{ data: any }> = React.memo(
  ({ data }) => {
    const columns = useMemo(() => {
      return [
        {
          title: "Usage Meter Name",
          dataIndex: "name",
          key: "name",
          render: (name: string) => (
            <div>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "black",
                }}
              >
                {name}
              </p>
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#475569",
                }}
              >
                Counter
              </span>
            </div>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (status: string) => (
            <StyledTag color={status === "ACTIVE" ? "green" : "orange"}>
              • {status}
            </StyledTag>
          ),
        },
        {
          dataIndex: "status",
          key: "actions",
          render: () => <RightOutlined />,
        },
      ];
    }, []);
    const navigate = useNavigate();

    const handleRowClick = useCallback(
      (record: any) => {
        navigate(`/usage-meter/${record?.usageMeterId}`);
      },
      [navigate]
    );

    return (
      <div style={{ padding: "24px" }}>
        {data.length ? (
          <StyledTable
            pagination={false}
            columns={columns}
            dataSource={data}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  handleRowClick(record);
                },
              };
            }}
            isCursorPointerShow
          />
        ) : (
          <EmptyData description="No Records Found" />
        )}
      </div>
    );
  }
);

export default AssociatedUSageMeterDrawer;
