import styled from "styled-components";

export const NewPricePlanContainer = styled.div`
  & .pricePlanHeader {
    padding:1rem 7rem;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .edit_btn{
    display: flex;
    align-items: center;
    gap: 10px;
 
  }

  .attachPlanHeader {
    background-color: white;
    display: flex;
    padding: 16px 5rem;
    flex-direction: column;

     .ant-breadcrumb{
    font-size: 12px;
    margin-bottom: 10px;
  }
  .ant-breadcrumb-link:hover{
    background: transparent !important;
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-breadcrumb-separator{
    color: black;
  }
  }
  .attachPlanHeaderTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .title {
    font-size: 0.9rem;
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 700;
  }
  .buttonContainer {
    display: flex;
    gap: 10px;
  }
`;

export const PricePlanContainer = styled.div`
  padding: 1.37rem 5rem;
  background-color: #f1f5f9;
  height: calc(100dvh - 97px);

  .contentContainer {
    display: flex;
    align-items: center;
    height: 100%;
    box-shadow: 0px 2px 8px 0px #cbd5e1;
    background-color: white;
    border-radius: 12px;
  }
  .stepsContainer {
    padding: 24px;
    align-self: baseline;
  }
  .formContainer {
    width: 100%;
    border-left: 1px solid #cbd5e1;
    height: 750px;
  }
`;
export const PlanPreviewContainer = styled.div`
  width: 100%;
  align-self: baseline;

  .previewItem {
    border-bottom: 1px solid #cbd5e1;
    padding: 16px;

    h3 {
      margin: 0;
      margin-bottom: 0.5rem;
    }
    .planType {
      margin-top: 12px;
      border-radius: 10px;
      border: 1px solid #cbd5e1;
      width: max-content;
      padding: 0 0.5rem;
      font-size: 13px;
    }
  }

  .rateCardPreviewContainer {
    .selectContainer {
      /* width: 100%; */
      padding: 1rem 1rem 0.5rem 1rem;
    }
    .select {
      width: 100%;
    }
    .rateCardPreviewItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1.5rem;
      padding-bottom: 0;
      font-size: 12px;
      font-weight: 600;
    }
    .rateCardPreviewItemCurrency {
      span {
        font-size: 11px;
        display: block;
        text-align: right;
      }
    }
    .rateCardPreviewHeader {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 600;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
      padding: 0.7rem 1.5rem;
    }
  }
`;
export const PricePlanFormHeader = styled.div`
  text-transform: uppercase;
  border-bottom: 1px solid #cbd5e1;
  padding: 0.7rem 1.5rem; 
  font-size: 13px;
`;

