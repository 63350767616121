import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Form,
  Input,
  Radio,
  Button,
  Select,
  Checkbox,
  Tooltip,
  InputNumber,
  Switch,
  RadioChangeEvent,
  Typography,
} from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { StyledForm } from "./Styles";
import { useDispatch, useSelector } from "react-redux";
import TextedSwitch from "../../../../components/common/textedSwitch";
import BillingCycleForm from "../../../../components/BillingCycleForm";
import { StyledRadio } from "../RateCard/forms/styles";
import { AppDispatch, RootState } from "../../../../redux/store";
import { setMidCyclePricing } from "../../../../redux/feature/pricePlan/pricePlanSlice";

interface ConfigItem {
  label: string;
  name: string;
  options: any;
}
const { Text } = Typography;


export const billingCycleOptionsArray = [
  "Weekly",
  "Monthly",
  "Quarterly",
  "Half Yearly",
  "Annually",
];
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const PricePlanForm: React.FC<any> = ({
  form,
  setIsMidCyclePricing,
  isMidCyclePricing,
  pricePlanType,
  setPricePlanType,
  isClone
}) => {
  const [anniversaryCycle, setAnniversaryCycle] = useState(false);
  const [deferredRevenue, setDeferredRevenue] = useState(false);
  const [isDescriptionShown, setIsDescriptionShown] = useState(false);
  const [billingCycle, setBillingCycle] = useState("MONTHLY");
  const { AccountCurrency, currencies } = useSelector(
    (state: any) => state.adminCenter
  );
  const { billingDetails} = useSelector(
    (state: RootState) => state.pricePlan
  );
  const dispatch = useDispatch<AppDispatch>();
  const planTypeHandler = (e: RadioChangeEvent) => {
    setPricePlanType(e.target.value);
  };
  const midCycleHandler = () => {
    setIsMidCyclePricing(!isMidCyclePricing);
    dispatch(setMidCyclePricing(!isMidCyclePricing));
  };

  const cycleHandler = () => {
    setAnniversaryCycle(!anniversaryCycle);
  };
  const billingCycleHandler = (e: RadioChangeEvent) => {
    setBillingCycle(e.target.value);
  };

  const rules = useMemo(() => {
    return [{ required: true, message: "Field is required!" }];
  }, []);

  useEffect(() => {
    if(isClone){
      const { name, description, type, anniversary_cycle, supportedCurrencies, gracePeriod ,billingCycle} = billingDetails;
      form.setFieldsValue({ name, description, type, anniversary_cycle, supportedCurrencies, gracePeriod ,billingCycle});
      setPricePlanType(type);
      setBillingCycle(billingCycle)

    }
  }, [billingDetails]);

  const currenciesOptions = useMemo(() => {
    const accountCurrencyIds = new Set(
      AccountCurrency.map((ac: any) => ac?.currency_id)
    );

    return currencies
      .filter((currency: any) => accountCurrencyIds.has(currency?.currency_id))
      .map((currency: any) => (
        <Select.Option
          value={currency?.currency_code}
          key={currency?.currency_code}
        >
          {currency?.currency_code}-{currency?.currency_name}
        </Select.Option>
      ));
  }, [AccountCurrency, currencies]);
  const billingCycleOptions = useMemo(
    () =>
      billingCycleOptionsArray.map((cycle) => (
        <Radio
          key={cycle}
          value={cycle === "Half Yearly" ? "HALF_YEARLY" : cycle.toUpperCase()}
        >
          {cycle}
        </Radio>
      )),
    []
  );

  

  return (
    <StyledForm
      form={form}
      layout="vertical"
    >
      <Form.Item
        label="Price Plan Name"
        name="name"
        rules={rules}
        className="name"
      >
        <Input placeholder="Eg. Growth" style={{ maxWidth: "500px" }} />
      </Form.Item>
      {isDescriptionShown ? (
        <Form.Item
          label="Description"
          className="descriptionField"
          name="description"
        >
          <Input.TextArea
            rows={3}
            placeholder="Description"
            style={{ maxWidth: "500px" }}
          />
        </Form.Item>
      ) : (
        <p
          onClick={() => setIsDescriptionShown(true)}
          style={{ cursor: "pointer" }}
          className="addDescription"
        >
          {" "}
          <PlusOutlined /> <span className="text"> Add Discription</span>
        </p>
      )}

      <Form.Item
        label="Price Plan Type"
        name="type"
        initialValue={pricePlanType}
      >
        <StyledRadio onChange={planTypeHandler}>
          <Radio value="RECURRING">Recurring</Radio>
          <Radio value="ONE_TIME">One-Time</Radio>
        </StyledRadio>
      </Form.Item>

      {pricePlanType === "RECURRING" && (
        <>
          <Form.Item
            label="Billing Cycle(s)"
            name="billingCycle"
            initialValue={billingCycle}
          >
            <StyledRadio onChange={billingCycleHandler}>
              {billingCycleOptions}
            </StyledRadio>
          </Form.Item>

          <Form.Item
            name="anniversary_cycle"
            valuePropName="checked"
            extra={
              <div className="extraContent">
                <InfoCircleOutlined style={{ height: "12px", width: "12px" }} />{" "}
                <span>
                  If enabled, startOffsets will be calculated based on the date
                  of price plan association and these offsets will be applied as
                  an override.
                </span>
              </div>
            }
          >
            <TextedSwitch text="Anniversary Cycle" onChange={cycleHandler} />
          </Form.Item>

          {/* {!anniversaryCycle && billingCycleDayOptions(billingCycle)} */}
          {!anniversaryCycle && (
            <BillingCycleForm billingCycle={billingCycle} form={form} />
          )}
        </>
      )}

      <Form.Item label="Currencies" name="supportedCurrencies" rules={rules}>
        <Select
          mode="multiple"
          placeholder="USD/INR etc."
          style={{ maxWidth: "500px" }}
        >
          {currenciesOptions}
          {/* <Select.Option value="aaa">aaa</Select.Option> */}
        </Select>
      </Form.Item>

      {pricePlanType === "RECURRING" && (
        <>
          <Form.Item
            label="Grace Period (Days)"
            name="gracePeriod"
            rules={[
              {
                pattern: /^(0|[1-9]\d*)$/,
                message: "Please enter a valid number!",
              },
            ]}
            initialValue={0}
            extra={
              <div className="extraContent">
                <InfoCircleOutlined style={{ height: "12px", width: "12px" }} />{" "}
                <span>
                  Invoices will be generated after the grace period. Corrections
                  can be made till the grace period is over.
                </span>
              </div>
            }
          >
            <Input
              type="number"
              min={0}
              placeholder="Days"
              style={{ width: "45%" }}
              onWheel={(e: any) => e.target.blur()}
              suffix={
                <Text
                  style={{
                    color: "rgba(0,0,0,0.5)",
                    marginRight: "0.5rem",
                  }}
                >
                  Days
                </Text>
              }
            />
          </Form.Item>
          <Form.Item
            name="deferredRevenue"
            valuePropName="checked"
            initialValue={true}
            extra={
              <div className="extraContent">
                <InfoCircleOutlined style={{ height: "12px", width: "12px" }} />{" "}
                <span>
                  Revenue is computed near real-time by default. Disabling this
                  allows to compute revenue at the end of the cycle.
                </span>
              </div>
            }
          >
            <TextedSwitch
              text="View real-time revenue analytics"
              checked={true}
            />
          </Form.Item>{" "}
          <Form.Item
            name="allowOngoingCycleUpdates"
            valuePropName="checked"
            initialValue={false}
            extra={
              anniversaryCycle && (
                <div className="extraContent">
                  <InfoCircleOutlined
                    style={{ height: "12px", width: "12px" }}
                  />{" "}
                  <span>
                    This option allows to edit the price plan during an ongoing
                    schedule. Any events ingested in the cycle would be
                    recomputed after the changes.
                  </span>
                </div>
              )
            }
          >
            <TextedSwitch
              text="Allow mid-cycle pricing change"
              onChange={midCycleHandler}
            />
          </Form.Item>{" "}
        </>
      )}
    </StyledForm>
  );
};

export default PricePlanForm;
