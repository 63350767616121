import { Form, Input, Select } from "antd";
import { NewSettingFormContainer } from "./styles";
import { useState } from "react";
import { Editor } from "@monaco-editor/react";
import CustomButton from "../../../components/common/button";
import CodeEditor from "../../../components/common/codeEditor";

const NewSettingForm: React.FC<{ toggleContent: () => void }> = ({
  toggleContent,
}) => {
  const [selectedType, setSelectedType] = useState<string | null>("str");
  const [code, setCode] = useState("");

  const handleChange = (value: string) => {
    setSelectedType(value);
  };

  const handleEditorChange = (value: string | undefined) => {
    if (value !== undefined) {
      setCode(value);
    }
  };

  const listData = [
    { value: "json", label: "JSON" },
    { value: "j-logic", label: "Json Logic" },
    { value: "num", label: "Numeric" },
    { value: "str", label: "String" },
  ];
  return (
    <NewSettingFormContainer>
      <h3>New Setting</h3>
      <div className="formFields">
        <Form
          layout="vertical"
          requiredMark={false}
          style={{ padding: "20px" }}
        >
          <Form.Item label="Namespace" name="namespace">
            <Select
              options={[{ value: "user", label: "User" }]}
              defaultValue="user"
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input Name First!" }]}
          >
            <Input placeholder="Name" type="text" />
          </Form.Item>
          <Form.Item
            label="ID"
            name="id"
            rules={[{ required: true, message: "Please input ID First!" }]}
          >
            <Input placeholder="ID" type="text" />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Please Select Type First!" }]}
          >
            <Select
              placeholder="Choose Type"
              onChange={handleChange}
              options={listData}
              defaultValue={selectedType}
            />
          </Form.Item>
          {selectedType === "json" || selectedType === "j-logic" ? (
            <Form.Item >
              {/* <div
                style={{
                  borderRadius: "8px",
                  overflow: "hidden",
                  border: "1px solid #d9d9d9",
                }}
              >
                <Editor
                  language="json"
                  height={200}
                  value={code}
                  onChange={handleEditorChange}
                  options={{
                    lineNumbers: "on",
                    roundedSelection: false,
                    scrollBeyondLastLine: false,
                    theme: "vs-light",
                  }}
                />
              </div> */}
              <CodeEditor title="Value"/>
            </Form.Item>
          ) : (
            <Form.Item
              label="Value"
              name="value"
              rules={[{ required: true, message: "Please input value First!" }]}
            >
              <Input placeholder="Value" type="text" />
            </Form.Item>
          )}
          <div className="actionButtons">
            <CustomButton text="Cancel" icon={false} type="default"  onClick={toggleContent}/>
            <CustomButton text="Save" icon={false} />
          </div>
        </Form>
      </div>
    </NewSettingFormContainer>
  );
};

export default NewSettingForm;
