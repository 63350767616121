import { Alert, DatePicker, Form, Input, Select } from "antd";
import CustomDrawer from "../../../../components/common/drawer";
import Extra from "../../../../components/common/extra";
import TextedSwitch from "../../../../components/common/textedSwitch";
import { useEffect, useMemo, useState } from "react";
import useSelection from "antd/es/table/hooks/useSelection";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/common/button";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import SpecificUsage from "../../../price-plans/NewPricePlan/RateCard/forms/CreditGrant/SpecificUsage";
import dayjs, { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";
import { createAddons } from "../../../../redux/feature/billableItems/billableThunk";
import { createCredit, getCreditBalance } from "../../../../redux/feature/customer/customerAsyncThunk";
import PageLoading from "../../../../components/Loader";

type AddCreditDrawerPropsType = {
  isOpen: boolean;
  onClose: () => void;
};

const { Option } = Select;

const AddCreditDrawer: React.FC<AddCreditDrawerPropsType> = ({
  isOpen,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [hasExpiryChecked, setHasExpiryChecked] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState();
  const { addOns } = useSelector((state: RootState) => state.billable);
  const { AccountCurrency } = useSelector(
    (state: RootState) => state.adminCenter
  );
  const { loading ,viewAccountDetails} = useSelector((state: RootState) => state.customer);
  const [isSpecificUsage, setIsSpecificUsage] = useState(false);
  const [selectedUsage, setSelectedUsage] = useState<string[]>([]);
  const [selectedAddOns, setSelectedAddOns] = useState<string[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [creditType, setCreditType] = useState("");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const { accountId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const hasExpiryCheckedHandler = () => {
    setHasExpiryChecked(!hasExpiryChecked);
  };

  const handleStartDateChange = (date: Dayjs) => {
    setStartDate(date);
  };

  useEffect(() => {
    if (selectedAddOns.length || selectedUsage.length) setErrorMessage("");
  }, [selectedAddOns, selectedUsage]);

  const addonsOptions = useMemo(() => {
    const creditGrantOptions = addOns
      .filter((addOn: any) => addOn.type === "CREDIT_GRANT")
      .map((addOn: any) => ({
        value: addOn.addOnId,
        label: addOn.name,
      }));

    return [
      ...creditGrantOptions,
      { value: "create_new", label: "Create New Add On" },
    ];
  }, [addOns]);

  const currencyCode = useMemo(() => {
    return AccountCurrency.find((currency: any) => currency.is_base);
  }, [AccountCurrency]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (
        values.creditID === "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES" &&
        !selectedAddOns.length &&
        !selectedUsage.length
      ) {
        setErrorMessage(
          "Please select at least one Add-On Or one Usage Meter."
        );
        return false;
      }
      let addOnData: any;
      if (selectedAddon === "create_new") {
        const addonPayload = {
          type: "CREDIT_GRANT",
          name: values.addon_name,
        };
        addOnData = await dispatch(createAddons(addonPayload));
      }
      const payload = {
        accountId: accountId,
        grantorId:
          selectedAddon === "create_new"
            ? addOnData?.payload
            : values.credit_addon,
        creditAmount: values.credit_mount,
        effectiveFrom: dayjs(values.effectiveFrom).format("YYYY-MM-DD"),
        priority: values.priority,
        applicable_entity_ids: {
          value: values.creditID,
          ...(values.creditID ===
            "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES" && {
            usage_base: selectedUsage,
            add_on: selectedAddOns,
          }),
        },
        ...(hasExpiryChecked && {
          effectiveUntil: dayjs(values.effectiveUntil).format("YYYY-MM-DD"),
        }),
      };
      await dispatch(createCredit({ creditRequests: [payload] }));
      await dispatch(getCreditBalance(accountId));

      form.resetFields();
      setSelectedAddOns([]);
      setSelectedUsage([]);
      onClose();
    } catch (error) {
      console.log("Submission error:", error);
    }
  };

  return (
    <CustomDrawer
      title="Add Credits"
      isOpen={isOpen}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      submitText="Add Credit"
      onFinish={handleSubmit}
    >
      {loading && <PageLoading />}
      <Form layout="vertical" style={{ padding: "20px" }} form={form}>
        <Form.Item
          label="Choose Credit Addon"
          name="credit_addon"
          rules={[
            { required: true, message: "Please Choose Credit Addon First!" },
          ]}
          extra={
            <Extra text="You can choose a credit grant from the add-ons list or create a new one here." />
          }
        >
          <Select
            placeholder="Choose Credit Addon"
            onChange={(e) => {
              setSelectedAddon(e);
            }}
          >
            {addonsOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {selectedAddon === "create_new" && (
          <Form.Item
            label="Credit Add-On Name"
            name="addon_name"
            rules={[
              { required: true, message: "Please input addon name First!" },
            ]}
            extra={
              <Extra text="This will be saved to the global Credit Grant Add-Ons list" />
            }
          >
            <Input placeholder="Credit Add-On Name" />
          </Form.Item>
        )}
        <Form.Item
          label="Credit Amount"
          name="credit_mount"
          rules={[
            { required: true, message: "Please input Credit Amount First!" },
            {
              pattern: /^(0|[1-9]\d*)$/,
              message: "Please enter a valid number!",
            },
          ]}
        >
          <Input
            suffix={<span>{viewAccountDetails?.currency_code}</span>}
            placeholder="Credit Amount"
            type="number"
            onWheel={(e:any) => e.target.blur()}
          />
        </Form.Item>
        <Form.Item
          label="Start Date"
          name="effectiveFrom"
          rules={[
            { required: true, message: "Please input Start Date First!" },
          ]}
        >
          <DatePicker
            suffixIcon={null}
            placeholder="Start Date"
            style={{ width: "100%" }}
            onChange={handleStartDateChange}
          />
        </Form.Item>
        <Form.Item>
          <TextedSwitch
            text="Has Expiry"
            checked={hasExpiryChecked}
            onChange={hasExpiryCheckedHandler}
          />
        </Form.Item>
        {hasExpiryChecked && (
          <Form.Item
            label="Expiry Date"
            name="effectiveUntil"
            rules={[
              { required: true, message: "Please input Expiry Date First!" },
            ]}
          >
            <DatePicker
              suffixIcon={null}
              placeholder="Expiry Date"
              style={{ width: "100%" }}
              disabledDate={(current) => {
                return current && startDate ? current < startDate : false;
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Priority"
          name="priority"
          extra={
            <Extra text="Lower number equals higher priority. Range 1-100" />
          }
          rules={[{ required: true, message: "Please input Priority First!" }]}
        >
          <Input placeholder="Priority" />
        </Form.Item>
        <Form.Item
          label="Apply Credits to"
          name="creditID"
          extra={
            <Extra text=" Choose the items that this Credit will be applied to." />
          }
          rules={[
            {
              required: true,
              message: "Please apply credit!",
            },
          ]}
          initialValue="ADD_ONS_AND_FEATURES"
        >
          <Select
            placeholder="Apply Credits to"
            onChange={(e: any) => {
              setCreditType(e);
            }}
            defaultValue='ADD_ONS_AND_FEATURES'
          >
            <Option value="ADD_ONS_AND_FEATURES">
              All Usage Meters, Add-Ons & Features
            </Option>
            <Option value="ALL_USAGE_METERS">All Usage Meters</Option>
            <Option value="ALL_FIXED_FEES">All Fixed Fees</Option>
            <Option value="ALL_LICENSE_FEES">All License Fees</Option>
            <Option value="ALL_FEATURES">All Features</Option>
            <Option value="ALL_CREDIT_GRANT_FEES">All Credit Grant Fees</Option>
            <Option value="ALL_CREDIT_GRANT_FEES_EXCLUDING_SELF">
              All Credit Grant Fees Excluding Self
            </Option>
            <Option value="SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES">
              Specific Usage Meters, Add-Ons & Features
            </Option>
          </Select>
        </Form.Item>
        {creditType === "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES" && (
          <>
            <Form.Item>
              <CustomButton
                text={
                  selectedUsage.length ||
                  selectedFeatures.length ||
                  selectedAddOns.length
                    ? `${
                        selectedUsage.length +
                        selectedAddOns.length +
                        selectedFeatures.length
                      } items Selected`
                    : " Specific Usage Meters, Add-Ons & Features"
                }
                icon={
                  selectedUsage.length ||
                  selectedFeatures.length ||
                  selectedAddOns.length ? (
                    <EditOutlined />
                  ) : (
                    <PlusOutlined />
                  )
                }
                onClick={() => {
                  setIsSpecificUsage(true);
                }}
                type="default"
                position="end"
              />
            </Form.Item>
            {errorMessage && (
              <div style={{ color: "red", marginBottom: "16px" }}>
                {errorMessage}
              </div>
            )}
          </>
        )}

        {isSpecificUsage && (
          <SpecificUsage
            setIsSpecificUsage={setIsSpecificUsage}
            setSelectedRowKeys={setSelectedUsage}
            setSelectedAddOns={setSelectedAddOns}
            setSelectedFeatures={setSelectedFeatures}
            selectedAddOns={selectedAddOns}
            selectedFeatures={selectedFeatures}
            selectedRowKeys={selectedUsage}
          />
        )}

        <Alert
          message="Credits cannot be edited once added."
          type="warning"
          showIcon
        />
      </Form>
    </CustomDrawer>
  );
};

export default AddCreditDrawer;
