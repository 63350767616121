import React, { useState } from "react";
import { Card, Button, Tag } from "antd";
import styled from "styled-components";
import { PricingLogCard } from "./styles";
import {
  ArrowRightOutlined,
  ExpandAltOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { StyledTag } from "../../../../components/common/tag/styles";
import { EmptyDataComponent } from "../../../../components/common/empty";

const StyledCard = styled(Card)`
  width: 400px;
  margin: 20px;
`;

const ComputationDetails = styled.div`
  margin-top: 10px;
`;

const ResultText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #444;
`;

const ComputationExpression = styled.div`
  font-size: 12px;
  background-color: #f9f9f9;
  padding: 8px;
  border-radius: 4px;
`;

const ExpandButton = styled(Button)`
  margin-top: 10px;
`;

const CollapsibleCard: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    // <PricingLogCard>
    //   <div className="cardNo">1</div>
    //   <div className="cardItem">
    //     <p>Rule Name: </p>
    //     <span>rul1</span>
    //   </div>
    //   <div className="cardItem">
    //     <p>Applied On: </p>
    //     <span> card2</span>
    //   </div>
    //   <div className="cardItem">
    //     <p>Result: </p>
    //     <span>Revenue changed from 0 to 44</span>
    //   </div>

    //   {expanded && (
    //     <div className="computation">
    //       <h4>Computation</h4>
    //       <div className="computationItem">
    //         <p className="header">Computation Expression</p>
    //         <p className="data">revenue.card1</p>
    //       </div>
    //       <div className="evolution">
    //         <div className="computationItem">
    //           <p className="header">Computation Evolution</p>
    //           <p className="data">44.00</p>
    //         </div>
    //         <ArrowRightOutlined />
    //         <StyledTag color="green">44</StyledTag>
    //       </div>
    //     </div>
    //   )}

    //   <p className="toggle" onClick={toggleExpand}>
    //     {expanded ? (
    //       <FullscreenExitOutlined className="icon" />
    //     ) : (
    //       <FullscreenOutlined className="icon" />
    //     )}
    //     {expanded ? "Collapse" : "Expand"}
    //   </p>
    // </PricingLogCard>
    <EmptyDataComponent
    description={'No Pricing Rule Logs Found'}
    />
  );
};

export default CollapsibleCard;
