import { FileOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Col, Form, Row, Select, Typography, UploadFile } from "antd";
import {
  FieldsMatchingTableContainer,
  MapFieldsContainer,
  StyledFormItem,
} from "./styles";
import { useEffect, useMemo, useState } from "react";
import { requiredFields } from "../../../utils/helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const { Text } = Typography;

type FieldMapping = {
  [key: string]: string[];
};

const FIELD_MAPPINGS: FieldMapping = {
  CUSTOMERS: [
    "billoptimCustomerId",
    "Name",
    "PrimaryEmail",
    "billingAddress",
    "billoptimCustomerAccountId",
    "accountName",
    "accountInvoiceCurrency",
    "accountAliases",
  ],
  ACCOUNTS: [
    "billoptimCustomerId",
    "billoptimCustomerAccountId",
    "name",
    "invoiceCurrency",
    "aliases",
  ],
  EVENTS: [
    "eventId",
    "accountId",
    "timestamp",
    "attribute.usage",
    "dimension.usage_meter_id",
    "attribute.usage.unit",
  ],
  LICENSE: [
    "LicenseId",
    "AccountId",
    "UpdateType",
    "Quantity",
    "EffectiveFrom",
  ],
};

const MapFields: React.FC<{
  file: UploadFile | null;
  importType: string;
  fieldOption: any;
  eventSchema:any;
}> = ({ file, importType, fieldOption ,eventSchema}) => {
  const [fields, setFields] = useState<string[]>([]);
  const events = useSelector((state:RootState) => state.event?.events);
  const [selectedValues, setSelectedValues] = useState<{
    [key: string]: string;
  }>({});

  const handleChange = (name: string, value: string) => {
    setSelectedValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const customerEventField = useMemo(() => {
    const eventField = events?.find((item: any) => item.event_schema_id === eventSchema);
  
    if (importType === 'EVENTS') {
      return eventField
        ? [
            "eventId", 
            "accountId", 
            "timestamp", 
            ...eventField.dimensions?.map((dim: any) => `dimension.${dim}`) || [],
            ...eventField.attributes?.map((attr: any) => `attribute.${attr.name}`) || []
          ]
        : [
            "eventId", 
            "accountId", 
            "timestamp", 
            "attribute.usage", 
            "dimension.usage_meter_id", 
            "attribute.usage.unit"
          ];
    }
  
    return [];
  }, [eventSchema, events, importType]);
  
  
  useEffect(() => {
    setFields(customerEventField.length && importType === 'EVENTS'? customerEventField : FIELD_MAPPINGS[importType] || []);
  }, [importType, customerEventField]);
  
  useEffect(() => {
    fields.map((item, index) =>
      setSelectedValues((prev) => ({
        ...prev,
        [item]: filterOption[index],
      }))
    );
  }, [fields]); 


  const filterOption = useMemo(() => {
    // Filter out options that are in `selectedValues`
    return fieldOption?.filter(
      (item: string) => item && !Object.values(selectedValues).includes(item)
    );
  }, [fieldOption, selectedValues]);

  const selectOptions = useMemo(
    () =>
      filterOption?.map((field: any) => (
        <Select.Option key={field} value={field}>
          {field}
        </Select.Option>
      )),
    [filterOption,selectedValues]
  );

  const renderFields = (item: any,index:any) => {
    const isRequired = requiredFields.includes(item);

 


    return (
      <StyledFormItem
        key={item}
        label={item}
        layout="horizontal"
        rules={[{ required: isRequired, message: `Required !` }]}
        name={item}
        initialValue={filterOption[index]}
      >
        <Select
          style={{ width: "90%" }}
          placeholder="Select"
          onChange={(value) => handleChange(item, value)}
          allowClear
        >
          {selectOptions}
        </Select>
      </StyledFormItem>
    );
  };

  return (
    <MapFieldsContainer>
      <Form.Item label="Uploaded File">
        <div className="uploadedFile">
          <div style={{ display: "flex", alignItems: "center" }}>
            <FileOutlined style={{ marginRight: "8px" }} />
            <Text>{file?.name}</Text>
          </div>
        </div>
      </Form.Item>
      <div className="alert">
        <InfoCircleOutlined />
        <span>
          Please match each field on the selected file with our fields.
        </span>
      </div>
      <FieldsMatchingTableContainer>
        <Row className="thead">
          <Col span={12}>
            <span>BillOptim Fields</span>
          </Col>
          <Col span={12}>
            <span>Imported file headers</span>
          </Col>
        </Row>
        {/* {fields.map((item) => (
          <StyledFormItem
            key={item}
            label={item}
            layout="horizontal"
            rules={[{ required: true, message: `${item} Required !` }]}
            name={item}
          >
            <Select style={{ width: "90%" }} placeholder="Select">
              {selectOptions}
            </Select>
          </StyledFormItem>
        ))} */}
        {fields.map((item,index) => renderFields(item,index))}
      </FieldsMatchingTableContainer>
    </MapFieldsContainer>
  );
};

export default MapFields;
