import { useNavigate } from "react-router-dom";
import { StyledTable } from "../../../../components/common/table/styles";
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import { StyledRangePicker } from "../../../billing/invoices/styles";
import dayjs from "dayjs";
import { EmptyData } from "../../../../components/common/empty";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { useState } from "react";
import { getInvoiceById } from "../../../../redux/feature/billing/billingThunk";
import { updateInvoices } from "../../../../redux/feature/billing/billingSlice";
import { StyledTag } from "../../../../components/common/tag/styles";

const customChargeColumns = [
  {
    title: "Charge Name",
    dataIndex: "charge_name",
    key: "charge_name",
  },
  {
    title: "Charge type",
    dataIndex: "charge_type",
    key: "charge_type",
    render: (charge_type: string) => (
      <StyledTag
        color={
          charge_type === "PAID"
            ? "green"
            : charge_type === "DUE"
            ? "purple"
            : "red"
        }
      >
        • {charge_type}
      </StyledTag>
    ),
  },
  {
    title: "Considered for revenue",
    dataIndex: "considered_for_revenue",
    key: "considered_for_revenue",
    render: (considered_for_revenue: string) => (
      <StyledTag
        color={
          considered_for_revenue === "PAID"
            ? "green"
            : considered_for_revenue === "DUE"
            ? "purple"
            : "red"
        }
      >
        • {considered_for_revenue}
      </StyledTag>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },

  {
    dataIndex: "action",
    render: () => (
      <div style={{ display: "flex", gap: "1rem" }}>
        {" "}
        <EditOutlined />
        <DeleteOutlined />
      </div>
    ),
  },
];

const BillingTable: React.FC<{
  billType: "Invoices" | "Orders" | "Custom Charges";
  data: any;
}> = ({ billType, data }) => {
  console.log("🚀 ~ data:", data)
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [spinningRows, setSpinningRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleClick = (rowKey: string) => {
    setSpinningRows((prevState) => ({
      ...prevState,
      [rowKey]: true,
    }));

    setTimeout(() => {
      setSpinningRows((prevState) => ({
        ...prevState,
        [rowKey]: false,
      }));
    }, 2000);
  };

  const invoicesColumns = [
    {
      title: "Sequence ID",
      dataIndex: "sequence_id",
      key: "sequence_id",
    },
    {
      title: "Billing Cycle",
      dataIndex: "billingCycle",
      key: "billingCycle",
      render: (_:any,record:any) => {
        console.log("🚀 ~ record:", record)
        return (<StyledRangePicker
          	size="small"
          	defaultValue={[dayjs(record?.start_date), dayjs(record?.end_date)]}
          	disabled
          	suffixIcon={<CalendarOutlined />}
          	format="MMM DD, YYYY"
        	/>
      	)
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <StyledTag
          color={
            status === "PAID"
              ? "green"
              : status === "DUE"
              ? "purple"
              : status === "PARTIALLY_PAID"
              ? "orange"
              : status === "DRAFT"
              ? "gold"
              : "red"
          }
        >
          • {status}
        </StyledTag>
      ),
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => {
        return dayjs(created_at).format("MMM DD, YYYY");
      },
    },
    {
      title: "Total Value",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (total_amount: number,record: any) => `${record?.currency_symbol}${total_amount}`,
    },
    {
      title: "Paid Value",
      dataIndex: "paid_amount",
      key: "paid_amount",
      render: (paid_amount: number) => `$${paid_amount}`,
    },
    {
      dataIndex: "action",
      render: (
        _: any,
        record: { sequence_id: string; status: string; invoice_id: string }
      ) => {
        const { sequence_id, status, invoice_id } = record;
        const isSyncVisible = status !== "DUE" && status !== "PAID";

        return (
          isSyncVisible && (
            <SyncOutlined
              spin={spinningRows[sequence_id] || false}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                handleClick(sequence_id);
                dispatch(getInvoiceById(invoice_id))
                  .then((res: any) => {
                    const { items, customer, ...rest } = res.payload;
                    dispatch(updateInvoices(rest));
                  })
                  .catch((error: any) => {
                    console.log("►►► ~ dispatch ~ error:", error);
                  });
              }}
              style={{ fontSize: "20px", cursor: "pointer" }}
            />
          )
        );
      },
    },
  ];

  return data.length === 0 ? (
    <EmptyData description={`No ${billType} Found`} />
  ) : (
    <StyledTable
      pagination={false}
      columns={billType === "Invoices" ? invoicesColumns : customChargeColumns}
      dataSource={data}
      // scroll={{ x: "max-content" }}
      isCursorPointerShow
      onRow={(record: any) => {
        return {
          onClick: () => {
            navigate(`/billing/${record.invoice_id}`);
          },
        };
      }}
    />
  );
};

export default BillingTable;
