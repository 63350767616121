import React from "react";
import { Container } from "./Styles";
import PricePlanForm from "./PricePlanForm";
import { PricePlanFormHeader } from "../Styles";

const BillingDetails: React.FC<any> = ({
  form,
  setIsMidCyclePricing,
  isMidCyclePricing,
  pricePlanType,
  setPricePlanType,
  isClone
}) => {
  return (
    <Container>
      <PricePlanFormHeader> Billing Details</PricePlanFormHeader>
      <div className="billingDetailForm">
        <PricePlanForm
          form={form}
          isMidCyclePricing={isMidCyclePricing}
          setIsMidCyclePricing={setIsMidCyclePricing}
          pricePlanType={pricePlanType}
          setPricePlanType={setPricePlanType}
          isClone={isClone}
        />
      </div>
    </Container>
  );
};

export default BillingDetails;
