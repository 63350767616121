import {
  Form,
  FormInstance,
  Input,
  Select,
  TableColumnsType,
} from "antd";
import {
  CopyOutlined,
  EditFilled,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EmptyData,
} from "../../../components/common/empty";
import CustomButton from "../../../components/common/button";
import Modal from "../../../components/common/modal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  createAddons,
  getAddons,
  updateAddons,
} from "../../../redux/feature/billableItems/billableThunk";
import { BillableItemsHeader } from "../Header";
import { copyToClipboard, timeAgo } from "../../../utils/helper";
import { StyledTable } from "../../../components/common/table/styles";
import { setFilter } from "../../../redux/feature/customer/customerSlice";
import { AddonContainer } from "./styles";
import CustomDrawer from "../../../components/common/drawer";
import  {
  SettingTableType,
} from "../../admin-center/setting/table";
import SettingDrawerContent from "./SettingDrawerContent";
import { StyledTag } from "../../../components/common/tag/styles";

type EventSchemaColumnsType = {
  key: React.Key;
  name: string;
  addOnId?: any;
  type: "Active" | "Inactive" | "Draft";
  createdAt: string;
};

// type DataIndex = keyof EventSchemaColumnsType;

type AddOnsPropsType = {
  isModalOpen: boolean;
  modalHandler: () => void;
  form: FormInstance;
  edit: boolean;
  setEdit: (edit: boolean) => void;
  currentTabKey: any;
};
const AddOns: React.FC<AddOnsPropsType> = ({
  isModalOpen,
  modalHandler,
  form,
  edit,
  setEdit,
  currentTabKey,
}) => {
  console.log("►►► ~ edit:", edit);
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [isSettingDrawerOpen, setIsSettingDrawerOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [status, setStatus] = useState("");
  const [formFilter] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [disable, setDisable] = useState(true);
  const [isNewSettingOn, setIsNewSettingOn] = useState(false);
  const [addonName, setAddonName] = useState("");
  const [searchValue, setSearchValue] = useState(searchTerm);

  const { filter } = useSelector((state: RootState) => state.customer);
  const { addOns, isAddonCreated , isGetAddons } = useSelector(
    (state: RootState) => state.billable
  );

  const settingDrawerHandler = useCallback(() => {
    setIsSettingDrawerOpen((prev) => !prev);
    setIsNewSettingOn(false);
  }, []);

  const newSettingHandler = useCallback(() => {
    setIsNewSettingOn((prev) => !prev);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => setSearchValue(searchTerm), 1000);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    if (currentTabKey === "2")
      dispatch(
        getAddons({
          sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
          search: searchValue,
          type: status,
        })
      );
  }, [filter, searchValue, status]);

  useEffect(() => {
    return () => {
      setStatus("");
      dispatch(setFilter("-updated_at"));
    };
  }, []);

  const addOnsDataSource = useMemo(() => {
    return addOns?.map((item: any) => item);
  }, [addOns]);

  const listData = [
    { value: "FIXED_FEE", label: "Fixed Fee" },
    { value: "CREDIT_GRANT", label: "Credit Grant" },
    { value: "LICENSE", label: "Licence" },
    { value: "NAMED_LICENSE", label: "Named Licence" },
  ];
  const handleChange = (value: string) => {
    setSelectedType(value);
  };

  const onSubmit = async () => {
    const data = await form.validateFields();
    console.log("⏩ ~ data:", data);
    try {
      if (edit) {
        data.id = edit;
        dispatch(updateAddons(data));
      } else {
        await dispatch(createAddons(data));
        // await dispatch(
        //   getAddons({
        //     sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
        //   })
        // );
      }
      modalHandler();
      if(!edit){
        await dispatch(
          getAddons({
            sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
          })
        );
      }
      setDisable(false);
      form.resetFields();
    } catch (error) {
      console.log("►►► ~ onSubmit ~ error:", error);
    }
  };

  const columns: TableColumnsType<any> = [
    {
      title: "Add-On Name",
      dataIndex: "name",
      width: 300,
      render: (_, record) => (
        <div>
          <div style={{ fontWeight: "bold" }}>{record?.name}</div>
          <div
            style={{
              fontSize: "12px",
              color: "#888",
              display: "flex",
              gap: "20px",
            }}
          >
            {record?.addOnId}
            <CopyOutlined
              style={{ height: "12px", width: "12px" }}
              onClick={() => copyToClipboard(record?.addOnId)}
            />
          </div>
        </div>
      ),
    },

    {
      title: "Type",
      dataIndex: "type",
      width: 150,

      render: (_, { type }) => {
        return (
          <StyledTag
            color={
              type === "CREDIT_GRANT"
                ? "green"
                : type === "LICENSE"
                ? "purple"
                : type === "NAMED_LICENSE"
                ? "geekblue"
                : "blue"
            }
          >
            {type}
          </StyledTag>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: 150,

      render: (text: any) => {
        return timeAgo(text);
      },
    },
    {
      dataIndex: "setting",
      width: 150,

      render: (_, record: any) => (
        <div className="setting-button-container">
          <CustomButton
            text="Setting"
            icon={<PlusOutlined />}
            onClick={() => {
              settingDrawerHandler();
              setAddonName(record?.name);
            }}
            type="default"
          />
        </div>
      ),
    },
    {
      dataIndex: "action",
      width: 80,

      render: (_, record: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <CustomButton
              text="Edit"
              icon={<EditFilled />}
              onClick={() => {
                modalHandler();
                form.setFieldsValue({
                  name: record?.name,
                  type: record?.type,
                  billableName: record?.billableName,
                });
                setEdit(record?.addOnId);
                setDisable(!record?.name || !record?.type);
              }}
              type="default"
            />
          </div>
        );
      },
    },
  ];

  const onSubmitFilter = async () => {
    try {
      const values = await formFilter.validateFields();
      console.log("►►► ~ onSubmitFilter ~ values:", values);
      setStatus(values.status);
      if (values.status) {
        navigate(
          `/add-ons?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}&` : "&"
          }type=${values.status}`
        );
      } else {
        navigate(
          `/add-ons?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}` : ""
          }`
        );
      }
    } catch (error) {}
  };

  const handleClearFilter = () => {
    setStatus("");
    formFilter.resetFields();
    navigate(
      `/add-ons?__sort__=${filter}${searchTerm ? `&__search__=${status}` : ""}`
    );
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    setDisable(!allValues.name || !allValues.type);
  };

  const onSearchHandle = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);

    const baseRoute = "/add-ons";
    const statusParam = status ? `&status=${status}` : "";
    const searchParam = value ? `&__search__=${value}` : "";
    const finalUrl = `${baseRoute}?__sort__=${filter}${statusParam}${searchParam}`;

    navigate(finalUrl);
  };

  const data: SettingTableType[] = [
    {
      key: "1",
      name: "kin",
      id: "123456",
      namespace: "User",
      type: "Numeric",
      value: "21",
    },
    {
      key: "1",
      name: "kin",
      id: "123456",
      namespace: "User",
      type: "String",
      value: "21",
    },
    {
      key: "1",
      name: "kin",
      id: "123456",
      namespace: "Togai",
      type: "String",
      value: "21",
    },
  ];

  return (
    <AddonContainer>
      <div>
        <BillableItemsHeader
          searchTerm={searchTerm}
          onSubmitFilter={onSubmitFilter}
          handleClearFilter={handleClearFilter}
          status={status}
          setStatus={setStatus}
          formFilter={formFilter}
          onSearchHandle={onSearchHandle}
          currentTabKey={currentTabKey}
        />
        {isGetAddons ? (
          <StyledTable
            pagination={false}
            columns={columns}
            loading={true}
            dataSource={[]}
            size="middle"
          />
        ) : addOns.length > 0 ? (
          <StyledTable
            pagination={false}
            columns={columns}
            dataSource={addOnsDataSource}
            size="middle"
          />
        ) : (
          <EmptyData
            description="No Add-Ons Found"
            btnText="New Add-On"
            onClick={modalHandler}
          />
        )}
      </div>
      <Modal
        title={form.getFieldValue("name") ? "Edit Add-On" : "New Add-On"}
        isOpen={isModalOpen}
        onClose={modalHandler}
        onSubmit={onSubmit}
        successBtnTitle={edit ? "Update" : "Create"}
        loading={isAddonCreated}
        isSuccessBtnDisable={disable}
      >
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            label="Add-On Name"
            name="name"
            rules={[
              { required: true, message: "Please input Add-On Name First!" },
            ]}
          >
            <Input placeholder="Add-On Name" type="text" />
          </Form.Item>
          <Form.Item
            label="Billable Name"
            name="billableName"
            extra={
              <>
                <InfoCircleOutlined
                  style={{
                    marginRight: "0.3rem",
                    height: "12px",
                    width: "12px",
                  }}
                />{" "}
                <span>This name will be used in the invoices</span>
              </>
            }
          >
            <Input placeholder="Billable Name" type="text" />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Please Select Type First!" }]}
          >
            <Select
              disabled={edit}
              placeholder="Choose Type"
              onChange={handleChange}
              options={listData}
            />
          </Form.Item>
        </Form>
      </Modal>
      <CustomDrawer
        isOpen={isSettingDrawerOpen}
        onClose={settingDrawerHandler}
        title={`Addon ${addonName} Settings`}
        width={588}
        isActionButtonsShown={false}
      >
        <SettingDrawerContent
          isNewSettingOn={isNewSettingOn}
          newSettingHandler={newSettingHandler}
        />
      </CustomDrawer>
    </AddonContainer>
  );
};

export default AddOns;
