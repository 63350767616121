import React, { ReactNode, useMemo, useState } from "react";
import { Card, Table, Dropdown, Checkbox, Button, Form } from "antd";
import type { TableColumnsType } from "antd";
import {
  CopyOutlined,
  DownOutlined,
  FilterOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { CalculationContainer } from "./styles";
import styled from "styled-components";
import CustomButton from "../../../components/common/button";
import CustomChargeModal from "./CustomChargeModal";
import AlertModal from "../../../components/common/alertModal";
import { copyToClipboard } from "../../../utils/helper";
import Modal from "../../../components/common/modal";

interface DataType {
  key: React.ReactNode;
  name?: string | ReactNode;
  quantity?: string;
  price?: string;
  total?: string;
  children?: DataType[];
  isSubtotal?: Boolean;
  classIdentifier?: any;
  classIdentifierUsageName?: any;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Item Name",
    dataIndex: "name",
    key: "name",
    width: "55%",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: "15%",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: "15%",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    width: "15%",
  },
];

const DropdownContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
`;

const Calculation: React.FC<any> = ({ selectedInvoice, baseCurrency }) => {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [isNestedTable, setIsNestedTable] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [isConsiderChargeForRevenue, setIsConsiderChargeForRevenue] =
    useState(false);
  const [isCustomChargeModalShown, setIsCustomChargeModalShown] =
    useState(false);

  const modalHandler = () => {
    setIsCustomChargeModalShown(!isCustomChargeModalShown);
    setIsConsiderChargeForRevenue(false);
    form.resetFields();
    form.setFieldValue("applyChargeAs", "EXTRA_CHARGE");
    form.setFieldValue("chargeType", "FIXED");
  };
  const deleteAlertHandler = () => {
    setIsDeleteAlertOpen(!isDeleteAlertOpen);
  };

  const rowClassName = (record: DataType) => {
    if (record?.isSubtotal) {
      return "sub-total-row";
    }
    if (record.key === "total") {
      return "total-row";
    }
    if (record.classIdentifier) {
      return "child-row-class";
    }
    if (record?.classIdentifierUsageName) {
      setIsNestedTable(true);
      return "child-nest-row-class";
    }
    return "";
  };

  const handleDropdownVisibleChange = (flag: boolean) => {
    setDropdownVisible(flag);
  };

  const menu = (
    <DropdownContent>
      <Checkbox
        onChange={(e) =>
          handleCheckboxChange(
            e,
            `zero${selectedInvoice?.currency_code}LineItems`
          )
        }
        checked={checkedValues.includes(
          `zero${selectedInvoice?.currency_code}LineItems`
        )}
      >
        {`Include zero ${selectedInvoice?.currency_code} line items`}
      </Checkbox>
      <Checkbox
        onChange={(e) => handleCheckboxChange(e, "zeroUsageRateCards")}
        checked={checkedValues.includes("zeroUsageRateCards")}
      >
        Include zero usage rate cards
      </Checkbox>
    </DropdownContent>
  );

  const handleCheckboxChange = (e: any, checkboxName: string) => {
    const newCheckedValues = e.target.checked
      ? [...checkedValues, checkboxName]
      : checkedValues?.filter((value) => value !== checkboxName);
    setCheckedValues(newCheckedValues);
  };

  const onExpand = (expanded: boolean, record: DataType) => {
    setExpandedRowKeys((prevExpandedKeys) =>
      expanded
        ? [...prevExpandedKeys, record.key]
        : prevExpandedKeys.filter((key) => key !== record.key)
    );
  };

  const transformedData = useMemo(() => {
    const showAllData = checkedValues.includes(
      `zero${selectedInvoice?.currency_code}LineItems`
    );
    const baseItems = (selectedInvoice?.items || [])
      .map((item: any) => {
        const children = [];

        children.push({
          key: `${item?.invoice_item_id}-child`,
          name: (
            <div className="name">
              <p>{item?.item_title}</p>
              <p>
                {item?.invoice_item_id}
                <CopyOutlined
                  className="icon"
                  onClick={() => copyToClipboard(item?.invoice_item_id)}
                />
              </p>
            </div>
          ),
          quantity: item?.quantity,
          price: item?.unit_price,
          total: `${item?.total_value ?? "0"} ${
            selectedInvoice?.currency_code
          }`,
          classIdentifier: item?.invoice_item_id,
        });
        if (
          item?.item_type === "USAGE BASED FEE" &&
          Array.isArray(item?.metadata)
        ) {
          const filteredMetadata = item?.metadata?.filter(
            (data: any) =>
              showAllData || (data?.total?.value && data?.amount?.value)
          );

          filteredMetadata?.forEach((data: any, index: any) => {
            children.push({
              key: `${item?.invoice_item_id}-${index}`,
              name: (
                <div className="usage_name">
                  <div>
                    <b>{data.name}</b>
                  </div>
                  <div>{data.range}</div>
                  <div>{data.type}</div>
                </div>
              ),
              quantity: data?.quantity ?? "",
              price: `${data?.amount?.value ?? "0"} ${data?.amount?.currency}`,
              total: `${data?.total?.value ?? ""} ${data?.total?.currency}`,
              classIdentifierUsageName: `${item?.invoice_item_id}-${index}`,
            });
          });
        }

        return [
          {
            key: item?.invoice_item_id,
            name: item?.item_type,
            ...(children.length > 0 ? { children } : {}),
          },
          {
            key: `${item?.invoice_item_id}-subtotal`,
            name: `${item?.item_type?.toLowerCase()} Sub total`,
            quantity: "",
            price: "",
            total: `${item?.total_value ?? "0"} ${
              selectedInvoice?.currency_code
            }`,
            isSubtotal: true,
          },
        ];
      })
      .flat();

    const totalAmount = baseItems?.reduce((acc: number, item: any) => {
      const itemTotal = item?.children
        ? parseFloat(item?.children[0]?.total?.split(" ")[0]) || 0
        : 0;
      return acc + itemTotal;
    }, 0);

    if (selectedInvoice?.status === "DRAFT") {
      baseItems.push({
        key: "button-row",
        name: (
          <CustomButton
            type="default"
            text="Add Custom Charge"
            onClick={modalHandler}
          />
        ),
      });
    }

    baseItems.push({
      key: "total",
      name: "Total Amount",
      total: `${selectedInvoice?.total_amount} ${selectedInvoice?.currency_code}`,
    });

    return baseItems;
  }, [selectedInvoice, baseCurrency, checkedValues]);

  return (
    <CalculationContainer isNestedTable={isNestedTable}>
      <div className="title">
        <h3>Billing Item</h3>
        <Dropdown
          placement="bottomRight"
          dropdownRender={() => menu}
          trigger={["click"]}
          open={dropdownVisible}
          onOpenChange={handleDropdownVisibleChange}
        >
          <Button type="text" className="dropDownBtn" icon={<FilterOutlined />}>
            Filter Line Items
          </Button>
        </Dropdown>
      </div>

      <Card className="card">
        <Table
          columns={columns}
          dataSource={transformedData}
          pagination={false}
          rowClassName={rowClassName}
          expandable={{
            expandedRowKeys,
            onExpand,
            expandIcon: ({ expanded, onExpand, record }: any) =>
              record.children ? (
                expanded ? (
                  <UpOutlined
                    className="expandIcon"
                    onClick={(e) => onExpand(record, e)}
                  />
                ) : (
                  <DownOutlined
                    className="expandIcon"
                    onClick={(e) => onExpand(record, e)}
                  />
                )
              ) : null,
          }}
        />
      </Card>

      <Modal
        isOpen={isCustomChargeModalShown}
        onClose={modalHandler}
        title="New Custom Charge"
        successBtnTitle="Create"
      >
        <CustomChargeModal
          form={form}
          isConsiderChargeForRevenue={isConsiderChargeForRevenue}
          setIsConsiderChargeForRevenue={setIsConsiderChargeForRevenue}
        />
      </Modal>

      <AlertModal
        open={isDeleteAlertOpen}
        close={deleteAlertHandler}
        title="Confirm Deletion of Custom Charge?"
        subtitle="Custom charge wrd will be deleted This action cannot be undone."
      />
    </CalculationContainer>
  );
};

export default Calculation;
