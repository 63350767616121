import { Col, Form, Input, InputNumber, Row, Select, Space } from "antd";
import { PriceModalContainer } from "../styles";
import { useEffect, useMemo, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";

const PriceModal: React.FC<{
  isPriceRangeShown: boolean;
  index?: number;
  editCardData?: any;
  id?: number;
  uniqueID: any;
  form: any;
  lastUnitChangeHandler: any;
  removeField?: (id: number) => void;
}> = ({
  isPriceRangeShown = false,
  index = 1,
  removeField,
  id,
  editCardData,
  uniqueID,
  lastUnitChangeHandler,
  form,
}) => {
  Form.useWatch(form);
  const [isMinPriceSelected, setIsMinPriceSelected] = useState(false);
  const [isMaxPriceSelected, setIsMaxPriceSelected] = useState(false);
  const [pricingType, setPricingType] = useState(form.getFieldValue(`pricingType-${uniqueID}`)||"");
  const [lastUnitValue, setLastUnitValue] = useState<string | number>(
    !isPriceRangeShown ? "" : uniqueID + 1
  );
  const { billingDetails } = useSelector((state: any) => state.pricePlan);
  const [maxPackageValue, setMaxPackageValue] = useState<any>(null);
  const isClone = useSelector((state: RootState) => state.pricePlan.isClone);

  useEffect(() => {
    const lastUnitVal = form.getFieldValue(`lastUnit-${uniqueID}`);
    const firstUnitVal = form.getFieldValue(`firstUnit-${uniqueID}`);
    const PackageSize = form.getFieldValue(`packageSize-${uniqueID}`);

    if (lastUnitVal === "∞") {
      setMaxPackageValue(null);
    }
    let lastUnit = Number(lastUnitVal) || 0;
    let firstUnit =
      uniqueID === 0
        ? 0
        : (typeof firstUnitVal === "string"
            ? Number(firstUnitVal.slice(1))
            : 0) || 0;

    const subtractionValue = lastUnit - firstUnit;
    const packageCount = !isNaN(PackageSize) ? Math.ceil( lastUnitVal) /PackageSize: 0; 
    setMaxPackageValue(Math.ceil(packageCount));
  }, [lastUnitValue, uniqueID]);

  useEffect(() => {
    if (!Object.keys(editCardData)?.length) {
      const data =
        index === 2 || !isPriceRangeShown
          ? "∞"
          : form.getFieldValue(`firstUnit-${uniqueID}`)
          ? typeof form.getFieldValue(`firstUnit-${uniqueID}`) === "number"
            ? form.getFieldValue(`firstUnit-${uniqueID}`) + 1
            : Number(form.getFieldValue(`firstUnit-${uniqueID}`).slice(1)) + 1
          : uniqueID + 1;

      form.setFieldsValue({
        [`lastUnit-${uniqueID}`]: data,
        [`firstUnit-${uniqueID}`]:
          uniqueID === 0
            ? uniqueID
            : `>${form.getFieldValue(`lastUnit-${uniqueID - 1}`)}`,
      });
      }
    if (isClone && Object.keys(editCardData)?.length) {
      const ids: any = [];
      Object.entries(editCardData).forEach(([key, value]) => {
        if (key.startsWith("lastUnit")) {
          const index = Number(key.split("-")[1]);
          ids.push({ id: index, value });
        }
      });
      ids.map((id: any) => {
        form.setFieldsValue({
          [`lastUnit-${id}`]: id.value,
        });
      });
    }
  }, [uniqueID, isPriceRangeShown, isClone,form,editCardData]);

  const handelShow = (
    setPrice: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setPrice((prev) => !prev);
  };

  const changePricingType = (value: string) => {
    setPricingType(value);
  };

  const getFormValue = (name: string) => {
    const nameValue = form.getFieldValue(name);
  };

  const spanSize = useMemo(() => {
    if (pricingType === "package" && isPriceRangeShown) return 3;
    else if (isPriceRangeShown) return 4;
    else return 6;
  }, [isPriceRangeShown, pricingType]);

  return (
    <>
      <PriceModalContainer>
        <Row style={{ minHeight: "72px" }}>
          <Col span={1}>
            <div className="indexWrapper">{`${uniqueID + 1}`}</div>
          </Col>

          <Col span={isPriceRangeShown ? 3 : 4}>
            <div className="itemWrapper">
              <Form.Item label="First Unit" name={`firstUnit-${uniqueID}`}>
                <Input disabled={true} size="small" className="input" />
              </Form.Item>
            </div>
          </Col>
          <Col span={isPriceRangeShown ? 3 : 4}>
            <div className="itemWrapper">
              <Form.Item
                label="Last Unit"
                name={`lastUnit-${uniqueID}`}
                rules={[
                  {
                    validator: async (_, value) => {
                      const firstUnitValue = form
                        .getFieldValue(`firstUnit-${uniqueID + 1}`)
                        ?.toString()
                        .replace(">", "");
                      const lastUnitValue = form.getFieldValue(
                        `lastUnit-${uniqueID + 1}`
                      );

                      if (Number(value) <= 0) {
                        return Promise.reject(
                          new Error("Please enter a number greater than 0")
                        );
                      }

                      if (Number(lastUnitValue) <= Number(firstUnitValue)) {
                        form.setFields([
                          {
                            name: `lastUnit-${uniqueID + 1}`,
                            errors: ["Should be greater than previous"],
                          },
                        ]);
                      } else {
                        form.setFields([
                          {
                            name: `lastUnit-${uniqueID + 1}`,
                            errors: [],
                          },
                        ]);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  type="number"
                  size="small"
                  min={1}
                  placeholder={
                    index === 2 || !isPriceRangeShown
                      ? "∞"
                      : form.getFieldValue(`firstUnit-${uniqueID}`)
                      ? typeof form.getFieldValue(`firstUnit-${uniqueID}`) ===
                        "number"
                        ? form.getFieldValue(`firstUnit-${uniqueID}`) + 1
                        : Number(
                            form.getFieldValue(`firstUnit-${uniqueID}`).slice(1)
                          ) + 1
                      : uniqueID + 1
                  }
                  className="input"
                  disabled={index === 2 || !isPriceRangeShown}
                  onChange={(e) => {
                    setLastUnitValue(e?.target?.value);
                    form.setFieldValue(
                      `firstUnit-${uniqueID + 1}`,
                      e?.target?.value?.length === 0
                        ? `>${uniqueID + 1}`
                        : e?.target?.value === "0"
                        ? ">1"
                        : `>${e?.target?.value}`
                    );
                  }}
                  onWheel={(e: any) => e.target.blur()}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={isPriceRangeShown ? 4 : 5}>
            <div className="itemWrapper">
              <Form.Item
                label="Type"
                className="type"
                name={`pricingType-${uniqueID}`}
                rules={[
                  {
                    required: true,
                    message: "Please input the rate card name!",
                  },
                ]}
              >
                <Select placeholder="Select" onChange={changePricingType}>
                  <Select.Option value="flat">Flat</Select.Option>
                  <Select.Option value="package">Package</Select.Option>
                  <Select.Option value="unit">Per Unit</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={isPriceRangeShown ? 3 : 5}>
            <div className="itemWrapper ">
              {billingDetails?.supportedCurrencies?.length > 0 &&
                billingDetails?.supportedCurrencies.map((currency: any) => (
                  <Form.Item
                    label={`${currency} Price`}
                    name={`${currency}-price-${uniqueID}`}
                    rules={[
                      {
                        required: true,
                        message: "required !",
                      },
                    ]}
                    className="currency"
                  >
                    <Input
                      size="small"
                      type="number"
                      placeholder="Price"
                      className="input"
                      onWheel={(e: any) => e.target.blur()}
                    />
                  </Form.Item>
                ))}
            </div>
          </Col>

          {pricingType === "package" && (
            <Col span={isPriceRangeShown ? 4 : 5}>
              <div className="itemWrapper " style={{alignItems: 'baseline'}}>
                <Form.Item
                  label="Package Size"
                  name={`packageSize-${uniqueID}`}
                >
                  <InputNumber
                    size="small"
                    max={form.getFieldValue(`lastUnit-${uniqueID}`) ? form.getFieldValue(`lastUnit-${uniqueID}`) : Infinity}
                    placeholder={`Max-${index === 2 || !isPriceRangeShown ? '':form.getFieldValue(`lastUnit-${uniqueID}`)}`}
                    className="input"
                    onInput={(e) => {
                      const value = parseFloat(e); 
                      const packageCount = !isNaN(value) ? Math.ceil( form.getFieldValue(`lastUnit-${uniqueID}`)) /value: 0; 
                      setMaxPackageValue(Math.ceil(packageCount));
                    }}
                  />
                </Form.Item>
                { !isNaN(maxPackageValue) && maxPackageValue !== Infinity  && maxPackageValue !== 0 ? (
                  <div>Packages:{maxPackageValue}</div>
                ):(
                  <></>
                )}
              </div>
            </Col>
          )}
          {isPriceRangeShown && pricingType !== "flat" && (
            <>
              <Col span={3}>
                <div className="itemWrapper ">
                  {billingDetails?.supportedCurrencies?.length > 0 &&
                    billingDetails?.supportedCurrencies.map((currency: any) =>
                      !isMinPriceSelected ? (
                        <p
                          onClick={() => handelShow(setIsMinPriceSelected)}
                          style={{ cursor: "pointer" }}
                          className="priceTag"
                        >
                          <PlusOutlined /> Min Price
                        </p>
                      ) : (
                        <Form.Item
                          label={
                            <div>
                              <span>{currency} Min</span>
                              <DeleteOutlined
                                style={{ marginLeft: "5px" }}
                                onClick={() =>
                                  handelShow(setIsMinPriceSelected)
                                }
                              />
                            </div>
                          }
                          name={`${currency}-min-${uniqueID}`}
                          className="currency"
                          rules={[
                            {
                              required: true,
                              message: "Please input the minimum price",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const maxValue = getFieldValue(
                                  `${currency}-max-${uniqueID}`
                                );
                                if (value && maxValue) {
                                  if (Number(value) <= Number(maxValue)) {
                                    return Promise.resolve();
                                  }else{

                                    return Promise.reject(
                                      new Error(
                                        "Min price must be less than  max price"
                                      )
                                    );
                                  }
                                }
                              },
                            }),
                          ]}
                        >
                          <Input
                            size="small"
                            type="number"
                            placeholder="Min"
                            className="input"
                            onChange={() => {
                              form.validateFields([
                                `${currency}-min-${uniqueID}`,
                                `${currency}-max-${uniqueID}`,
                              ]);
                            }}
                            onWheel={(e: any) => e.target.blur()}
                          />
                        </Form.Item>
                      )
                    )}
                </div>
              </Col>
              <Col span={3}>
                <div className="itemWrapper">
                  {billingDetails?.supportedCurrencies?.length > 0 &&
                    billingDetails?.supportedCurrencies.map((currency: any) =>
                      !isMaxPriceSelected ? (
                        <p
                          onClick={() => handelShow(setIsMaxPriceSelected)}
                          style={{ cursor: "pointer" }}
                          className="priceTag"
                        >
                          <PlusOutlined /> Max Price
                        </p>
                      ) : (
                        <Form.Item
                          label={
                            <div>
                              <span>{currency} Max</span>
                              <DeleteOutlined
                                style={{ marginLeft: "5px" }}
                                onClick={() =>
                                  handelShow(setIsMaxPriceSelected)
                                }
                              />
                            </div>
                          }
                          name={`${currency}-max-${uniqueID}`}
                          className="currency"
                          rules={[
                            {
                              required: true,
                              message: "Please input the maximum price",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const minValue = getFieldValue(
                                  `${currency}-min-${uniqueID}`
                                );
                                if (value && minValue) {
                                  if (Number(value) >= Number(minValue)) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "Max price must be greater than min price"
                                    )
                                  );
                                }
                              },
                            }),
                          ]}
                        >
                          <Input
                            size="small"
                            type="number"
                            placeholder="Max"
                            className="input"
                            onChange={() => {
                              form.validateFields([
                                `${currency}-min-${uniqueID}`,
                                `${currency}-max-${uniqueID}`,
                              ]);
                            }}
                            onWheel={(e: any) => e.target.blur()}
                          />
                        </Form.Item>
                      )
                    )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </PriceModalContainer>
      {index >= 3 && (
        <Col span={1}>
          <DeleteOutlined
            style={{ margin: "0 0.5rem" }}
            onClick={() => (removeField ? removeField(id ? id : 1) : {})}
          />
        </Col>
      )}
    </>
  );
};

export default PriceModal;
