import React from "react";
import {
  Bullet,
  DateText,
  DateWrapper,
  DetailsGrid,
  Icon,
  ScheduleItemWrapper,
  ScheduleTitle,
  VerticalLine,
} from "./Styles";
import { Card } from "antd";
import dayjs from "dayjs";
import { ScheduleData } from "../../Styles";
import { ArrowDownOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";

const ScheduleItem: React.FC<any> = ({
  item,
  isEmptySlot = false,
  gapPeriod = null,
  status = "detached"
}) => {
  console.log("🚀 ~ status:", status)
  console.log("🚀 ~ gapPeriod:", gapPeriod);

  return (
    <ScheduleItemWrapper status={status}>
      <div>
        <div
          className="card-body"
          style={{
            background: `${item?.active ? "#EEF2FF" : ""}`,
            borderRadius: `${item?.active ? "10px" : ""}`,
            padding:"10px 15px"
          }}
        >
          <div className="heading">
            <div className="dots"></div>
            <div>{!isEmptySlot ? item?.pricePlanName : "No Plan Attached"}</div>
          </div>
          <div className="content">
            <div className="divider"></div>
            <div className="content-text">
              <div>
                {" "}
                <ScheduleData>
                  <CalendarOutlined />
                  <span>
                    {gapPeriod
                      ? moment(gapPeriod?.from).local().format("YYYY-MM-DD")
                      : moment(item?.effectiveFrom)
                          .local()
                          .format("YYYY-MM-DD")}
                  </span>
                  <ArrowDownOutlined rotate={270} />
                  <span>
                    {gapPeriod
                      ? moment(gapPeriod?.to).local().format("YYYY-MM-DD")
                      : moment(item?.effectiveUntil)
                          .local()
                          .format("YYYY-MM-DD")}
                  </span>
                </ScheduleData>
              </div>
              {!isEmptySlot && (
                <div>
                  <div className="price-cycle">
                    <div>Pricing Cycle</div>
                    <div>{item?.pricingCycleInterval}</div>
                  </div>
                  <div className="price-cycle">
                    <div>Pricing Cycle Start Month</div>
                    <div>
                      {item?.pricingCycleInterval === "MONTHLY" ||
                      item?.pricingCycleInterval === "WEEKLY"
                        ? "-"
                        : item?.pricingCycleStartMonth}
                    </div>
                  </div>
                  <div className="price-cycle">
                    <div>
                      {item?.pricingCycleInterval === "WEEKLY"
                        ? "Pricing Cycle Day"
                        : "Pricing Cycle Date"}
                    </div>
                    <div>{item?.pricingCycleStartDay}</div>
                  </div>
                  <div className="price-cycle">
                    <div>Grace Period (Days)</div>
                    <div>{item?.gracePeriod}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ScheduleItemWrapper>
  );
};

export default ScheduleItem;
