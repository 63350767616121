import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  activatePricePlan,
  createPricePlan,
  deletePricePlan,
  getAttachPricePlan,
  getPricePlans,
  getSinglePricePlan,
} from "./pricePlanThunk";

export interface PricePlanSliceState {
  billingDetails: any;
  planModalDetail: any;
  midCyclePricing: boolean;
  pricePlans: any;
  singlePricePlan: any;
  loading: boolean;
  isPricePlanLoading: boolean;
  error: string | null;
  attachPricePlan: any[];
  overRideRateCards: any[];
  viewAttachPricePlan: any;
  isOverridePlan: boolean;
  editCardData: any;
  isClone: boolean;
}

const initialData = {
  rateCards: [],
  pricingRules: [],
};

const initialState: PricePlanSliceState = {
  billingDetails: initialData,
  planModalDetail: "",
  midCyclePricing: false,
  pricePlans: [],
  singlePricePlan: null,
  loading: false,
  isPricePlanLoading: false,
  error: null,
  overRideRateCards: [],
  attachPricePlan: [],
  viewAttachPricePlan: null,
  isOverridePlan: false,
  editCardData: {},
  isClone: false,
};

const pricePlanSlice = createSlice({
  name: "pricePlan",
  initialState,
  reducers: {
    setBillingDetails: (state, action) => {
      state.billingDetails = { ...state.billingDetails, ...action.payload };
    },
    setPlanModalDetail: (state, action) => {
      state.planModalDetail = action.payload;
    },
    setMidCyclePricing: (state, action) => {
      state.midCyclePricing = action.payload;
    },
    removeSinglePricePlanData: (state, action) => {
      state.singlePricePlan = action.payload;
    },
    setBillingRateCards: (state, action) => {
      state.billingDetails.rateCards = [
        ...state.billingDetails.rateCards,
        action.payload,
      ];
    },
    updateBillingRateCards: (state, action) => {
      const { uniqueID, rateCardId } = action.payload;
      const cardData = state.billingDetails.rateCards;
      if (!cardData.length) {
        return;
      }
      const index = cardData?.findIndex((card: any) => {
        if (uniqueID && card.uniqueID) {
          return card.uniqueID === uniqueID;
        } else if (rateCardId && card.rateCardId) {
          return card.rateCardId === rateCardId;
        }
        return false;
      });
      if (index !== -1) {
        state.billingDetails.rateCards[index] = action.payload;
      }
    },
    setEditCardData: (state, action) => {
      state.editCardData = action.payload;
    },
    setEmptyBillingRateCards: (state, action) => {
      state.billingDetails = action.payload;
    },
    setIsOverridePlan: (state, action) => {
      state.isOverridePlan = action.payload;
    },

    removeBillingRateCard: (state, action) => {
      state.billingDetails.rateCards = state.billingDetails.rateCards.filter(
        (card: any) => card.uniqueID !== action.payload
      );
    },
    setOverRideRateCards: (state, action) => {
      state.overRideRateCards = action.payload;
    },
    updateOverRideRateCards: (state, action) => {
      const { uniqueID, rateCardId } = action.payload;
      const cardData = state.overRideRateCards;
      const index = cardData?.findIndex((card) => {
        if (uniqueID && card.uniqueID) {
          return card.uniqueID === uniqueID;
        } else if (rateCardId && card.rateCardId) {
          return card.rateCardId === rateCardId;
        }
        return false;
      });
      if (index !== -1) {
        state.overRideRateCards[index] = action.payload;
      } else {
        state.overRideRateCards.push(action.payload);
      }

      if (
        !state?.billingDetails?.rateCards ||
        !Array.isArray(state.billingDetails.rateCards) ||
        state.billingDetails.rateCards.length === 0
      ) {
        return;
      }

      const billingIndex = state?.billingDetails?.rateCards?.findIndex(
        (card: any) => card.rateCardId === rateCardId
      );

      if (billingIndex !== -1) {
        state.billingDetails.rateCards[billingIndex] = action.payload;
      }
    },
    addOverRideRateCards: (state, action) => {
      const { uniqueID } = action.payload;
      const cardData = state.overRideRateCards;

      const index = cardData?.findIndex((card) => card.uniqueID === uniqueID);

      if (index !== -1) {
        state.overRideRateCards[index] = action.payload;
      } else {
        state.overRideRateCards.push(action.payload);
      }
    },
    deleteOverRideRate: (state, action) => {
      const { uniqueID, rateCardId, cardType } = action.payload;

      if (uniqueID) {
        state.billingDetails.rateCards = state.billingDetails.rateCards.filter(
          (card: any) => card.uniqueID !== uniqueID
        );
        state.overRideRateCards = state.overRideRateCards.filter(
          (card) => card.uniqueID !== uniqueID
        );
      }

      if (rateCardId) {
        state.billingDetails.rateCards = state.billingDetails.rateCards.filter(
          (card: any) => card.rateCardId !== rateCardId
        );
        const existingCardIndex = state.overRideRateCards.findIndex(
          (card) => card.rateCardId === rateCardId
        );
        if (existingCardIndex !== -1) {
          state.overRideRateCards[existingCardIndex] = {
            rateCardId: rateCardId,
            action: "delete",
            cardType: cardType,
          };
        } else {
          state.overRideRateCards.push({
            rateCardId: rateCardId,
            action: "delete",
            cardType: cardType,
          });
        }
      }
    },
    updateBillingRateCard: (state, action) => {
      const { data, override } = action.payload;
      state.billingDetails.rateCards = state.billingDetails.rateCards.map(
        (card: any) => {
          if (override) {
            return card.rateCardId === override ? data : card;
          } else {
            return card.uniqueID === data.uniqueID ? data : card;
          }
        }
      );
    },
    addBillingPricingRules: (state, action) => {
      state.billingDetails.pricingRules = [
        ...state.billingDetails.pricingRules,
        action.payload,
      ];
    },
    setBillingCurrency: (state, action) => {
      state.billingDetails.supportedCurrencies = action.payload;
    },
    setViewAttachPricePlan: (state, action) => {
      state.viewAttachPricePlan = action.payload;
    },
    setIsClonedPricePlan: (state, action) => {
      state.isClone = action.payload;
    },
    emptyAttachPricePlan: (state) => {
      state.attachPricePlan = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPricePlans.pending, (state, action) => {
        state.loading = true;
        state.isPricePlanLoading = true;
        state.error = null;
      })
      .addCase(getPricePlans.fulfilled, (state, action) => {
        state.loading = false;
        state.isPricePlanLoading = false;
        state.pricePlans = action.payload;
      })
      .addCase(getPricePlans.rejected, (state, action: any) => {
        state.loading = false;
        state.isPricePlanLoading = false;
        state.error = action.payload;
      })
      .addCase(getSinglePricePlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSinglePricePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.singlePricePlan = action.payload;
      })
      .addCase(getSinglePricePlan.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePricePlan.fulfilled, (state, action: any) => {
        state.singlePricePlan = null;
        state.pricePlans = state.pricePlans.map((plan: any) =>
          plan.pricePlanId === action.payload
            ? { ...plan, status: "ARCHIVED" }
            : plan
        );
      })
      .addCase(getAttachPricePlan.pending, (state, action: any) => {
        state.loading = true;
      })
      .addCase(getAttachPricePlan.fulfilled, (state, action: any) => {
        state.attachPricePlan = action.payload;
        state.loading = false;
      })
      .addCase(getAttachPricePlan.rejected, (state, action: any) => {
        state.loading = false;
      })
      .addCase(activatePricePlan.pending, (state, action: any) => {
        state.loading = true;

      })
      .addCase(activatePricePlan.fulfilled, (state, action: any) => {
        state.pricePlans = state.pricePlans.map((plan: any) =>
          plan.pricePlanId === action.payload
            ? { ...plan, status: "ACTIVE" }
            : plan
        );
        state.loading = false;
      })
      .addCase(activatePricePlan.rejected, (state, action: any) => {
        state.loading = false;
      });
  },
});

export const {
  setBillingDetails,
  setPlanModalDetail,
  setBillingRateCards,
  setEmptyBillingRateCards,
  addBillingPricingRules,
  removeBillingRateCard,
  setBillingCurrency,
  updateBillingRateCard,
  setViewAttachPricePlan,
  updateOverRideRateCards,
  deleteOverRideRate,
  addOverRideRateCards,
  removeSinglePricePlanData,
  setIsOverridePlan,
  updateBillingRateCards,
  setOverRideRateCards,
  setMidCyclePricing,
  setEditCardData,
  setIsClonedPricePlan,
  emptyAttachPricePlan,
} = pricePlanSlice.actions;

export const PricePlanSliceReducer = pricePlanSlice.reducer;
