import { Button, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import api from "../../../api/apiClient";
import { AxiosError } from "axios";
import { updateUserField } from "../../../redux/feature/auth/authSlice";
import Modal from "../../../components/common/modal";
import { getUser } from "../../../redux/feature/auth/authThunk";

const EditProfile: React.FC<any> = ({ editProfile, setEditProfile }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [timezones, setTimezones] = useState([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setEditProfile(null);
    form.resetFields();
  };

  interface User {
    name: string;
    company_name: string;
    timezone: string;
    email: string;
    created_at: string;
    user_id: any;
  }

  const fetchTimezones = async () => {
    try {
      const { data } = await api.get("/timezones");
      setTimezones(data);
      if (user && user.timezone) {
        const timezone = data.find((tz: any) => tz.timezone === user.timezone);
        if (timezone) {
          form.setFieldsValue({ timezone_id: timezone.timezone_id });
        }
      }
    } catch (error) {
      console.log("Error fetching timezones:", error);
    }
  };

  useEffect(() => {
    if (editProfile === "Timezone") {
      fetchTimezones();
    }
  }, [editProfile]);

  useEffect(() => {
    if (editProfile && user) {
      form.setFieldsValue(user);
    }
  }, [editProfile, user, form]);

  const onFinish = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      console.log("⏩ ~ values:", values);
      if (editProfile === "Change Password" || editProfile === "Set Password") {
        await api.post("/set-password", values);
        message.success("Password updated successfully!");
        dispatch(getUser())
        setEditProfile("");
      } else {
        await api.post("/update-profile", values);
        let timezone: any;
        if (editProfile === "Timezone") {
          timezone = timezones.find(
            ({ timezone_id }) => timezone_id == Object.values(values)[0]
          );
          dispatch(
            updateUserField({ field: "timezone", value: timezone?.timezone })
          );
        }
        dispatch(
          updateUserField({
            field: Object.keys(values)[0] as keyof User,
            value: Object.values(values)[0] as string,
          })
        );
        setEditProfile("");
      }
      form.resetFields();
    } catch (err: any) {
      console.log("🚀 ~ file: EditProfile.tsx:22 ~ onFinish ~ error:", err);
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const fieldMapping: any = {
    "Full Name": "name",
    "Company Name": "company_name",
    Timezone: "timezone_id",
  };
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return (
    <Modal
      title={
        editProfile === "Change Password"  || editProfile === "Set Password" ? editProfile : `Edit ${editProfile}`
      }
      isOpen={editProfile}
      onClose={handleModalClose}
      successBtnTitle="Update"
      onSubmit={onFinish}
      loading={loading}
    >
      <Form
        form={form}
        initialValues={user ? user : undefined}
        layout="vertical"
        style={{ marginTop: "1rem" }}
        requiredMark={false}
      >
        {editProfile === "Change Password" ? (
          <>
            <Form.Item
              label="Current Password"
              name="old_password"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                }
              ]}
            >
              <Input.Password placeholder="Current Password" />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="new_password"
              rules={[
                { required: true, message: "Please input your new password!" },
                {
                  pattern: passwordRegex,
                  message:
                    "Password must be at least 8 characters long, with at least one letter, one number, and one special character!",
                },
              ]}
            >
              <Input.Password placeholder="New Password" />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirm_password"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        " New Password and Confirm New Password Must be Same !"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm New Password" />
            </Form.Item>
          </>
        ) :editProfile === "Set Password" ? (
          <>
          <Form.Item
            label="New Password"
            name="new_password"
            rules={[
              { required: true, message: "Please input your new password!" },
              {
                pattern: passwordRegex,
                message:
                  "Password must be at least 8 characters long, with at least one letter, one number, and one special character!",
              },
            ]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirm_password"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      " New Password and Confirm New Password Must be Same !"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm New Password" />
          </Form.Item>
        </>
        ): editProfile === "Timezone" ? (
          <Form.Item
            label="Timezone"
            name="timezone_id"
            rules={[
              { required: true, message: "Please select your timezone!" },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a timezone"
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {timezones.map((timezone: any) => (
                <Select.Option
                  key={timezone.timezone_id}
                  value={timezone.timezone_id}
                >
                  {timezone.timezone}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item
            label={editProfile}
            name={fieldMapping[editProfile]}
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default EditProfile;
