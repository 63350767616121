import { Card, Col, Row, Spin, TableColumnsType, Tag, Tooltip } from "antd";
import { CreditContainer } from "./styles";
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomButton from "../../../../components/common/button";
import { StyledTable } from "../../../../components/common/table/styles";
import { useEffect, useMemo, useState, useCallback } from "react";
import AddCreditDrawer from "./AddCreditDrawer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreditBalance,
  getCreditById,
} from "../../../../redux/feature/customer/customerAsyncThunk";
import { AppDispatch, RootState } from "../../../../redux/store";
import PageLoading from "../../../../components/Loader";
import moment from "moment";
import { EmptyDataComponent } from "../../../../components/common/empty";
import { setViewCreditDetails } from "../../../../redux/feature/customer/customerSlice";
import { StyledTag } from "../../../../components/common/tag/styles";

export interface DataType {
  key: React.Key;
  credit_name: string;
  status: string;
  start_date: string;
  expiry_date: string;
  priority: number;
  grant_amount: string;
  consumption: string;
  balances: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Credit Name",
    dataIndex: "credit_name",
    render: (_, record: any) => (
      <div className="nameContainer">
        <p>{record?.purpose}</p>
        <span>{record?.credit_id}</span>
      </div>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (_, record) => (
      <StyledTag color={record?.status === "ACTIVE" ? "green" : "red"}>
        {record?.status}
      </StyledTag>
    ),
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    render: (_, record: any) => (
      <span>{`${moment(record.effectiveFrom).format("MMM DD YYYY")} UTC`}</span>
    ),
  },
  {
    title: "Expiry Date",
    dataIndex: "expiry_date",
    render: (_, record: any) => (
      <span>
        {record.effectiveUntil
          ? `${moment(record.effectiveUntil).format("MMM DD YYYY")} UTC`
          : "Forever"}
      </span>
    ),
  },
  {
    title: "Priority",
    dataIndex: "priority",
  },
  {
    title: "Grant Amount",
    dataIndex: "grant_amount",
    render: (_, record: any) => (
      <div className="dataContainer">
        <p>Granted :</p>
        <span>
          {Number(record?.creditAmount)} {record?.creditUnit}
        </span>
      </div>
    ),
  },
  {
    title: "Consumption",
    dataIndex: "consumption",
    render: (_, record: any) => (
      <div className="multiItemContainer">
        <div className="dataContainer">
          <p>Invoiced :</p>
          <span>
            {" "}
            {Number(record?.consumedAmount)} {record?.creditUnit}
          </span>
        </div>
        {record.status === "ACTIVE" && (
          <div className="dataContainer">
            <p>Ongoing :</p>
            <span>
              {" "}
              {(
                Number(record?.creditAmount) - Number(record?.holdAmount)
              ).toLocaleString()}{" "}
              {record?.creditUnit}
            </span>
          </div>
        )}
      </div>
    ),
  },
  {
    title: "Balances",
    dataIndex: "balances",
    render: (_, record: any) => {
      console.log("🚀 ~ ).toLocaleString ~ record:", record)

      return (<div className="multiItemContainer">
        	<div className="dataContainer">
          	<p>{record.status === "ACTIVE" ? "Closing" : "Voided"}</p>
          	<span>
            	{Number(record?.creditAmount)} {record?.creditUnit}
          	</span>
        	</div>
        	{record.status === "ACTIVE" && (
          	<div className="dataContainer">
            	<p>Running</p>
            	<span>
            	{Number(record?.holdAmount)} {record?.creditUnit}
            	</span>
          	</div>
        	)}
      	</div>)
    	
    }
  },
];

const Credits = () => {
  const [isAddCreditDrawerOpen, setIsAddCreditDrawerOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { accountId } = useParams();
  const {
    creditData: { credits, loading },
    viewAccountDetails,
    creditBalance,
  } = useSelector((state: RootState) => state.customer);
  const { attachPricePlan } = useSelector(
    (state: RootState) => state.pricePlan
  );
  const navigate = useNavigate();
  const location = useLocation();

  const addCreditDrawerHandler = useCallback(() => {
    setIsAddCreditDrawerOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (accountId) {
      dispatch(getCreditById(accountId));
      dispatch(getCreditBalance(accountId));
    }
  }, [accountId, dispatch]);

  const creditDataSource = useMemo(() => {
    return credits?.map((item: any) => item);
  }, [credits]);

  const totalVal = useMemo(() => {
    let total = 0;
    credits?.forEach((credit: any) => {
      total += Number(credit.creditAmount);
    });
    return total;
  }, [credits]);

  const totalHold = useMemo(() => {
    let total = 0;
    credits?.forEach((credit: any) => {
      total += Number(credit.holdAmount);
    });
    return total;
  }, [credits]);

  return loading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <CreditContainer>
      <div className="header">
        <Row gutter={[16, 0]}>
          <Col span={6}>
            <Card className="cardItem">
              <div className="title">
                <span>Total Active credits</span>
                <Tooltip title="Total granted credits that are applicable in the current pricing cycle.">
                  <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                </Tooltip>
              </div>
              <div className="subTitle">
                <span className="green">
                  {creditBalance?.totalCredits || 0.0}
                </span>
                <span>{creditBalance?.currency}</span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="cardItem">
              <div className="title">
                <span>Closing balance</span>
                <Tooltip title="Active granted credits subtracted by consumed credits in invoice.">
                  <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                </Tooltip>
              </div>
              <div className="subTitle">
                <span>{creditBalance?.closingBalance || 0}</span>
                <span>{creditBalance?.currency}</span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="cardItem">
              <div className="title">
                <span>Running Balance</span>
                <Tooltip title="Closing balance subtracted by ongoing invoice consumption.">
                  <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                </Tooltip>
              </div>
              <div className="subTitle">
                <span>{creditBalance?.runningBalance || 0}</span>
                <span>{creditBalance?.currency}</span>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="cardItem">
              <div className="title">
                <span>Inapplicable balance</span>
                <Tooltip title="Credits that are expiring before the pricing cycle end-date.">
                  <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                </Tooltip>
              </div>
              <div className="subTitle">
                <span>0.00</span>
                <span>{creditBalance?.currency}</span>
              </div>
            </Card>
          </Col>
        </Row>
        <CustomButton
          text="Add Credit"
          position="end"
          onClick={addCreditDrawerHandler}
        />
      </div>
      {creditDataSource.length ? (
        <StyledTable
          pagination={false}
          columns={columns}
          isCursorPointerShow
          dataSource={creditDataSource}
          onRow={(record: any) => ({
            onClick: () => {
              navigate(`${location.pathname}/${record.credit_id}`);
              dispatch(setViewCreditDetails(record));
            },
          })}
        />
      ) : (
        <EmptyDataComponent
          title="No Credits Found"
          description="Get started by adding a credit to this account."
          addButton={() => {
            return (
              <CustomButton
                text="Add Credit"
                position="end"
                onClick={addCreditDrawerHandler}
              />
            );
          }}
        />
      )}
      <AddCreditDrawer
        isOpen={isAddCreditDrawerOpen}
        onClose={addCreditDrawerHandler}
      />
    </CreditContainer>
  );
};

export default Credits;
