import { Card } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const OnboardingCard=styled(Card)`
.ant-card-body{
  box-shadow: 0px 2px 8px 0px #cbd5e1
}
`
