import React from "react";
import { javascript } from "@codemirror/lang-javascript";
import { StyledCodeMirror, StyledDiv } from "./styles";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../../../../utils/helper";
import CodeEditor from "../../../../../components/common/codeEditor";

const JsonPayload: React.FC<any> = ({
  selectedIngest,
  height = "450px",
  editable = false,
}) => {
  console.log("►►► ~ selectedIngest:", selectedIngest);
  const onChange = React.useCallback((val: any, viewUpdate: any) => {
    console.log("val:", val);
  }, []);

  const payload = {
    event_schema_id: selectedIngest?.event_schema_id,
    timestamp: selectedIngest?.timestamp,
    accountId: selectedIngest?.account_id,
    attributes: selectedIngest?.attributes,
    dimensions: selectedIngest?.dimensions,
    ingest_event_id: selectedIngest?.ingest_event_id,
  };

  return (
    <CodeEditor
      title="Event Payload"
      editable={editable}
      height={height}
      isCopyShow={true}
      onChange={onChange}
      payload={payload}
    />
  );
};
export default JsonPayload;
