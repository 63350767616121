import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Col,
  Form,
  message,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import {
  CheckCircleFilled,
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CustomButton from "../../../components/common/button";
import { ConfigureContainer } from "./styles";
import Extra from "../../../components/common/extra";
import customerCsv from "../../../assets/csv/customers-sample.csv";
import accountsCsv from "../../../assets/csv/accounts-sample.csv";
import eventsCsv from "../../../assets/csv/Events-Sample.csv";
import licensesCsv from "../../../assets/csv/Licenses-Sample.csv";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { setCsvFile } from "../../../redux/feature/adminCenter/adminCenterSlice";


const { Text, Paragraph } = Typography;
const { Option } = Select;


const DownloadSampleCsv = React.memo(({ importType,selectedEvent }) => {
  const handleDownload = useCallback(() => {


    if (selectedEvent && Object.keys(selectedEvent).length !==0) {
      const fileName = "Events-Sample.csv";

      const rows = [
        {
          "Event Id": "12",
          "Account Id": "c271831d-1de3-44b5-bd56-b2c7ea3559c9",
          Timestamp: "2024-11-28 17:58:48",
        },
      ];

      if (selectedEvent?.dimensions?.length) {
        selectedEvent.dimensions.forEach((dim) => {
          rows[0][`dimension.${dim}`] = ""; 
        });
      }

      if (selectedEvent?.attributes?.length) {
        selectedEvent.attributes.forEach((attr) => {
          rows[0][`attribute.${attr.name}`] = attr.default_unit || ""; 
        });
      }

      const csvContent = Papa.unparse(rows);

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return;
    }
    const filePaths = {
      CUSTOMERS: customerCsv,
      ACCOUNTS: accountsCsv,
      EVENTS: eventsCsv,
      LICENSES: licensesCsv,
    };

    const fileNames = {
      CUSTOMERS: "Customers-Sample.csv",
      ACCOUNTS: "Accounts-Sample.csv",
      EVENTS: "Events-Sample.csv",
      LICENSES: "Licenses-Sample.csv",
    };

    const filePath = filePaths[importType];
    const fileName = fileNames[importType];

    if (!filePath) {
      message.error("Please select a valid import type to download.");
      return;
    }

    const link = document.createElement("a");
    link.href = filePath;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [importType,selectedEvent]);

  return (
    <Row gutter={16} align="middle" className="sampleCsv">
      <Col span={24}>
        <Text strong style={{ fontSize: "14px" }}>
          Download sample csv
        </Text>
        <Paragraph style={{ marginTop: "5px", fontSize: 12, color: "#555" }}>
          Download a sample file below & compare it to your import file to
          ensure you have the right format for the import.
        </Paragraph>
        <CustomButton
          type="default"
          icon={<DownloadOutlined />}
          onClick={handleDownload}
          text="Download CSV"
        />
      </Col>
    </Row>
  );
});

const FileUploadAndDownload = React.memo(
  ({ file, setFile, setFieldOption,detailsData }) => {

    const dispatch = useDispatch();

    const handleUpload = useCallback(
      (file) => {
        dispatch(setCsvFile(file));
        setFile(file);
        // Papa.parse(file, {
        //   header: true,
        //   complete: function (result) {
        //     console.log("►►► ~ result:", result);
        //     if (result.data && result.data.length > 0) {
        //       const transformedData = Object.keys(result.data[0]);
        //       setFieldOption(transformedData);
        //     } else {
        //       message.error("Failed to parse CSV. No valid data found.");
        //     }
        //   },
        //   error: function () {
        //     message.error(
        //       "Failed to parse CSV. Please ensure the file is valid."
        //     );
        //   },
        // });
        // return false;
      },
      [file]
    );

    const handleRemove = useCallback(() => setFile(null), [setFile]);

    return (
      <>
        {!file ? (
          <Form.Item
            rules={[{ required: true, message: "CSV File is required !" }]}
            extra={<Extra text="Max File Size: 5 MB, CSV only." />}
            name={"csv-file"}
          >
            <div className="upload">
              <Upload
                beforeUpload={handleUpload}
                maxCount={1}
                accept=".csv"
                showUploadList={false}
              >
                <CustomButton
                  icon={
                    <PlusOutlined style={{ height: "12px", width: "12px" }} />
                  }
                  text="Choose file to upload"
                  type="default"
                />
              </Upload>
            </div>
          </Form.Item>
        ) : (
          <div
            className="uploadedFile"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <FileOutlined style={{ marginRight: "8px" }} />
              <Text>{file.name}</Text>
              <CheckCircleFilled
                style={{ marginLeft: "8px", color: "#52c41a" }}
              />
            </div>
            <DeleteOutlined
              style={{cursor:detailsData?.status === 'PREVIEW'? "not-allowed" : "pointer", marginLeft: "8px" }}
              onClick={detailsData?.status === 'PREVIEW' ? null : handleRemove}
              disabled={detailsData?.status === 'PREVIEW' }
            />
          </div>
        )}
      </>
    );
  }
);

const Configure = ({
  file,
  setFile,
  importType,
  setImportType,
  setFieldOption,
  detailsData,
  setEventSchema,
  eventSchema
}) => {
  const events = useSelector((state) => state.event?.events);


  const selectedEvent = useMemo(() => {
    return events?.find((item) => item.event_schema_id === eventSchema)
  }, [eventSchema])


  const handleImportTypeChange = (value) => setImportType(value);
  const handleEventSchemaChange = (value) => setEventSchema(value);

  const options = useMemo(() => [
    <Option key="billoptim-usage" value="billOptim-usage">
        billoptim-usage
    </Option>,
    ...events.filter?.((item)=>item.status === 'ACTIVE')?.map(schema => (
        <Option key={schema.event_schema_id} value={schema.event_schema_id}>
            {schema.name}
        </Option>
    )),
], [importType,events]);


  return (
    <ConfigureContainer>
      <Form.Item
        label="Import type"
        name="importType"
        rules={[{ required: true, message: "Required !" }]}
      >
        <Select
          placeholder="Select Import Type"
          onChange={handleImportTypeChange}
        >
          <Select.Option value="CUSTOMERS">Customers</Select.Option>
          <Select.Option value="ACCOUNTS">Accounts</Select.Option>
          <Select.Option value="EVENTS">Events</Select.Option>
          {/* <Select.Option value="LICENSES">License Purchases</Select.Option> */}
        </Select>
      </Form.Item>

      {importType === "EVENTS" && (
        <>
          <Form.Item
            label="Choose Event Schema"
            name="eventSchema"
            rules={[{ required: true, message: "Required !" }]}
            initialValue={detailsData?.additionalParams[0]?.value}
          >
            <Select
              placeholder="Choose Event Schema"
              onChange={handleEventSchemaChange}
            >
            {options}
            </Select>
          </Form.Item>
          {eventSchema && (
            <>
              <DownloadSampleCsv importType={importType}  selectedEvent={selectedEvent}/>
              <FileUploadAndDownload
                file={file}
                setFile={setFile}
                setFieldOption={setFieldOption}
                detailsData={detailsData}
              />
            </>
          )}
        </>
      )}

      {importType && importType !== "EVENTS" && (
        <>
          <DownloadSampleCsv importType={importType} selectedEvent={{}}/>
          <FileUploadAndDownload
            file={file}
            setFile={setFile}
            setFieldOption={setFieldOption}
            detailsData={detailsData}
          />
        </>
      )}
    </ConfigureContainer>
  );
};

export default Configure;
