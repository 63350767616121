import { CopyOutlined, MoreOutlined } from "@ant-design/icons";
import { EventDetailHeader } from "../styles";
import { Button, Dropdown, Menu, Select } from "antd";
import { useState } from "react";
import ReingestModal from "./ReingestModal";
import AlertModal from "../../../../components/common/alertModal";
import { copyToClipboard } from "../../../../utils/helper";

const DrawerHeader: React.FC<{ selectedIngest: any }> = ({
  selectedIngest,
}) => {
  console.log("►►► ~ selectedIngest:", selectedIngest)
  const [isEventActionSelectOpen, setIsEventActionSelectOpen] = useState(false);
  const [isEventActionName, setIsEventActionName] = useState("");
  const eventActionNameHandler = () => {
    setIsEventActionName("");
    setIsEventActionSelectOpen(false);
  };

  const handleMenuClick = (e:any) => {
    setIsEventActionName(e.key);
    console.log("Selected Action:", e.key); // Handle the action as needed
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="REVERT">Revert Event</Menu.Item>
      <Menu.Item key="REINGEST">Reingest Event</Menu.Item>
    </Menu>
  );

  return (
    <EventDetailHeader>
      <div className="EventTitle">
        <h4>Event Details</h4>
        <p className="id">
          {selectedIngest?.billoptim_ingest_event_id}{" "}
          <CopyOutlined
            style={{ marginLeft: "5px" }}
            onClick={() =>
              copyToClipboard(selectedIngest?.billoptim_ingest_event_id)
            }
          />
        </p>
      </div>
      {selectedIngest?.event_status ==='PROCESSED' && (
      <div>
        <Dropdown overlay={menu} trigger={["hover"]}>
          <div
            style={{
              color: "#475569",
              lineHeight: "14px",
              fontWeight: " 500",
              fontSize: "13px",
            }}
          >
            Event Actions <MoreOutlined />
          </div>
        </Dropdown>
      </div>
      )}

      <AlertModal
        open={isEventActionName === "REVERT"}
        close={eventActionNameHandler}
        title="Revert the Event?"
        subtitle="Reverting this event will revert all changes caused by it, such as
          Usages, Revenue and Entitlements (Feature Credits). This action cannot
          be undone."
      />
      <ReingestModal
        open={isEventActionName === "REINGEST"}
        close={eventActionNameHandler}
        selectedIngest={selectedIngest}
      />
    </EventDetailHeader>
  );
};

export default DrawerHeader;
