import Invoices from "./invoices";
import InvoiceGroup from "./invoiceGroup";
import Orders from "./orders";
import BillRuns from "./billRuns";
import PageLayout from "../../components/common/pageLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getInvoices } from "../../redux/feature/billing/billingThunk";

const Billing = () => {
  const [isNewGroupDrawerOpen, setIsNewGroupOpen] = useState(false);
  const drawerHandler = () => {
    setIsNewGroupOpen(!isNewGroupDrawerOpen);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {invoices}=useSelector((state:RootState)=>state.billing)
  const {filter}=useSelector((state:RootState)=>state.customer)


  // useEffect(() => {
  //   if(!invoices.length)dispatch(getInvoices({
  //     sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter
  //   }))
  // },[])

  const items = [
    {
      label: `Invoices`,
      key: "1",
      children: <Invoices invoices={invoices}/>,
    },
    // {
    //   label: `Invoice Groups`,
    //   key: "2",
    //   children: (
    //     <InvoiceGroup
    //       isNewGroupDrawerOpen={isNewGroupDrawerOpen}
    //       drawerHandler={drawerHandler}
    //     />
    //   ),
    // },
    // {
    //   label: `Orders`,
    //   key: "3",
    //   children: <Orders />,
    // },
    // {
    //   label: `Bill Runs`,
    //   key: "4",
    //   children: <BillRuns />,
    // },
  ];

  const currentTabKey = useMemo(() => {
   
    if (location.pathname === "/billing/bill-runs") {
      return "4";
    } else if (location.pathname === "/billing/orders") {
      return "3";
    } else if (location.pathname === "/billing/invoice-groups") {
      return "2";
    } else {
      return "1";
    }
  }, [location.pathname]);

  const handleTabChange = (key: string) => {
    const path =
      key === "1"
        ? `/billing/invoice?__sort__= ${filter}&&invoice-type=history`
        : key === "2"
        ? `/billing/invoice-groups?__sort__= ${filter}`
        : key === "3"
        ? `/billing/orders?__sort__= ${filter}`
        : "/billing/bill-runs";

    navigate(path);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    navigate(`${currentPath}?${searchParams.toString()}`);
  }, [location.pathname, navigate]);

  return (
    <div style={{ background: "transparent" }}>
      <PageLayout
        items={items}
        title="Billing"
        isBtnShow={currentTabKey === "2" ? true : false}
        handleTabChange={handleTabChange}
        activeKey={currentTabKey}
        isNavLinkShow={true}
        btnText="New Invoice Group"
        btnOnClick={currentTabKey === "2" ? drawerHandler : () => {}}
      />
    </div>
  );
};

export default Billing;
