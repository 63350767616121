import styled from "styled-components";

export const ScheduleItemWrapper = styled.div<{ status: string }>`
  display: grid;
  flex-direction: column;
  gap: 10px;

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }
  .heading {
    display: flex;
    gap: 14px;
    align-items: center;
  }
  .dots {
    border-radius: 10px;
    width: 8px;
    height: 8px;
    background: ${(props) =>
      props.status === "Upcoming"
        ? "#d97706"
        : props.status === "Ongoing"
        ? "green"
        : props.status === "Detached"
        ? "#cbd5e1"
        : "black"};
  }
  .content {
    display: flex;
    gap: 14px;
  }
  .divider {
    width: 1px;
    margin: 4px;
    background: ${(props) =>
      props.status === "Upcoming"
        ? "#d97706"
        : props.status === "Ongoing"
        ? "green"
        : props.status === "Detached"
        ? "#cbd5e1"
        : "black"};
  }
  .content-text {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .price-cycle {
    display: flex;
    justify-content: space-between;
  }
`;

export const Bullet = styled.div<{ bulletColor: string }>`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.bulletColor};
  border-radius: 50%;
  align-self: center;
`;

export const ScheduleTitle = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

export const VerticalLine = styled.div<{ bulletColor: string }>`
  width: 1px;
  background-color: ${(props) => props.bulletColor};
  justify-self: center;
`;

export const DateWrapper = styled.div`
  border: 1px solid var(--primary-color, #0f8a6b);
  border-radius: 0.375rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  background-color: white;
`;

export const DateText = styled.span`
  font-size: 0.875rem;
  color: var(--primary-text-color, #64748b);
`;

export const Icon = styled.i`
  font-size: 12px;
  color: #64748b;
  margin-right: 0.25rem;
`;

export const DetailsGrid = styled.div`
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(2, 1fr);
  font-size: 0.875rem;

  span:nth-of-type(odd) {
    color: var(--primary-text-color, #64748b);
  }

  span:nth-of-type(even) {
    text-align: right;
    font-weight: 500;
  }
`;
