import {
  AreaChartOutlined,
  EditOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Header } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../button";
import { ReactNode } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { setEmptyBillingRateCards } from "../../../redux/feature/pricePlan/pricePlanSlice";
import { setBillingDetails } from "../../../redux/feature/pricePlan/pricePlanSlice";

const PageHeader: React.FC<{
  title?: string;
  path: string;
  isActionButtonShown?: boolean;
  onBtnClick?: () => void;
  btnText?: string;
  btnType?:"primary" | "dashed" | "link" | "text" | "default";
  icon?: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  isDraftBtnShow?: boolean;
  draftOnBtnClick?: () => void;
}> = ({
  title,
  path,
  isActionButtonShown = false,
  onBtnClick,
  btnText,
  btnType = "primary",
  icon,
  isLoading = false,
  disabled = false,
  isDraftBtnShow = false,
  draftOnBtnClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleBackClick = () => {
    if (title === "Create New Price Plan" || title === "Clone Price Plan") {
      dispatch(setEmptyBillingRateCards({ rateCards: [], pricingRules: [] }));
    }
    navigate(path);
    dispatch(setBillingDetails({ rateCards: [], pricingRules: [] }));
  };

  return (
    <Header>
      <div className="header-item-container">
        <div className="title-container">
          <div
            className="leftIcon"
            onClick={handleBackClick}
            style={{ cursor: "pointer" }}
          >
            <LeftOutlined className="icon" />
          </div>
          <p className="title">{title}</p>
        </div>
        <div className="btn-container">
          {isActionButtonShown && (
            <>
              {isDraftBtnShow && (
                <CustomButton
                  text={"Save as draft"}
                  icon={<RightOutlined />}
                  border={false}
                  position="end"
                  type="default"
                  onClick={draftOnBtnClick}
                />
              )}

              {btnText && (
                <CustomButton
                  text={btnText}
                  onClick={onBtnClick}
                  type={btnType}
                  icon={icon}
                  border={false}
                  position="end"
                  loading={isLoading}
                  disabled={disabled}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Header>
  );
};

export default PageHeader;
