import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {  Tooltip } from "antd";
import { StyledDiv, StyledInput, SupportedFields,  } from "./styles";

type SearchInputPropType = {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  TooltipSupportedFields?:string;
};

const SearchInput: React.FC<SearchInputPropType> = ({
  value,
  onChange,
  width = "400px",
  TooltipSupportedFields = ""
}) => {
  return (
    <StyledDiv>
      <StyledInput
        placeholder="Search"
        allowClear
        suffix={
          <div className="suffix">
            <Tooltip title={<SupportedFields><p>Supported Fields :</p><span>{TooltipSupportedFields}</span></SupportedFields>} >
              <InfoCircleOutlined />
            </Tooltip>
            <SearchOutlined />
          </div>
        }
        value={value}
        onChange={onChange}
        width={width}
      />
      {value && <p>Showing results for “{value}”</p>}
    </StyledDiv>
  );
};

export default SearchInput;
