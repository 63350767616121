import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/apiClient";
import { message } from "antd";

export const getCurrencies = createAsyncThunk(
  "adminCenter/getCurrencies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/currencies");
      return response.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const generateNewToken = createAsyncThunk(
  "adminCenter/generateNewToken",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/create-api-token", payload);
      message.success(response.data.message);
      return {
        secret_key: response.data.secret_key,
        token_id: response.data.token_id,
        created_at: response.data.created_at,
      };
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getAllToken = createAsyncThunk(
  "adminCenter/getAllToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/get-api-token");
      return response.data.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const updateTokenStatus = createAsyncThunk(
  "adminCenter/updateTokenStatus",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/api-token-status", payload);
      message.success(response.data.message);
      return payload;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const deleteUserToken = createAsyncThunk(
  "adminCenter/deleteUserToken",
  async (tokenId: any, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/delete-api-token?tokenId=${tokenId}`);
      message.success(response.data.message);
      return tokenId;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getAllCountry = createAsyncThunk(
  "adminCenter/getAllCountry",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/get-country");
      return response.data.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createBaseCurrency = createAsyncThunk(
  "adminCenter/createBaseCurrency",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/base-currency", payload);
      message.success("Base Currency created successfully");
      return response.data.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createAdditionalCurrency = createAsyncThunk(
  "adminCenter/createBaseCurrency",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/create-currency", payload);
      message.success("Additional Currency created successfully");
      return response;
    } catch (error: any) {
      message.error(error, Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getAccountCurrency = createAsyncThunk(
  "adminCenter/getAccountCurrency",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/currency");
      return response.data.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createBillingInfo = createAsyncThunk(
  "adminCenter/createBillingInfo",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/billing_info", payload.data);
      console.log("►►► ~ response:", response);
      return {
        billingInformationId: response.data.billing_information_id,
        ...payload.data,
        country: payload.country_name,
      };
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getBillingInfo = createAsyncThunk(
  "adminCenter/getBillingInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/billing_info");
      console.log("►►► ~ get billing response:", response);
      return response.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const deleteBillingInfo = createAsyncThunk(
  "adminCenter/deleteBillingInfo",
  async (billingInformationId: any, { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `/billing_info?billingInformationId=${billingInformationId}`
      );
      message.success("Billing address Delete successfully");
      return billingInformationId;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const imports = createAsyncThunk(
  "adminCenter/imports",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/imports",
        payload,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return response.data;
    } catch (error: any) {
      message.error(error.response.data.error || "Bulk Import failed.");
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getImports = createAsyncThunk(
  "adminCenter/getImports",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/imports");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const downloadS3File = createAsyncThunk("adminCenter/downloadCsv",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/imports/${id}/download_file`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const importsInitiate = createAsyncThunk(
  "adminCenter/importsInitiate",
  async (payload:any, { rejectWithValue }) => {
    const {importId,columnMappings}=payload
    try {
      const response = await api.post(`initiate/${importId}/imports`,{columnMappings:columnMappings});
      message.success(response.data.message)
      return importId;
    } catch (error: any) {
      return rejectWithValue(error.response.data.Error);
    }
  }
);
export const archivedBulkImports = createAsyncThunk(
  "adminCenter/archivedBulkImports",
  async (importId:any, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/imports/${importId}`);
      message.success(response.data.message)
      return importId;
    } catch (error: any) {
      return rejectWithValue(error.response.data.Error);
    }
  }
);


export const getBulkImportById = createAsyncThunk(
  "adminCenter/getBulkImportById",
  async (importId:any, { rejectWithValue }) => {
    try {
      const response = await api.get(`/imports/${importId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.Error);
    }
  }
);