import React, { useEffect, useMemo, useState } from "react";
import { PricePlanFormHeader } from "../../../../../price-plans/NewPricePlan/Styles";
import {
  Container,
  StyledForm,
} from "../../../../../price-plans/NewPricePlan/BillingDetails/Styles";
import { Col, DatePicker, Form, Input, Row, Select, Spin, Switch } from "antd";
import TextedSwitch from "../../../../../../components/common/textedSwitch";
import { NestedCard } from "../../../../../price-plans/NewPricePlan/RateCard/forms/styles";
import { billingCycleOptionsArray } from "../../../../../price-plans/NewPricePlan/BillingDetails/PricePlanForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../redux/store";
import dayjs, { Dayjs } from "dayjs";
import { adjustPricePlanDates } from "../../../../../../utils/helper";
import BillingCycleForm from "../../../../../../components/BillingCycleForm";
import moment from "moment";
import { getPricePlans, getSinglePricePlan } from "../../../../../../redux/feature/pricePlan/pricePlanThunk";
import { useLocation, useSearchParams } from "react-router-dom";
import { removeSinglePricePlanData } from "../../../../../../redux/feature/pricePlan/pricePlanSlice";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const { Option } = Select;
const PlanDetails: React.FC<any> = ({
  setSchedules,
  dates,
  setDates,
  initSchedules,
  schedules,
  form,
}) => {
  const [overridePricePlanCycle, setOverridePricePlanCycle] = useState(
    form.getFieldValue("override_price_plan_cycle") || false
  );
  const [isForever, setIsForever] = useState(false);
  const [billingCycle, setBillingCycle] = useState("MONTHLY");
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { pricePlans, singlePricePlan, loading, attachPricePlan } = useSelector(
    (state: RootState) => state.pricePlan
  );
  const { viewAccountDetails } = useSelector(
    (state: RootState) => state.customer
  );
  console.log("🚀 ~ viewAccountDetails:", viewAccountDetails);
  const { AccountCurrency } = useSelector(
    (state: RootState) => state.adminCenter
  );
  const dispatch = useDispatch<AppDispatch>();
  const generateId = () =>
    `sch.${Math.random().toString(36).substring(2, 12)}.${Math.random()
      .toString(36)
      .substring(2, 8)}`;

  const rules = useMemo(() => {
    return [{ required: true, message: "Field is required!" }];
  }, []);

  useEffect(() => {
    const start = params.get("startDate");
    console.log("🚀 ~ start:", start);
    const end = params.get("endDate");
    console.log("🚀 ~ end:", end);

    if (start && end) {
      form.setFieldsValue({
        effectiveFrom: dayjs(start),
        effectiveUntil: dayjs(end),
      });
      setDates((prev: any) => ({
        ...prev,
        effectiveFrom: dayjs(start).format("YYYY-MM-DD"),
        effectiveUntil: dayjs(end).format("YYYY-MM-DD"),
      }));
    }
  }, [singlePricePlan]);

  useEffect(()=>{
      dispatch(getPricePlans({
        sort: "-updated_at",
        type: "BILLING",
      }));
  },[])
  
  const pricePlanOptions = useMemo(() => {
    return pricePlans
    ?.filter((item: any) =>
      item.supportedCurrencies?.includes(viewAccountDetails?.currency_code) && item.status !== 'ARCHIVED' && item.status !== 'DRAFT'
  )
  ?.map((plan: any) => (
    <Option key={plan.pricePlanId} value={plan.pricePlanId}>
          {plan.name}
        </Option>
      ));
    }, [pricePlans,viewAccountDetails]);

  useEffect(() => {
    setDates((prev: any) => ({
      ...prev,
      pricePlanName: singlePricePlan?.name,
    }));
    console.log("►►► ~ useEffect ~ singlePricePlan:", singlePricePlan);
  }, [singlePricePlan]);

  const handleSwitchChange = (checked: boolean) => {
    setIsForever(checked);
    form.setFieldsValue({ effectiveUntil: null });
    setDates((prev: any) => ({
      ...prev,
      effectiveUntil: null,
    }));
  };

  const billingCycleOptions = useMemo(
    () =>
      billingCycleOptionsArray.map((cycle) => (
        <Option
          key={cycle}
          value={cycle === "Half Yearly" ? "HALF_YEARLY" : cycle.toUpperCase()}
        >
          {cycle}
        </Option>
      )),
    []
  );

  const handleDateChange = (field: any, date: Dayjs) => {
    setDates((prev: any) => ({
      ...prev,
      [field]: dayjs(date).format("YYYY/MM/DD"),
    }));
  };

 

  useEffect(() => {
    if (dates.effectiveFrom && (dates.effectiveUntil || isForever)) {
      const updatedSchedules = adjustPricePlanDates(
        initSchedules,
        dates,
        isForever
      );
      console.log("►►► ~ useEffect ~ updatedSchedules:", updatedSchedules);
      setSchedules(updatedSchedules?.eventSchema);
    } else {
      setSchedules(initSchedules);
    }
  }, [dates, isForever, singlePricePlan]);


  
  return (
    <Container>
      <PricePlanFormHeader>Price Plan Details</PricePlanFormHeader>

      <div className="billingDetailForm">
        <StyledForm layout="vertical" form={form}>
          <Form.Item label="Choose Price Plan" name="pricePlanId" rules={rules}>
            <Select
              placeholder="Select Price Plan"
              onChange={(value: any) => dispatch(getSinglePricePlan(value))}
            >
              {pricePlanOptions}
            </Select>
          </Form.Item>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            singlePricePlan && (
              <>
                <Form.Item
                  label="Effective From (in UTC)"
                  name="effectiveFrom"
                  rules={rules}
                >
                  <DatePicker
                    placeholder="Effective From (in UTC)"
                    format="YYYY/MM/DD"
                    onChange={(date: any) => {
                      return handleDateChange("effectiveFrom", date);
                    }}
                    disabledDate={(current: any) => {
                      const today = dayjs().startOf("day");
                    
                      if (attachPricePlan.length) {
                        const hasOngoingPlan = attachPricePlan?.some(plan => {
                          const ongoingStartDate = dayjs(plan?.effective_from).startOf("day");
                          const ongoingEndDate = dayjs(plan?.effective_until).endOf("day");
                    
                          return today.isBetween(ongoingStartDate, ongoingEndDate, null, '[]');
                        });
                    
                        return hasOngoingPlan && current && current <= dayjs().startOf("day");
                      }
                    
                      return false; // Default: no disabled dates if no ongoing plans
                    }}
                    
                    
                  />
                </Form.Item>

                {!isForever ? (
                  <Form.Item
                    label={
                      isForever ? "Effective Until" : "Effective Until (in UTC)"
                    }
                    name="effectiveUntil"
                    rules={rules}
                  >
                    <DatePicker
                      placeholder={
                        isForever ? "Forever" : "Effective Until (in UTC)"
                      }
                      format="YYYY/MM/DD"
                      disabled={isForever} 
                      onChange={(date: any) =>
                        handleDateChange("effectiveUntil", date)
                      }
                      disabledDate={(current: any) => {
                        return current && current <= dayjs().add(1, 'day').startOf("day");
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={
                      isForever ? "Effective Until" : "Effective Until (in UTC)"
                    }
                    name="isForever"
                  >
                    <Input
                      placeholder="Forever"
                      disabled={true}
                      value={"Forever"}
                      style={{ width: "320px" }}
                    />
                  </Form.Item>
                )}

                <Form.Item label="Until Forever" name="forever">
                  <Switch checked={isForever} onChange={handleSwitchChange} />
                </Form.Item>
                <Form.Item
                  name="override_price_plan_cycle"
                  valuePropName="checked"
                >
                  <TextedSwitch
                    text="Override Price Plan Cycle"
                    checked={overridePricePlanCycle}
                    onChange={() => {
                      setOverridePricePlanCycle(!overridePricePlanCycle);
                    }}
                  />
                </Form.Item>
                {overridePricePlanCycle && (
                  <NestedCard maxWidth={"510px"}>
                    <Form.Item name="interval">
                      <Select
                        onChange={(e: any) => {
                          setBillingCycle(e);
                          setDates((prev: any) => ({
                            ...prev,
                            pricingCycleInterval: e,
                          }));
                        }}
                        defaultValue={"Monthly"}
                      >
                        {billingCycleOptions}
                      </Select>
                    </Form.Item>
                    <BillingCycleForm
                      billingCycle={billingCycle}
                      setDates={setDates}
                      form={form}
                      isAttachedPlan={true}
                    />
                    <Form.Item label="Grace Period" name="gracePeriod">
                      <Input
                        type="number"
                        placeholder="Grace Period"
                        min={0}
                        onChange={(e: any) => {
                          setDates((prev: any) => ({
                            ...prev,
                            gracePeriod: e.target.value,
                          }));
                        }}
                        onWheel={(e: any) => e.target.blur()}
                      />
                    </Form.Item>
                  </NestedCard>
                )}
              </>
            )
          )}
        </StyledForm>
      </div>
    </Container>
  );
};

export default PlanDetails;
