import { Col, Tag } from "antd";
import { SpecificUsage, StyledRow } from "./styles";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import AppliedEntitiesDrawer from "./AppliedEntitiesDrawer";
import ApplicableEntityList from "./ApplicableEntityList";
import { StyledTag } from "../../../../../components/common/tag/styles";

interface AddOn {
  addOnId: string;
  name: string;
}

const CommonExpandItem: React.FC<{ record: any; currency?: any }> = ({
  record,
  currency,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addonIds, setAddonIds] = useState<string[]>([]);
  const [usageIds, setUsageIds] = useState<string[]>([]);

  const { addOns, usageMeters } = useSelector(
    (state: RootState) => state.billable
  );

  useEffect(() => {
    const applicableEntity = record?.[0]?.grantDetails?.applicableEntityIds[0];
    if (
      applicableEntity?.value === "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES"
    ) {
      const addon = applicableEntity?.add_on?.length;
      const usage = applicableEntity?.usage_base?.length;

      if (addon) setAddonIds(applicableEntity.add_on);
      if (usage) setUsageIds(applicableEntity.usage_base);
    }
  }, [record]);

  const addonData = useMemo(() => {
    if (addonIds.length && addOns.length) {
      return addOns
        .filter((addon: AddOn) => addonIds.includes(addon.addOnId))
        .map((item: AddOn) => ({ field: item.addOnId, value: item.name }));
    }
    return [];
  }, [addonIds, addOns]);

  const usageData = useMemo(() => {
    if (usageIds.length && usageMeters.length) {
      return usageMeters
        .filter((usage: any) => usageIds.includes(usage.usageMeterId))
        .map((item: any) => ({ field: item.usageMeterId, value: item.name }));
    }
    return [];
  }, [usageIds, usageMeters]);

  const drawerHandler = () => setIsOpen((prev) => !prev);

  return (
    <>
      <StyledRow>
        <Col span={6} className="header">
          <span>Rate Card Configuration</span>
        </Col>
        <Col span={18}>
          <div className="configuration">
            {record?.map((item: any) => {
              return (
                <>
                  {item?.cardType === "CREDIT_GRANT" ||
                  item?.cardType === "Credit Grant Fee" ? (
                    <>
                      <div className="configurationItem">
                        <span>Billable ID</span>
                        <span className="id">
                          {item.id ?? item?.rateCardId}
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Invoice Timing</span>
                        <span className="id">
                          <StyledTag>
                            {item.invoiceTiming === "IN_ADVANCE"
                              ? "In Advance "
                              : item.invoiceTiming === "PREPAID"
                              ? "Prepaid"
                              : "In Arrears"}
                          </StyledTag>
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Applicable Entities</span>
                        <span className="id">
                          <ApplicableEntityList
                            item={item}
                            drawerHandler={drawerHandler}
                          />
                        </span>
                      </div>

                      <div className="configurationItem">
                        <span>Priority</span>
                        <span className="id">
                          {item?.grantDetails?.priority ?? "-"}
                        </span>
                      </div>
                      {item?.grantDetails?.expiryType === "CUSTOM" && (
                        <div className="configurationItem">
                          <span>Expiry Duration</span>
                          <span className="id">
                            {item?.grantDetails?.expiryDuration.slice(0, -1)}{" "}
                            {item?.grantDetails?.expiryDuration.slice(-1) ===
                            "D"
                              ? "Days"
                              : item?.grantDetails?.expiryDuration.slice(-1) ===
                                "M"
                              ? "Months"
                              : "Years"}
                          </span>
                        </div>
                      )}
                      <div className="configurationItem">
                        <span>Recurrence Type</span>
                        <span className="id">
                          <StyledTag>
                            {item.recurringRateCard?.type ?? item?.type}
                          </StyledTag>
                        </span>
                      </div>
                      {item?.type === "RECURRING" && (
                        <>
                          {" "}
                          <div className="configurationItem">
                            <span>Repeat every nth cycle</span>
                            <span className="id">
                              {item?.recurrenceConfig?.interval || "-"}
                            </span>
                          </div>
                          <div className="configurationItem">
                            <span>Start from nth cycle</span>
                            <span className="id">
                              {item?.recurrenceConfig?.offset || "-"}
                            </span>
                          </div>{" "}
                        </>
                      )}
                    </>
                  ) : item?.cardType === "FIXED_FEE" ||
                    item?.cardType === "Fixed Fee" ? (
                    <>
                      <div className="configurationItem">
                        <span>Billable ID</span>
                        <span className="id">{item.id || item.rateCardId}</span>
                      </div>
                      <div className="configurationItem">
                        <span>Invoice Timing</span>
                        <span className="id">
                          <StyledTag>
                            {" "}
                            {item.invoiceTiming === "IN_ADVANCE"
                              ? "In Advance "
                              : "In Arrears"}
                          </StyledTag>
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Proration</span>
                        <span className="id">
                          {item.enableProration ? (
                            <StyledTag>Enable</StyledTag>
                          ) : (
                            <StyledTag>Disable</StyledTag>
                          )}
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Recurrence Type</span>
                        <span className="id">
                          <StyledTag>{item?.type}</StyledTag>
                        </span>
                      </div>
                      {item?.recurrenceConfig && (
                        <>
                          <div className="configurationItem">
                            <span>Repeat every nth cycle</span>
                            <span className="id">
                              {item?.recurrenceConfig?.interval ?? "-"}
                            </span>
                          </div>
                          <div className="configurationItem">
                            <span>Start from nth cycle</span>
                            <span className="id">
                              {item?.recurrenceConfig?.offset ?? "-"}
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  ) : item?.cardType === "LICENSE" ||
                    item?.cardType === "License Fee" ? (
                    <>
                      <div className="configurationItem">
                        <span>Billable ID</span>
                        <span className="id">
                          {item?.id ?? item?.rateCardId}
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Type</span>
                        <span className="id">
                          <StyledTag>
                            {item?.type === "NAMED_LICENSE"
                              ? "Named License"
                              : "License"}
                          </StyledTag>
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Invoice Timing</span>
                        <span className="id">
                          <StyledTag>
                            {" "}
                            {item?.invoiceTiming === "IN_ADVANCE"
                              ? "In Advance "
                              : "In Arrears"}
                          </StyledTag>
                        </span>
                      </div>
                      {item.type === "LICENSE" && (
                        <div className="configurationItem">
                          <span>Proration</span>
                          <span className="id">
                            <StyledTag>
                              {item?.enableProration ? "Enabled" : "Disabled"}
                            </StyledTag>
                          </span>
                        </div>
                      )}
                      <div className="configurationItem">
                        <span>Prorated Refund Mode</span>
                        <span className="id">
                          {item?.proratedRefundMode || "-"}
                        </span>
                      </div>
                      {item.type === "LICENSE" && (
                        <div className="configurationItem">
                          <span>Maximum Allowed Quantity</span>
                          <span className="id">
                            {item?.config?.maxQuantity} units
                          </span>
                        </div>
                      )}
                    </>
                  ) : item?.cardType === "USAGE_BASED_FEE" ||
                    item?.cardType === "Usage Based Fee" ? (
                    <>
                      <div className="configurationItem">
                        <span>Usage Meter ID</span>
                        <span className="id">
                          {item?.id || item?.usageMeterId}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </div>
        </Col>
      </StyledRow>

      <StyledRow>
        <Col span={6} className="header">
          <span>Price Configuration</span>
        </Col>
        <Col
          span={18}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {record?.map((item: any, recordIndex: number) => {
            if (
              item?.cardType === "USAGE_BASED_FEE" ||
              item?.cardType === "Usage Based Fee" ||
              item?.cardType === "LICENSE"
            ) {
              return (
                <React.Fragment key={recordIndex}>
                  {item?.ratePlan?.slabs?.map(
                    (slab: any, slabIndex: number) => {
                      console.log("►►► ~ {record.map ~ slab:", slab);
                      const nextSlab = item?.ratePlan?.slabs[slabIndex + 1];
                      const index = item?.rateValues?.findIndex((item: any) => {
                        return item?.currency === currency;
                      });
                      const rateValue = item?.rateValues.length
                        ? item?.rateValues[index !== -1 ? index : 0]
                        : {};

                      return (
                        <div key={slabIndex}>
                          <div className="configuration priceConfiguration">
                            <div className="priceItem left">
                              <span>
                                {slab?.startAfter} -{" "}
                                {nextSlab
                                  ? nextSlab?.startAfter
                                  : "Infinity (∞)"}
                              </span>
                              <span>
                                {slab?.priceType}
                                {slab?.priceType === "package"
                                  ? `-${slab?.slabConfig?.packageSize}`
                                  : ""}
                              </span>
                            </div>
                            <div className="priceItem right">
                              {rateValue?.slabRates[slabIndex]?.rate && (
                                <span>
                                  <span className="currency">
                                    {rateValue?.currency}{" "}
                                  </span>
                                  {rateValue?.slabRates[slabIndex]?.rate}
                                </span>
                              )}
                              {(rateValue?.slabRates[slabIndex]?.slabRateConfig
                                ?.minimumRate ||
                                rateValue?.slabRates[slabIndex]?.slabRateConfig
                                  ?.maximumRate) && (
                                <span>
                                  Min:{" "}
                                  {
                                    rateValue?.slabRates[slabIndex]
                                      ?.slabRateConfig?.minimumRate
                                  }{" "}
                                  Max:{" "}
                                  {
                                    rateValue?.slabRates[slabIndex]
                                      ?.slabRateConfig?.maximumRate
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                  {item?.rateValues.length &&
                  item?.rateValues[0]?.rateConfig ? (
                    (() => {
                      const index = item?.rateValues?.findIndex(
                        (rateValue: any) => rateValue?.currency === currency
                      );
                      const rateValuesData =
                        item?.rateValues[index !== -1 ? index : 0] || {};

                      return (
                        <React.Fragment key={index}>
                          {rateValuesData?.rateConfig?.minimumRate && (
                            <div className="configuration priceConfiguration">
                              <div className="priceItem left minMax">
                                <span>Minimum Price</span>
                              </div>
                              <div className="priceItem right">
                                <span>
                                  <span className="currency">
                                    {rateValuesData?.currency}{" "}
                                  </span>
                                  {rateValuesData?.rateConfig?.minimumRate}
                                </span>
                              </div>
                            </div>
                          )}
                          {rateValuesData?.rateConfig?.maximumRate && (
                            <div className="configuration priceConfiguration">
                              <div className="priceItem left minMax">
                                <span>Maximum Price</span>
                              </div>
                              <div className="priceItem right ">
                                <span>
                                  <span className="currency">
                                    {rateValuesData?.currency}{" "}
                                  </span>
                                  {rateValuesData?.rateConfig?.maximumRate}
                                </span>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })()
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            } else if (
              item?.cardType === "CREDIT_GRANT" ||
              item?.cardType === "Credit Grant Fee"
            ) {
              const index =
                item?.rateDetails?.currencySlabRateDetails?.findIndex(
                  (item: any) => {
                    return item.currency === currency;
                  }
                );
              const creditGrantData =
                item?.rateDetails?.currencySlabRateDetails[
                  index !== -1 ? index : 0
                ];

              return (
                <div>
                  <div
                    className="configuration priceConfiguration"
                    style={{ marginBottom: "1rem" }}
                  >
                    <div className="priceItem left">
                      <span>Price</span>
                    </div>
                    <div className="priceItem right">
                      <span>
                        <span className="currency">
                          {creditGrantData?.currency}{" "}
                        </span>
                        {creditGrantData?.slabDetails?.[0]?.rate}
                      </span>
                    </div>
                  </div>
                  <div className="configuration priceConfiguration">
                    <div className="priceItem left">
                      <span>Credit Grants</span>
                    </div>
                    <div className="priceItem right">
                      <span>
                        <span className="currency">
                          {creditGrantData?.currency}{" "}
                        </span>
                        {creditGrantData?.creditAmount}
                      </span>
                    </div>
                  </div>
                </div>
              );
            } else {
              const index = item?.rateValues?.findIndex((item: any) => {
                return item?.currency === currency;
              });
              const rateValuesData =
                item?.rateValues[index !== -1 ? index : 0] || {};
              return (
                <div>
                  <div className="configuration priceConfiguration">
                    <div className="priceItem left">
                      <span>Price</span>
                    </div>
                    <div className="priceItem right">
                      <span>
                        <span className="currency">
                          {rateValuesData?.currency}{" "}
                        </span>
                        {rateValuesData?.rate}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </Col>
        <AppliedEntitiesDrawer
          isOpen={isOpen}
          onClose={drawerHandler}
          addonData={addonData}
          usageData={usageData}
        />
      </StyledRow>
    </>
  );
};
export default CommonExpandItem;
