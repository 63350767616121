import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState, useEffect, useCallback } from "react";
import { UserContainer } from "./styles";
import ActiveUsers from "./tables/Users";
import UserDrawer from "./drawer";
import Segment from "../../../components/common/segmented";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import CustomButton from "../../../components/common/button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../api/apiClient";
import {
  setActiveUsers,
  setInvitedUsers,
} from "../../../redux/feature/auth/authSlice";

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const listType = searchParams.get("listType");
  const [usersFilter, setUsersFilter] = useState<string>("");
  const location = useLocation();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const drawerHandler = () => {
    setIsVisible(!isVisible);
  };
  useEffect(() => {
    if (!listType && location.pathname.includes("/users")) {
      navigate("/admin-center/users?listType=ACTIVE");
    } else {
      if(location.pathname.includes("/users"))setUsersFilter(listType === "ACTIVE" ? "Active Users" : "Invited Users");
    }
  }, [listType, navigate, location.pathname]);

  const handleFilterChange = (value: string) => {
    const newFilter = value === "Active Users" ? "ACTIVE" : "INVITED";
    setUsersFilter(value);
    setSearchParams({ listType: newFilter });
  };


  const fetchUsers = useCallback(
    async (type: "active" | "invited") => {
      try {
        const response = await api.get(`/users?type=${type}`);
        if (type === "active") {
          dispatch(setActiveUsers(response.data.data));
        } else {
          dispatch(setInvitedUsers(response.data.data));
        }
      } catch (err) {
        console.error("Error fetching users:", err);
      }
    },
    [dispatch]
  );
  return (
    <UserContainer>
      <div className="header">
        <Segment
          options={["Active Users", "Invited Users"]}
          value={usersFilter}
          onChange={handleFilterChange}
        />
        <CustomButton
          text="New User"
          icon={<PlusOutlined />}
          onClick={drawerHandler}
        />
      </div>
      <ActiveUsers usersFilter={usersFilter}  />
      <UserDrawer isOpen={isVisible} type={"new"} onClose={drawerHandler}  />
    </UserContainer>
  );
};

export default Users;
