import { Table } from "antd";
import styled from "styled-components";

export const StyledTable = styled(Table) <any>` 
    border: ${({ isShowBorder = true }) => (isShowBorder ? '1px solid #cbd5e1 ' : 'unset')};
    border-radius: 10px;
    padding: 8px;
    padding-bottom: 5px;
    background-color: white;

    .ant-table-thead > tr > th {
      border-bottom: none;
      padding: 8px 16px ;
      color: #475569;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      background-color: #f6f7f8;
    }
    .ant-table-thead > tr :first-child{
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .ant-table-thead > tr :last-child{
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .ant-table-cell::before{
      display: none;
    }
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #d9d9d9;
      font-size: 14px;
      font-weight: 400;
      color: #475569;
      padding: 16px 8px !important;
    }
    .ant-table-tbody :last-child > td {
      border-bottom: none !important;
    }
    .ant-table-container::before ,.ant-table-container::after  {
      display: none !important;
      box-shadow: none !important;
    }
    .ant-table-row{
      cursor: ${(props) => (props.isCursorPointerShow ? 'pointer' : 'default')};
    }
`;