import React from "react";
import CustomTable from "../../../../../components/common/customTable";
import CustomDrawer from "../../../../../components/common/drawer";
import { EmptyData } from "../../../../../components/common/empty";
import { AppliedEntitiesDrawerContent } from "./styles";

type dataType = {
    field:string;
    value:string;
}
type AppliedEntitiesDrawerPropsType = {
    isOpen: boolean;
    onClose: () => void;
    usageData?: dataType[];
    addonData?: dataType[];
} 

const AppliedEntitiesDrawer:React.FC<AppliedEntitiesDrawerPropsType> = ( { isOpen , onClose , usageData , addonData } ) => {
    return(
        <CustomDrawer
        isOpen={isOpen}
        onClose={onClose}
        title="Applied Entities"
        isActionButtonsShown={false}
        width={500}
      >
        <AppliedEntitiesDrawerContent>
          <div className="entityItem">
            <p className="title">Applied Usage Meters</p>
           {usageData?.length ?  <CustomTable column={["Usage Meter Id","Usage Meter Name"]} data={usageData} isCopyShow={false} /> : <EmptyData description="Applied to no Usage Meters" />}
          </div>
          <div className="entityItem">
            <p className="title">Applied Addons</p>
            { addonData?.length  ? <CustomTable column={["Addon Id","Addon Name"]} data={addonData} isCopyShow={false} /> : <EmptyData description="Applied to no Addons" />}
          </div>
         
        </AppliedEntitiesDrawerContent>
      </CustomDrawer>
    )
}

export default AppliedEntitiesDrawer;