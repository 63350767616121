import React from "react";

import { AvatarContainer } from "./Styles";
import { Avatar } from "antd";
import { getColorFromName } from "../../../utils/helper";

const UserAvatar: React.FC<any> = ({ user }) => {
  let displayName = "";
  let baseString = "";
  if (user?.name) {
    displayName += user?.name?.slice(0, 2)?.toUpperCase();
    baseString = user.name;
  } else {
    displayName += user?.customer_name?.slice(0, 2)?.toUpperCase() ;
    baseString = user?.customer_name ;
  }

  const avatarColor = getColorFromName(baseString || "default");
  return (
    <Avatar
      style={{
        height: "37px",
        width: "37px",
        borderRadius: "100%",
        backgroundColor:avatarColor,
        lineHeight:'13px'
      }}
    >
      {displayName}
    </Avatar>
   
  );
};

export default UserAvatar;
