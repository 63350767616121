import { message } from "antd";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { RootState, store } from "../redux/store";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(utc);
dayjs.extend(timezone);

const colors = [
  '#9c786d',
  '#5e8558',
  '#7b8cad',
  '#836096',
  '#8c5656',
  '#995a77',
  '#6b8f8a',
  '#1e313d',
];

export const getColorFromName = (name:any) => colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];

export const emailRegex = /^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{2,}$/;

export const capitalizeFirstLetter = (str: string): string => {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  return str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatDateToLocalTime = (isoDate: any,) => {
 const {auth:{user}} = store.getState();
  const indiaTime = dayjs.tz(isoDate);
    return indiaTime.tz(user?.timezone).format('DD MMM, YYYY HH:mm z');
};

export const transformFormValues = (
  data: any,
  name: any = "",
  initValues: any = ""
) => {
  const values = { ...data };

  if (name && initValues) {
    Object.keys(initValues || {}).forEach((key) => {
      if (key !== "description" && values.hasOwnProperty(key)) {
        delete values[key];
      }
    });
  }
  console.log(
    "🚀 ~ file: index.tsx:162 ~ transformFormValues ~ result:",
    values
  );

  const transformed = {
    name: values.name,
    description: values.description,
    attributes: [],
    dimensions: [],
  };
  let atr = 0;
  let unt = 0;
  let dim = 0;

  Object.keys(values).forEach((key, i) => {
    if (key.startsWith("attribute-")) {
      const index = Number(key.split("-")[1]);
      transformed.attributes[atr] = transformed.attributes[atr] || {}; //  @ts-ignore
      transformed.attributes[atr].name = values[key];
      atr++;
    }

    if (key.startsWith("unit-")) {
      const index = Number(key.split("-")[1]);
      transformed.attributes[unt] = transformed.attributes[unt] || {}; //  @ts-ignore
      transformed.attributes[unt].defaultUnit = values[key] || null;
      unt++;
    }

    if (key.startsWith("dimensions-")) {
      const index = Number(key.split("-")[1]);
      transformed.dimensions[dim] = transformed.dimensions[dim] || {}; //  @ts-ignore
      transformed.dimensions[dim].name = values[key];
      dim++;
    }
  });
  console.log(
    "🚀 ~ file: index.tsx:204 ~ transformFormValues ~ transformed:",
    transformed
  );
  return transformed;
};

export const transformUsageMeterFormValues = (formData: any) => {
  const data = { ...formData };

  const transformed: any = {
    name: data.name,
    description: data.description,
    billableName: data.billableName,
    aggregation: data.aggregationType,
    computations: [],
    eventSchemaId: data.eventSchemaId,
  };
  let computationObj: any = {};

  const operators: any = [];
  const values: any = [];
  const dimensions: any = [];
  let matcher: any = null;

  // ************************  Code for creating matcher objects ************************ //

  Object.keys(data).forEach((key, i) => {
    if (key.startsWith("operator-")) {
      const index = Number(key.split("-")[1]);
      operators.push(data[`operator-${index}`]);
      values.push(data[`value-${index}`]);
      dimensions.push(data[`chooseAttributeDimension-${index}`]);
    }
  });

  const createCondition = (index: any) => {
    return {
      [operators[index]]: [
        {
          var: `attributes.${dimensions[index]}`,
        },
        values[index],
      ],
    };
  };

  const buildObject: any = (index: any) => {
    if (index === 0) {
      return createCondition(index);
    }

    const previous = buildObject(index - 1);
    return {
      and: [previous, createCondition(index)],
    };
  };

  if (operators.length > 0) {
    matcher = buildObject(operators.length - 1);
  }

  // ************************  --------------------------------    ************************ //

  // ************************  Code for creating computation objects ************************ //
  let computation: any = null;
  if (data?.aggregationType?.toLowerCase() === "sum") {
    const baseVar = {
      var: `attributes.${data.chooseAttribute}`,
    };

    let computationObject: any = baseVar;

    Object.keys(data).forEach((key, i) => {
      if (key.startsWith("computationOperator-")) {
        const index = Number(key.split("-")[1]);
        const operator = data[`computationOperator-${index}`];
        const attribute = data[`computationAttribute-${index}`];

        if (operator && attribute) {
          computationObject = {
            [operator]: [
              computationObject,
              {
                var: `attributes.${attribute}`,
              },
            ],
          };
        }
      }
    });

    computation = computationObject;
  }

  // ************************  --------------------------------    ************************ //

  computationObj.matcher = JSON.stringify(matcher);
  computationObj.computation = JSON.stringify(computation);
  transformed.computations.push(computationObj);
  return transformed;
};


export const timeAgo = (date: Date) => {
  return moment(date).fromNow();
};

// Helper function to adjust price plan dates
// export const adjustPricePlanDates = (
//   data: any[],
//   newEntry: any,
//   generateId: () => string,
//   isForever: boolean
// ) => {
//   return data.reduce((result: any[], period: any) => {
//     const existingStart = new Date(period.effectiveFrom);
//     const existingEnd = new Date(period.effectiveUntil);
//     const newStart = new Date(newEntry.effectiveFrom);
//     const newEnd = new Date(newEntry.effectiveUntil);

//     // Check for overlap
//     if (newStart <= existingEnd && newEnd >= existingStart) {
//       // Period before overlap
//       if (newStart > existingStart) {
//         result.push(
//           createSchedule(
//             period,
//             period.effectiveFrom,
//             newEntry.effectiveUntil,
//             isForever,
//             generateId
//           )
//         );
//       }

//       // Add new entry if not present
//       if (!result.some((entry) => isSamePeriod(entry, newEntry))) {
//         result.push(
//           createSchedule(
//             newEntry,
//             newEntry.effectiveFrom,
//             newEntry.effectiveUntil,
//             isForever,
//             generateId
//           )
//         );
//       }

//       // Period after overlap
//       if (newEnd < existingEnd) {
//         result.push(
//           createSchedule(
//             newEntry,
//             newEntry.effectiveFrom,
//             newEntry.effectiveUntil,
//             isForever,
//             generateId
//           )
//         );
//       }
//     } else {
//       result.push(period);
//     }

//     return result;
//   }, []);
// };

// export function adjustPricePlanDates(data:any[], newEntry:any, isForever: boolean ) {
//   console.log("🚀 ~ file: helper.ts:248 ~ adjustPricePlanDates ~ data:", data, newEntry, isForever);
//   const result:any = [];
//   let newEntryAdded = false;

//   if (isForever) {
//     newEntry.effectiveUntil = "9999-01-01";
//   }

//   const newStart = new Date(newEntry.effectiveFrom);
//   const newEnd = new Date(newEntry.effectiveUntil);

//   if (data.length === 0 && newEntry.effectiveFrom && newEntry.effectiveUntil) {
//     result.push({
//       effectiveFrom: newEntry.effectiveFrom,
//       effectiveUntil: newEntry.effectiveUntil,
//       type: 'create',
//       active: true,
//     });
//     return { eventSchema: result };
//   }

//   data.forEach((period) => {
//     const existingStart = new Date(period.effectiveFrom);
//     const existingEnd = new Date(period.effectiveUntil);

//     // Check if there is an overlap
//     if (newStart <= existingEnd && newEnd >= existingStart) {
//       // Case where the new entry starts after the existing period, split the period
//       if (newStart > existingStart) {
//         result.push({
//           effectiveFrom: period.effectiveFrom,
//           effectiveUntil: newEntry.effectiveFrom,
//           type: 'update',  // updating this part to end where the new entry starts
//           eventScheduleId: period.eventScheduleId,
//         });
//       }

//       // Add the new entry as a created period, only if not already added
//       if (!newEntryAdded) {
//         result.push({
//           effectiveFrom: newEntry.effectiveFrom,
//           effectiveUntil: newEntry.effectiveUntil,
//           type: 'create',
//           active: true,
//         });
//         newEntryAdded = true;
//       }

//       if (newEnd < existingEnd) {
//         result.push({
//           effectiveFrom: newEntry.effectiveUntil,
//           effectiveUntil: period.effectiveUntil,
//           type: 'update',  // update the existing event with a new start date
//           eventScheduleId: period.eventScheduleId,
//         });
//       }

//       // Mark the existing event for deletion if it is fully overlapped by the new entry
//       if (newStart <= existingStart && newEnd >= existingEnd) {
//         result.push({
//           effectiveFrom: period.effectiveFrom,
//           effectiveUntil: period.effectiveUntil,
//           type: 'delete',
//           eventScheduleId: period.eventScheduleId,
//         });
//       }
//     } else {
//       // No overlap, keep the period as is
//       result.push({
//         effectiveFrom: period.effectiveFrom,
//         effectiveUntil: period.effectiveUntil,
//         type: 'update',
//         eventScheduleId: period.eventScheduleId,
//       });
//     }
//   });

//   return { eventSchema: result };
// }
export function adjustPricePlanDates(
  data: any[],
  newEntry: any,
  isForever: boolean
) {
  console.log(
    "🚀 ~ file: helper.ts:248 ~ adjustPricePlanDates ~ data:",
    data,
    newEntry,
    isForever
  );
  const result: any = [];
  let newEntryAdded = false;

  if (isForever) {
    newEntry.effectiveUntil = "9999-01-01";
  }

  const newStart = new Date(newEntry.effectiveFrom);
  const newEnd = new Date(newEntry.effectiveUntil);

  if (data.length === 0 && newEntry.effectiveFrom && newEntry.effectiveUntil) {
    result.push({
      ...newEntry,
      effectiveFrom: newEntry.effectiveFrom,
      effectiveUntil: newEntry.effectiveUntil,
      type: "create",
      active: true, // Active flag for new entry
    });
    return { eventSchema: result };
  }

  data.forEach((period) => {
    const existingStart = new Date(period.effectiveFrom);
    const existingEnd = new Date(period.effectiveUntil);

    // Check if there is an overlap
    if (newStart <= existingEnd && newEnd >= existingStart) {
      // Case where the new entry starts after the existing period, split the period
      if (newStart > existingStart) {
        result.push({
          ...period,
          effectiveFrom: period.effectiveFrom,
          effectiveUntil: newEntry.effectiveFrom,
          type: "update", // updating this part to end where the new entry starts
          eventScheduleId: period.eventScheduleId,
        });
      }

      // Add the new entry as a created period, only if not already added
      if (!newEntryAdded) {
        result.push({
          ...newEntry,
          effectiveFrom: newEntry.effectiveFrom,
          effectiveUntil: newEntry.effectiveUntil,
          type: "create",
          active: true, // Active flag for new entry
        });
        newEntryAdded = true;
      }

      // If the new entry ends before the existing period ends, split the period and treat it as a new creation
      if (newEnd < existingEnd) {
        result.push({
          ...newEntry,
          effectiveFrom: newEntry.effectiveUntil,
          effectiveUntil: period.effectiveUntil,
          type: "create", // Treating this as a newly created period
          eventScheduleId: period.eventScheduleId, // Keeping the existing eventScheduleId
        });
      }

      // Mark the existing event for deletion if it is fully overlapped by the new entry
      if (newStart <= existingStart && newEnd >= existingEnd) {
        result.push({
          effectiveFrom: period.effectiveFrom,
          effectiveUntil: period.effectiveUntil,
          type: "delete",
          eventScheduleId: period.eventScheduleId,
        });
      }
    } else {
      // No overlap, keep the period as is
      result.push({
        ...period,
        effectiveFrom: period.effectiveFrom,
        effectiveUntil: period.effectiveUntil,
        type: "update",
        eventScheduleId: period.eventScheduleId,
      });
    }
  });

  if (!newEntryAdded) {
    result.push({
      ...newEntry,
      effectiveFrom: newEntry.effectiveFrom,
      effectiveUntil: newEntry.effectiveUntil,
      type: "create",
      active: true,
    });
    newEntryAdded = true;

  }

  return { eventSchema: result };
}

// Helper to create a schedule object
export const createSchedule = (
  entry: any,
  from: string,
  until: string,
  isForever: boolean,
  generateId: () => string
) => {
  return {
    id: generateId(),
    bulletColor: "#0f8a6b",
    title: "new schedule",
    effectiveFrom: dayjs(from).format("MMM DD, YYYY"),
    effectiveUntil: isForever ? "Forever" : dayjs(until).format("MMM DD, YYYY"),
    cycle: "MONTHLY",
    startMonth: "-",
    cycleDate: "1",
    gracePeriod: "0",
  };
};

// Helper to check if two periods are the same
export const isSamePeriod = (entry1: any, entry2: any) => {
  return (
    entry1.effectiveFrom === entry2.effectiveFrom &&
    entry1.effectiveUntil === entry2.effectiveUntil
  );
};

export const transformPricePlanPayload = (payload: any) => {
  console.log("⏩ ~ payload:", payload)
  const transformRateCard = (rateCard: any) => {
    console.log("⏩ ~ rateCard: >>>>>>>>>>>>>>>>>>>", rateCard)

    let transformedCard = {};

    // Helper function to dynamically map slabs
    const getDynamicSlabs = (rateCard: any) => {
      let slabs = [];
      let index = 0;

      // Ensure first slab starts at 0 if not provided
      const firstUnit =
        rateCard[`firstUnit-0`] !== undefined
          ? parseInt(rateCard[`firstUnit-0`], 10)
          : 0;

      // Add the first slab explicitly with startAfter = 0
      slabs.push({
        order: 1,
        startAfter: firstUnit,
        priceType: rateCard[`pricingType-0`] || "FLAT",
        slabConfig: rateCard[`packageSize-0`]
          ? { packageSize: parseInt(rateCard[`packageSize-0`], 10) }
          : {},
      });

      // Dynamically collect additional slab details starting from index 1
      index = 1; // Now we start from index 1
      while (rateCard[`pricingType-${index}`] !== undefined) {
        const slab: any = {
          order: index + 1,
          startAfter: parseInt(rateCard[`firstUnit-${index}`], 10) || 0,
          priceType: rateCard[`pricingType-${index}`] || "FLAT",
          slabConfig: {},
        };

        // Check if there's a packageSize associated with the slab
        if (rateCard[`packageSize-${index}`]) {
          slab.slabConfig.packageSize = parseInt(
            rateCard[`packageSize-${index}`],
            10
          );
        }

        slabs.push(slab);
        index++;
      }

      return slabs;
    };

    // Helper function to dynamically map slab rates
    const getDynamicSlabRates = (rateCard: any, currency: any) => {
      let slabRates = [];
      let index = 0;

      while (rateCard[`${currency}-price-${index}`] !== undefined) {
        const rate: any = {
          rate: parseFloat(rateCard[`${currency}-price-${index}`]),
          order: index + 1,
        };

        // Check for min and max pricing limits
        if (
          rateCard[`${currency}-min-${index}`] ||
          rateCard[`${currency}-max-${index}`]
        ) {
          rate.slabRateConfig = {
            minimumRate: rateCard[`${currency}-min-${index}`]
              ? parseFloat(rateCard[`${currency}-min-${index}`])
              : null,
            maximumRate: rateCard[`${currency}-max-${index}`]
              ? parseFloat(rateCard[`${currency}-max-${index}`])
              : null,
          };
        }

        slabRates.push(rate);
        index++;
      }
      return slabRates;
    };

    switch (rateCard.cardType) {
      case "CREDIT_GRANT":
        transformedCard = {
          id: rateCard.id || null,
          displayName: rateCard.displayName,
          type: rateCard.type || null,
          cardType: "CREDIT_GRANT",
          recurrenceConfig: {
            interval: rateCard.interval
              ? parseInt(rateCard.interval, 10)
              : null,
            offset: rateCard.offset ? parseInt(rateCard.offset, 10) : null,
          },
          invoiceTiming: rateCard.invoiceTiming || "IN_ARREARS",
          rateDetails: {
            pricingModel: rateCard.pricingModal || "TIERED",
            currencySlabRateDetails: payload.supportedCurrencies.map(
              (currency: any) => ({
                currency: currency,
                creditAmount: rateCard[`${currency}-creditGrant`] || 0,
                slabDetails: [
                  {
                    startAfter: 0,
                    endAt: null,
                    priceType: rateCard.pricingType || "FLAT",
                    slabConfig: {},
                    rate: rateCard[`${currency}-Price`]
                      ? parseFloat(rateCard[`${currency}-Price`])
                      : 0,
                    slabRateConfig: {},
                  },
                ],
              })
            ),
          },
          tag: rateCard.tag || null,
          grantDetails: {
            expiryType: rateCard?.expiry_type || null,
            expiryDuration: `${rateCard?.expiry_duration}${rateCard?.expiry_interval}`,
            priority: rateCard.priority
              ? parseInt(rateCard.priority, 10)
              : null,
            applicableEntityIds: [
              rateCard.creditID || "ALL_USAGE_METER_RATE_CARDS",
            ],
          },
        };
        break;
      case "FIXED_FEE":
        transformedCard = {
          id: rateCard.id || null,
          displayName: rateCard.displayName,
          tag: rateCard.tag || null,
          type: rateCard.type,
          invoiceTiming: rateCard.invoiceTiming || "IN_ARREARS",
          enableProration: rateCard.anniversary_cycle || false,
          cardType: "FIXED_FEE",
          rateValues: payload.supportedCurrencies.map((currency: any) => ({
            currency: currency,
            rate: rateCard[`${currency}-Price`],
          })),
        };
        if (rateCard.type === "RECURRING") {
          transformedCard = {
            ...transformedCard,
            recurrenceConfig: {
              interval: rateCard.interval
                ? parseInt(rateCard.interval, 10)
                : null,
              offset: rateCard.offset ? parseInt(rateCard.offset, 10) : null,
            },
          };
        }
        break;
      case "LICENSE":
        transformedCard = {
          id: rateCard.id || null,
          displayName: rateCard.displayName,
          type: rateCard.type || "LICENSE",
          cardType: "LICENSE",
          tag: rateCard.tag,
          enableProration: rateCard.enableProration,
          invoiceTiming: rateCard.invoiceTiming || "IN_ARREARS",
          proratedRefundMode:
            rateCard.enableProration && rateCard.invoiceTiming === "IN_ADVANCE"
              ? rateCard.proratedRefundMode
              : null,
          config: {
            maxQuantity: rateCard.license_quantity,
          },
          ratePlan: {
            pricingModel: rateCard.pricingModal || "VOLUME",
            slabs: getDynamicSlabs(rateCard),
          },
          rateValues: payload.supportedCurrencies.map((currency: any) => ({
            currency: currency,
            slabRates: getDynamicSlabRates(rateCard, currency),
            rateConfig: {
              minimumRate: rateCard[`${currency}-minPriceLimit`]
                ? parseFloat(rateCard[`${currency}-minPriceLimit`])
                : null,
              maximumRate: rateCard[`${currency}-maxPriceLimit`]
                ? parseFloat(rateCard[`${currency}-maxPriceLimit`])
                : null,
            },
          })),
        };
        break;
      case "USAGE_BASED_FEE":
        transformedCard = {
          usageMeterId: rateCard.usageMeterId
            ? rateCard.usageMeterId.toString()
            : null,
          displayName: rateCard.displayName,
          tag: rateCard.tag,
          cardType: "USAGE_BASED_FEE",
          ratePlan: {
            pricingModel: rateCard.pricingModal || "TIERED",
            slabs: getDynamicSlabs(rateCard),
          },
          rateValues: payload.supportedCurrencies.map((currency: any) => ({
            currency: currency,
            slabRates: getDynamicSlabRates(rateCard, currency),
            rateConfig: {
              minimumRate: rateCard[`${currency}-minPriceLimit`]
                ? parseFloat(rateCard[`${currency}-minPriceLimit`])
                : null,
              maximumRate: rateCard[`${currency}-maxPriceLimit`]
                ? parseFloat(rateCard[`${currency}-maxPriceLimit`])
                : null,
            },
          })),
        };
        break;
    }
    return transformedCard;
  };
  console.log(payload);

  return {
    name: payload.name,
    description: payload?.description,
    type: payload.type === "RECURRING" ? "BILLING" : "PURCHASE",
    pricePlanDetails: {
      supportedCurrencies: payload.supportedCurrencies,
      pricingCycleConfig: {
        interval: payload.billingCycle,
        anniversaryCycle: payload.anniversary_cycle || false,
        gracePeriod: payload?.gracePeriod || 0,
        startType: "STATIC",
        startOffset: {
          dayOffset:
            payload.billingCycle === "WEEKLY"
              ? payload.startOffDate ?? "Monday"
              : payload.startOffDate ?? 1,
          monthOffset:
            payload.billingCycle !== "WEEKLY" &&
            payload.billingCycle !== "MONTHLY"
              ? payload.startOffMonth ?? 1
              : null,
        },
      },
      deferredRevenue: payload.deferredRevenue,
      allowOngoingCycleUpdates: payload.allowOngoingCycleUpdates,
      billingEntitlementRateCards: [],
      creditGrantRateCards: payload.rateCards
        .filter((rc: any) => rc.cardType === "CREDIT_GRANT")
        .map(transformRateCard),
      entitlementOverageRateCards: [],
      fixedFeeRateCards: payload.rateCards
        .filter((rc: any) => rc.cardType === "FIXED_FEE")
        .map(transformRateCard),
      licenseRateCards: payload.rateCards
        .filter((rc: any) => rc.cardType === "LICENSE" )
        .map(transformRateCard),
      minimumCommitment: {
        displayName: "Minimum Commitment",
        rateValues: [],
      },
      usageRateCards: payload.rateCards
        .filter((rc: any) => rc.cardType === "USAGE_BASED_FEE")
        .map(transformRateCard),
    },
    pricingRules: payload.pricingRules,
  };
};
export const reverseTransformRateCard = (rateCard: any) => {
  console.log(
    "🚀 ~ file: helper.ts:848 ~ reverseTransformRateCard ~ rateCard:",
    rateCard
  );
  let formRateCard: any = {};

  // Helper function to reverse dynamic slabs
  const reverseDynamicSlabs = (slabs: any) => {
    let slabFields: any = {};
    slabs?.forEach((slab: any, index: number) => {
      slabFields[`firstUnit-${index}`] = slab?.startAfter;
      slabFields[`lastUnit-${index}`] = slabs[index+1]?.startAfter ?? '∞';
      slabFields[`pricingType-${index}`] = slab?.priceType;

      if (slab?.slabConfig?.packageSize) {
        slabFields[`packageSize-${index}`] = slab?.slabConfig?.packageSize;
      }
    });
    return slabFields;
  };

  // Helper function to reverse slab rates
  const reverseDynamicSlabRates = (currency: string, slabRates: any) => {
    let slabRateFields: any = {};
    slabRates?.forEach((slabRate: any, index: number) => {
      slabRateFields[`${currency}-price-${index}`] = slabRate?.rate;
      if (slabRate.slabRateConfig) {
        slabRateFields[`${currency}-min-${index}`] =
          slabRate?.slabRateConfig?.minimumRate;
        slabRateFields[`${currency}-max-${index}`] =
          slabRate?.slabRateConfig?.maximumRate;
      }
    });
    return slabRateFields;
  };

  switch (rateCard?.cardType) {
    case "CREDIT_GRANT":
      const [_, numberPart, letterPart] = rateCard?.grantDetails?.expiryDuration?.match(/(\d+)([a-zA-Z])/) || [];

      formRateCard = {
        rateCardId: rateCard?.rateCardId,
        id: rateCard?.addOnId,
        displayName: rateCard?.displayName,
        cardType: "CREDIT_GRANT",
        tag: rateCard.tag,
        type: rateCard?.type,
        priority: rateCard?.grantDetails?.priority,
        creditID: rateCard?.grantDetails?.applicableEntityIds[0],
        interval: rateCard?.recurrenceConfig?.interval || null,
        offset: rateCard?.recurrenceConfig?.offset || null,
        expiry_type: rateCard?.grantDetails?.expiryType,
        expiry_duration: numberPart,
        expiry_interval: letterPart,
        pricingType: "FLAT", // Assuming flat pricing for CREDIT_GRANT
        invoiceTiming: rateCard.invoiceTiming,
      };
      rateCard?.rateDetails?.currencySlabRateDetails?.forEach(
        (currencyDetail: any) => {
          const currency = currencyDetail?.currency;
          formRateCard[`${currency}-creditGrant`] =
            currencyDetail?.creditAmount || null;
          formRateCard[`${currency}-Price`] =
            currencyDetail?.slabDetails[0]?.rate || null;
        }
      );

      break;

    case "FIXED_FEE":
      formRateCard = {
        rateCardId: rateCard.rateCardId,
        id: rateCard?.addOnId,
        displayName: rateCard.displayName,
        tag: rateCard.tag,
        type: rateCard?.type,
        invoiceTiming: rateCard.invoiceTiming,
        anniversary_cycle: rateCard.enableProration || false,
        cardType: "FIXED_FEE",
        interval: rateCard.recurrenceConfig?.interval,
        offset: rateCard.recurrenceConfig?.offset,
        ...(rateCard?.rateValues || rateCard?.rate_values)?.reduce((acc: any, rateValue: any) => {
          acc[`${rateValue.currency}-Price`] = rateValue.rate;
          return acc;
        }, {}),
      };
      break;

    case "LICENSE":
      formRateCard = {
        rateCardId: rateCard.rateCardId,
        id: rateCard?.addOnId,
        displayName: rateCard.displayName,
        type: rateCard?.type || "LICENSE",
        cardType: "LICENSE",
        maxQuantity: rateCard?.maxQuantity,
        invoiceTiming: rateCard.invoiceTiming,
        pricingModal: rateCard.ratePlan?.pricingModel || "TIERED",
        tag: rateCard.tag,
        enableProration: rateCard?.enableProration,
        ...reverseDynamicSlabs(rateCard.ratePlan?.slabs || []),
        ...rateCard?.rateValues?.reduce((acc: any, rateValue: any) => {
          return {
            ...acc,
            ...reverseDynamicSlabRates(
              rateValue.currency,
              rateValue.slabRates
            ),
            [`${rateValue.currency}-minPriceLimit`]:
              rateValue.rateConfig?.minimumRate || null,
            [`${rateValue.currency}-maxPriceLimit`]:
              rateValue.rateConfig?.maximumRate || null,
          };
        }, {}),
      };
      break;

    case "USAGE_BASED_FEE":
      formRateCard = {
        rateCardId: rateCard.rateCardId,
        id: rateCard?.addOnId,
        displayName: rateCard.displayName,
        cardType: "USAGE_BASED_FEE",
        usageMeterId: rateCard.usageMeterId,
        pricingModal: rateCard.ratePlan?.pricingModel || "TIERED",
        tag: rateCard.tag,
        ...reverseDynamicSlabs(rateCard.ratePlan?.slabs || []),
        ...rateCard?.rateValues?.reduce((acc: any, rateValue: any) => {
          return {
            ...acc,
            ...reverseDynamicSlabRates(
              rateValue.currency,
              rateValue.slabRates
            ),
            [`${rateValue.currency}-minPriceLimit`]:
              rateValue.rateConfig?.minimumRate || null,
            [`${rateValue.currency}-maxPriceLimit`]:
              rateValue.rateConfig?.maximumRate || null,
          };
        }, {}),
      };
      break;
  }

  return formRateCard;
};
export const reverseTransformPricePlanPayload = (transformedPayload: any) => {


  return {
    name: transformedPayload?.name,
    type: transformedPayload?.type === "BILLING" ? "RECURRING" : "PURCHASE",
    billingCycle: transformedPayload?.pricingCycleInterval,
    pricingCycleStartOffsetDay: transformedPayload?.pricingCycleStartOffsetDay,
    pricingCycleStartOffsetMonth:
      transformedPayload?.pricingCycleStartOffsetMonth,
    anniversary_cycle: transformedPayload?.anniversaryCycle,
    supportedCurrencies: transformedPayload?.supportedCurrencies,
    gracePeriod: transformedPayload?.gracePeriod,
    rateCards: transformedPayload?.rateCards?.map(reverseTransformRateCard),
  };
};

export const transformAttachPricePlanPayload = (planDetails: any) => {
  console.log("►►► ~ transformAttachPricePlanPayload ~ planDetails:", planDetails)
  const payload: any = {
    pricePlanId: planDetails?.pricePlanId,
    effectiveFrom: dayjs(planDetails?.effectiveFrom).format("YYYY-MM-DD"),
    effectiveUntil:planDetails?.effectiveUntil ?  dayjs(planDetails?.effectiveUntil).format("YYYY-MM-DD") : '9999-01-01',
    mode: "ASSOCIATE",
  };

  if (planDetails?.interval || planDetails?.startOffDate) {
    payload.pricingCycleConfig = {
      interval: planDetails?.interval,
      startOffset: {
        dayOffset: planDetails?.startOffDate || null,
        monthOffset: planDetails?.startOffMonth || null,
      },
      gracePeriod: planDetails?.gracePeriod,
    };
  }

  return payload;
};

export const copyToClipboard = (data: string) => {
  if (data) {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        message.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  }
};


export const downloadCSV = ({attachPricePlan,accountCurrency}:{attachPricePlan:any,accountCurrency:any}) => {
  if (!attachPricePlan || attachPricePlan.length === 0) {
    message.warning('No data available to download.');
    return;
  }
  const formattedData = attachPricePlan?.map((item: any) => {
    const currentDate = moment();
    const status= currentDate.isBetween(
      moment(item.effective_from),
      moment(item.effective_until)
    )
    return {
    price_plan_event_schedule_id: item?.price_plan_event_schedule_id,
    price_plan_name: item?.priceplanname,
    price_plan_id: item?.price_plan_id,
    price_plan_attach_id: item?.price_plan_attach_id,
    effective_from: item?.effective_from,
    effective_until: item?.effective_until,
    isOngoing: status,
    isOverRide: item?.isOverRide,
    pricing_cycle: item?.pricing_cycle_interval,
    grace_period: item?.grace_period,
    start_day_offset: item?.start_day_offset,
    start_month_offset: item?.start_month_offset,
    account_currency: accountCurrency,
  }});
  
 

  const headers = Object.keys(formattedData[0]).join(',');

  const rows = formattedData.map((row:any) =>
    Object.values(row).map(value =>
      typeof value === 'string' ? `"${value}"` : value
    ).join(',')
  );

  const csvContent = [headers, ...rows].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'schedule_data.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const requiredFields = [
  "billoptimCustomerId",
  "PrimaryEmail",
  "BillingAddress",
  "billoptimCustomerAccountId",
  "AccountInvoiceCurrency",
  'InvoiceCurrency',
  'AccountId',
  'Timestamp',
];