import { Card } from "antd";
import styled from "styled-components";

export const NewEventSchemaContainer = styled.div`
.body{
  padding: 2rem 5rem 1rem;
  display: flex;
  flex-direction:column;
  gap: 1rem;
  height: calc(100vh - 148px);
  overflow-y: scroll;
  .createEventSchemaForm{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .form-body{
    display: flex !important;
    flex-direction:column ;
    gap: 20px;
  }

  .ant-form-item-required{
      font-size: 0.9rem;
      font-weight: 300 !important;
    }
  .ant-form-item{
    margin-bottom: 0;
  }
  .name{
    margin-bottom: 1rem;
  }
  .form{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .configureId{
    height:100%;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #2853C2;
    cursor: pointer;
  }
  .templateItem{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .deleteIcon{
    border: 1px solid red;
    padding: 0.2rem 0.6rem ;
    border-radius: 10px;
    color: red;
  }
  
}
.footerContent{
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem 7rem ;
  }
  .footerActions{
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .collapse{
   
  }
  .flexible{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header{
    gap: 1rem;
    justify-content: center !important;
  }
  .headingText{
    text-align: center;
  }
  .items{
    width: 50%;
    margin: 0;
  }
  .attributes{
    display: flex;
    justify-content: space-between;
  }
`;

export const AddItemCard = styled(Card)`
background-color: #F8FAFC;
margin-bottom: 1rem;

  .ant-card-head-wrapper{
    min-height:0 !important;
  } 
  .ant-card-head{
    min-height: 0;
  }
  .ant-card-head-title{
    padding: 0.5rem 0 !important;;
  }
  .ant-card-body{
    padding: 1rem;
    gap: 0!important;
    display: flex;
    flex-direction: column;
  }
  .action{
    cursor: pointer;
  }
`;
