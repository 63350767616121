import { DeleteOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Dropdown,
  Form,
  Input,
  message,
  Select,
  Table,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import Modal from "../../../../components/common/modal";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import api from "../../../../api/apiClient";
import { AxiosError } from "axios";
import { removeAlias } from "../../../../redux/feature/adminCenter/adminCenterSlice";
import { StyledTable } from "../../../../components/common/table/styles";
import { formatDateToLocalTime } from "../../../../utils/helper";
import { EmptyDataComponent } from "../../../../components/common/empty";
import { StyledDatePicker } from "../styles";

export interface DataType {
  key: React.Key;
  name: string;
  created: string;
  alias_id: number;
}

const listData = [{ value: "no-data", label: "No Data Found" }];

const AliasTable = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [aliasName, setAliasName] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<number | null>(
    null
  );
  const alias = useSelector((state: RootState) => state.adminCenter.alias);
  console.log("table re rendered");
  const [loading, setLoading] = useState(false);
 

  const [selectedType, setSelectedType] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  const addModalHandler = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };
  const deleteModalHandler = (aliasId: number | null) => {
    setIsDeleteModalOpen(aliasId);
  };
  const handleChange = (value: string) => {
    setSelectedType(value);
  };

  const onDelete = async () => {
    setLoading(true)
    try {
      const id = isDeleteModalOpen;
      await api.delete(`/delete-alias?aliasId=${id}`);
      dispatch(removeAlias(id));
      setIsDeleteModalOpen(null);
      message.success("alias deleted successfully");
    } catch (err) {
      const error = err as AxiosError;
      console.log("🚀 ~ file: EditProfile.tsx:22 ~ onFinish ~ error:", error);
    }finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    if (isDeleteModalOpen !== null) {
      const selectedAlias = alias.find(
        (item) => item.alias_id === isDeleteModalOpen
      );
      if (selectedAlias) {
        setAliasName(selectedAlias.name);
      } else {
        setAliasName("");
      }
    }
  }, [isDeleteModalOpen, alias]);

  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (_, record) => formatDateToLocalTime(record.created),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const items = [
          {
            key: "1",
            label: <span>Add To Account</span>,
            icon: <PlusOutlined />,
            onClick: addModalHandler,
          },
          {
            key: "2",
            label: <span>Delete</span>,
            icon: <DeleteOutlined />,
            onClick: () => deleteModalHandler(record.alias_id),
          },
        ];
        return (
          <Dropdown placement="bottom" menu={{ items }}>
            <Link to={"#"}>
              <MoreOutlined />
            </Link>
          </Dropdown>
        );
      },
    },
  ];



  const { Text } = Typography;
  return (
    <>
      <StyledTable
        className="table"
        pagination={false}
        columns={columns}
        dataSource={alias}
        size="middle"
      />

      <Modal
        title="Add Alias to Account"
        isOpen={isAddModalOpen}
        onClose={addModalHandler}
        width="370px"
      >
        <Form
          layout="vertical"
          requiredMark={false}
          style={{ paddingTop: "1rem" }}
        >
          <Form.Item
            label="Select Account"
            name="account"
            rules={[{ required: true, message: "Please Select Type First!" }]}
          >
            <Select
              placeholder="Choose Account"
              onChange={handleChange}
              options={listData}
            />
          </Form.Item>
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
              alignItems: "center",
            }}
          > */}
          <Form.Item
            label="Effective From"
            name="from"
            rules={[{ required: true, message: "Please input Name First!" }]}
          >
            <StyledDatePicker
              placeholder="Effective From"
              size="large"
              showTime
              format={{
                format: "DD MMM YYYY HH:mm:ss",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Effective Until"
            name="until"
            rules={[{ required: true, message: "Please input Name First!" }]}
          >
            <StyledDatePicker
              placeholder="Effective Until"
              size="large"
              showTime
            />
          </Form.Item>
          {/* </div> */}
        </Form>
      </Modal>
      <Modal
        title="Delete Alias Confirmation"
        isOpen={isDeleteModalOpen}
        onSubmit={onDelete}
        onClose={() => deleteModalHandler(null)}
        successBtnTitle="Delete"
        icon={<DeleteOutlined />}
        loading={loading}
      >
        <div style={{ padding: "2rem 0px" }}>
          <Text>
            If the alias <Text strong>{aliasName}</Text> has been currently
            associated to any account, it will be detached from now. Are you
            sure you want to delete the alias?
          </Text>
        </div>
      </Modal>
    </>
  );
};

export default AliasTable;
