import React, { useEffect } from "react";
import {
  NewPricePlanContainer,
  PricePlanContainer,
} from "../../../../price-plans/NewPricePlan/Styles";
import { Button, Col, message, Row, Tag } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CustomButton from "../../../../../components/common/button";
import PlanRateCardTable from "../../../../price-plans/ViewPricePlan/PlanRateCardTable";
import useSelection from "antd/es/table/hooks/useSelection";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AccountDetails, CardContainer } from "./Styles";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import PageLoading from "../../../../../components/Loader";
import { getSinglePricePlan } from "../../../../../redux/feature/pricePlan/pricePlanThunk";
import { getPricePlanScheduleById } from "../../../../../redux/feature/customer/customerAsyncThunk";
import { StyledTag } from "../../../../../components/common/tag/styles";

const ViewAttachPricePlan = () => {
  const { customerId, accountId, aPlanId } = useParams();
  const navigate = useNavigate();
  const { details, loading } = useSelector(
    (state: RootState) => state.customer.pricingScheduleDetails
  );
  const dispatch = useDispatch<AppDispatch>();

  const { viewAccountDetails, customerAccounts } = useSelector(
    (state: RootState) => state.customer
  );

  useEffect(() => {
    if (accountId && aPlanId) {
      dispatch(
        getPricePlanScheduleById({
          accountId: accountId,
          pricePlanAttachId: aPlanId,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.price_plan_id) {
            dispatch(getSinglePricePlan(res?.price_plan_id));
          } else {
            message.error("Price plan ID not found in the response.");
          }
        })
        .catch((e) => {
          console.log(":rocket: ~ file: index.tsx:85 ~ useEffect ~ e:", e);
          message.error("Failed to fetch event details.");
        });
    }
  }, [accountId, aPlanId]);

  return loading ? (
    <PageLoading />
  ) : (
    <NewPricePlanContainer>
      <div className="pricePlanHeader">
        <h3 className="title">
          <Button
            icon={<ArrowLeftOutlined />}
            style={{ width: "50px" }}
            onClick={() => {
              navigate(
                `/customers/${customerId}/accounts/${accountId}/price-plan-schedule`
              );
            }}
          />
          Pricing Schedule Details
        </h3>
      </div>
      <PricePlanContainer>
        <Row gutter={24}>
          <Col span={18}>
            <PlanRateCardTable span={24} isAddRoutes={false} />
          </Col>
          <Col span={6}>
            <AccountDetails>
              <CardContainer>
                <div className="card_body">
                  <div className="card_header">PRICING SCHEDULE DETAILS</div>
                  <div className="card-details">
                    <div className="item">
                      <div>Price Plan Name</div>
                      <div>{details?.priceplanname}</div>
                    </div>
                    <div className="item">
                      <div>Start Date</div>
                      <div>
                        {moment(details?.effective_from).format("MMM DD YYYY")}
                      </div>
                    </div>
                    <div className="item">
                      <div>End Date</div>
                      <div>
                        {moment(details?.effective_until).format("MMM DD YYYY")}
                      </div>
                    </div>
                    <div className="item">
                      <div>Pricing Cycle</div>
                      <div>{details?.pricing_cycle_interval}</div>
                    </div>
                    <div className="item">
                      <div>Cycle Start Month</div>
                      <div>{details?.start_month_offset}</div>
                    </div>
                    <div className="item">
                      <div>Cycle Start Date</div>
                      <div>{details?.start_day_offset}</div>
                    </div>
                    <div className="item">
                      <div>Grace Period</div>
                      <div>{details?.grace_period}</div>
                    </div>
                  </div>
                </div>
              </CardContainer>
              <CardContainer>
                <div className="card_body">
                  <div className="card_header">ACCOUNT DETAILS</div>
                  <div className="card-details">
                    <div className="item">
                      <div>Account Name</div>
                      <div>{viewAccountDetails.account_name}</div>
                    </div>
                    <div className="item">
                      <div>Account ID</div>
                      <div>{viewAccountDetails.customer_account_id}</div>
                    </div>
                    <div className="item">
                      <div>Customer ID</div>
                      <div>{viewAccountDetails.billoptim_customer_id}</div>
                    </div>
                    <div className="item">
                      <div>Currency</div>
                      <div>
                        <StyledTag>
                          {viewAccountDetails?.currency_code}
                        </StyledTag>
                      </div>
                    </div>
                    <div className="item">
                      <div>Primary Email</div>
                      <div>{viewAccountDetails?.account_email}</div>
                    </div>
                    <div className="item">
                      <div>Tags</div>
                      <div>
                        {Array.isArray(viewAccountDetails?.tags) ? (
                          viewAccountDetails.tags.map(
                            (tag: string, index: number) => (
                              <StyledTag key={index} color="gold">
                                {tag}
                              </StyledTag>
                            )
                          )
                        ) : typeof viewAccountDetails?.tags === "string" ? (
                          JSON.parse(viewAccountDetails.tags).map(
                            (tag: string, index: number) => (
                              <StyledTag key={index} color="gold">
                                {tag}
                              </StyledTag>
                            )
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </CardContainer>
            </AccountDetails>
          </Col>
        </Row>
      </PricePlanContainer>
    </NewPricePlanContainer>
  );
};

export default ViewAttachPricePlan;
