import styled from "styled-components";

export const AddonContainer = styled.div`
  padding: 0.5rem 0;

  .setting-button-container {
    display: none !important;
  }

  .ant-table-row:hover .setting-button-container {
    display: flex !important;
    justify-content: end !important;
  }
`;

export const SettingContent = styled.div`
  padding: 24px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px !important;
  }
  & .header-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
 .ant-table-expanded-row>td{
    background-color: #fff !important;
 }
    .ant-table-tbody > .ant-table-row > .ant-table-cell {
      border-bottom: 0px !important;
    }
`;

export const NewSettingFormContainer = styled.div`
  .formFields {
    background-color: #f1f5f9;
    border-radius: 12px;
  }
  .actionButtons {
    display: flex;
    gap: 16px;
  }
`;

export const ExpandItem = styled.div`

  display: flex;
  flex-direction: column;
  gap:16px;
  .item {
    display: flex;
    gap: 16px;
    line-height:14px;

    p {
      width: 100px;
      font-size: 14px !important;
      line-height: 17px !important;
      font-weight: 500  !important;
      color: #64748b !important;
    }
     .value{
      color:black !important;
      font-weight: 400 !important;
     }
  }
`;
