import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../api/apiClient";
import { message } from "antd";

export const getUsageMeters = createAsyncThunk(
  "event/getUsageMeters",
  async (filterData: any, { rejectWithValue }) => {
    const { sort, search, status } = filterData

    try {
      const response = await api.get(`/usage_meters?sort=${sort}${search ? `&search=${search}` : ''}${status ? `&status=${status}` : ''}`);
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: authThunk.tsx:24 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getUsageMeter = createAsyncThunk(
  "event/getUsageMeter",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/usage_meters/${payload}`
      );
      return response.data.data;
    } catch (error: any) {
      console.log("►►► ~ error:", error)
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const activeUsageMeter = createAsyncThunk(
  "event/activeUsageMeter",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/usage_meters/${payload.usageMeterId}/activate`
      );
      message.success('Successfully activated')
      return payload;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);


export const getAddons = createAsyncThunk(
  "event/getAddons",
  async (filterData: any, { rejectWithValue }) => {
    const { sort, search, type } = filterData

    try {
      const response = await api.get(`/addons?sort=${sort}${search ? `&search=${search}` : ''}${type ? `&type=${type}` : ''}`);
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: authThunk.tsx:24 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createAddons = createAsyncThunk(
  "event/createAddons",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/addons", payload);
      message.success('Addons added successfully')
      return response.data.add_on_id;
    } catch (error: any) {
      console.log("🚀 ~ file: authThunk.tsx:232 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);


export const updateAddons = createAsyncThunk(
  "event/updateAddons",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.put(`/addons/${payload.id}`, { name: payload.name, billableName: payload.billableName });
      message.success('Addons update successfully')

      return response.data.add_on;
    } catch (error: any) {
      console.log("🚀 ~ file: authThunk.tsx:232 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createUsageMeters = createAsyncThunk(
  "event/createUsageMeters",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`/usage_meters`, payload);
      message.success('Usage Meters added successfully')
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: authThunk.tsx:232 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);