import { Row } from "antd";
import styled from "styled-components";

export const StyledRow = styled(Row)`
  padding: 1rem;
  text-wrap: balance;
  .header {
    text-align: left;
    font-size: 13px;
  }
  .configuration {
    background-color: #f8fafc;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 0 1rem;
  }
  .priceConfiguration {
    display: flex;
  }
  .configurationItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    border-bottom: 1px solid lightgray;
    padding: 0.7rem;
    .id {
      font-weight: 600;
      width: 50%;
      text-align: left;
    }
  }
  .priceItem {
    width: 50%;
    padding: 0.7rem 0.5rem;
    font-size: 12px;
  }
  .left {
    border-right: 1px solid lightgray;
    display: flex;
    flex-direction: column;
  }
  .minMax{
    border-right: none !important;


  }
  .currency{
    color: rgb(15 138 107 );
    font-weight: 500;
  }
  .right {
    display: flex;
    gap: 10px;
    align-items: end;
    flex-direction: column;
  }
`;

export const SpecificUsage = styled.div`

  display: flex;
  gap: 8px;
  .value{
    color: black;
    font-weight: 500;
  }
  .span{
    border-bottom: 1px solid black;
    cursor: pointer;
  }
`;

export const AppliedEntitiesDrawerContent = styled.div`
  padding:16px;
  .entityItem{
    margin-bottom:24px;
  }
  .title{
    font-size: 16px;
    color:#334155;
    line-height: 19px;
    font-weight: 500;
    margin-bottom:16px;
  }
`;