import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
  TableColumnsType,
  Tag,
} from "antd";
import react, { useEffect, useState } from "react";
import { ApiTokenContainer } from "./Styles";
import CustomButton from "../../../components/common/button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  deleteUserToken,
  generateNewToken,
  getAllToken,
  updateTokenStatus,
} from "../../../redux/feature/adminCenter/adminCenterAsyncThunk";
import {
  CopyOutlined,
  DeleteOutlined,
  MoreOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Modal from "../../../components/common/modal";
import { setRemoveToken } from "../../../redux/feature/adminCenter/adminCenterSlice";
import { EmptyDataComponent } from "../../../components/common/empty";
import { TabHeader } from "../Styles";
import { StyledTable } from "../../../components/common/table/styles";
import { formatDateToLocalTime } from "../../../utils/helper";
import AlertModal from "../../../components/common/alertModal";
import PageLoading from "../../../components/Loader";
import { StyledTag } from "../../../components/common/tag/styles";

const ApiToken: React.FC<{ isTitleShow?: boolean }> = ({
  isTitleShow = true,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<number | null>(
    null
  );
  const deleteModalHandler = (id: number) => {
    setIsDeleteModalOpen(id);
  };
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: any) => state.auth);
  const {
    userToken: { authToken, loading, getTokenLoading },
    newApiToken,
  } = useSelector((state: RootState) => state.adminCenter);

  useEffect(() => {
    if (!authToken.length) dispatch(getAllToken());
  }, []);

  const handleStatusChange = (record: any) => {
    dispatch(
      updateTokenStatus({
        tokenId: record.token_id,
        status: !record.is_active,
      })
    );
  };
  const handleDeleteToken = () => {
    dispatch(deleteUserToken(isDeleteModalOpen));
    setIsDeleteModalOpen(null);
  };

  const columns: TableColumnsType<any> = [
    {
      title: "Token ID",
      dataIndex: "token_id",
    },
    {
      title: "Generated At",
      dataIndex: "created_at",
      render: (_, record) => {
        return formatDateToLocalTime(record.created_at);
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active) => {
        return (
          <StyledTag color={is_active ? "green" : "red"}>
            {is_active ? "Active" : "Inactive"}
          </StyledTag>
        );
      },
    },
    {
      key: "status",
      dataIndex: "status",
      render: (_, record) => {
        return (
          <CustomButton
            type="default"
            text={record.is_active ? "Deactivate" : "Activate"}
            icon={<RightOutlined />}
            position="end"
            onClick={() => {
              handleStatusChange(record);
            }}
          />
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        const items = [
          {
            key: "1",
            label: <span>Delete Token</span>,
            onClick: () => deleteModalHandler(record.token_id),
          },
        ];
        return (
          <Dropdown placement="bottom" menu={{ items }}>
            <Link to={"#"}>
              <MoreOutlined />
            </Link>
          </Dropdown>
        );
      },
    },
  ];

  const handleGenerateNewToken = () => {
    dispatch(generateNewToken({ userId: user?.user_id }));
  };

  const handleModalClose = () => {
    dispatch(setRemoveToken(undefined));
  };
  const copyToClipboard = () => {
    if (newApiToken?.secret_key) {
      navigator.clipboard
        .writeText(newApiToken.secret_key)
        .then(() => {
          message.success("Secret Key copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  return (
    <ApiTokenContainer isTitleShow={isTitleShow}>
      <>
        <div className="tokenHeader">
          {isTitleShow && (
            <TabHeader style={{ fontSize: "14px" }} className="header">
              API Tokens List
            </TabHeader>
          )}
          <CustomButton
            type="primary"
            text="Generate New Token"
            position="start"
            onClick={handleGenerateNewToken}
            loading={loading}
          />
        </div>
        {getTokenLoading && !authToken.length ? (
          <PageLoading />
        ) : authToken.length ? (
          <StyledTable
            columns={columns}
            dataSource={authToken}
            size="middle"
            pagination={false}
            loading={getTokenLoading}
          />
        ) : (
          <EmptyDataComponent title="No Api Tokens Found" isAddButton={false} />
        )}
        <Modal
          title={"Generate New API Token"}
          isOpen={newApiToken}
          onClose={handleModalClose}
          successBtnTitle={"Copy Secret Key"}
          icon={<CopyOutlined />}
          iconPosition="end"
          onSubmit={copyToClipboard}
          width="370px"
        >
          <div
            style={{
              padding: "20px 0",
              maxHeight: "calc(100vh - 200px)",
              overflowY: "auto",
            }}
          >
            <Form layout="vertical" disabled={true}>
              <Form.Item label="Token ID">
                <Input placeholder="Token ID" value={newApiToken?.token_id} />
              </Form.Item>
              <Form.Item label="Secret Key">
                <Input
                  placeholder="Secret Key"
                  value={newApiToken?.secret_key}
                />
              </Form.Item>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message="This is the only time that the secret access keys can be viewed or downloaded. You cannot recover them later. However, you can create new access keys at any time."
                  type="warning"
                  showIcon
                />
              </Space>
            </Form>
          </div>
        </Modal>
        <AlertModal
          open={isDeleteModalOpen ? true : false}
          close={() => setIsDeleteModalOpen(null)}
          title=" API Token Delete Confirmation "
          subtitle={
            <>
              Are you sure you want to delete the API token <br /> '
              {isDeleteModalOpen}' ?
            </>
          }
          submitText="Delete"
          onSubmit={handleDeleteToken}
        />
      </>
    </ApiTokenContainer>
  );
};

export default ApiToken;
