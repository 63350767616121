import React, { useMemo } from "react";
import { PlanPreviewContainer, PricePlanFormHeader } from "./Styles";
import { Select, Tag } from "antd";
import { StyledTag } from "../../../components/common/tag/styles";

const PlanPreview: React.FC<any> = ({
  billingDetails,
  selectedPreviewLineItem,
  setSelectedPreviewLineItem,
  accountCurrency = "",
  isAttachPricePlan = false,
}) => {
  const currencyOptions = useMemo(() => {
    if (isAttachPricePlan) {
      return (
        <Select.Option key={accountCurrency} value={accountCurrency}>
          {accountCurrency}
        </Select.Option>
      );
    }
    return billingDetails?.supportedCurrencies?.map((currency: string) => (
      <Select.Option key={currency} value={currency}>
        {currency}
      </Select.Option>
    ));
  }, [billingDetails?.supportedCurrencies, accountCurrency, isAttachPricePlan]);
  console.log("►►► ~ currencyOptions ~ isAttachPricePlan:", currencyOptions);

  return (
    <PlanPreviewContainer>
      <PricePlanFormHeader>Preview</PricePlanFormHeader>
      <>{console.log("⏩ ~ billingDetails:", billingDetails)}</>
      {billingDetails.name ? (
        <>
          <div className="previewItem">
            {billingDetails?.name && <h3>{billingDetails?.name}</h3>}
            {billingDetails?.description && (
              <h5>{billingDetails?.description}</h5>
            )}

            {isAttachPricePlan ? (
              <StyledTag color="purple">{accountCurrency}</StyledTag>
            ) : (
              billingDetails?.supportedCurrencies?.map((c: any) => (
                <StyledTag color="purple">{c}</StyledTag>
              ))
            )}
            {billingDetails?.billingCycle && (
              <p className="planType">
                Recurring: {billingDetails?.billingCycle}
              </p>
            )}
          </div>
          <div className="rateCardPreviewContainer">
            {billingDetails?.rateCards.length > 0 && (
              <>
                {billingDetails?.supportedCurrencies && (
                  <div className="selectContainer">
                    <Select
                      className="select"
                      defaultValue={
                        isAttachPricePlan
                          ? accountCurrency
                          : billingDetails?.supportedCurrencies[0]
                      }
                      onChange={setSelectedPreviewLineItem}
                    >
                      {currencyOptions}
                    </Select>
                  </div>
                )}
                <div className="rateCardPreviewHeader">
                  <span>LINE ITEMS</span>
                  <span>PRICE</span>
                </div>
                {billingDetails?.rateCards?.map((rate: any, index: number) => {
                  const selectedCurrency = isAttachPricePlan
                    ? accountCurrency
                    : selectedPreviewLineItem ||
                      billingDetails.supportedCurrencies[0];

                  const priceKey =
                    rate?.cardType === "USAGE_BASED_FEE" ||
                    rate?.cardType === "LICENSE"
                      ? `price-0`
                      : `Price`;

                  const fullPriceKey = `${selectedCurrency}-${priceKey}`;

                  return (
                    <div className="rateCardPreviewItem" key={index}>
                      <span>{rate?.displayName}</span>
                      <div className="rateCardPreviewItemCurrency">
                        <span>
                          {rate?.[fullPriceKey]
                            ? `${selectedCurrency} ${rate[fullPriceKey]}`
                            : "-"}{" "}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </PlanPreviewContainer>
  );
};

export default PlanPreview;
