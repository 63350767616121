import {
  Form,
  Input,
  InputRef,
  message,
  Select,
  Table,
  TableColumnsType,
  TableColumnType,
  Tag,
  Tooltip,
} from "antd";
import {
  CloseCircleOutlined,
  CopyOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useLocation, useNavigate } from "react-router-dom";
import { EmptyData } from "../../../components/common/empty";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  reverseTransformPricePlanPayload,
  timeAgo,
} from "../../../utils/helper";
import ViewPricePlan from "../ViewPricePlan";
import { Container } from "../NewPricePlan/BillingDetails/Styles";
import FilterDropdown from "../../../components/common/filter";
import { setFilter } from "../../../redux/feature/customer/customerSlice";
import CustomButton from "../../../components/common/button";
import PageLoading from "../../../components/Loader";
import FilterOption from "../../../components/common/filter/FilterOption";
import { StyledTable } from "../../../components/common/table/styles";
import {
  getPricePlans,
  getSinglePricePlan,
} from "../../../redux/feature/pricePlan/pricePlanThunk";
import SearchInput from "../../../components/common/search";
import {
  setBillingDetails,
  setIsClonedPricePlan,
} from "../../../redux/feature/pricePlan/pricePlanSlice";
import { StyledTag } from "../../../components/common/tag/styles";

type PricePlanColumnsType = {
  key: React.Key;
  name: string;
  cycle?: string;
  status: string;
  currencies: string[];
  supportedCurrencies: string[];
  lastUpdated: string;
  updatedAt: Date;
};
type DataIndex = keyof PricePlanColumnsType;

const PricePlanTable = ({
  keyProp,
  activeKey,
  isLoading,
  searchTerm,
  setSearchTerm,
  setStatus,
  status
}: {
  keyProp?: string;
  activeKey?: string;
  isLoading?: boolean;
  searchTerm?: string;
  setSearchTerm: (searchTerm: string) => void;
  setStatus: (status: 'DRAFT' | 'ACTIVE' |'') => void;
  status:'DRAFT' | 'ACTIVE' |'';
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const pricePlans = useSelector(
    (state: RootState) => state.pricePlan.pricePlans
  );
  const isPricePlanLoading  = useSelector((state:RootState) => state.pricePlan.isPricePlanLoading)

  const [form] = Form.useForm();

  const  filter  = useSelector((state: RootState) => state.customer.filter);

  const onSubmitFilter = async () => {
    try {
      const values :any= await form.validateFields();
      console.log("►►► ~ onSubmitFilter ~ values:", values)
      setStatus(values.status);
      if (values.status) {
        navigate(
          `/price-plan/${activeKey}?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}&` : "&"
          }status=${values.status}`
        );
      } else {
        navigate(
          `/price-plan/${activeKey}?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}` : ""
          }`
        );
      }
    } catch (error) {}
  };

  const pricePlanDataSource = useMemo(() => {
    return pricePlans?.map((item: any) => item);
  }, [pricePlans]);

  const columns: TableColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record: any) => {
        return (
          <Tooltip title={record?.name ?? "-"}>
            <div
              style={{
                width: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {record?.name ?? "-"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => {
        return (
          <StyledTag color={status === "ACTIVE" ? "green" : "orange"}>
            • {status}
          </StyledTag>
        );
      },
    },
    ...(keyProp !== "PURCHASE"
      ? [
          {
            title: "Cycle",
            dataIndex: "pricingCycleInterval",
          },
        ]
      : []),
    {
      title: "Currencies",
      dataIndex: "supportedCurrencies",
      render: (_, { supportedCurrencies }) => {
        return supportedCurrencies.map((cur: any) => (
          <StyledTag color="blue"> {cur} </StyledTag>
        ));
      },
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      render: (_, { updatedAt }) => {
        return timeAgo(updatedAt);
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <Tooltip title={"Clone this price plan"}>
          <CopyOutlined
            onClick={(e: any) => {
              e.stopPropagation();
              const { rateCards, ...rest } =
                reverseTransformPricePlanPayload(record);
              const formattedRateCards = rateCards?.map(
                ({ rateCardId, ...rate }: any, index: number) => ({
                  uniqueID: Date.now() + index,
                  ...rate,
                })
              );

              dispatch(
                setBillingDetails({
                  rateCards: formattedRateCards,
                  ...rest,
                  name: `${rest.name}-copy`,
                })
              );
              dispatch(setIsClonedPricePlan(true));
              navigate(`/price-plan/${record?.pricePlanId}/clone`);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  const options = [
    {
      value: "-updated_at",
      label: "Updated At Descending",
    },
    {
      value: "2Bupdated_at",
      label: "Updated At Ascending",
    },
  ];

  const handleSearchChange = (
    (e: any) => {
      const newValue = e.target.value;
      setSearchTerm(newValue);

      navigate(
        `/price-plan/${activeKey}?__sort__=${filter}${
          status ? `&status=${status}` : ""
        }${newValue ? `&__search__=${newValue}` : ""}`
      );
    }
  );

  const resetFilter=()=>{
    form.resetFields();
  } 

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchInput
          value={searchTerm}
          onChange={handleSearchChange}
          TooltipSupportedFields="Name"
        />
        <div style={{ display: "flex", gap: "5px" }}>
          {status && (
            <CustomButton
              text="Clear Filter"
              type="link"
              icon={false}
              onClick={() => {
                setStatus('');
                form.resetFields();
                navigate(
                  `/price-plan/${activeKey}?__sort__=${filter}${
                    searchTerm ? `&__search__=${status}` : ""
                  }`
                );
              }}
            />
          )}
          <FilterDropdown onSubmitFilter={onSubmitFilter} isFilterApplied={status ? true : false} resetFilter={resetFilter}>
            <Form layout="vertical" form={form}>
              <Form.Item label="Status" name="status">
                <Select placeholder="Status">
                  <Select.Option value="ACTIVE">Active</Select.Option>
                  <Select.Option value="DRAFT">Draft</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </FilterDropdown>
          <FilterOption options={options} defaultValue="-updated_at" key={activeKey}/>
        </div>
      </div>
      { isPricePlanLoading ? (
        <StyledTable
          pagination={false}
          columns={columns}
          loading={true}
          dataSource={[]}
          size="middle"
        />
      ) : !pricePlans.length ? (
        <EmptyData
          description="No Pricing Plan Found"
          btnText="New Price Plan"
          onClick={() => {
            navigate(
              `/price-plan/create?plan-type=${
                activeKey === "recurring" ? "RECURRING" : "ONE_TIME"
              }`
            );
          }}
        />
      ) : (
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={pricePlanDataSource}
          size="middle"
          isCursorPointerShow
          onRow={(record: any) => {
            return {
              onClick: () => {
                if (record.status === "ARCHIVED") {
                  message.error("Price plan not found");
                  return;
                }

                navigate(
                  `/price-plan/${record.pricePlanId}?__sort__=${filter}`
                );
              },
            };
          }}
        />
      )}
    </div>
  );
};

export default PricePlanTable;
