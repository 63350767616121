import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Tag, Tooltip, Row, Col, Divider, Space } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CalendarOutlined,
  DownloadOutlined,
  EditOutlined,
  CloseOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomButton from "../../../../components/common/button";
import {
  ScheduleContainer,
  ScheduleCycleCard,
  ScheduleData,
  ScheduleVersion,
} from "./Styles";
import CalendarCard from "./CalendarCard";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useLocale } from "antd/es/locale";
import ScheduleCard from "./ScheduleCard";
import { EmptyDataComponent } from "../../../../components/common/empty";

const ViewSchedule: React.FC<any> = ({ attachPricePlan, onPlanClick }) => {
  const [isShowPast, setIsShowPast] = useState(false);

  const getStatus = (plan: any) => {
    const currentDate = moment();
    if (currentDate.isBefore(moment(plan.effective_from))) {
      return "Upcoming";
    } else if (
      currentDate.isBetween(
        moment(plan.effective_from).startOf('day'),
        moment(plan.effective_until).endOf('days')
      )
    ) {
      return "Ongoing";
    } else {
      return "Expired";
    }
  };

  const isPastScheduleBtnShown = useMemo(() => {
    if (!attachPricePlan) return false;
    return attachPricePlan.some((plan: any) => getStatus(plan) === "Expired");
  }, [attachPricePlan]);

  const sortedPlans = useMemo(() => {
    const statusOrder = ["Expired", "Ongoing", "Upcoming"];

    const filteredPlans = attachPricePlan
      ? attachPricePlan
          .filter((plan: any) => isShowPast || getStatus(plan) !== "Expired")
          .sort((a: any, b: any) => {
            const statusA = getStatus(a);
            const statusB = getStatus(b);
            return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
          })
          .sort((first: any, second: any) => {
            const dateAFrom = new Date(first.effective_from);
            const dateBFrom = new Date(second.effective_from);
            const dateAUntil = new Date(first.effective_until);
            const dateBUntil = new Date(second.effective_until);

            if (dateAFrom.getTime() === dateBFrom.getTime()) {
              return dateAUntil.getTime() - dateBUntil.getTime();
            }
            return dateAFrom.getTime() - dateBFrom.getTime();
          })
          .map((item: any, index: number, arr: any) => {
            const currentItem = item;
            const nextItem = arr[index + 1];

            const getDaysDifference = (fromDate: any, toDate: any) => {
              const from = new Date(fromDate);
              const to = new Date(toDate);
              const timeDiff = to.getTime() - from.getTime();
              return timeDiff / (1000 * 3600 * 24);
            };

            const daysGap = nextItem
              ? getDaysDifference(
                  currentItem.effective_until,
                  nextItem.effective_from
                )
              : 0;

            const hasDateGap = nextItem && daysGap > 1;

            const adjustDate = (dateString: any, days: any) => {
              const date = new Date(dateString);
              date.setDate(date.getDate() + days);
              return date.toISOString().split("T")[0];
            };

            const gapPeriod = hasDateGap
              ? {
                  from: adjustDate(currentItem.effective_until, 1),
                  to: adjustDate(nextItem.effective_from, -1),
                }
              : null;

            return {
              ...item,
              hasDateGap: hasDateGap,
              gapPeriod: gapPeriod,
              status: getStatus(item),
            };
          })
      : [];

    return filteredPlans;
  }, [attachPricePlan, isShowPast]);

  return (
    <ScheduleContainer>
      <Row
        gutter={24}
        style={{
          display: "flex",
          gap: "16px",
          margin: "0",
        }}
      >
        {isPastScheduleBtnShown && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <Button
              type="link"
              className="view-schedules"
              onClick={() => {
                setIsShowPast(!isShowPast);
              }}
            >
              <span>{isShowPast ? "Hide" : "View"} Past Schedules</span>{" "}
              {isShowPast ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
            </Button>
          </div>
        )}
        <ScheduleCard
          onPlanClick={onPlanClick}
          isNavigatable={true}
          sortedPlans={sortedPlans}
          isCursorPointerShow
        />
      </Row>
    </ScheduleContainer>
  );
};

export default ViewSchedule;
