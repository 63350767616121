import { useEffect, useMemo, useState } from "react";
import Segment from "../../../../components/common/segmented";
import { BillingContainer } from "./styles";
import CustomButton from "../../../../components/common/button";
import SearchInput from "../../../../components/common/search";
import FilterDropdown from "../../../../components/common/filter";
import FilterOption from "../../../../components/common/filter/FilterOption";
import { DatePicker, Form, Select, Tag } from "antd";
import { StyledTable } from "../../../../components/common/table/styles";
import { StyledRangePicker } from "../../../billing/invoices/styles";
import { CalendarOutlined, ReloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "../../../../components/common/modal";
import CustomInvoiceForm from "../../../billing/detailsPage/invoiceGroupDetail/customInvoiceForm";
import CustomChargeModal from "../../../billing/detailsPage/CustomChargeModal";
import BillingTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import { Root } from "react-dom/client";
import { AppDispatch, RootState } from "../../../../redux/store";
import { getInvoices } from "../../../../redux/feature/billing/billingThunk";

const options = [
  {
    value: "-updated_at",
    label: "Created At Descending",
  },
  {
    value: "2Bupdated_at",
    label: "Created At Ascending",
  },
  {
    value: "-id",
    label: "ID Descending",
  },
  {
    value: "2Bid",
    label: "ID Ascending",
  },
];

const customChargeData = [
  {
    key: "1",
    charge_name: "sasa",
    charge_type: "Extra charge",
    considered_for_revenue: "No",
    created_on: "Oct 04, 2024 UTC ",
    price: "USD 111",
  },
];

const Billing = () => {
  const [billType, setBillType] = useState<
    "Invoices" | "Orders" | "Custom Charges"
  >("Invoices");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isConsiderChargeForRevenue, setIsConsiderChargeForRevenue] =
    useState(false);
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();

  const createCustomModalHandler = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const AddCustomModalHandler = () => {
    setIsAddModalOpen(!isAddModalOpen);
    setIsConsiderChargeForRevenue(false);
    form.resetFields();
    form.setFieldValue("applyChargeAs", "EXTRA_CHARGE");
    form.setFieldValue("chargeType", "FIXED");
  };
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterInvoice, setFilterInvoice] = useState({
    customerAccountId: "",
    status: "",
    pricePlan: "",
    startDate: "",
    endDate: "",
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { accountId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { invoices } = useSelector((state: RootState) => state.billing);
  const { filter } = useSelector((state: RootState) => state.customer);
  const { attachPricePlan } = useSelector(
    (state: RootState) => state.pricePlan
  );
  const rules = useMemo(() => {
    return [{ required: true, message: "Field is required!" }];
  }, []);

  const isFilterApplied = useMemo(() => {
    return !!(
      filterInvoice?.customerAccountId ||
      filterInvoice?.status ||
      filterInvoice?.startDate ||
      filterInvoice?.endDate
    );
  }, [filterInvoice]);

  const pricePlanOptions = useMemo(() => {
    return attachPricePlan?.map((item) => {
      return (
        <Select.Option value={item.price_plan_id}>
          {item.priceplanname}
        </Select.Option>
      );
    });
  }, [attachPricePlan]);

  useEffect(() => {
    dispatch(
      getInvoices({
        sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
        customerAccountId: accountId,
      })
    );
  }, [filter, accountId]);

  const invoicesData = useMemo(() => {
    return invoices
      ?.filter((item) => item.customer_account_id === accountId)
      .map((item) => item);
  }, [invoices, accountId]);

  const onSubmitFilter = async () => {
    try {
      const values = await filterForm.validateFields();
      console.log("🚀 ~ onSubmitFilter ~ values:", values);

      const range = values.range || [null, null];

      setFilterInvoice({
        customerAccountId: values?.customerAccountId,
        status: values?.status,
        pricePlan: values?.pricePlan,
        startDate: range[0]?.format("YYYY-MM-DD") || "",
        endDate: range[1]?.format("YYYY-MM-DD") || "",
      });

      if (values?.status) searchParams.set("status", values.status);
      if (values?.customerAccountId)
        searchParams.set("owner_id", values?.customerAccountId);

      if (range[0]?.format("YYYY-MM-DD")) {
        searchParams.set("invoice_date_get", range[0].format("YYYY-MM-DD"));
      }
      if (range[1]?.format("YYYY-MM-DD")) {
        searchParams.set("invoice_date_lte", range[1].format("YYYY-MM-DD"));
      }

      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    } catch (error) {
      console.error("Error submitting filter:", error);
    }
  };

  const resetFilter=()=>{
    filterForm.resetFields();
  }

  return (
    <BillingContainer>
      <div className="billingHeader">
        <Segment
          isWhite={true}
          options={["Invoices"]}
          value={billType}
          onChange={setBillType}
        />
        {billType !== "Orders" && (
          <CustomButton
            text={
              billType === "Invoices"
                ? "Create Custom Charge"
                : "Add Custom Charge"
            }
            position={billType === "Invoices" ? "start" : "end"}
            onClick={
              billType === "Invoices"
                ? createCustomModalHandler
                : AddCustomModalHandler
            }
          />
        )}
      </div>
      <div className="tableHeader">
        <p className="bold">
          {" "}
          All {billType}{" "}
          <span className="count">
            ({billType === "Invoices" ? invoicesData?.length || 0 : 0})
          </span>
        </p>
        {billType !== "Custom Charges" && (
          <div className="actions">
            <SearchInput
              TooltipSupportedFields="Customer ID, Account ID, Customer name, Account Name"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (e.target.value) {
                  searchParams.set("__search__", searchValue);
                } else {
                  searchParams.delete("__search__");
                }
                navigate({
                  pathname: location.pathname,
                  search: `?${searchParams.toString()}`,
                });
              }}
            />
            {isFilterApplied ? (
              <CustomButton
                text="Clear filter"
                type="link"
                icon={false}
                onClick={() => {
                  filterForm.resetFields();
                  setFilterInvoice({
                    customerAccountId: "",
                    status: "",
                    pricePlan: "",
                    startDate: "",
                    endDate: "",
                  });
                  const filterParams = [
                    "status",
                    "owner_id",
                    "invoice_date_get",
                    "invoice_date_lte",
                  ];
                  filterParams.forEach((param) => searchParams.delete(param));

                  navigate({
                    pathname: location.pathname,
                    search: `?${searchParams.toString()}`,
                  });
                }}
              />
            ) : (
              <></>
            )}
            <FilterDropdown
              onSubmitFilter={onSubmitFilter}
              isFilterApplied={isFilterApplied}
              resetFilter={resetFilter}
            >
              <Form layout="vertical" requiredMark={false} form={filterForm}>
                <Form.Item label="Status" name="status">
                  <Select
                    placeholder="Status"
                    style={{ width: "100%", marginBottom: "10px" }}
                    mode="multiple"
                  >
                    <Select.Option value="PAID">Paid</Select.Option>
                    <Select.Option value="PARTIALLY_PAID">
                      Partially Paid
                    </Select.Option>
                    <Select.Option value="DUE">Due/Overdue</Select.Option>
                    <Select.Option value="VOID">Void</Select.Option>
                    <Select.Option value="REFUND_INITIATED">
                      Refund Initiated
                    </Select.Option>
                    <Select.Option value="REFUND_COMPLETED">
                      Refund Complete
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Invoice Date ( End Date + Grace Period )"
                  name="range"
                  // rules={rules}
                >
                  <DatePicker.RangePicker
                    style={{
                      width: "100%",
                      marginBottom: billType === "Invoices" ? "10px" : "0px",
                    }}
                    placeholder={["Start date", "End date"]}
                  />
                </Form.Item>

                {billType !== "Orders" && (
                  <Form.Item label="Price-Plan" name="pricePlan">
                    <Select placeholder="Price Plan" style={{ width: "100%" }}>
                      {pricePlanOptions}
                    </Select>
                  </Form.Item>
                )}
              </Form>
            </FilterDropdown>
            <FilterOption options={options} />
          </div>
        )}
      </div>
      <BillingTable
        billType={billType}
        data={
          billType === "Invoices"
            ? invoicesData
            : billType === "Custom Charges"
            ? customChargeData
            : []
        }
      />
      <Modal
        title="Create Custom Invoice"
        isOpen={isCreateModalOpen}
        onClose={createCustomModalHandler}
      >
        <CustomInvoiceForm />
      </Modal>
      <Modal
        isOpen={isAddModalOpen}
        onClose={AddCustomModalHandler}
        title="New Custom Charge"
        successBtnTitle="Create"
      >
        <CustomChargeModal
          form={form}
          isConsiderChargeForRevenue={isConsiderChargeForRevenue}
          setIsConsiderChargeForRevenue={setIsConsiderChargeForRevenue}
        />
      </Modal>
    </BillingContainer>
  );
};

export default Billing;
