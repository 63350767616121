import { Provider } from "react-redux";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { RenderRoutes } from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import { persister, store } from "./redux/store";
import "./styles.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
  return (
    <GoogleOAuthProvider clientId={clientId!}>
      <Provider store={store}>
        <PersistGate loading={"loading..."} persistor={persister}>
          <RenderRoutes />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
