import React, { useEffect, useState } from "react";
import { PlanModal } from "./Styles";
import CustomButton from "../../../../components/common/button";
import { Col, Form, Input, Row, Select, Steps } from "antd";
import { CustomForm } from "../../../admin-center/aliasManagement/form/styles";
import FixedFeeForm from "./forms/FixedFeeForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  setBillingDetails,
  setBillingRateCards,
  setEditCardData,
  setPlanModalDetail,
  updateBillingRateCard,
  updateBillingRateCards,
  updateOverRideRateCards,
} from "../../../../redux/feature/pricePlan/pricePlanSlice";
import CreditGrant from "./forms/CreditGrant/CreditGrant";
import AddPrice from "./forms/AddPrice";
import LicenseFee from "./forms/LicenseFee";
import UsageBasedFeeForm from "./forms/usageBased/UsageBasedFee";
import UsageBasedAddPrice from "./forms/usageBased/addPrice";
import {
  createAddons,
  getAddons,
} from "../../../../redux/feature/billableItems/billableThunk";
import { transformPricePlanPayload } from "../../../../utils/helper";
import { useSearchParams } from "react-router-dom";

const Option = Select.Option;

const cardTypeMapping: any = {
  FIXED_FEE: "fixedFeeRateCards",
  CREDIT_GRANT: "creditGrantRateCards",
  LICENSE: "licenseRateCards",
  USAGE_BASED_FEE: "usageRateCards",
};

const steps = [
  {
    title: "Configure Rate Card",
    content: "First-content",
  },
  {
    title: "Add Price",
    content: "Second-content",
  },
];

const EditPlan: React.FC<any> = ({ rateCards }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { planModalDetail, isOverridePlan } =
    useSelector((state: any) => state.pricePlan);
  const [form1] = Form.useForm();
  const type = useSearchParams()[0].get("plan-type") || " ";
  const [usageMeterValue, setUsageMeterValue] = useState("");
  const [createdEventSchema, setCreatedEventSchema] = useState<any>("");
  const [createdUsageMeter, setCreatedUsageMeter] = useState<any>("");
  const [errorMsg, setErrorMsg] = useState<string>();
  const [creditType, setCreditType] = useState("");
  const editCardData = useSelector(
    (state: RootState) => state.pricePlan.editCardData
  );
  const [selectedAddon, setSelectedAddon] = useState<any>("");
  const [addonType, setAddonType] = useState(null);

  const [addonValue, setAddonValue] = useState("");
  const [specificCreditGrantValues, setSpecificCreditGrantValues] =
    useState<any>();
  const { addOns, usageMeters } = useSelector(
    (state: RootState) => state.billable
  );

  const [initData, setInitData] = useState("");
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const [expiryType, setExpiryType] = useState(
    type === "RECURRING" ? "PRICING_CYCLE" : "NO_EXPIRY"
  );

  const updateCurrent = async (value: "next" | "prev") => {
    try {
      if (value === "next") {
        const values = await form1.validateFields();
      }
      setCurrent((prev) => (value === "next" ? prev + 1 : prev - 1));
    } catch (error) {}
  };

  useEffect(() => {
    if (
      (specificCreditGrantValues?.usage_base &&  specificCreditGrantValues?.usage_base.length > 0) ||
      (specificCreditGrantValues?.add_on && specificCreditGrantValues?.add_on.length > 0)
    ) {
      setErrorMsg("");
    }
  }, [specificCreditGrantValues]);

  const handleProceed = () => {
    if (
      (specificCreditGrantValues?.add_on.length === 0 ||
        specificCreditGrantValues?.add_on.length === undefined) &&
      (specificCreditGrantValues?.usage_base.length === 0 ||
        specificCreditGrantValues?.usage_base === undefined) &&
      (form1.getFieldValue("creditID")?.value ===
        "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES" ||
        form1.getFieldValue("creditID") ===
          "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES")
    ) {
      setErrorMsg("must NOTE have fewer than 1 items.");
    } else {
      setErrorMsg("");
      updateCurrent("next");
    }
  };

  useEffect(() => {
    if (!Object.keys(editCardData).length) {
      setSpecificCreditGrantValues(null);
    }else{
      if(editCardData?.cardType === 'CREDIT_GRANT'){
        setSpecificCreditGrantValues(editCardData?.creditID);
      }
    }
  }, [editCardData]);

  useEffect(() => {
    const usageData = usageMeters?.find(
      (item: { usageMeterId: string }) => item.usageMeterId === usageMeterValue
    );
    const filterData = addOns?.find(
      (item: { addOnId: string }) => item.addOnId === addonValue
    );
    if (filterData) {
      form1.setFieldValue("displayName", filterData?.billableName);
    }
    if (usageData) {
      form1.setFieldValue("displayName", usageData?.billableName);
    }
  }, [addonValue, addOns, form1, usageMeters, usageMeterValue]);

  const handleModalClose = () => {
    dispatch(setPlanModalDetail(""));
    setAddonValue("");
    setCurrent(0);
    dispatch(setEditCardData({}));
    setInitData("");
    setUsageMeterValue("");
    setCreatedEventSchema("");
    setCreatedUsageMeter("");
    setSelectedAddon(null);
    setAddonType(null);
    setSpecificCreditGrantValues(null);
    setErrorMsg("");
    setCreditType("");
    setExpiryType(type === "RECURRING" ? "PRICING_CYCLE" : "NO_EXPIRY");
    form1.resetFields();
  };
  useEffect(() => {
    if (Object.keys(editCardData).length) {
      setInitData(editCardData);
      form1.setFieldsValue(editCardData);
    } else {
      setInitData("");
      form1.resetFields();
    }
  }, [editCardData, form1, planModalDetail]);

  const handleSubmit = async () => {
    try {
      const values = await form1.validateFields();
      if (Object.keys(values).length > 0) {
        dispatch(setBillingDetails(values));
        dispatch(setPlanModalDetail(""));
      }
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };

  const handleStepChange = async (e: number) => {
    try {
      if (current === 0) {
        await form1.validateFields();
      }
      setCurrent(e);
    } catch (error) {
      console.log("►►► ~ handleStepChange ~ error:", error);
    }
  };

  const handleOverrideRateCardAction = (
    data: any,
    values: any,
    uniqueID: string
  ) => {
    const selectedCardType = cardTypeMapping[values.cardType];
    console.log("►►► ~ selectedCardType:", selectedCardType);

    const actionType = uniqueID ? "create" : "update";

    const payload = {
      ...data.pricePlanDetails[selectedCardType]?.[0],
      actionType,
      uniqueID,
      rateCardId: values.rateCardId,
    };

    dispatch(updateOverRideRateCards(payload));
    dispatch(updateBillingRateCards(payload));
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);
    try {
      const cardObj: any = {};
      const data = await form1.validateFields();
      const values = form1.getFieldsValue(true);
      for (let key in values) {
        if (typeof values[key] === "string" && values[key].includes(">")) {
          values[key] = values[key].replace(">", "");
        }
      }

      if (planModalDetail === "LICENSE" && values.id !== "new") {
        const addonType = addOns.find(
          (item: any) => values.id === item?.addOnId
        );
        values.type = addonType.type;
      }
      if (
        planModalDetail === "FIXED_FEE" ||
        planModalDetail === "CREDIT_GRANT" ||
        planModalDetail === "LICENSE"
      ) {
        if (values.id === "new") {
          const payload = {
            name: values.addonName,
            billableName: values.billableName,
            type: planModalDetail === "LICENSE" ? values.type : planModalDetail,
          };
          const addonData: any = await dispatch(createAddons(payload));
          if (addonData?.error) {
            throw new Error(addonData.error.message);
          }
          await dispatch(
            getAddons({
              sort: "-updated_at",
            })
          );
          values.id = addonData.payload;
        }
      }
      if (values.tag === "newTag") {
        values.tag = values.newTagName;
      }
      if (planModalDetail === "CREDIT_GRANT") {
        if (
          values.creditID === "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES" ||
          values.creditID.value === "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES"
        ) {
          values.creditID = {
            ...specificCreditGrantValues,
            value: "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES",
          };
        } else {
          if (typeof values.creditID === "object" && values.creditID !== null) {
            values.creditID = {
              value: values.creditID.value,
            };
          } else {
            values.creditID = {
              value: values.creditID,
            };
          }
        }
      }
      values.cardType = planModalDetail;
      if (Object.keys(editCardData).length > 0) {
        if (isOverridePlan) {
          const { uniqueID, pricePlanMetadata, ...rateCardData } = values;
          const { rateCards: Cards, ...planMetaData } = rateCards;

          const data: any = transformPricePlanPayload({
            ...(pricePlanMetadata || planMetaData),
            rateCards: [rateCardData],
          });

          handleOverrideRateCardAction(data, rateCardData, uniqueID);

          form1.resetFields();
        } else {
          dispatch(updateBillingRateCard({ data: values, override: false }));
        }
      } else {
        values.uniqueID = Date.now();
        if (isOverridePlan) {
          const { rateCards: rateCardDetails, ...pricePlanMetadata } =
            rateCards;
          const data: any = transformPricePlanPayload({
            ...pricePlanMetadata,
            rateCards: [values],
          });
          handleOverrideRateCardAction(data, values, values.uniqueID);
          dispatch(setBillingRateCards(values));
          form1.resetFields();
        } else {
          console.log(
            "🚀 ~ file: EditPlan.tsx:223 ~ handleFormSubmit ~ values:",
            values
          );
          dispatch(setBillingRateCards(values));
        }
      }
      setAddonValue("");
      setAddonType(null);
      form1.resetFields();
      setCurrent(0);
      dispatch(setPlanModalDetail(""));
      handleModalClose();
    } catch (error) {
      console.log(
        "🚀 ~ file: EditPlan.tsx:147 ~ handleFormSubmit ~ error:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (Object.keys(editCardData)?.length && !initData) {
    return null;
  }

  return (
    <PlanModal
      centered
      title={
        <div className="planModalTitle">
          <div className="title">
            {planModalDetail === "Plan Minimum"
              ? planModalDetail
              : editCardData
              ? `Edit ${planModalDetail} Rate Card`
              : `Add ${planModalDetail} Rate Card`}
          </div>
          {planModalDetail === "Plan Minimum" && (
            <div className="description">
              This is a bare-minimum fee that a customer is required to pay if
              their projected overall cost is not met.
            </div>
          )}

          {planModalDetail !== "Plan Minimum" &&
            planModalDetail !== "FIXED_FEE" && (
              <Steps
                className="steps"
                size="small"
                current={current}
                items={items}
                onChange={handleStepChange}
              />
            )}
        </div>
      }
      open={planModalDetail}
      // onClose={handleModalClose}
      closeIcon={null}
      // onCancel={handleModalClose}
      footer={[
        <div className="footer">
          <CustomButton
            text="Cancel"
            onClick={handleModalClose}
            icon={false}
            type={"default"}
            disabled={isLoading}
          />
          <div className="footerStepAction">
            {current === 1 && (
              <CustomButton
                text="Go Back"
                onClick={() => updateCurrent("prev")}
                icon={false}
                type={"default"}
                disabled={isLoading}
              />
            )}
            <CustomButton
              type="primary"
              htmlType={current === 1 ? "submit" : "button"}
              text={
                planModalDetail === "Plan Minimum"
                  ? "Add  Plan Minimum"
                  : planModalDetail === "FIXED_FEE" || current === 1
                  ? "Submit"
                  : "Proceed"
              }
              onClick={
                planModalDetail === "Plan Minimum"
                  ? handleSubmit
                  : planModalDetail === "FIXED_FEE" || current === 1
                  ? () => handleFormSubmit()
                  : () => handleProceed()
              }
              icon={false}
              loading={isLoading}
            />
          </div>
        </div>,
      ]}
      width={
        planModalDetail === "Plan Minimum"
          ? 530
          : planModalDetail === "USAGE_BASED_FEE"
          ? 950
          : 1300
      }
    >
      <div className="modal-body">
        <div className="PlanModalBody">
          <CustomForm
            form={form1}
            layout="vertical"
            onFinish={handleFormSubmit}
            requiredMark={false}
            initialValues={{ proratedRefundMode: "NONE" }}
          >
            <Row>
              <Col span={16}>
                {planModalDetail === "Plan Minimum" ? (
                  <Form.Item label="Plan Minimum (AFN)" name="plan_minimum">
                    <Input placeholder="Plan Minimum (AFN)" />
                  </Form.Item>
                ) : planModalDetail === "FIXED_FEE" ? (
                  <FixedFeeForm
                    editCardData={editCardData}
                    addonValue={addonValue}
                    setAddonValue={setAddonValue}
                  />
                ) : planModalDetail === "CREDIT_GRANT" ? (
                  current === 0 ? (
                    <CreditGrant
                      addonValue={addonValue}
                      setAddonValue={setAddonValue}
                      setExpiryType={setExpiryType}
                      expiryType={expiryType}
                      errorMessage={errorMsg}
                      specificCreditGrantValues={specificCreditGrantValues}
                      creditType={creditType}
                      setCreditType={setCreditType}
                      setSpecificCreditGrantValues={
                        setSpecificCreditGrantValues
                      }
                      form1={form1}
                    />
                  ) : (
                    <AddPrice expiryType={expiryType} />
                  )
                ) : planModalDetail === "LICENSE" ? (
                  <LicenseFee
                    addonValue={addonValue}
                    setAddonValue={setAddonValue}
                    editCardData={editCardData}
                    form={form1}
                    current={current}
                    selectedAddon={selectedAddon}
                    setSelectedAddon={setSelectedAddon}
                    addonType={addonType}
                    setAddonType={setAddonType}
                  />
                ) : planModalDetail === "USAGE_BASED_FEE" ? (
                  current === 0 ? (
                    <UsageBasedFeeForm
                      form={form1}
                      usageMeterValue={usageMeterValue}
                      setUsageMeterValue={setUsageMeterValue}
                      createdEventSchema={createdEventSchema}
                      setCreatedEventSchema={setCreatedEventSchema}
                      createdUsageMeter={createdUsageMeter}
                      setCreatedUsageMeter={setCreatedUsageMeter}
                    />
                  ) : (
                    <UsageBasedAddPrice
                      editCardData={editCardData}
                      form={form1}
                    />
                  )
                ) : planModalDetail === "ENTITLEMENT_FEE" ? (
                  <>ENTITLEMENT_FEE</>
                ) : planModalDetail === "ENTITLEMENT_OVERAGE" ? (
                  <>ENTITLEMENT_OVERAGE</>
                ) : (
                  <UsageBasedAddPrice />
                )}
              </Col>
            </Row>
          </CustomForm>
        </div>
      </div>
    </PlanModal>
  );
};

export default EditPlan;
