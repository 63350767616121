import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getBillingInfo } from "../../redux/feature/adminCenter/adminCenterAsyncThunk";
import { AdminCenterContainer, AdminCenterTabs } from "./Styles";
import PageLoading from "../../components/Loader";
import Users from "./users";
import Currencies from "./currencies";
import AliasManagement from "./aliasManagement";
import Setting from "./setting";
import Profile from "./userProfile";
import ApiToken from "./apiToken";
import BillingInformation from "./billingInformation";
import BulkImport from "./bulkImport";

interface TabItem {
  label: string;
  key: string;
  children: React.ReactNode;
}

const AdminCenter: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [activeTabKey, setActiveTabKey] = useState<string>("profile");
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  const isAuthLoading = useSelector((state: RootState) => state.auth.loading);

  useEffect(() => {
    const path = location.pathname;
    const currentTabKey = path.split("/")[2] || "profile";

    setActiveTabKey(currentTabKey);
    if (path === "/admin-center" || path === "/") {
      navigate(`/admin-center/${currentTabKey}`);
    }
    setIsPageLoading(false);
  }, [location.pathname, navigate]);

  useEffect(() => {
    dispatch(getBillingInfo());
  }, [dispatch]);

  const handleTabChange = useCallback((key: string) => {
    navigate(`/admin-center/${key}`);
  }, [navigate]);

  const tabItems = useMemo<TabItem[]>(
    () => [
      { label: "Profile", key: "profile", children: isAuthLoading ? <PageLoading /> : <Profile /> },
      { label: "Users", key: "users", children: <Users /> },
      { label: "Currencies", key: "currencies", children: <Currencies /> },
      { label: "Alias Management", key: "aliases", children: <AliasManagement /> },
      { label: "API Token", key: "api-tokens", children: <ApiToken /> },
      { label: "Billing Information", key: "billing-information", children: <BillingInformation /> },
      { label: "Bulk Import", key: "bulk-import", children: <BulkImport /> },
    ],
    [isAuthLoading]
  );

  return (
    <div>
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <AdminCenterContainer>
          <h1 className="header">Admin Center</h1>
          <AdminCenterTabs
            tabPosition="left"
            activeKey={activeTabKey}
            onTabClick={handleTabChange}
            items={tabItems}
          />
        </AdminCenterContainer>
      )}
    </div>
  );
};

export default AdminCenter;
