import { Form, Input, Radio, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { NestedCard } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
const Option = Select.Option;

interface AddonItemProps {
  addonValue: string;
  setAddonValue: (value: string) => void;
  isAddLicenseType?: boolean;
  editCardData?: any;
  setLicenseType?: (value: string) => void;
  setSelectedAddon?: any;
}
const AddonItem: React.FC<AddonItemProps> = ({
  addonValue,
  setAddonValue,
  editCardData,
  isAddLicenseType = false,
  setLicenseType = () => {},
  setSelectedAddon = () => {},
}) => {
  const { billingDetails, planModalDetail } = useSelector(
    (state: any) => state.pricePlan
  );
  const [data, setData] = useState<any>([
    { value: "new", label: "Create New Addon" },
  ]);
  const { addOns, loading, error } = useSelector(
    (state: RootState) => state.billable
  );

  useEffect(() => {
    let addonIds: any = [];
    if (billingDetails?.rateCards && billingDetails.rateCards.length > 0) {
      addonIds = billingDetails?.rateCards.map((card: any) => card.id);
      if (editCardData) {
        addonIds = addonIds.filter(
          (addonId: any) => addonId !== editCardData.id
        );
      }
    }
    if (addOns && addOns.length > 0) {
      const addonData = addOns
        .filter((addon: any) => {
          if (planModalDetail === "LICENSE") {
            return (
              (addon.type === "LICENSE" || addon.type === "NAMED_LICENSE") &&
              !addonIds.includes(addon.addOnId)
            );
          }
          return (
            addon.type === planModalDetail && !addonIds.includes(addon.addOnId)
          );
        })
        .map((addon: any) => { 
          
          return({
          value: addon.addOnId,
          label: (
            <div>
              {addon.name}
              {addon.type === "NAMED_LICENSE" ? (
                <span>-(Named License)</span>
              ) : (
                ""
              )}{" "}
            </div>
          ),
          type: addon.type ,
        })});  
      setData((prev: any) => [
        ...addonData,
        { value: "new", label: "Create New Addon" },
      ]);
    }
  }, [addOns, addOns.length, editCardData, planModalDetail, billingDetails]);

  const handleChange = (val: any) => {
    console.log("⏩ ~ val:", val);
    setAddonValue(val);
    if (val === "new") setSelectedAddon("new");
    else {
      console.log("⏩ ~ data:", data);
      const addon = data.find((addon: any) => addon.value === val);

      setSelectedAddon(addon);
    }
  };
  return (
    <>
      <Form.Item label="Choose Addon" name="id" rules={[{required:true,message:"Required !"}]}>
        <Select placeholder="Create New Addon" onChange={handleChange}>
          {data.map((addon: any) => {
            return (
              <Select.Option key={addon.value} value={addon.value}>
                {addon.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      {addonValue === "new" && (
        <NestedCard>
          <Form.Item
            label="Addon Name"
            name="addonName"
            rules={[
              {
                required: true,
                message: "Please input the addon name!",
              },
            ]}
          >
            <Input placeholder="Addon Name" />
          </Form.Item>

          <Form.Item
            label="Billable Name"
            name="billableName"
            rules={[
              {
                required: true,
                message: "Please input the billable name!",
              },
            ]}
          >
            <Input placeholder="Billable Name" />
          </Form.Item>
          {isAddLicenseType && (
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please input the billable name!",
                },
              ]}
            >
              <Select placeholder="Type" onChange={setLicenseType}>
                <Option value="LICENSE">License</Option>
                <Option value="NAMED_LICENSE">Named License</Option>
              </Select>
            </Form.Item>
          )}
        </NestedCard>
      )}
    </>
  );
};

export default AddonItem;
