import {
  Card,
  Col,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { StyledRadio } from "../../../../price-plans/NewPricePlan/RateCard/forms/styles";
import { StyledCard } from "../styles";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useParams } from "react-router-dom";
import { getInvoices } from "../../../../../redux/feature/billing/billingThunk";

type ActionCardPropsType = {
  nameKey: number;
  id: number;
  onDelete: (id: number, nameKey: number) => void;
  currencyCode: any;
  debitAmount: any;
  setDebitAmount: any;
  form:any;
  setIsAvailableAmountShown : (val:boolean)=>void;
};

const ActionCard: React.FC<ActionCardPropsType> = ({
  nameKey,
  id,
  onDelete,
  currencyCode,
  setDebitAmount,
  debitAmount,
  form,
  setIsAvailableAmountShown
}) => {
  console.log("🚀 ~ nameKey:", nameKey)
  const [actionType, setActionType] = useState("INVOICE");
  const {invoices}=useSelector((state:RootState)=>state.billing)
  console.log("►►► ~ invoices:", invoices)
  const {viewAccountDetails}=useSelector((state:RootState)=>state.customer)
  const {accountId}=useParams()
  const dispatch = useDispatch<AppDispatch>();

  const actionTypeOptions = useMemo(() => {
    const today = new Date();
    return invoices
      ?.filter((inv:any) =>
        ['DUE', 'PARTIALLY_PAID', 'OVERDUE'].includes(inv.status) &&
        inv.customer_account_id === accountId 
      )
      .map((invoice) => ({
        value: invoice.invoice_id,
        label: invoice.invoice_id,
      }));
  }, [invoices, accountId]);

  useEffect(()=>{
    dispatch(getInvoices({sort:'-updatedAt'}))
  },[])
  const actionTypeHandler = (e: RadioChangeEvent) => {
    console.log("►►► ~ actionTypeHandler ~ e:", e);
    setActionType(e.target.value);
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    const amountFields = Object.keys(values).filter((key) =>
      key.startsWith("amount-")
  );
    const allAmountFieldsFilled = amountFields.every(
      (field) =>
        values[field] !== undefined &&
        values[field] !== null &&
        values[field] !== ""
    );
    setIsAvailableAmountShown(allAmountFieldsFilled);
  }, [debitAmount, form]);
  
  return (
    <StyledCard>
      <Row>
        <Col span={16}>
          <Form.Item label="Action" name={`type-${nameKey}`}>
            <StyledRadio onChange={actionTypeHandler} defaultValue={actionType}>
              <Radio value="INVOICE">Invoice</Radio>
              <Radio value="CREDIT">Credit</Radio>
            </StyledRadio>
          </Form.Item>
          <Form.Item
            label={`Amount (${viewAccountDetails?.currency_code})`}
            name={`amount-${nameKey}`}
            rules={[
              { required: true, message: "Field is required!" },
              {
                pattern: /^[1-9]\d*$/,
                message: "Please enter a valid number greater than 0!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder={`Amount in ${viewAccountDetails?.currency_code}`}
              onChange={(e: any) => {
                setDebitAmount((prev: any) => ({
                  ...prev,
                  [id]: e.target.value,
                }));
              }}
              onWheel={(e:any) => e.target.blur()}
            />
          </Form.Item>
          {actionType === "INVOICE" && (
            <Form.Item
              label="Invoice ID"
              name={`invoice_id-${nameKey}`}
              className="invoice"
              rules={[{ required: true, message: "Field is required!" }]}
            >
              <Select
                options={actionTypeOptions}
                placeholder="Search Invoice ID "
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <div className="delete" onClick={() => onDelete(id, nameKey)}>
        <DeleteOutlined />
      </div>
    </StyledCard>
  );
};
export default ActionCard;
