import { useLocation, useNavigate } from "react-router-dom";
import PricePlanTable from "./table";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import PageLayout from "../../components/common/pageLayout";
import { getPricePlans } from "../../redux/feature/pricePlan/pricePlanThunk";
import { setFilter } from "../../redux/feature/customer/customerSlice";

const PricePlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("recurring");
  const filter = useSelector((state: RootState) => state.customer.filter);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchKey, setSearchKey] = useState(searchTerm);
  const [status, setStatus] = useState<"DRAFT" | "ACTIVE" | "">("");

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    navigate(`/price-plan/${activeKey}?__sort__=${filter}`);
  }, [location.pathname, filter]);

  useEffect(() => {
    if (activeKey) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [activeKey]);

  useEffect(() => {
    const handler = setTimeout(() => setSearchKey(searchTerm), 1000);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  const handleTabChange = (key: any) => {
    setLoading(true);
    setActiveKey(key);
    const newFilter = "-updated_at";
    dispatch(setFilter(newFilter));
    navigate(`/price-plan/${key}?__sort__=${newFilter}`);
  };

  useEffect(() => {
    dispatch(
      getPricePlans({
        sort: filter.startsWith("2B") ? filter.slice(2) : filter,
        search: searchKey,
        status: status,
        type: activeKey === "recurring" ? "BILLING" : "PURCHASE",
      })
    );
  }, [activeKey, filter, searchKey, status]);

  const items = useMemo(
    () => [
      {
        label: `Recurring Plans`,
        key: "recurring",
        children: (
          <PricePlanTable
            keyProp="BILLING"
            activeKey={activeKey}
            isLoading={isLoading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setStatus={setStatus}
            status={status}
          />
        ),
      },
      {
        label: `One-Time Plans`,
        key: "one-time",
        children: (
          <PricePlanTable
            keyProp="PURCHASE"
            activeKey={activeKey}
            isLoading={isLoading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setStatus={setStatus}
            status={status}
          />
        ),
      },
    ],
    [activeKey, isLoading, status, searchTerm]
  );

  return (
    <div>
      <PageLayout
        title="Price Plan"
        btnText="New Price Plan"
        activeKey={activeKey}
        handleTabChange={handleTabChange}
        btnOnClick={() =>
          navigate(
            `/price-plan/create?plan-type=${
              activeKey === "recurring" ? "RECURRING" : "ONE_TIME"
            }`
          )
        }
        items={items}
      />
    </div>
  );
};
export default PricePlan;
