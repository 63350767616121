import { useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store"; // Assuming RootState is defined
import { getAllCustomer } from "../../redux/feature/customer/customerAsyncThunk";
import { setFilter, setIsNewCustomer } from "../../redux/feature/customer/customerSlice";
import EditCustomer from "./viewCustomer/EditCustomer";
import Accounts from "./table/Accounts";
import Customers from "./table/Customers";
import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../../components/common/pageLayout";

const CustomerTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { customers, customerAccounts, filter } = useSelector(
    (state: RootState) => state.customer
  );
  const updateSearchParams = useCallback(
    (path: string, sortParam: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("__sort__", sortParam);
      navigate(`${path}?${searchParams.toString()}`);
    },
    [location.search, navigate]
  );

  const currentTabKey = useMemo(() => {
    return location.pathname === "/accounts"  ? "2" : "1";
  }, [location.pathname]);

  

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(getAllCustomer({ sort: "-updated_at" }));
    }
  }, [customers.length, dispatch]);

 
  const handleTabChange = useCallback(
    (key: string) => {
      dispatch(setFilter("-updated_at"));
      const path = key === "1" ? "/customer" : "/accounts";
      updateSearchParams(path, "-updated_at");
    },
    [dispatch, updateSearchParams]
  );

  const items = useMemo(
    () => [
      {
        label: "All Customers",
        key: "1",
        children: <Customers currentTabKey={currentTabKey} />,
      },
      {
        label: "All Accounts",
        key: "2",
        children: <Accounts currentTabKey={currentTabKey} />,
      },
    ],
    [currentTabKey]
  );

  return (
    <div>
      <PageLayout
        items={items}
        title="Customers"
        activeKey={currentTabKey}
        handleTabChange={handleTabChange}
        btnText="New Customer"
        btnOnClick={() => dispatch(setIsNewCustomer(true))}
      />
      <EditCustomer />
    </div>
  );
};

export default CustomerTab;
