import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const exportToExcel = (data: any): void => {
  const { pricePlanId, rateCards, title } = data;
  console.log("►►► ~ exportToExcel ~ rateCards:", rateCards);
  const workbook = XLSX.utils.book_new();
  
  const cardTypeCounts: Record<string, number> = {};
  rateCards.forEach((item: any) => {
    const cardType = item?.cardType || "Sheet";
    cardTypeCounts[cardType] = (cardTypeCounts[cardType] || 0) + 1;
  });

  const sheetCounters: Record<string, number> = {};
  rateCards.forEach((item: any) => {
    const formattedData: Record<string, any> = {
      "Rate Card Name": item?.displayName || "",
      ...(item?.usageMeterId ? { "Usage Meter ID": item?.usageMeterId } : {}),
      ...(item?.addOnId ? { "Addon ID": item?.addOnId } : {}),
      Tag: item?.tag || "",
      "Pricing Model": item?.ratePlan?.pricingModel || "",
      cardType: item?.cardType || "",
      "Invoice Timing": item?.invoiceTiming || "",
      ...(item?.cardType === "FIXED_FEE" || item?.cardType === "LICENSE"
        ? { "Proration Enabled": item?.enableProration }
        : {}),
      ...(item?.cardType === "CREDIT_GRANT"
        ? {
            Priority: item?.grantDetails?.priority || "",
            "Expiry Duration": item?.grantDetails?.expiryDuration || "",
            "Expiry Type": item?.grantDetails?.expiryType || "",
            "Applicable Entity IDs":
              item?.grantDetails?.applicableEntityIds[0]?.value ===
              "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES"
                ? [
                    ...(item?.grantDetails?.applicableEntityIds[0]?.add_on ||
                      []),
                    ...(item?.grantDetails?.applicableEntityIds[0]
                      ?.usage_base || []),
                  ].join(", ") 
                : item?.grantDetails?.applicableEntityIds[0]?.value,
            "Recurrence Interval": item?.recurrenceConfig?.interval || "",
            "Recurrence Offset": item?.recurrenceConfig?.offset || "",
          }
        : {}),
      ...(item?.cardType === "LICENSE"
        ? {
            "Max. Qty": item?.maxQuantity || "",
            "Max. Qty Breach Action": "",
            "Pricing Model": item?.ratePlan?.pricingModel || "",
          }
        : {}),
      type: item?.type || "",
    };

    item?.supportedCurrencies?.forEach((c: any) => {
      if (item?.cardType === "USAGE_BASED_FEE" || item?.cardType === "LICENSE") {
        item?.ratePlan?.slabs?.forEach((slab: any, i: any) => {
          const nextSlabStart = item?.ratePlan?.slabs?.[i + 1]?.startAfter;
          const endRange = nextSlabStart !== undefined ? nextSlabStart : "Infinity";
          formattedData[`Slab/${i + 1}/Range`] = `${slab?.startAfter} - ${endRange}`;
          formattedData[`Slab/${i + 1}/Pricing Type`] = slab?.priceType?.toUpperCase() || "";
          item?.rateValues?.forEach((rate: any) => {
            formattedData[`Slab/${i + 1}/Price/${c}`] =
              rate?.slabRates?.find((r: any) => r?.order === slab?.order)?.rate || "";
            formattedData[`${c}/Minimum Price`] = rate?.rateConfig?.maximumRate || "";
            formattedData[`${c}/Maximum Price`] = rate?.rateConfig?.minimumRate || "";
          });
        });
      } else if (item?.cardType === "CREDIT_GRANT") {
        item?.rateDetails?.currencySlabRateDetails?.forEach((rate: any) => {
          formattedData[`Price/${rate?.currency}`] = rate?.creditAmount || "";
          formattedData[`Credits/${rate?.currency}`] = rate?.slabDetails[0]?.rate || "";
        });
      } else {
        item?.rateValues?.forEach((rate: any) => {
          formattedData[`Price/${rate?.currency}`] = rate?.rate || "";
        });
      }
    });

    console.log("►►► ~ rateCards?.forEach ~ formattedData:", formattedData);

    const cardType = item?.cardType || "Sheet";
    let sheetName = cardType;
    if (cardTypeCounts[cardType] > 1) {
      sheetCounters[cardType] = (sheetCounters[cardType] || 0) + 1;
      sheetName = `${cardType}-${sheetCounters[cardType]}`;
    }

    const worksheet = XLSX.utils.json_to_sheet([formattedData]);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${title}-${pricePlanId}.xlsx`);
};
