import { useDispatch } from "react-redux";
import FilterOption from "../../../components/common/filter/FilterOption";
import SearchInput from "../../../components/common/search";
import { Container } from "./Styles";
import { useLocation, useNavigate } from "react-router-dom";
import { setFilter } from "../../../redux/feature/customer/customerSlice";
import { AppDispatch } from "../../../redux/store";
import { useEffect, useState } from "react";

const CustomerHeader: React.FC<any> = ({
  searchTerm,
  setSearchTerm,
  tooltipText,
  currentTabKey
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const [sortValue, setSortValue] = useState("-updated_at");

  const handleOnChange = (e: any) => {
    console.log("►►► ~ handleOnChange ~ e:", e);
    dispatch(setFilter(e));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const sortParam = queryParams.get("__sort__");
    if (sortParam) {
      setSortValue(sortParam);
    }
  }, [location.search]);

  const options = [
    {
      value: "-updated_at",
      label: "Updated At Descending",
    },
    {
      value: "2Bupdated_at",
      label: "Updated At Ascending",
    },
    {
      value: "-id",
      label: "ID Descending",
    },
    {
      value: "2Bid",
      label: "ID Ascending",
    },
  ];

  return (
    <Container key={currentTabKey}>
      <SearchInput
        value={searchTerm}
        onChange={(e) => {
          const searchParams = new URLSearchParams(location.search);
          searchParams.set("__search__", e.target.value);
          if (!e.target.value) {
            searchParams.delete("__search__");
          }
          setSearchTerm(e.target.value);
          navigate({
            pathname: location.pathname,
            search: `?${searchParams.toString()}`,
          });
        }}
        TooltipSupportedFields={tooltipText}
      />
      <FilterOption key={sortValue} options={options} defaultValue={sortValue} />
    </Container>
  );
};

export default CustomerHeader;
