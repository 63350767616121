import { Spin } from 'antd'
import React from 'react'

const PageLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        zIndex: '9999',
        opacity: '.5',
        position:'absolute',
        width:'100%'
      }}
    >
      <Spin size="large" />
    </div>
  )
}

export default PageLoading