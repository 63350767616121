import {
  Avatar,
  Card,
  Col,
  Divider,
  message,
  Row,
  Segmented,
  Switch,
  Tag,
} from "antd";
import PageHeader from "../../../../components/common/pageHeader";
import { DetailsContainer } from "./styles";
import {
  CopyOutlined,
  EditOutlined,
  LockOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import ConfigurationItemCard from "../configurationItemCard";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  activeUsageMeter,
  getUsageMeter,
} from "../../../../redux/feature/billableItems/billableThunk";
import { AxiosError } from "axios";
import PageLoading from "../../../../components/Loader";
import BackPageHeader from "../../../../components/common/BackPageHeader";
import { Label } from "../../../billing/detailsPage/styles";
import {
  copyToClipboard,
  formatDateToLocalTime,
} from "../../../../utils/helper";
import { EmptyData } from "../../../../components/common/empty";
import { DetailsCard } from "../../../../components/common/detailsCard/styles";
import { StyledTable } from "../../../../components/common/table/styles";
import { StyledTag } from "../../../../components/common/tag/styles";
import AlertModal from "../../../../components/common/alertModal";
import { getEvent } from "../../../../redux/feature/events/eventThunk";
import { setEmptyCurrentUsageMeter } from "../../../../redux/feature/billableItems/billableSlice";

const UsageMeterDetail = () => {
  const [type, setType] = useState<
    | "Configuration"
    | "Associated Price Plans"
    | "Ingest Usage"
    | "External Usage Sources"
    | "Settings"
  >("Configuration");
  const [isConformationModalOpen, setsConformationModalOpen] = useState(false);
  const conformationModalHandler = () => {
    setsConformationModalOpen(!isConformationModalOpen);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { currentUsageMeter, loading } = useSelector(
    (state: RootState) => state.billable
  );

  // useEffect(() => {
  //   if (currentUsageMeter?.eventSchemaId) {
  //     dispatch(getEvent(currentUsageMeter?.eventSchemaId))
  //       .unwrap()
  //       .catch((e) => {
  //         console.log("🚀 ~ file: index.tsx:45 ~ useEffect ~ e:", e);
  //         message.error("Failed to fetch event details.");
  //       });
  //   }
  // }, [currentUsageMeter?.eventSchemaId, dispatch]);

  // const { currentEvent } = useSelector((state: RootState) => state.event);

  // console.log("🚀 ~ UsageMeterDetail ~ currentEvent:", currentEvent);
  console.log("►►► ~ UsageMeterDetail ~ currentUsageMeter:", currentUsageMeter);

  useEffect(() => {
    if (id) {
      dispatch(getUsageMeter(id))
        .unwrap()
        .catch((e) => {
          console.log("🚀 ~ file: index.tsx:45 ~ useEffect ~ e:", e);
          message.error("Failed to fetch event details.");
        });
    }
    return ()=>{
      dispatch(setEmptyCurrentUsageMeter({}));
    }
  }, [id, dispatch]);

  const toggleUsageMeterStatus = () => {
    try {
      const payload = {
        usageMeterId: currentUsageMeter?.usageMeterId,
        status: true,
      };

      dispatch(activeUsageMeter(payload));
    } catch (err) {
      const error = err as AxiosError;
      console.log("🚀 ~ file: index.tsx:73 ~ onChange ~ error:", error);
    } finally {
      setsConformationModalOpen(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Price Plan Name",
        dataIndex: "pricePlanName",
        key: "pricePlanName",
      },
      {
        title: "Price Plan Id",
        dataIndex: "pricePlanId",
        key: "pricePlanId",
      },
      {
        title: "Pricing Cycle",
        dataIndex: "pricingCycleInterval",
        key: "pricingCycleInterval",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        render: () => {
          return <RightOutlined />;
        },
      },
    ],
    [type]
  );

  const associatedPricePlansData = useMemo(() => {
    return (
      currentUsageMeter?.associatedPricePlans?.map((item: any) => item) || []
    );
  }, [currentUsageMeter]);

  if (loading && !currentUsageMeter) {
    return <PageLoading />;
  }

  return (
    <DetailsContainer>
      <BackPageHeader
        actions={[
          {
            label: "Edit Usage Meter",
            icon: <EditOutlined />,
            onClick: () => {
              if (
                currentUsageMeter.status !== "ACTIVE" &&
                currentUsageMeter.status !== "INACTIVE"
              ) {
                navigate(
                  `/usage-meter/${currentUsageMeter?.usageMeterId}/edit`
                );
              } else {
                message.warning(
                  "Active or inactive usage meter cannot be edited"
                );
              }
            },
          },
        ]}
        text="Back to All Usage Meters"
        path="/usage-meter"
      />
      <div className="detailBody">
        <Row gutter={32}>
          <Col span={16} className="leftSide">
            <Card className="userDetailCard">
              <div className="userProfile">
                <div>
                  <Avatar size={50} icon={<UserOutlined />} />
                </div>
                <div className="profileDescription">
                  <p>Usage Meter Details</p>
                  <h5>{currentUsageMeter.name}</h5>
                  <StyledTag
                    color={`${
                      currentUsageMeter.status === "DRAFT" ? "orange" : "green"
                    }`}
                  >
                    • {currentUsageMeter.status}
                  </StyledTag>
                </div>
              </div>
              <div className="toggleContainer">
                <p>TOGGLE STATUS</p>
                <div className="toggle">
                  {currentUsageMeter?.status === "ACTIVE" && <LockOutlined />}
                  <Switch
                    checked={
                      currentUsageMeter?.status === "ACTIVE" ? true : false
                    }
                    onChange={() => {
                      const { eventSchemaStatus } = currentUsageMeter || {};
                      if (eventSchemaStatus === "DRAFT") {
                        message.error(
                          "Active event schema not found for usage meter"
                        );
                        return;
                      }
                      if (eventSchemaStatus === "INACTIVE") {
                        message.error(
                          "Computations should be null for usage meters without event schema association"
                        );
                        return;
                      }
                      conformationModalHandler();
                    }}
                    disabled={currentUsageMeter?.status === "ACTIVE"}
                  />
                </div>
              </div>
            </Card>
            <Segmented
              className="segment"
              options={["Configuration", "Associated Price Plans"]}
              value={type}
              onChange={setType}
            />
            <div className="detailContent">
              {type === "Configuration" ? (
                <>
                  <div>Transformations Configured</div>
                  {currentUsageMeter?.filterCondition ? (
                    <ConfigurationItemCard
                      filterCondition={currentUsageMeter.filterCondition}
                    />
                  ) : (
                    <EmptyData description="No configuration found." />
                  )}
                </>
              ) : type === "Associated Price Plans" ? (
                associatedPricePlansData.length ? (
                  <StyledTable
                    columns={columns}
                    pagination={false}
                    dataSource={associatedPricePlansData}
                    onRow={(record: any) => {
                      return {
                        onClick: () => {
                          navigate(`/price-plan/${record.pricePlanId}`);
                        },
                      };
                    }}
                    isCursorPointerShow
                  />
                ) : (
                  <EmptyData description="No Associated Price Plans found." />
                )
              ) : (
                <div></div>
              )}
            </div>
          </Col>
          <Col span={8} className="rightSide">
            <DetailsCard topBarColor="rgb(251, 191, 36)">
              <div className="topBar"></div>
              <div className="item">
                <div>Aggregation Type</div>
                <b>{currentUsageMeter?.aggregation}</b>
              </div>
              <div className="item">
                <p>Usage Meter ID</p>
                <b>
                  {currentUsageMeter?.usageMeterId}{" "}
                  <span>
                    {currentUsageMeter?.usageMeterId && (
                      <CopyOutlined
                        className="copyIcon"
                        onClick={() => {
                          copyToClipboard(currentUsageMeter?.usageMeterId);
                        }}
                      />
                    )}
                  </span>
                </b>
              </div>
              <div
                className="item"
                onClick={() => {
                  if (currentUsageMeter?.eventSchemaName) {
                    navigate(`/events/${currentUsageMeter?.eventSchemaName}`);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <div>Attached Event Schema</div>
                {currentUsageMeter?.eventSchemaName ? (
                  <b className="dummy">
                    {currentUsageMeter?.eventSchemaName}{" "}
                    <span className="dummy">
                      <RightOutlined className="rightIcon" />
                    </span>
                  </b>
                ) : (
                  <b>-</b>
                )}
              </div>
              <div className="item">
                <div>Usage MTD</div>
                <b>0</b>
              </div>
              <div className="footerContent">
                <Divider dashed className="divider" />
                <div style={{ marginBottom: "8px" }}>
                  Created {formatDateToLocalTime(currentUsageMeter?.createdAt)}
                </div>
                <div>
                  Last Updated{" "}
                  {formatDateToLocalTime(currentUsageMeter?.updatedAt)}
                </div>
              </div>
            </DetailsCard>
          </Col>
        </Row>
        <AlertModal
          open={isConformationModalOpen}
          close={conformationModalHandler}
          title="Activate Usage Meter?"
          subtitle="Once the Usage Meter is activated, it cannot be edited again. Do you want to proceed?"
          submitText="Activate Usage Meter"
          onSubmit={toggleUsageMeterStatus}
        />
      </div>
    </DetailsContainer>
  );
};

export default UsageMeterDetail;
