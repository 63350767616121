import {
  Form,
  Input,
  InputRef,
  Select,
  Table,
  TableColumnsType,
  TableColumnType,
  Tag,
} from "antd";
import {
  CloseCircleOutlined,
  CopyOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useLocation, useNavigate } from "react-router-dom";
import { EmptyData } from "../../../components/common/empty";
import CustomButton from "../../../components/common/button";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../../../redux/feature/events/eventThunk";
import { StyledTable } from "../../../components/common/table/styles";
import FilterDropdown from "../../../components/common/filter";
import FilterOption from "../../../components/common/filter/FilterOption";
import SearchInput from "../../../components/common/search";
import { timeAgo } from "../../../utils/helper";
import { StyledTag } from "../../../components/common/tag/styles";
import PageLoading from "../../../components/Loader";

type EventSchemaColumnsType = {
  key: React.Key;
  name: string;
  schemaSubtitle?: string;
  is_active: boolean;
  is_draft: boolean;
  usageMeters?: string;
  attributes: string;
  dimensions: string;
  lastUpdated: string;
};

type DataIndex = keyof EventSchemaColumnsType;

const Schema = ({
  keyProp,
  currentTabKey,
}: {
  keyProp?: string;
  currentTabKey: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { events, loading } = useSelector((state: RootState) => state.event);
  console.log("🚀 ~ events:", events.length);

  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  console.log("►►► ~ Schema ~ status:", status);
  const { filter } = useSelector((state: RootState) => state.customer);
  const [searchKey, setSearchKey] = useState(searchTerm);
  const [sortValue, setSortValue] = useState("-timestamp");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const status = queryParams.get("status");
    const sortParam = queryParams.get("__sort__");
    const searchParam = queryParams.get("__search__");

    const initialValues: Record<string, any> = {};

    if (status) {
      initialValues.status = status;
    }
    setSearchKey(searchParam || "");
    setSearchTerm(searchParam || "");

    form.setFieldsValue(initialValues);
    setStatus(status || "");

    if (sortParam) {
      setSortValue(sortParam);
    }
  }, [location.search, form]);

  // useEffect(() => {
  //   if (currentTabKey === "2") {
  //     console.log("►►► ~ useEffect ~ filter.slice(2):", filter.slice(2));
  //     dispatch(
  //       getEvents({
  //         sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
  //         status: status,
  //         search: searchKey,
  //       })
  //     );
  //   }
  // }, [searchKey, currentTabKey, filter, status]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (currentTabKey === "2") {
        dispatch(
          getEvents({
            sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
            status: status,
            search: searchKey,
          })
        );
      }
    }, 100); // Debounce API calls

    return () => {
      clearTimeout(debounce);
    };
  }, [searchKey, currentTabKey, filter, status]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchKey(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const eventsDataSource = useMemo(() => {
    return events?.map((item: any) => item);
  }, [events]);

  const columns: TableColumnsType<any> = [
    {
      title: "Event Schema Name",
      dataIndex: "name",
      render: (_, { name, schemaSubtitle }) => (
        <div>
          <div style={{ fontWeight: "bold" }}>{name}</div>
          {schemaSubtitle && (
            <div style={{ fontSize: "12px", color: "#888" }}>
              {schemaSubtitle}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record: any) => {
        return (
          <StyledTag
            color={
              record.status === "ACTIVE"
                ? "green"
                : record?.status === "DRAFT"
                ? "orange"
                : "red"
            }
          >
            •{" "}
            {record.status === "ACTIVE"
              ? "Active"
              : record.status === "DRAFT"
              ? "Draft"
              : "Inactive"}
          </StyledTag>
        );
      },
    },
    {
      title: "Usage Meters",
      dataIndex: "usageMetersCount",
      render: (_, { usageMetersCount }) => usageMetersCount ?? 0,
    },
    {
      title: "Attributes",
      dataIndex: "attributes",
      render: (_, { attributes }) => attributes?.length,
    },
    {
      title: "Dimensions",
      dataIndex: "dimensions",
      render: (_, { dimensions }) => dimensions?.length,
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      render: (text: any) => {
        return timeAgo(text);
      },
    },
  ];

  const options = [
    {
      value: "-updated_at",
      label: "Updated At Descending",
    },
    {
      value: "2Bupdated_at",
      label: "Updated At Ascending",
    },
    {
      value: "-name",
      label: "Schema Name At Ascending",
    },
    {
      value: "2Bname",
      label: "Schema Name Ascending",
    },
  ];

  const onSubmitFilter = async () => {
    try {
      const values = await form.validateFields();
      setStatus(values.status);
      if (values.status) {
        navigate(
          `/event-schemas?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}&` : "&"
          }status=${values.status}`
        );
      } else {
        navigate(
          `/event-schemas?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}` : ""
          }`
        );
      }
    } catch (error) {}
  };

  const resetFilter = () => {
    form.resetFields();
  };

  return (
    <div>
      <div style={{ marginBottom: "1rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SearchInput
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value) {
                navigate(
                  `/event-schemas?__sort__=${filter}${
                    status ? `&status=${status}&` : "&"
                  }__search__=${e.target.value}`
                );
              } else {
                navigate(
                  `/event-schemas?__sort__=${filter}${
                    status ? `&status=${status}` : ""
                  }`
                );
              }
            }}
            TooltipSupportedFields="Name"
          />
          <div style={{ display: "flex", gap: "5px" }}>
            {status ? (
              <CustomButton
                text="Clear Filter"
                type="link"
                icon={false}
                onClick={() => {
                  setStatus("");
                  form.resetFields();
                  navigate(
                    `/event-schemas?__sort__=${filter}${
                      searchTerm ? `&__search__=${status}` : ""
                    }`
                  );
                }}
              />
            ) : (
              <></>
            )}
            <FilterDropdown
              onSubmitFilter={onSubmitFilter}
              isFilterApplied={status ? true : false}
              resetFilter={resetFilter}
            >
              <Form layout="vertical" form={form}>
                <Form.Item label="Status" name="status">
                  <Select placeholder="Status">
                    <Select.Option value="ACTIVE">Active</Select.Option>
                    <Select.Option value="DRAFT">Draft</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </FilterDropdown>
            <FilterOption
              options={options}
              defaultValue={sortValue}
              key={sortValue}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <StyledTable
          pagination={false}
          columns={columns}
          loading={true}
          dataSource={[]}
          size="middle"
        />
      ) : events?.length > 0 ? (
        <StyledTable
          pagination={false}
          columns={columns}
          loading={loading}
          dataSource={eventsDataSource}
          size="middle"
          onRow={(record: any) => ({
            onClick: () => {
              console.log(record);
              navigate(`/events/${record.name}`);
            },
          })}
        />
      ) : (
        <EmptyData
          description="No Schemas Found"
          btnText="New Event Schema"
          onClick={() => navigate("/events/create")}
        />
      )}
    </div>
  );
};

export default Schema;
