import React, { useState } from "react";
import { StyledRadio } from "./styles";
import AddonItem from "./AddonItem";
import Extra from "../../../../../components/common/extra";
import { Col, Form, Input, Radio, Row, Select } from "antd";
import GroupByTag from "../../../../../components/GroupTag.tsx";
import { CreditGrantFormContainer } from "./CreditGrant/Styles";
import { useSelector } from "react-redux";

const AddPrice: React.FC<any> = ({ tags,expiryType }) => {
  const { billingDetails } = useSelector((state: any) => state.pricePlan);
  const [invoiceTiming,setInvoiceTiming]=useState('IN_ARREARS')
  return (
    <CreditGrantFormContainer>
      <Form.Item
        label="Invoice Association"
        name="invoiceTiming"
        extra={
          <Extra text={invoiceTiming !== "PREPAID" ?`Credits fee will be billed in the ${invoiceTiming === 'IN_ADVANCE'? "previous invoice." : 'current invoice.'}` : "Credits will be granted only after invoice is paid."} />
        }
      >
        <StyledRadio defaultValue={"IN_ARREARS"} onChange={(e:any)=>{setInvoiceTiming(e.target.value)}}>
          <Radio value="IN_ARREARS">In Arrears</Radio>
          <Radio value="IN_ADVANCE">In Advance</Radio>
          {expiryType !== "PRICING_CYCLE" && (
          <Radio value="PREPAID">Prepaid</Radio>
          )}
        </StyledRadio>
      </Form.Item>
      {billingDetails?.supportedCurrencies.length > 0 &&
        billingDetails?.supportedCurrencies.map((currency: any) => (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={`${currency} Price`}
                name={`${currency}-Price`}
                rules={[
                  {
                    required: true,
                    message: "Please input the price!",
                  },
                ]}
              >
                <Input type="number" placeholder={`${currency} price`}  width={"50%"} onWheel={(e:any) => e.target.blur()}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={`${currency} credits grant`}
                name={`${currency}-creditGrant`}
                rules={[
                  {
                    required: true,
                    message: "Please input the credit grant!",
                  },
                ]}
              >
                <Input type="number" placeholder={`${currency} credits grant`} onWheel={(e:any) => e.target.blur()}/>
              </Form.Item>
            </Col>
          </Row>
        ))}
      <GroupByTag />
    </CreditGrantFormContainer>
  );
};

export default AddPrice;
