import React, { useEffect, useMemo, useState } from "react";
import { Card, Typography, Row, Col, Divider, Avatar, Tag } from "antd";
import {
  MoreOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  CopyOutlined,
  ExportOutlined,
  SettingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { Label, StyledCard, StyledRow } from "./styles";
import Calculation from "./Calculation";
import CustomDrawer from "../../../components/common/drawer";
import NewSettingForm from "../../admin-center/setting/form";
import {
  EmptyData,
  EmptyDataComponent,
} from "../../../components/common/empty";
import PricingRuleLogCard from "./pricingRuleLogCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import PageLoading from "../../../components/Loader";
import moment from "moment";
import UserAvatar from "../../../components/common/avatar";
import { capitalizeFirstLetter, copyToClipboard, formatDateToLocalTime } from "../../../utils/helper";
import { getInvoiceById } from "../../../redux/feature/billing/billingThunk";
import BackPageHeader from "../../../components/common/BackPageHeader";
import { BillingInvoice } from "../../../components/common/pdfFormat/billingInvoice";
import { getBillingInfo } from "../../../redux/feature/adminCenter/adminCenterAsyncThunk";
import { StyledTag } from "../../../components/common/tag/styles";
const { Text } = Typography;

const Details: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isRuleDrawerOpen, setIsRuleDrawerOpen] = useState(false);
  const drawerHandler = () => {
    setIsRuleDrawerOpen(!isRuleDrawerOpen);
  };
  const { selectedInvoice, loading } = useSelector(
    (state: RootState) => state.billing
  );
  const { id } = useParams();
  const {
    AccountCurrency,
    billingInfo: { bills, loading: infoLoading },
  } = useSelector((state: RootState) => state.adminCenter);
  useEffect(() => {
    if (!bills?.length) {
      dispatch(getBillingInfo());
    }
  }, []);

  const baseCurrency = useMemo(() => {
    return AccountCurrency.find((currency: any) => currency.is_base);
  }, [AccountCurrency, selectedInvoice]);

  const totalAmount = useMemo(() => {
    const baseItems = selectedInvoice?.items || [];

    const total = baseItems.reduce((acc: number, item: any) => {
      const itemTotal = parseFloat(item?.total_value || "0");
      return acc + itemTotal;
    }, 0);

    return total.toFixed(2);
  }, [selectedInvoice, baseCurrency]);

  console.log("Total Amount111111111111111111:", totalAmount);

  useEffect(() => {
     dispatch(getInvoiceById(id));
  }, [id]);

  return loading && !selectedInvoice ? (
    <PageLoading />
  ) : (
    <div>
      <BackPageHeader
        actions={[
          {
            label: "Download as PDF",
            icon: <DownloadOutlined />,
            onClick: () => BillingInvoice(selectedInvoice, bills),
          },
          // { label: "Preview PDF", icon: <EyeOutlined /> },
          { label: "Pricing Rule Logs", 
            icon: <EyeOutlined />,
            onClick: drawerHandler },
        ]}
      />
      <StyledRow gutter={32}>
        <Col span={16}>
          <StyledCard className="detailCard">
            <div className="orderDetailContainer">
              <div className="orderDetailHeader">
                <h3>Order Details</h3>
                <div className="orderDetailItem">
                  <Label>Sequence Id</Label>
                  <p>{selectedInvoice?.sequence_id}</p>
                  <CopyOutlined
                    className="copy"
                    onClick={() => {
                      copyToClipboard(selectedInvoice?.sequence_id);
                    }}
                  />
                </div>
              </div>
              <div>
                {" "}
                <StyledTag
                  color={
                    selectedInvoice?.status === "PAID"
                      ? "green"
                      : selectedInvoice?.status === "DUE"
                      ? "purple"
                      : selectedInvoice?.status === "PARTIALLY_PAID"
                      ? "orange"
                      : selectedInvoice?.status === "DRAFT"
                      ? "gold"
                      : "red"
                  }
                >
                  {capitalizeFirstLetter(selectedInvoice?.status)}
                </StyledTag>
              </div>
            </div>
            <Row gutter={12} className="cardContainer">
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Price Plan</Label>
                  <p>{selectedInvoice?.price_plan_id}</p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Start Date</Label>
                  <p>
                    {moment(selectedInvoice?.start_date).format("MMM DD YYYY")}
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="cardItem">
                  <Label>End Date</Label>
                  <p>
                    {/* {moment().format("MMM DD YYYY")} */}
                    {selectedInvoice?.end_date}
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Due Date</Label>
                  <p>
                    {moment(selectedInvoice?.due_date).format("MMM DD YYYY")}
                  </p>
                </Card>
              </Col>
            </Row>
          </StyledCard>

          <Calculation
            selectedInvoice={selectedInvoice}
            baseCurrency={baseCurrency}
          />
        </Col>

        <Col span={8}>
          <StyledCard>
            <div className="dueDetails">
              <p>Total Due</p>
              <div className="item">
                <p>{selectedInvoice?.total_amount ?? "0"}</p>
                <span className="currency">
                  {selectedInvoice?.currency_code}
                </span>
                {selectedInvoice?.status !== "DUE" && (
                  <StyledTag color="gold">• Draft Total</StyledTag>
                )}
              </div>
            </div>
            <Divider style={{ margin: 0, marginBottom: "0.5rem" }} />
            <div className="dueDetails">
              <p>Paid till now</p>
              <div className="item">
                <p>{selectedInvoice?.paid_amount}</p>
                <span className="currency">
                  {selectedInvoice?.currency_code}
                </span>
              </div>
            </div>
            <div className="timing">
              <p>
                Last Updated :{" "}
                {formatDateToLocalTime(selectedInvoice?.updated_at)}
              </p>
              <p>
                Generated At :
                {formatDateToLocalTime(selectedInvoice?.created_at)}
              </p>
            </div>
          </StyledCard>

          <StyledCard>
            <p>CUSTOMER DETAILS</p>
            <Divider className="detailDivider" />
            <Row align="middle" style={{gap:'8px'}}>
              <Col>
                <UserAvatar user={selectedInvoice} />
              </Col>
              <Col >
                <Text>{selectedInvoice?.customer_name}</Text>
                <br />
                <Text type="secondary">{selectedInvoice?.customer?.email}</Text>
              </Col>
            </Row>
            <Divider className="detailDivider" />
            <div>
              <div className="details">
                <p>Customer Id:</p>
                <p className="value">
                  {selectedInvoice?.customer_id}{" "}
                  <CopyOutlined
                    className="copy"
                    onClick={() => {
                      copyToClipboard(selectedInvoice?.customer_id);
                    }}
                  />
                </p>
              </div>
              <div className="details">
                <p>Account:</p>
                <a
                  href={`/customers/${selectedInvoice?.customer_id}/accounts/${selectedInvoice?.customer_account_id}/details`}
                  className="value-link"
                  target="_blank"
                >
                  {selectedInvoice?.customer_account_name}{" "}
                  <ExportOutlined className="copy" />
                </a>
              </div>
              <div className="details">
                <p>Account ID:</p>
                <p className="value">
                  {selectedInvoice?.customer_account_id}{" "}
                  <CopyOutlined
                    className="copy"
                    onClick={() => {
                      copyToClipboard(selectedInvoice?.customer_account_id);
                    }}
                  />
                </p>
              </div>
              <div className="details">
                <p>Price Plan:</p>
                <a
                  href={`/price-plan/${selectedInvoice?.price_plan_id}`}
                  className="value-link"
                  target="_black"
                >
                  {selectedInvoice?.price_plan_id}{" "}
                  <ExportOutlined className="copy" />
                </a>
              </div>
            </div>
          </StyledCard>

          <StyledCard>
            <p>BILL TO</p>
            <Divider className="detailDivider" />
            <div>
              <div className="details">
                <p>Legal Name</p>
                <p className="value">
                  {selectedInvoice?.customer_account_name}
                </p>
              </div>
              <div className="details">
                <p>Billing Email</p>
                <p className="value">{selectedInvoice?.customer?.email}</p>
              </div>
              <div className="details">
                <p>Address</p>
                <p className="value">
                  {selectedInvoice?.customer?.line1},
                  {selectedInvoice?.customer?.line2 &&
                    selectedInvoice?.customer?.line2 + ", "},
                  {selectedInvoice?.customer?.city},{" "}
                  {selectedInvoice?.customer?.state}{" "}
                  {selectedInvoice?.customer?.postalCode},
                  {selectedInvoice?.customer?.country}
                </p>
              </div>
            </div>
          </StyledCard>
        </Col>
      </StyledRow>
      <CustomDrawer
        isOpen={isRuleDrawerOpen}
        onClose={drawerHandler}
        title="Pricing Rule Logs"
        isActionButtonsShown={false}
        width={500}
      >
        <div style={{ padding: "20px" }}>
          <PricingRuleLogCard />
        </div>
      </CustomDrawer>
    </div>
  );
};

export default Details;
