import { DatePicker } from "antd";
import styled from "styled-components";

export const InvoicesContainer = styled.div`
  border-radius: 10px;
  padding: 0.5rem 0rem;

  .filter {
    display: flex;
    gap: 1rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .ant-form-item {
      margin-bottom: 5px !important;
    }
  }
  .dueInvoice {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 2rem;
  }
`;
export const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 240px;
  cursor: default !important;
  
  

  &.ant-picker-disabled {
    background-color: white !important;
    color: #475569 !important;
    .ant-picker-input > input {
      color: #475569 !important;
    }
  }

  &.ant-picker-disabled:hover {
    cursor: default !important;

    .ant-picker-input > input {
      cursor: default !important;
    }
  }
`;

export const DetailedDiv = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    font-weight: 600;
  }
`;
