import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Input, Select, Typography } from "antd";
import { Form } from "antd";
import { useMemo, useState } from "react";
import Modal from "../../../../components/common/modal";
import { ModalBodyContainer, MOdalTitleContainer } from "./Styles";
import { useDispatch, useSelector } from "react-redux";
import {
  createAdditionalCurrency,
  createBaseCurrency,
  getAccountCurrency,
} from "../../../../redux/feature/adminCenter/adminCenterAsyncThunk";
import { AppDispatch, RootState } from "../../../../redux/store";

const { Text } = Typography;
const { Option } = Select;

const CurrencyModel: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  isBaseCurrency: boolean;
}> = ({ isOpen, onClose, isBaseCurrency }) => {
  const [form] = Form.useForm();
  const { currencies, AccountCurrency, loading } = useSelector(
    (state: RootState) => state.adminCenter
  );
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedCurrency, setSelectedCurrency] = useState();


  const baseCurrency=useMemo(()=>{
    return AccountCurrency?.find((item:any)=>item.is_base)
  },[AccountCurrency])
  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (isBaseCurrency)
        await dispatch(
          createBaseCurrency({
            currencyId: values.currency_id,
          })
        );
      if (!isBaseCurrency)
        await dispatch(
          createAdditionalCurrency({
            currencyId: values.currency_id,
            exchangeRate: values.exchange,
          })
        );
      await dispatch(getAccountCurrency());
      onClose();
      form.resetFields();
    } catch (error) {
      console.log("►►► ~ onSubmit ~ error:", error);
    }
  };

  const handleFilterOption = (input: any, option: any) => {
    if (!option) return false;
    const optionText = option.children
      ? option.children.toString().toLowerCase()
      : "";
    return optionText.includes(input.toLowerCase());
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    if (isBaseCurrency) {
      setDisable(!allValues.currency_id);
    } else {
      setDisable(!allValues.currency_id || !allValues.exchange);
    }
  };
  return (
    <Modal
      title={
        <MOdalTitleContainer>
          <div className="title">
            {isBaseCurrency ? "Set Currency" : "Add New Currency"}
          </div>
          {isBaseCurrency ? (
            <div className="description">
              Please choose your base currency to get started with BillOptima
            </div>
          ) : (
            <></>
          )}
        </MOdalTitleContainer>
      }
      isOpen={isOpen}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      successBtnTitle={isBaseCurrency ? "Submit" : "Add New Currency"}
      onSubmit={onSubmit}
      isCancelable={!isBaseCurrency}
      loading={loading}
      isSuccessBtnDisable={disable}
    >
      <ModalBodyContainer>
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            label="Choose Currency"
            name="currency_id"
            rules={[
              { required: true, message: "Please input Currency First!" },
            ]}
          >
            <Select
              placeholder="Choose Currency"
              showSearch
              filterOption={handleFilterOption}
              onChange={(value, option) => {
                setSelectedCurrency(option.code);
              }}
            >
              {currencies
                ?.filter(
                  (currency: any) =>
                    !AccountCurrency?.map(
                      (ac: any) => ac?.currency_id
                    ).includes(currency?.currency_id)
                )
                .map((currency: any) => (
                  <Option
                    key={currency?.currency_id}
                    value={currency?.currency_id}
                    code={currency.currency_code}
                  >
                    {currency.currency_code} - {currency.currency_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {!isBaseCurrency && (
            <>
              <Form.Item
                label="Exchange Rate"
                name="exchange"
                rules={[
                  {
                    required: true,
                    message: "Please input Exchange Rate First!",
                  },
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Please enter a valid number!",
                  },
                ]}
              >
                <Input
                  size="small"
                  suffix={
                    <Text
                      style={{
                        color: "rgba(0,0,0,0.5)",
                        marginRight: "0.5rem",
                      }}
                    >
                      {baseCurrency?.currency_code}
                    </Text>
                  }
                  prefix={
                    <Text
                      style={{
                        color: "rgba(0,0,0,0.5)",
                        marginRight: "0.5rem",
                      }}
                    >
                      {`${selectedCurrency ? `1 ${selectedCurrency}= `:''}`}
                    </Text>
                  }
                  placeholder="Exchange Rate"
                  type="text"
                />
              </Form.Item>

              <Alert
                message={
                  <Text>
                    This exchange rate <Text strong>cannot be changed</Text>{" "}
                    once set.
                  </Text>
                }
                type="warning"
                icon={<InfoCircleOutlined />}
                showIcon
                style={{
                  backgroundColor: "#FFFBE6",
                  border: "none",
                  padding: "1.5rem",
                }}
              />
            </>
          )}
        </Form>
      </ModalBodyContainer>
    </Modal>
  );
};

export default CurrencyModel;
