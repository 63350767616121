import React, { useEffect, useMemo, useState } from "react";
import { Container } from "../BillingDetails/Styles";
import { RateCardHeader } from "./Styles";
import {
  CheckCircleOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileProtectOutlined,
  LineChartOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Menu,
  Dropdown,
  Row,
  Col,
} from "antd";
import EditPlan from "./EditPlan";
import { EmptyDataComponent } from "../../../../components/common/empty";
import { useDispatch, useSelector } from "react-redux";
import {
  removeBillingRateCard,
  setIsOverridePlan,
  setPlanModalDetail,
} from "../../../../redux/feature/pricePlan/pricePlanSlice";
import { AppDispatch, RootState } from "../../../../redux/store";
import { getAddons } from "../../../../redux/feature/billableItems/billableThunk";
import TextedSwitch from "../../../../components/common/textedSwitch";
import PricePlanDetailsTable from "../PricePlanTable";
import FilterOption from "../../../../components/common/filter/FilterOption";
import SearchInput from "../../../../components/common/search";
import Segment from "../../../../components/common/segmented";
import { useLocation, useNavigate } from "react-router-dom";

const menuItems: any = {
  "1": { label: "Fixed Fee", value: "FIXED_FEE", icon: <DollarOutlined /> },
  "2": {
    label: "Usage Based Fee",
    value: "USAGE_BASED_FEE",
    icon: <LineChartOutlined />,
  },
  "3": {
    label: "Credit Grant Fee",
    value: "CREDIT_GRANT",
    icon: <CreditCardOutlined />,
  },
  "4": {
    label: "License Fee",
    value: "LICENSE",
    icon: <FileProtectOutlined />,
  },
  "5": {
    label: "Entitlement Fee",
    value: "ENTITLEMENT_FEE",
    icon: <CheckCircleOutlined />,
  },
  "6": {
    label: "Entitlement Overage",
    value: "ENTITLEMENT_OVERAGE",
    icon: <DollarOutlined />,
  },
};

const RateCard: React.FC<any> = ({
  form,
  isAttachPricePlan = false,
  isMidCyclePricing,
  pricePlanType,
  editAttachPlan,
  isNew,
  proceedBtnDisabled,
  currency,
}) => {
  console.log("►►► ~ editAttachPlan:", editAttachPlan);
  const [rateCards, setRateCards] = useState<any>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  console.log("►►► ~ rateCards1234:", rateCards);
  const dispatch = useDispatch<AppDispatch>();
  const {
    billingDetails,
    planModalDetail,
    singlePricePlan,
    overRideRateCards,
    isOverridePlan,
  } = useSelector((state: RootState) => state.pricePlan);

  const { addOns, loading, error } = useSelector(
    (state: RootState) => state.billable
  );
  const { AccountCurrency } = useSelector(
    (state: RootState) => state.adminCenter
  );
  const { currency_code } = AccountCurrency.find((item: any) => {
    return item.is_base === true;
  });
  useEffect(() => {
    if (addOns?.length === 0) {
      dispatch(
        getAddons({
          sort: "-updated_at",
        })
      );
    }
  }, [dispatch, addOns?.length]);

  useEffect(() => {
    let updatedRateCards;

    if (isAttachPricePlan) {
      if (isOverridePlan) {
        const { rateCards, ...rest } = singlePricePlan;
        updatedRateCards = {
          rateCards: overRideRateCards,
          ...rest,
        };
      } else {
        updatedRateCards = singlePricePlan;
      }
    } else {
      updatedRateCards = billingDetails || {};
    }
    setRateCards(updatedRateCards);
  }, [
    billingDetails?.rateCards?.length,
    billingDetails,
    isAttachPricePlan,
    editAttachPlan,
    overRideRateCards,
  ]);

  useEffect(() => {
    if (rateCards?.rateCards?.length <= 0) {
      proceedBtnDisabled(true);
    } else {
      proceedBtnDisabled(false);
    }
  }, [rateCards?.rateCards?.length]);

  const [selectedCurrency, setSelectedCurrency] = useState(
    billingDetails?.supportedCurrencies?.[0] || ""
  );

  const filteredMenuItems = useMemo(
    () =>
      pricePlanType === "ONE_TIME"
        ? ["1", "3", "5"].reduce(
            (acc, key) => ({ ...acc, [key]: menuItems[key] }),
            {}
          )
        : isMidCyclePricing
        ? ["1", "2", "4"].reduce(
            (acc, key) => ({ ...acc, [key]: menuItems[key] }),
            {}
          )
        : menuItems,
    [isMidCyclePricing, pricePlanType]
  );

  const handleMenuClick = (e: any) => {
    dispatch(setPlanModalDetail(menuItems[e.key]?.value));
  };

  const handleCardRemove = (record: any) => {
    dispatch(removeBillingRateCard(record?.uniqueID));
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {Object.keys(filteredMenuItems).map((key) => (
        <Menu.Item key={key} icon={menuItems[key].icon}>
          <span>{menuItems[key].label}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const RateCardDropDown = (isBtn: boolean = true) => {
    return (
      <Dropdown overlay={menu} trigger={["hover"]}>
        {isBtn ? (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            title="  Add Rate Card"
          >
            Add Rate Card
          </Button>
        ) : (
          <div className="addRateCard">
            <PlusOutlined />
            Add Rate Card
          </div>
        )}
      </Dropdown>
    );
  };

  const handleOverride = () => {
    dispatch(setIsOverridePlan(!isOverridePlan));
  };

  const options = [
    {
      value: "-rateCardDetails.displayName",
      label: "Rate Card Name Descending",
    },
    {
      value: "2BrateCardDetails.displayName",
      label: "Rate Card Name Ascending",
    },
    {
      value: "-type",
      label: "Rate Card Type  Descending",
    },
    {
      value: "2Btype",
      label: "Rate Card Type Ascending",
    },
  ];

  const renderPricePlanDetailsTable = () => (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SearchInput
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value) {
              searchParams.set("__search__", e.target.value);
            } else {
              searchParams.delete("__search__");
            }
            navigate({
              pathname: location.pathname,
              search: `?${searchParams.toString()}`,
            });
          }}
          TooltipSupportedFields="Name"
        />
        <FilterOption
          options={options}
          defaultValue="-rateCardDetails.displayName"
        />
      </div>
      {/* <Row gutter={24}> */}
        <div style={{border:'1px solid #cbd5e1 ',borderRadius:"10px",overflowY: "scroll", maxHeight: "640px",scrollbarWidth:'none' }}>
          <PricePlanDetailsTable
            setRateCards={setRateCards}
            rateCards={rateCards}
            isAttachPricePlan={isAttachPricePlan}
            selectedPricePlan={singlePricePlan?.pricePlanId}
            currency={isAttachPricePlan ? currency : selectedCurrency}
            searchTerm={searchTerm}
            isNew={isNew}
            pagination={false}
          />
        </div>
      {/* </Row> */}
    </div>
  );

  return (
    <>
      <Container>
        <RateCardHeader>
          {" "}
          <div className="title">RATE CARDS</div>
          <div className="rateCardLeftHeader">
            {isAttachPricePlan && (
              <>
                {isOverridePlan && (
                  <>
                    <div
                      className="addRateCard"
                      onClick={() =>
                        dispatch(setPlanModalDetail("Plan Minimum"))
                      }
                    >
                      <PlusOutlined /> Plan Minimum
                    </div>
                    {RateCardDropDown(false)}
                  </>
                )}
                <TextedSwitch
                  text="Override Rate Cards"
                  onChange={handleOverride}
                  value={isOverridePlan}
                  checked={isOverridePlan}
                />
              </>
            )}

            {!isAttachPricePlan && (
              <>
                {pricePlanType === "RECURRING" && (
                  <div
                    className="addRateCard"
                    onClick={() => dispatch(setPlanModalDetail("Plan Minimum"))}
                  >
                    <PlusOutlined /> Plan Minimum
                  </div>
                )}
                {RateCardDropDown(false)}
              </>
            )}
          </div>
        </RateCardHeader>
        <div
          style={{ padding: "16px 24px"}}
        >
          {rateCards?.rateCards?.length || isAttachPricePlan ? (
            <>
              {billingDetails?.supportedCurrencies?.length > 1 &&
                !isAttachPricePlan && (
                  <div style={{ marginBottom: "1rem" }}>
                    <Segment
                      options={billingDetails?.supportedCurrencies || []}
                      value={selectedCurrency}
                      onChange={setSelectedCurrency}
                    />
                  </div>
                )}
              {renderPricePlanDetailsTable()}
            </>
          ) : (
            <EmptyDataComponent
              addButton={RateCardDropDown}
              title="No Pricing Rules configured"
              description="You can set rules for usage or revenue computation for a rate card conditionally."
            />
          )}
        </div>
      </Container>
      <EditPlan
        planModalDetail={planModalDetail}
        setPlanModalDetail={setPlanModalDetail}
        rateCards={rateCards}
      />
    </>
  );
};

export default RateCard;
