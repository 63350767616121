import { Button, Col, Divider, message, Row } from "antd";
import { CustomDivider, ProfileContainer } from "./style";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useCallback, useMemo, useState } from "react";
import EditProfile from "./EditProfile";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { TabHeader } from "../Styles";
import PageLoading from "../../../components/Loader";
import CustomButton from "../../../components/common/button";
import GoogleLoginButton from "../../../components/common/GoogleLoginButton";
import GoogleLogo from "../../../assets/google";
import { CredentialResponse } from "@react-oauth/google";
import { getUser } from "../../../redux/feature/auth/authThunk";
import api from "../../../api/apiClient";
import { formatDateToLocalTime } from "../../../utils/helper";

type ProfileDataItem = {
  title: string;
  value: string;
};

const UserProfile = () => {
  const [editProfileType, setEditProfileType] = useState<string | null>(null);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { user: userData, loading: isUserLoading } = useSelector(
    (state: RootState) => state.auth
  );

  const handleGoogleLoginSuccess = async (
    credentialResponse: CredentialResponse
  ) => {
    setIsGoogleLoading(true);
    try {
      await api.post("/google/login", {
        token: credentialResponse.credential,
        connect: true,
        loggedInEmail: userData?.email,
      });
      dispatch(getUser());
    } catch (error) {
      console.error("Google login error", error);
      message.error("Google login failed");
    } finally {
      setIsGoogleLoading(false);
    }
  };

  const getProfileData: ProfileDataItem[] = useMemo(() => {
    if (!userData) return [];
    return [
      { title: "Full Name", value: userData.name },
      { title: "Email", value: userData.email },
      { title: "Company Name", value: userData.company_name },
      { title: "Account Creation", value: formatDateToLocalTime(userData.created_at) },
      { title: "Timezone", value: userData.timezone },
    ];
  }, [userData]);

  const handlePasswordChangeClick = useCallback(() => {
    const profileType =
      userData?.is_sso && !userData.passwordSet ? "Set Password" : "Change Password";
    setEditProfileType(profileType);
  }, [userData]);

  const renderProfileInfo = () => (
    <Row>
      <Col span={12}>
        <h5>BASIC INFO</h5>
      </Col>
      <Col span={12}>
        {getProfileData.map((item) => (
          <div key={item.title}>
            <div className="basicInfoContainer">
              <div className="infoDescription">
                <div className="profile-details__title">{item.title}</div>
                <div className="profile-details__value">{item.value}</div>
              </div>
              {item.title !== "Email" && item.title !== "Account Creation" && (
                <EditOutlined
                  style={{ fontSize: "16px" }}
                  onClick={() => setEditProfileType(item.title)}
                />
              )}
            </div>
            {item.title !== "Timezone" && <CustomDivider variant="solid" />}
          </div>
        ))}
      </Col>
    </Row>
  );

  const renderPasswordSection = () => (
    <Row>
      <Col span={12}>
        <h5>PASSWORD</h5>
      </Col>
      <Col span={12}>
        <CustomButton
          block={true}
          text={userData?.is_sso && !userData.passwordSet ? "Set Password" : "Change Password"}
          type="default"
          icon={<ArrowRightOutlined />}
          position="end"
          onClick={handlePasswordChangeClick}
        />
      </Col>
    </Row>
  );

  const renderContactSection = () => (
    <Row>
      <Col span={12}>
        <h5>CONTACT WITH</h5>
      </Col>
      <Col span={12}>
        {userData?.is_sso ? (
          <Button
            block
            type="default"
            icon={
              <div
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <GoogleLogo />
                <CheckCircleOutlined style={{ color: "green" }} />
              </div>
            }
            style={{ marginBottom: 20 }}
          >
            <p className="btn-text">Google</p>
          </Button>
        ) : (
          <GoogleLoginButton
            onGoogleLoginSuccess={handleGoogleLoginSuccess}
            loading={isGoogleLoading}
            width="100%"
            text="Google"
          />
        )}
      </Col>
    </Row>
  );

  if (!userData) return null;

  if (isUserLoading) return <PageLoading />;

  return (
    <ProfileContainer>
      <TabHeader>Profile Details</TabHeader>
      <div className="description">
        {renderProfileInfo()}
        <Divider variant="dashed" style={{ borderColor: "black", margin: "24px 0" }} />
        {renderPasswordSection()}
        <Divider variant="dashed" style={{ borderColor: "black", margin: "24px 0" }} />
        {renderContactSection()}
      </div>
      <EditProfile editProfile={editProfileType} setEditProfile={setEditProfileType} />
    </ProfileContainer>
  );
};

export default UserProfile;