import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  activeUsageMeter,
  createAddons,
  createUsageMeters,
  getAddons,
  getUsageMeter,
  getUsageMeters,
  updateAddons,
} from "./billableThunk";

export interface AuthState {
  addOns: any;
  usageMeters: any;
  currentUsageMeter: any;
  loading: boolean;
  isGetUsageMeter:boolean;
  isGetAddons:boolean;
  isAddonCreated:boolean;
  error: string | null;
}

const initialState: AuthState = {
  addOns: [],
  usageMeters: [],
  currentUsageMeter: {},
  isGetUsageMeter: false,
  isGetAddons: false,
  isAddonCreated:false,
  loading: false,
  error: null,
};

const billableSlice = createSlice({
  name: "billable",
  initialState,
  reducers: {
    setEmptyCurrentUsageMeter:(state,action)=>{
      state.currentUsageMeter={}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddons.pending, (state, action) => {
        state.isGetAddons = true;
        state.loading = true;
        state.error = null;
      })
      .addCase(getAddons.fulfilled, (state, action) => {
        state.loading = false;
        state.isGetAddons = false;
        state.addOns = action.payload;
      })
      .addCase(getAddons.rejected, (state, action: any) => {
        state.isGetAddons = false;
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createAddons.pending, (state, action) => {
        state.isAddonCreated = true;
        state.error = null;
      })
      .addCase(createAddons.fulfilled, (state, action) => {
        state.isAddonCreated = false;
      })
      .addCase(createAddons.rejected, (state, action: any) => {
        state.isAddonCreated = false;
        state.error = action.payload;
      })
      .addCase(updateAddons.pending, (state, action) => {
        state.isGetAddons = true;
        state.error = null;
      })
      .addCase(updateAddons.fulfilled, (state, action) => {
        state.isGetAddons = false;
        state.addOns = state.addOns.map((addOn: any) => {
          if (addOn?.addOnId === action.payload?.add_on_id) {
            return { ...addOn, billableName:action.payload?.billable_name,name:action.payload?.name };
          }
          return addOn;
        });
      })
      .addCase(updateAddons.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(activeUsageMeter.fulfilled, (state, action) => {
        state.usageMeters = state.usageMeters.map((usage: any) => {
          if (usage?.usageMeterId === action.payload?.usageMeterId) {
            const updatedUsageMeter = { ...usage, status: "ACTIVE" };
            state.currentUsageMeter = updatedUsageMeter;
            return updatedUsageMeter;
          }
          return usage;
        });
      })
      .addCase(getUsageMeters.pending, (state, action) => {
        state.isGetUsageMeter = true;
      })
      .addCase(getUsageMeters.fulfilled, (state, action) => {
        state.isGetUsageMeter = false;
        state.usageMeters = action.payload;
      })
      .addCase(getUsageMeters.rejected, (state, action) => {
        state.isGetUsageMeter = false;
        state.loading = false;
      })
      .addCase(createUsageMeters.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createUsageMeters.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createUsageMeters.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getUsageMeter.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUsageMeter.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUsageMeter = action.payload;
      })
      .addCase(getUsageMeter.rejected, (state, action: any) => {
        state.loading = false;
      });
  },
});

export const { setEmptyCurrentUsageMeter } = billableSlice.actions;

export const billableReducer = billableSlice.reducer;
