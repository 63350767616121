import styled from "styled-components";

export const NewFieldContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: end;
    gap: 1rem;
    margin-bottom: 2rem;
  .antFormItem{
    margin: 0;
  }
    .ant-form-item-row{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    }
    .icon{
      border: 1px solid rgba(0,0,0,0.1);
      background-color: white;
      // padding: 5px 8px;
      border-radius: 10px;
      height:30px;
      width: 30px;
      svg{
        margin:0 !important;
      }
    }
`;