import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { Container } from "../customer/table/Styles";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { setFilter } from "../../redux/feature/customer/customerSlice";
import FilterOption from "../../components/common/filter/FilterOption";
import FilterDropdown from "../../components/common/filter";
import CustomButton from "../../components/common/button";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Select } from "antd";
import SearchInput from "../../components/common/search";

export const BillableItemsHeader: React.FC<any> = ({
  searchTerm,
  setSearchTerm,
  onSubmitFilter,
  handleClearFilter,
  status,
  setStatus,
  formFilter,
  isUsage,
  onSearch,
  onSearchHandle,
  currentTabKey,
}) => {
  const navigate = useNavigate();
  const { filter } = useSelector((state: RootState) => state.customer);
  const location = useLocation();
  const [sortValue, setSortValue] = useState("-updated_at");

  const options = [
    {
      value: "-updated_at",
      label: "Updated At Descending",
    },
    {
      value: "2Bupdated_at",
      label: "Updated At Ascending",
    },
    {
      value: "-name",
      label: "Name Ascending",
    },
    {
      value: "2Bname",
      label: "Name Descending",
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const status = queryParams.get("type");
    const sortParam = queryParams.get("__sort__");

    const initialValues: Record<string, any> = {};

    if (status) {
      initialValues.status = status;
    }

    formFilter.setFieldsValue(initialValues);
    setStatus(status || "");

    if (sortParam) {
      setSortValue(sortParam);
    }
  }, [location.search, formFilter]);

  const resetFilter = () => {
    formFilter.resetFields();
  };

  const getOptions = () => (isUsage ? options.slice(0, 2) : options);
  return (
    <Container>
      <SearchInput
        value={searchTerm}
        onChange={onSearchHandle}
        TooltipSupportedFields="Name"
      />
      <div style={{ display: "flex", gap: "5px" }}>
        {status && (
          <CustomButton
            text="Clear Filter"
            type="link"
            icon={false}
            onClick={handleClearFilter}
          />
        )}
        <FilterDropdown
          onSubmitFilter={onSubmitFilter}
          isFilterApplied={status ? true : false}
          resetFilter={resetFilter}
        >
          <Form layout="vertical" form={formFilter}>
            <Form.Item label={isUsage ? "Status" : "Type"} name="status">
              <Select placeholder={isUsage ? "Status" : "Type"}>
                {isUsage ? (
                  <>
                    <Select.Option value="ACTIVE">Active</Select.Option>
                    <Select.Option value="DRAFT">Draft</Select.Option>
                    <Select.Option value="INACTIVE">Inactive</Select.Option>
                  </>
                ) : (
                  <>
                    <Select.Option value="LICENSE">License</Select.Option>
                    <Select.Option value="FIXED_FEE">Fixed Fee</Select.Option>
                    <Select.Option value="CREDIT_GRANT">
                      Credit Grant
                    </Select.Option>
                  </>
                )}
              </Select>
            </Form.Item>
          </Form>
        </FilterDropdown>
        <FilterOption
          options={getOptions()}
          defaultValue={sortValue}
          key={sortValue}
        />
      </div>
    </Container>
  );
};
