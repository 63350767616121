import { useEffect, useMemo } from "react";

import Stream from "./stream";
import Schema from "./schemas";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import PageLayout from "../../components/common/pageLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { setFilter } from "../../redux/feature/customer/customerSlice";

const Events = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const { events, ingest, loading } = useSelector(
    (state: RootState) => state.event
  );
  const { filter } = useSelector((state: RootState) => state.customer);

  const currentTabKey = useMemo(() => {
    if (location.pathname === "/event-schemas") {
      return "2";
    }
    return "1";
  }, [location.pathname]);

  const items = [
    {
      label: `Events Stream`,
      key: "1",
      children: <Stream currentTabKey={currentTabKey} />,
    },
    {
      label: `Event Schemas`,
      key: "2",
      children: <Schema currentTabKey={currentTabKey} />,
    },
  ];

  const handleTabChange = (key: string) => {
    const path = key === "1" ? "/events" : "/event-schemas";
    const newFilter = path === "/events" ? "-timestamp" : "-updated_at";
    dispatch(setFilter(newFilter));
    navigate(`${path}?__sort__=${newFilter}`);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    if (currentPath === "/events") {
      searchParams.set("event-type", "billOptim-event");
    }
    navigate(`${currentPath}?${searchParams.toString()}`);
  }, [location.pathname, navigate, filter]);

  return (
    <PageLayout
      title="Events"
      activeKey={currentTabKey}
      isBtnShow={currentTabKey === "2" ? true : false}
      items={items}
      handleTabChange={handleTabChange}
      btnText={currentTabKey === "2" ? "New Event Schema" : ""}
      btnOnClick={
        currentTabKey === "2" ? () => navigate("/events/create") : () => {}
      }
    />
  );
};

export default Events;
