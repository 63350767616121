import React, { useEffect, useMemo } from "react";
import { Form, Select } from "antd";

interface ConfigItem {
  label: string;
  name: string;
  options: (number | string)[];
  value?: any;
}

interface BillingCycleConfigProps {
  billingCycle: string;
  setDates?:(prev:any)=>void;
  form?: any;
  isAttachedPlan?: boolean;
}
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const BillingCycleForm: React.FC<BillingCycleConfigProps> = ({
  billingCycle,
  setDates=()=>{},
  form,
  isAttachedPlan=false,
}) => {
  const billingCycleConfig: Record<string, ConfigItem[]> = useMemo(() => ({
    WEEKLY: [
      {
        label: "Pricing Cycle Start Day",
        name: "startOffDate",
        options: daysOfWeek, 
        value:'Monday'
      },
    ],
    MONTHLY: [
      {
        label: "Pricing Cycle Start Date",
        name: "startOffDate",
        options: [
          ...Array.from({ length: 30 }, (_, i) => (i + 1).toString()),
          "Last Day of Month",
        ],
        value:1
      },
    ],
    QUARTERLY: [
      {
        label: "Pricing Cycle Start Month",
        name: "startOffMonth",
        options: Array.from({ length: 3 }, (_, i) => (i + 1).toString()),
        value:1
      },
      {
        label: "Pricing Cycle Start Date",
        name: "startOffDate",
        options: [
          ...Array.from({ length: 30 }, (_, i) => (i + 1).toString()),
          "Last Day of Month",
        ],
        value:1

      },
    ],
    HALF_YEARLY: [
      {
        label: "Pricing Cycle Start Month",
        name: "startOffMonth",
        options: Array.from({ length: 6 }, (_, i) => (i + 1).toString()),
        value:1
      },
      {
        label: "Pricing Cycle Start Date",
        name: "startOffDate",
        options: [
          ...Array.from({ length: 30 }, (_, i) => (i + 1).toString()),
          "Last Day of Month",
        ],
        value:1
      },
    ],
    ANNUALLY: [
      {
        label: "Pricing Cycle Start Month",
        name: "startOffMonth",
        options: Array.from({ length: 12 }, (_, i) => (i + 1).toString()),
        value:1
      },
      {
        label: "Pricing Cycle Start Date",
        name: "startOffDate",
        options: [
          ...Array.from({ length: 30 }, (_, i) => (i + 1).toString()),
          "Last Day of Month",
        ],
        value:1
      },
    ],
  }), [billingCycle]);

  useEffect(() => {
    form.resetFields(['startOffDate', 'startOffMonth']);
  }, [billingCycle, form]);
  

  if (!(billingCycle in billingCycleConfig)) {
    console.error(`Invalid billing cycle: ${billingCycle}`);
    return null;
  }

  const formItems = billingCycleConfig[billingCycle];

  return (
    <>
      {formItems.map(({ label, name, options,value }, index) => (
        <Form.Item key={index} label={label} name={name}>
          <Select placeholder="Select an option" style={{ maxWidth: "500px" }} 
          defaultValue={value}
           onChange={(value: string) => {
            setDates((prev: any) => ({
              ...prev,
              ...(name === 'startOffDate' ? { pricingCycleStartDay: value } : {}),
              ...(name === 'startOffMonth' ? { pricingCycleStartMonth: value } : {}),
            }));
          }}>
            {options.map((option,index) => (
              <Select.Option key={option} value={(billingCycle === 'WEEKLY' && isAttachedPlan) ? index :option.toString()}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ))}
    </>
  );
};

export default BillingCycleForm;
