import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Table, TableColumnsType } from "antd";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CurrenciesContainer } from "./styles";
import CurrencyModel from "./modal";
import { EmptyDataComponent } from "../../../components/common/empty";
import { TabHeader } from "../Styles";
import { StyledTable } from "../../../components/common/table/styles";
import CustomButton from "../../../components/common/button";
import { RootState } from "../../../redux/store";

interface BaseDataType {
  key: React.Key;
  currency: string;
  symbol: string;
}
interface AdditionalDataType {
  key: React.Key;
  currency: string;
  symbol: string;
  exchangeRate: string;
}

const BaseColumns: TableColumnsType<BaseDataType> = [
  {
    title: "Currency",
    dataIndex: "currency",
  },
  {
    title: "Symbol",
    dataIndex: "symbol",
  },
];

const AdditionalColumns: TableColumnsType<AdditionalDataType> = [
  {
    title: "Currency",
    dataIndex: "currency",
  },
  {
    dataIndex: "symbol",
  },
  {
    title: "Exchange Rate",
    dataIndex: "exchangeRate",
  },
];

const Currencies = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { AccountCurrency, currencies } = useSelector(
    (state: RootState) => state.adminCenter
  );

  const modalHandler = () => {
    setIsOpen(!isOpen);
  };

  const baseCurrencyDataSource = useMemo(
    () =>
      AccountCurrency.filter(
        ({ is_base }: { is_base: boolean }) => is_base
      ).map((bc: any) => ({
        key: bc?.currency_id,
        currency: bc.currency_code,
        symbol: `${bc.currency_name}-${bc.currency_symbol}`,
      })),
    [AccountCurrency]
  );

  const additionalCurrencyDataSource = useMemo(
    () =>
      AccountCurrency?.filter(
        ({ is_base }: { is_base: boolean }) => !is_base
      ).map((ac: any) => ({
        key: ac.currency_id,
        currency: ac.currency_code,
        symbol: `${ac.currency_name}-${ac.currency_symbol}`,
        exchangeRate: ac.exchange_rate,
      })),
    [AccountCurrency]
  );

  const AddCurrencyButton = () => {
    return (
      <CustomButton
        text="Add New Currency"
        icon={<PlusOutlined />}
        onClick={modalHandler}
        position="end"
      />
    );
  };

  return (
    <CurrenciesContainer>
      <div className="base-currency">
        <div style={{ marginBottom: "1rem" }}>
          <TabHeader>Base Currency</TabHeader>
        </div>
        <StyledTable
          className="table"
          pagination={false}
          columns={BaseColumns}
          dataSource={baseCurrencyDataSource}
          size="middle"
        />
      </div>
      <div>
        <div className="additional-header">
          <TabHeader>{`Additional Currencies (${additionalCurrencyDataSource.length})`}</TabHeader>
          <CustomButton
            text="Add New Currency"
            icon={<PlusOutlined />}
            onClick={modalHandler}
            position="end"
          />
        </div>
        {!additionalCurrencyDataSource.length ? (
          <EmptyDataComponent
            title="Add More Currencies"
            description="In addition to Base Currency, you can create price plans in multiple other currencies. To enable that, add an additional currency here."
            addButton={AddCurrencyButton}
          />
        ) : (
          <StyledTable
            className="table"
            pagination={false}
            columns={AdditionalColumns}
            dataSource={additionalCurrencyDataSource}
            size="middle"
          />
        )}
      </div>
      <CurrencyModel
        isOpen={isOpen}
        onClose={modalHandler}
        isBaseCurrency={false}
      />
    </CurrenciesContainer>
  );
};

export default Currencies;
