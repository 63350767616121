import {
  DownOutlined,
  EditFilled,
  PlusOutlined,
  UpOutlined,
} from "@ant-design/icons";
import CustomButton from "../../../components/common/button";
import Segment from "../../../components/common/segmented";
import { ExpandItem, SettingContent } from "./styles";
import { StyledTable } from "../../../components/common/table/styles";
import { EmptyData } from "../../../components/common/empty";
import { useState } from "react";
import { TableColumnsType, Tag } from "antd";
import { SettingTableType } from "../../admin-center/setting/table";
import NewSettingForm from "./NewSettingForm";
import { StyledTag } from "../../../components/common/tag/styles";

const tableData: any = [
  {
    name: "aaaa",
    id: "123456",
    namespace: "User",
    valueType: "String",
    value: 1234,
  },
  {
    name: "bbbb",
    id: "123457",
    namespace: "User",
    valueType: "String",
    value: 1234,
  },
];

const SettingDrawerContent: React.FC<{
  newSettingHandler: () => void;
  isNewSettingOn: boolean;
}> = ({ newSettingHandler, isNewSettingOn }) => {
  const [type, setType] = useState<"All" | "User" | "BillOptim">("All");
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const handleExpand = (record: any) => {
    setExpandedRowKeys((prevKeys) =>
      prevKeys.includes(record.id) ? [] : [record.id]
    );
  };

  const columns: TableColumnsType<SettingTableType> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Namespace",
      dataIndex: "namespace",
    },
    {
      dataIndex: "action",
      render: () => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <CustomButton
            text="Edit"
            icon={<EditFilled />}
            onClick={() => console.log("Edit clicked")}
            type="default"
          />
        </div>
      ),
    },
  ];

  return (
    <SettingContent>
      {!isNewSettingOn ? (
        <>
          <div className="header">
            <h2>Settings</h2>
            <div className="header-actions">
              <Segment
                options={["All", "User", "BillOptim"]}
                value={type}
                onChange={setType}
              />
              <CustomButton
                text="New Setting"
                icon={<PlusOutlined />}
                onClick={newSettingHandler}
              />
            </div>
          </div>

          {tableData.length > 0 ? (
            <StyledTable
              pagination={false}
              columns={columns}
              dataSource={tableData}
              size="middle"
              rowKey="id"
              expandable={{
                expandedRowRender: (record: any) => (
                  <ExpandItem>
                    <div className="item">
                      <p>Value Type</p>
                      <StyledTag color="blue">{record.valueType}</StyledTag>
                    </div>
                    <div className="item">
                      <p>Value</p>
                      <p className="value">{record.value}</p>
                    </div>
                  </ExpandItem>
                ),
                expandIcon: ({ expanded, onExpand, record }: any) =>
                  expanded ? (
                    <UpOutlined onClick={() => handleExpand(record)} />
                  ) : (
                    <DownOutlined onClick={() => handleExpand(record)} />
                  ),
                expandedRowKeys: expandedRowKeys,
              }}
            />
          ) : (
            <EmptyData description="Create your first Add On Setting" />
          )}
        </>
      ) : (
        <NewSettingForm toggleContent={newSettingHandler} />
      )}
    </SettingContent>
  );
};

export default SettingDrawerContent;
