import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserAvatar from "../../../components/common/avatar";
import { EmptyData } from "../../../components/common/empty";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  setIsNewCustomer,
  setFilter,
  setViewAccountDetails,
} from "../../../redux/feature/customer/customerSlice";
import { Container, UserNameContainer } from "./Styles";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import { render } from "@testing-library/react";
import { getAllCustomerAccounts } from "../../../redux/feature/customer/customerAsyncThunk";
import { getAttachPricePlan } from "../../../redux/feature/pricePlan/pricePlanThunk";
import FilterOption from "../../../components/common/filter/FilterOption";
import { StyledTable } from "../../../components/common/table/styles";
import SearchInput from "../../../components/common/search";
import { formatDateToLocalTime } from "../../../utils/helper";
import { emptyAttachPricePlan } from "../../../redux/feature/pricePlan/pricePlanSlice";
import CustomerHeader from "./CustomHeader";

type CustomerColumnsType = {
  key: React.Key;
  name: string;
  customer_id?: string;
  created_at: string;
};
type DataIndex = keyof CustomerColumnsType;

const Accounts = ({ currentTabKey }: { currentTabKey?: string }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState(searchTerm);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { customers, customerAccounts, filter, isApiCalling } = useSelector(
    (state: RootState) => state.customer
  );

  useEffect(() => {
    return () => {
      dispatch(setFilter("-updated_at"));
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => setSearchValue(searchTerm), 1000);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    if (currentTabKey === "2")
      dispatch(
        getAllCustomerAccounts({
          sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
          search: searchValue,
        })
      );
  }, [searchValue, filter, currentTabKey]);

  const columns = [
    {
      title: "Name",
      dataIndex: "accountName",
      render: (text: string, record: any) => (
        <UserNameContainer>
          <div className="textContainer">
            <div className="name">{record.account_name}</div>
            <div className="email">{record.billoptim_customer_account_id}</div>
          </div>
        </UserNameContainer>
      ),
    },
    {
      title: "Customer Details",
      dataIndex: "name",
      render: (text: string, record: any) => {
        const customerDetails = customers.find(
          (customer: any) => customer.customer_id === record?.customer_id
        );
        console.log("►►► ~ Accounts ~ customerDetails:", customerDetails);
        return (
          <UserNameContainer>
            <UserAvatar user={customerDetails} />
            <div className="textContainer">
              <div className="name">{customerDetails?.name}</div>
              <div className="email">{record?.billoptim_customer_id}</div>
            </div>
          </UserNameContainer>
        );
      },
    },
    {
      title: "Price Plan",
      dataIndex: "price_plan_name",
      render: (_: any, record: any) => {
        return (
          <Tooltip title={record?.price_plan_name}>
            <div
              style={{
                width: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {record?.price_plan_name ?? "-"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      render: (_: any, record: any) => {
        return <div>{formatDateToLocalTime(record?.created_at)}</div>;
      },
    },
  ];

  const filteredAccount = useMemo(() => {
    return customerAccounts.map((item: any) => item);
  }, [customerAccounts]);

  return (
    <div style={{ padding: "0.5rem 0px" }}>
      <CustomerHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        tooltipText="Account name"
        currentTabKey={currentTabKey}
      />
      {isApiCalling ? (
        <StyledTable
          pagination={false}
          columns={columns}
          loading={true}
          dataSource={[]}
          size="middle"
        />
      ) : filteredAccount.length > 0 ? (
        <StyledTable
          pagination={false}
          columns={columns}
          loading={isApiCalling}
          isCursorPointerShow
          dataSource={filteredAccount}
          size="middle"
          onRow={(record: any) => ({
            onClick: () => {
              dispatch(emptyAttachPricePlan());
              // dispatch(setViewAccountDetails(record));
              // dispatch(getAttachPricePlan(record?.customer_account_id));
              navigate(
                `/customers/${record?.customer_id}/accounts/${record?.customer_account_id}/details`
              );
            },
          })}
        />
      ) : (
        <EmptyData
          description="No Customer Added"
          btnText="New Customer"
          onClick={() => {
            dispatch(setIsNewCustomer(true));
          }}
        />
      )}
    </div>
  );
};

export default Accounts;
