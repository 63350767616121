import React, { useEffect, useMemo, useState } from "react";
import { LicenseFeeFormContainer } from "./Styles";
import AddonItem from "../AddonItem";
import { Form, Input, Radio, Select } from "antd";
import Extra from "../../../../../../components/common/extra";
import TextedSwitch from "../../../../../../components/common/textedSwitch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import UsageBasedAddPrice from "../usageBased/addPrice";
import { StyledRadio } from "../styles";
import { useSearchParams } from "react-router-dom";

const LicenseFee: React.FC<any> = ({
  addonValue,
  setAddonValue,
  editCardData,
  form,
  current,
  selectedAddon,
  setSelectedAddon,
  addonType,
  setAddonType,
}) => {
  console.log("🚀 ~ editCardData:", editCardData);
  const [licenseType, setLicenseType] = useState("");
  console.log("🚀 ~ licenseType:", licenseType);
  const [addonList, setAddonList] = useState<any>([]);
  const [defaultLicenseOpened, setDefaultLicenseOpened] = useState<any>(false);
  console.log("🚀 ~ defaultLicenseOpened:", defaultLicenseOpened);
  const [enableProration, setEnableProration] = useState(false);
  const [invoiceTiming, setInvoiceTiming] = useState("IN_ARREARS");
  const { midCyclePricing } = useSelector(
    (state: RootState) => state.pricePlan
  );
  const selectedAddonValue = useMemo(() => {
    return selectedAddon;
  }, [selectedAddon]);

  const type = useSearchParams()[0].get("plan-type");

  const { addOns } = useSelector((state: RootState) => state.billable);
  console.log("🚀 ~ addOns:", addOns);

  useEffect(() => {
    if (licenseType !== "LICENSE" || selectedAddonValue?.type !== "LICENSE") {
      form.resetFields([
        "invoiceTiming",
        "enableProration",
        "proratedRefundMode",
      ]);
    }
  }, [editCardData, licenseType, selectedAddonValue]);

  useEffect(() => {
    if (editCardData) {
      setDefaultLicenseOpened(!!editCardData.invoiceTiming);

      form.setFieldsValue({
        displayName: editCardData.displayName || "",
        license_quantity: editCardData.maxQuantity,
        invoiceTiming: editCardData.invoiceTiming || "IN_ARREARS",
        enableProration: editCardData.enableProration || false,
        proratedRefundMode: editCardData.proratedRefundMode || "NONE",
        usageCycle: editCardData.usageCycle || "monthly",
      });
      setLicenseType(editCardData.type || "");
      setSelectedAddon(editCardData.id || "");
      setEnableProration(editCardData.enableProration || false);
      setInvoiceTiming(editCardData.invoiceTiming || "IN_ARREARS");
    }
  }, [editCardData, form]);



  useEffect(() => {
    if (editCardData) {
      const id = form.getFieldValue("id");
      const data = addOns.find((addon: any) => addon.addOnId === id);
      if (data) {
        setAddonType(data?.type);
      }
    }
    return () => {
      setAddonType(null);
    };
  }, [form]);

  return current === 0 ? (
    <LicenseFeeFormContainer>
      <AddonItem
        editCardData={editCardData}
        addonValue={addonValue}
        setAddonValue={setAddonValue}
        isAddLicenseType
        setLicenseType={setLicenseType}
        setSelectedAddon={setSelectedAddon}
      />
      <Form.Item
        label="Rate Card Name"
        name="displayName"
        rules={[
          {
            required: true,
            message: "Please input the rate card name!",
          },
        ]}
      >
        <Input placeholder="Rate Card Name" />
      </Form.Item>
      {((licenseType === "LICENSE" && selectedAddonValue === "new") ||
        selectedAddonValue?.type === "LICENSE" ||
        defaultLicenseOpened) && (
        <>
          <Form.Item
            label="Maximum License Quantity"
            name="license_quantity"
            extra={
              <Extra text="Maximum number of licenses that can be purchased in the priceplan. If left blank, there will be no limit." />
            }
            rules={[
              {
                pattern: /^[1-9]\d*(\.\d+)?$/,
                message: "Please enter valid input.",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Maximum License Quantity"
              onWheel={(e: any) => e.target.blur()}
              min={1}
            />
          </Form.Item>
          <Form.Item
            label="Invoice Association"
            name="invoiceTiming"
            initialValue={type !== "RECURRING" ? "IN_ADVANCE" : "IN_ARREARS"}
            extra={
              <Extra text="License fee will be billed in the current invoice." />
            }
          >
            <StyledRadio
              defaultValue={"IN_ARREARS"}
              onChange={(e: any) => {
                setInvoiceTiming(e.target.value);
              }}
            >
              <Radio value="IN_ARREARS">In Arrears</Radio>
              <Radio value="IN_ADVANCE" disabled={midCyclePricing}>
                In Advance
              </Radio>
            </StyledRadio>
          </Form.Item>
          <Form.Item name="enableProration" valuePropName="checked">
            <TextedSwitch
              text="Proration of license fee"
              onChange={() => {
                setEnableProration(!enableProration);
              }}
            />
          </Form.Item>
          {enableProration && invoiceTiming === "IN_ADVANCE" && (
            <Form.Item
              label="Prorated refund options"
              name="proratedRefundMode"
              extra={
                <Extra text="This controls how your prorated refund is provided during the cycle." />
              }
            >
              <StyledRadio defaultValue="NONE">
                <Radio value="NONE">No Refund</Radio>
                <Radio value="CREDITS">Refund via Credits</Radio>
              </StyledRadio>
            </Form.Item>
          )}
        </>
      )}
      {(selectedAddonValue?.type === "NAMED_LICENSE" ||
        addonType === "NAMED_LICENSE") && (
        <Form.Item
          label="Usage Cycle"
          name="usageCycle"
          extra={
            <Extra text="This would be the aggregation window for the licenses within the pricing cycle." />
          }
        >
          <Select defaultValue={"monthly"}>
            <Select.Option value="monthly">Monthly</Select.Option>
            <Select.Option value="weekly">Weekly</Select.Option>
          </Select>
        </Form.Item>
      )}
    </LicenseFeeFormContainer>
  ) : (
    <UsageBasedAddPrice
      form={form}
      isGroupFieldShow={true}
      editCardData={editCardData}
    />
  );
};

export default LicenseFee;
