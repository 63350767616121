import { Card, Col, Form, Row, Select, Spin, TableColumnsType, Tooltip } from "antd";
import { PaymentContainer, WalletContainer } from "./styles";
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomButton from "../../../../components/common/button";
import SearchInput from "../../../../components/common/search";
import FilterDropdown from "../../../../components/common/filter";
import { StyledTable } from "../../../../components/common/table/styles";
import AddPaymentModal from "./modal/AddPaymentModal";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { setIsAddPaymentModalOpen } from "../../../../redux/feature/customer/customerSlice";
import { getPayment, getWallet } from "../../../../redux/feature/customer/customerAsyncThunk";
import { useNavigate, useParams } from "react-router-dom";
import PageLoading from "../../../../components/Loader";
import CustomDrawer from "../../../../components/common/drawer";
import { Typography, Table } from "antd";
import PaymentDetailsDrawerContent from "./PaymentDetailsDrawerContent";
import { formatDateToLocalTime } from "../../../../utils/helper";

const { Title, Text } = Typography;

interface WalletDataType {
  key: React.Key;
  paymentId: string;
  entity_id: string;
  totalAmount: string;
  closing_balance: string;
  createdAt: string;
}

const Wallet = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accountId, customerId } = useParams();
  const [searchTerm, setSearchTerm] = useState<any>();
  const [form] = Form.useForm();
  const [filterPayment, setFilterPayment] = useState({
    paymentId: "",
    statue: "",
  });
  const isFilterApplied = useMemo(() => {
    return !!(
      filterPayment.paymentId || filterPayment.statue
    );
  }, [filterPayment]);
  const queryParams = new URLSearchParams();
  console.log("►►► ~ Wal ~ filterPayment:", filterPayment);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const { isAddPaymentModalOpen, payments, loading ,wallet,viewAccountDetails} = useSelector(
    (state: RootState) => state.customer
  );

  const { AccountCurrency } = useSelector(
    (state: any) => state.adminCenter
  );
  const [openingBalance, setOpeningBalance] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (payments.length) {
      const lastClosingBalance = payments?.reduce(
        (acc, payment) => acc + Number(payment.totalAmount),
        0
      );
      setOpeningBalance(lastClosingBalance);
    }
  }, [payments]);



  const accountCurrencySymbol = useMemo(() => {
    return AccountCurrency?.find(
       ({ additional_currency_id }: { additional_currency_id: string }) =>
         additional_currency_id === viewAccountDetails.additional_currency_id
     );
   }, [AccountCurrency,viewAccountDetails]);


  const paymentDataSource = useMemo(() => {
    if (!payments) return []; 
    
    const paymentData= [...payments]?.sort((a: any, b: any) => {
      const dateA = new Date(a.createdAt).getTime(); 
      const dateB = new Date(b.createdAt).getTime(); 
      
      return dateA - dateB; 
    });

    return paymentData.reverse();
  }, [payments]);


  useEffect(() => {
    dispatch(getWallet(accountId))
      .then((res) => {
        console.log("Wallet data:", res);
        if (res.payload?.wallet_id)
          dispatch(getPayment({ walletId: res.payload?.wallet_id }));
      })
      .catch((e) => {
        console.log("Error fetching wallet data:", e);
      });
  }, [dispatch, accountId]); 

  useEffect(() => {
    if (wallet?.wallet_id)
      dispatch(getPayment({ walletId: wallet?.wallet_id, search: searchTerm }));
  }, [searchTerm]);
  
  const columns: TableColumnsType<WalletDataType> = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      render: (_, record) => {
        console.log("►►► ~ Wal ~ record:", record);
        return <div >{record.paymentId}</div>;
      },
    },
    {
      title: "Entity ID",
      dataIndex: "entity_id",
      render: (_, record) => {
        return <div>-</div>;
      },
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      render: (_, record:any) => {
        return <div style={{ color: record?.paymentMethod === 'CREDIT'?"green":'red' }}>{record?.paymentMethod === 'CREDIT'?"+":'-' } {accountCurrencySymbol?.currency_symbol} {record.totalAmount}</div>;
      },
    },
    {
      title: "Closing Balance",
      dataIndex: "closingBalance",
      render: (_, record: any) => {
        return <div>{accountCurrencySymbol?.currency_symbol} {record.closingBalance}</div>;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (_, record) => {
        return <div>{formatDateToLocalTime(record.createdAt)}</div>;
      },
    },
  ];

  const onSubmitFilter = async () => {
    try {
      const values = await form.validateFields();
      console.log("►►► ~ onSubmitFilter ~ values:", values);
      setFilterPayment({
        paymentId: values.entity_id,
        statue: values.transaction_type,
      });
      if (searchTerm) queryParams.set("__search__", searchTerm);
      if (values.transaction_type)
        queryParams.set("transaction_type", values.transaction_type);
      if (values.entity_id) queryParams.set("entity_id", values.entity_id);

      navigate(
        `/customers/${customerId}/accounts/${accountId}/wallet?${queryParams.toString()}`
      );
    } catch (error) {}
  };

  const resetFilter=()=>{
    form.resetFields();
  } 

  return (
    <WalletContainer>
      <div className="header">
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Card className="cardItem">
              <div className="title">
                <span>Total Balance</span>
                <Tooltip title="Total available balance">
                  <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                </Tooltip>
              </div>
              <div className="subTitle">
                <span className="green">{wallet?.balance}</span>
                <span>{wallet?.currency}</span>
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card className="cardItem">
              <div className="title">
                <span>Hold Amount</span>
                <Tooltip title="Amount going to be consumed by the ongoing invoices of current cycle">
                  <InfoCircleOutlined style={{ marginLeft: "8px" }} />
                </Tooltip>
              </div>
              <div className="subTitle">
                <span>{wallet?.hold_amount}</span>
                <span>{wallet?.currency}</span>
              </div>
            </Card>
          </Col>
        </Row>
        <CustomButton
          text="Add Payment"
          position="end"
          onClick={() => {
            dispatch(setIsAddPaymentModalOpen(true));
          }}
        />
      </div>
      <div className="tableHeader">
        <div className="detail">
          <span>Wallet ID</span>
          <p>{wallet?.wallet_id}</p>
        </div>
        <div className="actions">
          <SearchInput
            width="320px"
            onChange={(e) => {
              setSearchTerm(e?.target.value);
              if (e?.target.value)
                queryParams.set("__search__", e?.target.value);
              if (filterPayment.statue)
                queryParams.set("transaction_type", filterPayment.statue);
              if (filterPayment.paymentId)
                queryParams.set("entity_id", filterPayment.paymentId);
              navigate(
                `/customers/${customerId}/accounts/${accountId}/wallet?${queryParams.toString()}`
              );
            }}
            TooltipSupportedFields="Entity ID"
          />
          {isFilterApplied ? (
            <CustomButton
              text="Clear filter"
              type="link"
              icon={false}
              onClick={() => {
                form.resetFields();
                setFilterPayment({
                  paymentId: "",
                  statue: "",
                });
              }}
            />
          ) : (
            <></>
          )}
          <FilterDropdown onSubmitFilter={onSubmitFilter} isFilterApplied={isFilterApplied} resetFilter={resetFilter}>
            <Form layout="vertical" form={form}>
              <Form.Item className="type" name="transaction_type">
                <Select placeholder="Transaction Type">
                  <Select.Option value="CREDIT">Credit</Select.Option>
                  <Select.Option value="DEBIT">Debit</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="entity_id">
                <Select placeholder="Entity ID">
                  {payments?.map((data: any) => {
                    return (
                      <Select.Option value={data.paymentId}>
                        {data.paymentId}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </FilterDropdown>
        </div>
      </div>
      <StyledTable
        pagination={false}
        columns={columns}
        loading={loading}
        isCursorPointerShow
        dataSource={paymentDataSource}
        onRow={(record: any) => ({
          onClick: () => {
            setSelectedRow(record);
          },
        })}
      />
      <AddPaymentModal isOpen={isAddPaymentModalOpen} />
      <CustomDrawer
        title="Payment Details"
        isOpen={selectedRow}
        onClose={() => {
          setSelectedRow(null);
        }}
        isSubmitBtnShown={false}
        width={536}
      >
        <PaymentDetailsDrawerContent
          selectedRow={selectedRow}
          walletID={wallet?.wallet_id}
        />
      </CustomDrawer>
    </WalletContainer>
  );
};

export default Wallet;
