import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../api/apiClient";
import { message } from "antd";

export const getEvents = createAsyncThunk(
  "event/getEvents",
  async (filterData: any, { rejectWithValue }) => {
    const { search, sort, status } = filterData;
    try {
      const response = await api.get(
        `/get-event-schemas?sort=${sort}${search ? `&search=${search}` : ""}${
          status ? `&status=${status}` : ""
        }`
      );
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: authThunk.tsx:24 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createEvent = createAsyncThunk(
  "event/createEvent",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/create-event-schema", payload);
      return [response.data];
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);


export const updateEvent = createAsyncThunk(
  "event/updateEvent",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/update-event-schema", payload);
      return [response.data];
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getEvent = createAsyncThunk(
  "event/getEvent",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/get-event-schema?eventSchemaId=${payload}`
      );
      return response.data.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const changeEventStatus = createAsyncThunk(
  "event/changeEventStatus",
  async (payload: any, { rejectWithValue }) => {
    console.log("►►► ~ payload:", payload);
    try {
      let response;
      if (payload.checked || payload?.isActive) {
        response = await api.post(
          `/event_schema/${payload?.eventSchemaId}/activate`
        );
        message.success("event Schema Activate");
      } else {
        response = await api.post(
          `/event_schema/${payload?.eventSchemaId}/deactivate`
        );
        message.success("event Schema Deactivate");
      }
      console.log("►►► ~ response:", response);

      return {
        eventSchemaId: payload.eventSchemaId,
        status: response.data.status,
      };
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const archiveEventSchema = createAsyncThunk(
  "event/archiveEventSchema",
  async (schemaName: string, { rejectWithValue }) => {
    try {
     const  response = await api.delete( `/event_schema/${schemaName}`);
      message.success(response.data.message);
    } catch (error: any) {
      console.log("►►► ~ error:", error)
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createIngest = createAsyncThunk(
  "event/createIngest",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/ingest", payload);
      message.success("ingest created successfully");
      return [response.data];
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getIngest = createAsyncThunk(
  "event/getIngest",
  async (filterData: any, { rejectWithValue }) => {
    const {
      schema_name,
      sort,
      customer_account_id,
      status,
      timestamp_lte,
      timestamp_gte,
      event_schema_id,
    } = filterData;

    const params = new URLSearchParams({
      sort,
      ...(sort && { sort }),
      ...(schema_name && { schema_name }),
      ...(status && { status }),
      ...(customer_account_id && { customer_account_id}),
      ...(timestamp_lte && { timestamp_lte }),
      ...(timestamp_gte && { timestamp_gte }),
      ...(event_schema_id && { event_schema_id }),
    }).toString();

    try {
      const response = await api.get(`/events?${params}`);
      return response.data.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);
export const getIngestById = createAsyncThunk(
  "event/getIngestById",
  async (ingest_event_id: any, { rejectWithValue }) => {
    try {
      const response = await api.get(`/event/${ingest_event_id}`);
      return response.data.data[0];
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);
