import { Card } from "antd";
import styled from "styled-components";

export const DetailsCard = styled(Card)<{topBarColor?:string}>`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  .ant-card-body {
    padding: 0 !important;
    padding-top: 16px !important;
  }

  .topBar {
    background-color: ${(props)=>props.topBarColor || "#2853c2"};
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    position: absolute;
    right: 12px;
    left: 12px;
    top:0;
    height: 4px;
  }

  .item {
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    div {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: #64748b;
    }
    b {
      font-size: 14px;
      font-weight: 500;
    }
    .anticon {
      margin-left: 8px;
      color: black;
    }
  }

  .item:hover {
    background-color: #f8fafc;
  }

  .footerContent {
    padding: 24px !important;
    padding-top: 0 !important;;
    display: flex !important;
    flex-direction: column !important;;
    align-items: flex-start !important;;
    border-bottom-left-radius: 8px !important;;
    border-bottom-right-radius: 8px !important;;

    .divider {
      border-top: 2px dashed #cbd5e1 !important;
      margin: 12px 0px 16px 0px !important;
    }
    div {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: #475569;
    }
  }
`;
