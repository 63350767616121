import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../../utils/helper";
import { StyledCodeMirror } from "../../../pages/events/stream/eventDetails/jsonPayload/styles";
import { javascript } from "@codemirror/lang-javascript";
import { CodeEditorContainer } from "./styles";

type CodeEditorPropsType = {
  payload?: any;
  height?: string;
  onChange?: (value: any , viewUpdate:any) => void;
  editable?: boolean;
  isCopyShow?: boolean;
  title?: string;
};
const CodeEditor: React.FC<CodeEditorPropsType> = ({
  payload,
  height,
  onChange,
  editable = true,
  isCopyShow=false,
  title,
}) => {
  return (
    <CodeEditorContainer>
      <div className="editor-header">
        <span>{title}</span>
        {isCopyShow && (
          <span
            className="iconContainer"
            onClick={() => {
              copyToClipboard(JSON.stringify(payload, null, 2));
            }}
          >
            Copy{" "}
            <CopyOutlined
              style={{ height: "11px", width: "11px", marginLeft: "5px" }}
            />{" "}
          </span>
        )}
      </div>
      <StyledCodeMirror
        value={JSON.stringify(payload, null, 2)}
        height={height}
        extensions={[javascript({ jsx: true })]}
        onChange={onChange}
        editable={editable}
      />
    </CodeEditorContainer>
  );
};

export default CodeEditor;
